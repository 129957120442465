<template>
    <div
        class="relative flex flex-col items-center justify-between h-full px-4 py-1 text-white md:px-4 md:py-6 md:space-y-10"
    >
        <div
            class="flex flex-row items-center justify-between w-full md:flex-col md:space-y-10"
        >
            <current-reservation-widget></current-reservation-widget>
            <language-indicator></language-indicator>
            <navigation></navigation>
        </div>
        <div>
            <profile v-if="isLoggedIn"></profile>
        </div>
    </div>
</template>

<script>
import Navigation from "@guest/layout/Navigation";
import Topbar from "@guest/layout/Topbar";
import Profile from "@guest/layout/Profile";
import { mapGetters, mapState, mapActions } from "vuex";
import CurrentReservationWidget from "@guest/layout/CurrentReservationWidget";
import LanguageIndicator from "@guest/layout/LanguageIndicator";

export default {
    components: {
        Topbar,
        Navigation,
        Profile,
        CurrentReservationWidget,
        LanguageIndicator,
    },
    computed: {
        ...mapGetters({
            isLoggedIn: "isLoggedIn",
        }),
        ...mapState({
            locales: "locales",
        }),
    },
    methods: {
        ...mapActions({
            setLocale: "setLocale",
        }),
    },
};
</script>

<style></style>
