<template>
    <full-width-container v-if="currentStep" class="bg-r-green">
        <content-container padding="lg:py-4 lg:px-12">
            <stepper
                :current_step="currentStep"
                :stepperSteps="steps"
                max-width="max-w-lg"
            >
                {{ $t("reservations.edit.check_in") }}
                <template v-slot:end>
                    <router-link
                        class="hover:text-r-darkgreen font-normal not-italic text-base"
                        :to="{
                            name: 'bookings.edit.summary',
                            params: reservationQueryPayload,
                        }"
                    >
                        {{ $t("reservations.checkin.cancel_checkin") }}
                    </router-link>
                </template>
            </stepper>
        </content-container>
    </full-width-container>
</template>

<script>
import Logo from "@guest/layout/Logo";
import AppInput from "@guest/common/Input";
import AppButton from "@guest/common/Button";
import FullWidthContainer from "@guest/common/FullWidthContainer";
import ContentContainer from "@guest/common/ContentContainer";
import { mapActions, mapState } from "vuex";
import Stepper from "@guest/bookings/new/Stepper.vue";
import ReservationQueryPayload from "@mixins/ReservationQueryPayload";

export default {
    mixins: [ReservationQueryPayload],
    watch: {
        search: {
            handler: function (val) {
                console.log(val);
                this.$emit("input", val);
            },
            immediate: true,
        },
    },
    components: {
        Logo,
        AppInput,
        AppButton,
        FullWidthContainer,
        ContentContainer,
        Stepper,
    },
    computed: {
        ...mapState({
            user: "profile",
        }),
        currentStep() {
            console.log("current_route", this.$route.name);
            let current_step = this.steps.find(
                (step) => step.route === this.$route.name
            );
            if (current_step === undefined) {
                current_step = this.showAnywaySteps.find(
                    (step) => step.route === this.$route.name
                );
            }
            return current_step !== undefined ? current_step.step : null;
        },
    },
    methods: {
        ...mapActions({
            logout: "logout",
        }),
        clickEvent(event) {
            console.log("Click Event in CheckInBar", event);
        },
    },
    data: function () {
        return {
            showAnywaySteps: [
                {
                    step: 1,
                    icon: "documents",
                    route: "bookings.edit.checkin.statistics",
                    label: "reservations.checkin.heading_statistics",
                },
            ],
            steps: [
                {
                    step: 1,
                    icon: "user-no-circle",
                    route: "bookings.edit.checkin.name",
                    label: "reservations.checkin.heading_guest_data",
                },
                {
                    step: 2,
                    icon: "id",
                    route: "bookings.edit.checkin.identification",
                    label: "reservations.checkin.heading_identification",
                },
                {
                    step: 3,
                    icon: "signature",
                    route: "bookings.edit.checkin.signature",
                    label: "reservations.checkin.heading_signature",
                },
                {
                    step: 4,
                    icon: "key",
                    route: "bookings.edit.checkin.completed",
                    label: "reservations.checkin.heading_completed",
                },
            ],
        };
    },
    props: {
        search: {
            type: String,
            required: false,
            default: null,
        },
    },
};
</script>

<style></style>
