<template>
  <div>
      <layout-with-header>
          <template v-slot:title>
              {{$t('logout.heading')}}
          </template>
          <content-container>
              <div class="flex flex-col justify-center w-full text-xl space-y-4 lg:w-2/3">
                  <div>
                      {{$t('logout.lead')}}
                  </div>
                  <div>
                      {{$t('logout.your_roatel_team')}}
                  </div>
                  <div class="flex justify-center">
                      <app-button
                          size="lg"
                          color="red"
                          :outline="false"
                          @click="submit">
                          {{$t('logout.button_logout')}}
                      </app-button>
                  </div>
              </div>
          </content-container>
      </layout-with-header>
  </div>
</template>

<script>
import LayoutWithHeader from '@guest/layout/LayoutWithHeader'
import AppButton from '@admin/common/Button'
import AppAlert from '@admin/common/Alert'
import ContentContainer from '@admin/common/ContentContainer'
import {mapActions, mapGetters} from 'vuex'

export default {
    components: {
        LayoutWithHeader,
        ContentContainer,
        AppButton,
        AppAlert,
    },
    data: function() {
        return {
        }
    },
    computed: {
        ...mapGetters({
            isLoggedIn: 'isLoggedIn',
        }),
    },
    methods: {
        submit() {
            let temp = this.logout()
                .then(() => {
                    this.$router.push({name: 'home'})
                    console.log('Logout successful')
                })
                .catch(error => {
                    console.log('Logout failed', error)
                })
            console.log('Logout promise', temp)
        },
        ...mapActions({
            logout: 'logout',
        })
    },
    mounted() {
        if (!this.isLoggedIn) {
            this.$router.push({name: 'home'})
        }
    }
}
</script>

<style>

</style>
