<template>
    <div class="flex flex-col gap-10 lg:pr-8 pb-5 lg:pb-0">
        <template v-if="reservation.status == 'Confirmed'">
            <h2
                :class="[
                    checkInEnabled
                        ? 'text-center'
                        : 'lg:pr-8 text-center lg:text-left',
                ]"
                class="text-2xl italic font-black text-r-green"
            >
                <template v-if="checkInEnabled">
                    {{ $t("reservations.summary.check_in.can_heading") }}
                </template>
                <template v-else-if="checkInToday">
                    {{
                        $t("reservations.summary.check_in.today_heading", {
                            time: date(
                                reservation.earliest_check_in_time,
                                "time_localized"
                            ),
                        })
                    }}
                </template>
                <template v-else>
                    {{
                        $t("reservations.summary.check_in.cannot_heading", {
                            date: date(
                                reservation.earliest_check_in_time,
                                "date_localized"
                            ),
                            time: date(
                                reservation.earliest_check_in_time,
                                "time_localized"
                            ),
                        })
                    }}
                </template>
            </h2>
            <div>
                <div
                    :class="[
                        checkInEnabled
                            ? 'text-center'
                            : 'text-center lg:text-left',
                    ]"
                >
                    <span v-if="checkInEnabled">
                        {{ $t("reservations.summary.check_in.can_body") }}
                    </span>
                    <span v-else-if="checkInToday">
                        {{
                            $t("reservations.summary.check_in.today_body", {
                                time: date(
                                    reservation.earliest_check_in_time,
                                    "time_localized"
                                ),
                            })
                        }}
                    </span>
                    <span v-else>
                        {{
                            $t("reservations.summary.check_in.cannot_body", {
                                time: date(
                                    reservation.earliest_check_in_time,
                                    "time_localized"
                                ),
                            })
                        }}
                    </span>
                </div>
            </div>
            <div class="flex justify-center">
                <div>
                    <app-button
                        :disabled="!checkInEnabled"
                        @click="canCheckIn"
                        color="green"
                    >
                        {{ $t("reservations.edit.button_check_in") }}
                    </app-button>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import ClipContainer from "@guest/common/ClipContainer";
import AppButton from "@guest/common/Button";
import DayJS from "@mixins/DayJS";
import { mapActions, mapState, mapGetters } from "vuex";

export default {
    mixins: [DayJS],
    components: {
        ClipContainer,
        AppButton,
    },
    props: {
        reservation: {
            required: true,
            type: Object,
        },
        reservationQueryPayload: {
            required: true,
        },
    },
    computed: {
        checkInToday() {
            return this.dayjs(
                this.reservation.earliest_check_in_time
            ).isToday();
        },
        // checkIn() {
        //     return this.dayjs().isAfter(this.reservation.arrival);
        // },
        checkInEnabled() {
            return (
                this.reservation.status == "Confirmed" &&
                this.dayjs().isAfter(this.reservation.earliest_check_in_time)
            );
        },
        ...mapState({
            locales: "locales",
        }),
        ...mapGetters({
            locale: "currentLocale",
        }),
    },
    methods: {
        setLanguage(lang) {
            this.setLocale(lang);
        },
        ...mapActions({
            // getUserReservation: "getUserReservation",
            getUserReservationCanCheckIn: "getUserReservationCanCheckIn",
            // setReturnToPath: "setReturnToPath",
            setLocale: "setLocale",
            // putUserReservationCancel: "putUserReservationCancel",
        }),
        canCheckIn() {
            console.log("can check in");
            this.getUserReservationCanCheckIn(this.reservationQueryPayload)
                .then((response) => {
                    this.$router.push({
                        name: "bookings.edit.checkin.name",
                        params: this.reservationQueryPayload,
                    });
                })
                .catch((error) => {
                    console.log(error.response);
                });
        },
    },
};
</script>

<style></style>
