<template>
    <div class="min-h-screen bg-white" id="top" v-if="locale">
        <div v-if="initialized" class="flex flex-col min-h-screen">
            <div class="w-full flex justify-center py-4">
                <topbar
                    @locale="$refs.localeSelector.$refs.locale_selector.show()"
                ></topbar>
            </div>
            <login-bar v-if="showLoginBar"></login-bar>
            <!-- <div class="w-full flex justify-center" v-if="showUserBar"> -->
            <logged-in-user-bar v-if="showUserBar"></logged-in-user-bar>
            <!-- </div> -->
            <div class="w-full flex justify-center" v-if="showCheckInBar">
                <check-in-bar></check-in-bar>
            </div>
            <div class="w-full bg-white pb-10">
                <main>
                    <transition
                        name="temper"
                        mode="out-in"
                        enter-active-class="transition-all duration-150 ease-in-out"
                        leave-active-class="transition-all duration-150 ease-in-out"
                        enter-class="opacity-0 scale-75 transform -translate-x-48"
                        enter-to-class="opacity-100 scale-100"
                        leave-class="opacity-100 scale-100"
                        leave-to-class="opacity-0 scale-75 transform translate-x-48"
                    >
                        <router-view @checkInStep="checkin_step = $event">
                        </router-view>
                    </transition>
                </main>
            </div>
            <!-- <footer-image></footer-image> -->
            <footer-menu></footer-menu>
            <!-- <footer-social></footer-social> -->
            <footer-imprint></footer-imprint>
        </div>
        <CookieConsent></CookieConsent>
        <LocaleSelector ref="localeSelector"></LocaleSelector>
        <Loading></Loading>
        <SlideInNavigation></SlideInNavigation>
    </div>
</template>

<script>
import AppHeader from "@guest/layout/Header";
import Navigation from "@guest/layout/Navigation";
import Sidebar from "@guest/layout/Sidebar";
import Topbar from "@guest/layout/Topbar";
import FindRoatelBar from "@guest/layout/FindRoatelBar";
import Loading from "@guest/layout/Loading";
import { mapActions, mapState, mapGetters } from "vuex";
import CookieConsent from "@guest/CookieConsent";
import SlideInNavigation from "@guest/layout/SlideInNavigation";
import LoggedInUserBar from "@guest/layout/LoggedInUserBar";
import CheckInBar from "@guest/layout/CheckInBar";
import FooterImage from "@guest/layout/FooterImage.vue";
import FooterMenu from "@guest/layout/FooterMenu.vue";
import FooterSocial from "@guest/layout/FooterSocial.vue";
import FooterImprint from "@guest/layout/FooterImprint.vue";
import LocaleSelector from "@guest/LocaleSelector";
import LoginBar from "@guest/layout/LoginBar";

export default {
    components: {
        AppHeader,
        CookieConsent,
        Navigation,
        Sidebar,
        Topbar,
        Topbar,
        Loading,
        FindRoatelBar,
        SlideInNavigation,
        LoggedInUserBar,
        CheckInBar,
        FooterImage,
        FooterMenu,
        FooterSocial,
        FooterImprint,
        LocaleSelector,
        LoginBar,
    },
    data: function () {
        return {
            profileIntervalId: null,
        };
    },
    computed: {
        ...mapState({
            initialized: (state) => state.initialized,
            locales: "locales",
        }),
        ...mapGetters({
            isLoggedIn: "isLoggedIn",
            locale: "currentLocale",
            profile: "profile",
        }),
        showUserBar() {
            console.log(
                "showUserBar",
                this.$route,
                this.$route.name?.substring(0, 12)
            );
            return (
                this.isLoggedIn &&
                this.$route.name !== "home" &&
                this.$route.name.substring(0, 12) !== "bookings.new" &&
                this.$route.name.substring(0, 21) !== "bookings.edit.checkin"
            );
            // bookings.new
        },
        showCheckInBar() {
            console.log("CheckInBar", this.$route.name?.substring(0, 21));
            return (
                this.$route.name.substring(0, 21) === "bookings.edit.checkin"
            );
            // bookings.new
        },
        showLoginBar() {
            console.log("LoginBar", this.$route.name?.substring(0, 21));
            return (
                [
                    "login",
                    "register",
                    "forgot-password",
                    "reset-password",
                ].includes(this.$route.name) && !this.isLoggedIn
            );
            // bookings.new
        },
    },
    watch: {
        isLoggedIn() {
            if (this.isLoggedIn) {
                console.log("profile");
                if (this.profileIntervalId === null) {
                    this.profileIntervalId = setInterval(
                        function () {
                            this.getProfile({ loading: false });
                            console.log("checking session");
                        }.bind(this),
                        300000
                    );
                    console.log("setting interval", this.profileIntervalId);
                }
            } else {
                console.log("clearing interval", this.profileIntervalId);
                if (this.profileIntervalId !== null) {
                    clearInterval(this.profileIntervalId);
                }
            }
        },
    },
    methods: {
        async storeInit() {
            await this.getProfile();
        },
        ...mapActions({
            getProfile: "getProfile",
            initStoreFromLocalStorage: "initStoreFromLocalStorage",
            setLocale: "setLocale",
        }),
        setLanguage(lang) {
            this.setLocale(lang);
        },
        //async getProfile() {
        //await axios.get('profile')
        //.then(response => {
        //console.log(response)
        //})
        //.catch(error => {
        //console.log(error.response)
        //})
        //}
    },
    async mounted() {
        this.initStoreFromLocalStorage();
        await this.storeInit();
        //this.getProfile()
    },
};
</script>

<style></style>
