<template>
    <div class="flex flex-col gap-5">
        <div
            class="bg-white rounded-lg border border-r-gray-light p-5 lg:p-10 grid flex-shrink"
        >
            <check-in-balance-widget
                v-if="
                    hasCheckinBalanceError &&
                    ['CheckedOut'].includes(reservation.status) === false
                "
                :reservation="reservation"
                :reservationQueryPayload="reservationQueryPayload"
            >
            </check-in-balance-widget>
            <div
                :class="[checkInEnabled ? 'grid-cols-1' : 'lg:grid-cols-2']"
                class="grid lg:divide-x divide-x-0 divide-y lg:divide-y-0 divide-r-gray-light"
            >
                <check-in-widget
                    v-if="
                        reservation.status === 'Confirmed' &&
                        !isCheckingIn &&
                        hasCheckinBalanceError === false
                    "
                    :reservation="reservation"
                    :reservationQueryPayload="reservationQueryPayload"
                >
                </check-in-widget>
                <pre-check-in-widget
                    v-if="
                        reservation.status === 'Confirmed' &&
                        !isCheckingIn &&
                        hasCheckinBalanceError === false
                    "
                    :reservation="reservation"
                >
                </pre-check-in-widget>
            </div>
            <checking-in-widget
                v-if="isCheckingIn"
                :reservation="reservation"
                :reservationQueryPayload="reservationQueryPayload"
            >
            </checking-in-widget>
            <room-widget
                v-if="
                    reservation.status === 'InHouse' &&
                    hasCheckinBalanceError === false
                "
                @open="openLock"
                :reservation="reservation"
            ></room-widget>
            <booking-completed-widget
                v-if="
                    ['CheckedOut', 'Canceled', 'NoShow'].includes(
                        reservation.status
                    )
                "
                :reservationQueryPayload="reservationQueryPayload"
                :reservation="reservation"
            ></booking-completed-widget>
        </div>
        <div class="grid lg:grid-cols-2 gap-5">
            <div v-if="reservation.property.wifi_ssid">
                <div
                    class="bg-white border border-r-gray-light rounded-lg p-5 lg:p-8"
                >
                    <div
                        class="italic font-black text-r-green text-xl lg:text-2xl flex flex-row items-center justify-between mb-8"
                    >
                        <div>
                            {{ $t("reservations.summary.wifi.heading") }}
                        </div>
                        <div>
                            <icon
                                icon="wifi"
                                class="w-7 h-7 stroke-r-green"
                            ></icon>
                        </div>
                    </div>
                    <div class="grid grid-cols-2 gap-3 font-bold">
                        <div class="grid gap-1">
                            <div class="text-r-gray-medium">
                                {{ $t("reservations.summary.wifi.ssid") }}
                            </div>
                            <div>
                                {{ reservation.property.wifi_ssid }}
                            </div>
                        </div>
                        <div class="grid gap-1">
                            <div class="text-r-gray-medium">
                                {{ $t("reservations.summary.wifi.password") }}
                            </div>
                            <div>
                                {{ reservation.property.wifi_password }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div
                    class="bg-white border border-r-gray-light rounded-lg p-5 lg:p-8 grid gap-5"
                >
                    <div
                        class="italic font-black text-r-green text-xl lg:text-2xl flex flex-row items-center justify-between"
                    >
                        <div>
                            {{ $t("reservations.summary.contact.heading") }}
                        </div>
                        <div>
                            <icon
                                icon="questionmark"
                                class="w-7 h-7 stroke-r-green"
                            ></icon>
                        </div>
                    </div>
                    <div class="text-xs font-bold">
                        {{
                            $t("reservations.summary.contact.service_times", {
                                start: "09:00",
                                end: "23:00",
                            })
                        }}
                    </div>

                    <div class="grid gap-3">
                        <a
                            v-for="contact in contact_types"
                            :key="contact.label"
                            :href="contact.href"
                            class="bg-r-gray-ultralight p-3 border rounded-lg border-r-gray-medium flex flex-row gap-5 items-center font-bold"
                        >
                            <div>
                                <icon
                                    :icon="contact.icon"
                                    class="w-10 h-10 stroke-r-black"
                                ></icon>
                            </div>
                            <div class="grid">
                                <div class="text-r-gray-medium">
                                    {{ $t(contact.label) }}
                                </div>
                                <div>{{ contact.content }}</div>
                            </div>
                        </a>
                        <div
                            @click.stop="$emit('cancel')"
                            v-if="['Confirmed'].includes(reservation.status)"
                            class="bg-r-gray-ultralight p-3 border rounded-lg border-r-gray-medium flex flex-row gap-5 items-center font-bold cursor-pointer"
                        >
                            <div>
                                <icon
                                    icon="bed-off"
                                    class="w-10 h-10 stroke-r-black"
                                ></icon>
                            </div>
                            <div class="grid">
                                <div>
                                    {{
                                        $t(
                                            "reservations.summary.button_reservation_cancel"
                                        )
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ContentContainer from "@guest/common/ContentContainer.vue";
import FullWidthContainer from "@guest/common/FullWidthContainer";
import ClipContainer from "@guest/common/ClipContainer";
import DayJS from "@mixins/DayJS";
import Currency from "@mixins/Currency";
import AppButton from "@guest/common/Button";
import { mapActions, mapGetters, mapState } from "vuex";
import OpenLock from "@mixins/OpenLock";
import Alert from "@guest/common/Alert";
import PropertyCard from "@guest/bookings/new/PropertyCard.vue";
import BookingSummarySidebar from "@guest/bookings/edit/BookingSummarySidebar.vue";
import BookingBackLink from "@guest/common/BookingBackLink.vue";
import CheckInWidget from "@guest/bookings/edit/CheckInWidget";
import CheckInBalanceWidget from "@guest/bookings/edit/CheckInBalanceWidget";
import PreCheckInWidget from "@guest/bookings/edit/PreCheckInWidget";
import CheckingInWidget from "@guest/bookings/edit/CheckingInWidget";
import BookingCompletedWidget from "@guest/bookings/edit/BookingCompletedWidget";
import RoomWidget from "@guest/bookings/edit/RoomWidget";
import Icon from "@guest/common/Icon";

export default {
    mixins: [DayJS, Currency, OpenLock],
    components: {
        Icon,
        CheckInWidget,
        PreCheckInWidget,
        BookingCompletedWidget,
        FullWidthContainer,
        Alert,
        ContentContainer,
        AppButton,
        ClipContainer,
        PropertyCard,
        BookingSummarySidebar,
        BookingBackLink,
        CheckingInWidget,
        RoomWidget,
        CheckInBalanceWidget,
    },
    data: function () {
        return {
            contact_types: [
                {
                    icon: "phone",
                    label: "reservations.summary.contact.germany",
                    content: "0800 - 80 111 08",
                    href: "tel:08008011108",
                },
                {
                    icon: "phone",
                    label: "reservations.summary.contact.international",
                    content: "+49 211 21092000",
                    href: "tel:+4921121092000",
                },
                {
                    icon: "mail",
                    label: "models.account.email",
                    content: "support@roatel.com",
                    href: "mailto:support@roatel.com",
                },
            ],
        };
    },
    methods: {
        openPropertyInMaps() {
            let url =
                "https://www.google.com/maps/dir/?api=1&destination=" +
                this.reservation.property.lat +
                "," +
                this.reservation.property.lng;
            window.open(url, "_blank").focus();
            console.log(url);
        },
        openAssetInNewWindow(invoice) {
            let url =
                process.env.MIX_ASSET_URL +
                "/assets/guest/reservations/invoice/";
            if (this.reservationQueryPayload.secret !== null) {
                url += "key/";
            } else {
                url += "user/";
            }
            url += this.reservation.uuid + "/" + invoice.uuid;
            if (this.reservationQueryPayload.secret !== null) {
                url += "/" + this.reservationQueryPayload.secret;
            }
            window.open(url, "_blank").focus();
            console.log(url);
        },
        ...mapActions({
            putUserReservationCancel: "putUserReservationCancel",
        }),
        reservationCancel() {
            console.log("cancel", this.reservation);
            this.putUserReservationCancel(this.reservationQueryPayload);
        },
    },
    computed: {
        hasCheckinBalanceError() {
            return (
                this.reservation.balance_amount < 0 &&
                this.reservation.has_balance_checkin_overwrite === false
            );
        },
        isCancellationFree() {
            console.log(
                "isCancellationFreer",
                this.reservation.cancellation_fee
            );
            return this.dayjs().isSameOrBefore(
                this.reservation.cancellation_fee.dueDateTime
            );
        },
        ...mapState({
            isCheckingIn: "isCheckingIn",
        }),
        ...mapGetters({
            isLoggedIn: "isLoggedIn",
        }),
        isBookerChangeable() {
            return (
                this.reservation.status === "Confirmed" ||
                this.reservation.status === "InHouse"
            );
        },
        checkInEnabled() {
            return (
                this.reservation.status == "Confirmed" &&
                this.dayjs().isAfter(this.reservation.earliest_check_in_time)
            );
        },
    },
    props: {
        reservation: {
            required: true,
            type: Object,
        },
        isAwaitingReservationUpdate: {
            required: true,
            type: Boolean,
        },
    },
    mounted() {
        this.$scrollTo("#top", 300);
    },
};
</script>

<style></style>
