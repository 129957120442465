var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.booking),expression:"booking"}]},[_c('full-width-container',{staticClass:"bg-r-green"},[_c('content-container',{attrs:{"padding":"lg:py-4 lg:px-12 py-3"}},[_c('stepper',{attrs:{"current_step":_vm.current_step}},[_vm._v("\n                "+_vm._s(_vm.$t("navigation.footer.book_roatel"))+"\n            ")])],1)],1),_vm._v(" "),_c('full-width-container',{staticClass:"bg-r-gray-ultralight"},[_c('content-container',[_c('div',{staticClass:"flex flex-col lg:flex-row gap-8"},[_c('div',{staticClass:"w-full lg:w-1/3 flex flex-col gap-10"},[_c('new-booking-summary-sidebar',{attrs:{"property":_vm.property,"booking":_vm.booking,"show-dates":true,"show-room-count":true,"show-total-amount":true,"show-information":true,"show-imprint-link":true,"show-guests":true,"show-booker":true,"show-open-in-map":true,"show-address":true,"show-carousel":true,"swiper-toggle-visible":true,"swiper-initial-status":false},scopedSlots:_vm._u([{key:"before",fn:function(){return [_c('router-link',{staticClass:"group inline-flex gap-3 text-r-gray items-center",attrs:{"to":{
                                    name: 'bookings.new.booker',
                                }}},[_c('icon',{staticClass:"stroke-r-gray w-6 h-6 group-hover:stroke-r-green",attrs:{"icon":"arrow-narrow-left"}}),_vm._v(" "),_c('span',{staticClass:"group-hover:text-r-green leading-none"},[_vm._v(_vm._s(_vm.$t("new_reservation.booker.heading")))])],1)]},proxy:true}])})],1),_vm._v(" "),_c('div',{staticClass:"w-full lg:w-2/3 flex flex-col gap-10",attrs:{"id":"payment_container"}},[_c('div',[_c('div',{staticClass:"bg-white lg:px-8 lg:py-16 px-3 py-2 rounded-lg border border-r-gray-light grid gap-8"},[_c('h2',{staticClass:"text-xl lg:text-2xl italic pb-4 font-black text-center text-r-green py-7"},[_vm._v("\n                                "+_vm._s(_vm.$t("new_reservation.payment.heading"))+"\n                            ")]),_vm._v(" "),(_vm.payment_refused)?_c('alert',{staticClass:"mb-6"},[_vm._v("\n                                "+_vm._s(_vm.$t(
                                        "new_reservation.payment.payment_refused"
                                    ))+"\n                            ")]):_vm._e(),_vm._v(" "),(_vm.put_booking_errors.length > 0)?_c('alert',{staticClass:"mb-6"},_vm._l((_vm.put_booking_errors),function(put_booking_error){return _c('div',[_vm._v("\n                                    "+_vm._s(put_booking_error)+"\n                                ")])}),0):_vm._e(),_vm._v(" "),(_vm.payment_error)?_c('alert',{staticClass:"mb-6"},[_vm._v("\n                                "+_vm._s(_vm.$t(
                                        "new_reservation.payment.payment_error"
                                    ))+"\n                            ")]):_vm._e(),_vm._v(" "),(_vm.payment_starting)?_c('div',{staticClass:"mb-6 flex flex-row text-lg space-x-3 text-center p-4 text-white"},[_c('div',[_c('font-awesome-icon',{attrs:{"icon":"spinner","spin":""}})],1),_vm._v(" "),_c('div',[_vm._v("\n                                    "+_vm._s(_vm.$t(
                                            "new_reservation.payment.payment_starting"
                                        ))+"\n                                ")])]):_vm._e(),_vm._v(" "),(_vm.payment_pending)?_c('div',{staticClass:"mb-6 flex flex-row text-lg space-x-3 text-center p-4 text-white"},[_c('div',[_c('font-awesome-icon',{attrs:{"icon":"spinner","spin":""}})],1),_vm._v(" "),_c('div',[_vm._v("\n                                    "+_vm._s(_vm.$t(
                                            "new_reservation.payment.payment_pending"
                                        ))+"\n                                ")])]):_vm._e(),_vm._v(" "),_c('div',{ref:"adyen",attrs:{"id":"dropin-container"}}),_vm._v(" "),_c('div',{staticClass:"flex justify-center py-4"},[(
                                        !_vm.start_payment &&
                                        _vm.hasPaymentPending === false &&
                                        !_vm.payment_starting
                                    )?_c('app-button',{attrs:{"color":"green","size":"xl"},on:{"click":_vm.startPayment}},[_vm._v(_vm._s(_vm.$t(
                                            "new_reservation.payment.button_start_payment"
                                        )))]):_vm._e()],1)],1)])])]),_vm._v(" "),_c('property-imprint-modal',{ref:"propertyImprintModal"})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }