<template>
    <property-card
        v-if="reservation.property"
        :show-imprint-link="true"
        :property="reservation.property"
        :show-address="true"
        :show-open-in-map="true"
        borderClass="border border-r-gray-light"
    >
        <template v-slot:top>
            <slot name="top"></slot>
        </template>
        <template v-slot:after>
            <div class="grid gap-5">
                <div
                    class="border-r-gray-light border rounded-lg grid grid-cols-2 divide-x divide-r-gray-light"
                >
                    <div class="p-3 grid gap-2">
                        <div class="text-r-gray-medium">
                            {{ $t("reservations.card.booking_number") }}
                        </div>
                        <div>
                            <div class="font-bold">
                                {{ reservation.apaleo_id }}
                            </div>
                        </div>
                    </div>
                    <div class="p-3 grid gap-2">
                        <div class="text-r-gray-medium">
                            {{ $t("reservations.card.status") }}
                        </div>
                        <div>
                            <div>
                                <reservation-status
                                    :status="reservation.status"
                                ></reservation-status>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="border-r-gray-light border rounded-lg grid grid-cols-2 divide-x divide-r-gray-light"
                >
                    <div class="p-3 grid gap-2">
                        <div class="text-r-gray-medium">
                            {{ $t("new_reservation.dates.check_in_label") }}
                        </div>
                        <div>
                            <div>
                                {{
                                    date(reservation.arrival, "date_localized")
                                }}
                            </div>
                            <div>
                                {{
                                    $t("common.checkin_time", {
                                        time: date(
                                            reservation.arrival,
                                            "time_localized"
                                        ),
                                    })
                                }}
                            </div>
                        </div>
                    </div>
                    <div class="p-3 grid gap-2">
                        <div class="text-r-gray-medium">
                            {{ $t("new_reservation.dates.check_out_label") }}
                        </div>
                        <div>
                            <div>
                                {{
                                    date(
                                        reservation.departure,
                                        "date_localized"
                                    )
                                }}
                            </div>
                            <div>
                                {{
                                    $t("common.checkout_time", {
                                        time: date(
                                            reservation.departure,
                                            "time_localized"
                                        ),
                                    })
                                }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="border-r-gray-light border rounded-lg grid">
                    <div class="p-3 grid gap-2">
                        <div
                            class="text-r-gray-medium flex flex-row items-center justify-between"
                        >
                            <div class="select-none">
                                {{
                                    $tc(
                                        "new_reservation.booking_summary.guest.guest_list_heading",
                                        1
                                    )
                                }}
                            </div>
                        </div>
                        <div>
                            <div>
                                {{ reservation.primary_guest.first_name }}
                                {{ reservation.primary_guest.last_name }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="border-r-gray-light border rounded-lg grid">
                    <div class="p-3 grid gap-2">
                        <div
                            class="text-r-gray-medium flex flex-row items-center justify-between"
                        >
                            <div>
                                {{ $t("new_reservation.booker.heading") }}
                            </div>
                            <div class="stroke-r-black hover:stroke-r-green">
                                <router-link
                                    v-if="
                                        $route.name !==
                                            'bookings.edit.booker' &&
                                        [
                                            'Confirmed',
                                            'InHouse',
                                            'CheckedOut',
                                            'NoShow',
                                            'Canceled',
                                        ].includes(reservation.status)
                                    "
                                    :to="{
                                        name: 'bookings.edit.booker',
                                        params: reservationQueryPayload,
                                    }"
                                >
                                    <icon icon="edit" class="w-6 h-6"></icon>
                                </router-link>
                            </div>
                        </div>
                        <div>
                            <div v-for="line in bookerAddress">
                                {{ line }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="grid gap-2 py-5">
                    <div class="text-r-green text-3xl font-bold text-center">
                        {{ currency(payment_amount, payment_currency) }}
                    </div>
                    <div
                        class="text-r-gray-medium flex flex-row items-center gap-1 justify-center"
                    >
                        <span>
                            {{
                                $t(
                                    "new_reservation.booking_summary.total_amount.total_amount_label"
                                )
                            }}
                        </span>

                        <span>
                            {{
                                $tc(
                                    "new_reservation.booking_summary.total_amount.total_nights",
                                    reservation.nights
                                )
                            }}
                        </span>
                    </div>
                </div>
                <div class="grid gap-2 py-5 border-t border-r-gray-light">
                    <div class="font-bold text-sm">
                        {{
                            $t("new_reservation.offers.offer_card.information")
                        }}
                    </div>

                    <div class="text-xs flex flex-col">
                        <div class="inline-flex gap-2 items-center">
                            <icon
                                icon="check-circle"
                                :class="informationIconClass"
                            ></icon>
                            <span>
                                {{
                                    $t(
                                        "apaleo.cancellationFee." +
                                            reservation.cancellation_fee.code +
                                            ".description"
                                    )
                                }}
                            </span>
                        </div>
                        <div class="inline-flex gap-2 items-center">
                            <icon
                                :class="informationIconClass"
                                icon="check-circle"
                            >
                            </icon>
                            <span>
                                {{
                                    $t(
                                        "new_reservation.offers.offer_card.price_information"
                                    )
                                }}
                            </span>
                        </div>
                        <div
                            class="inline-flex gap-2 items-center"
                            v-if="reservation.has_city_tax"
                        >
                            <icon
                                :class="informationIconClass"
                                icon="check-circle"
                            >
                            </icon>
                            <span>
                                {{
                                    $t(
                                        "new_reservation.payment.including_city_tax",
                                        {
                                            amount: currency(
                                                reservation.city_tax_amount,
                                                reservation.balance_currency
                                            ),
                                        }
                                    )
                                }}
                            </span>
                        </div>
                        <!-- <div -->
                        <!--     v-if=" -->
                        <!--         reservation.acl.full_access &#38;&#38; -->
                        <!--         reservation.status == 'Confirmed' -->
                        <!--     " -->
                        <!--     class="flex justify-start pt-3 col-span-2" -->
                        <!-- > -->
                        <!--     <app-button -->
                        <!--         :disabled="isAwaitingReservationUpdate" -->
                        <!--         class="text-left" -->
                        <!--         color="gray" -->
                        <!--         @click="$emit('cancel', reservation)" -->
                        <!--     > -->
                        <!--         {{ -->
                        <!--             $t( -->
                        <!--                 "reservations.summary.button_reservation_cancel" -->
                        <!--             ) -->
                        <!--         }} -->
                        <!--     </app-button> -->
                        <!-- </div> -->
                    </div>
                </div>
            </div>
        </template>
    </property-card>
</template>
<script>
import ContentContainer from "@guest/common/ContentContainer";
import FullWidthContainer from "@guest/common/FullWidthContainer.vue";
import PropertyCard from "@guest/PropertyCard.vue";
import DayJS from "@mixins/DayJS";
import Currency from "@mixins/Currency";
import Icon from "@guest/common/Icon";
import ReservationStatus from "@guest/bookings/ReservationStatus";
import ReservationQueryPayload from "@mixins/ReservationQueryPayload";
import AppButton from "@guest/common/Button";

export default {
    mixins: [DayJS, Currency, ReservationQueryPayload],
    components: {
        ContentContainer,
        FullWidthContainer,
        PropertyCard,
        Icon,
        AppButton,
        ReservationStatus,
    },
    data: function () {
        return {};
    },
    computed: {
        payment_amount() {
            return (
                this.reservation.total_gross_amount +
                this.reservation.city_tax_amount
            );
        },
        payment_currency() {
            return this.reservation.total_gross_currency;
        },
        bookerAddress() {
            let address = [];
            if (this.reservation.booker.company_name) {
                address.push(this.reservation.booker.company_name);
            }
            if (this.reservation.booker.first_name) {
                address.push(
                    this.reservation.booker.first_name +
                        " " +
                        this.reservation.booker.last_name
                );
            }
            address.push(this.reservation.booker.address_line1);
            let city = [];
            if (this.reservation.booker.country_code.toLowerCase() !== "de") {
                city.push(this.reservation.booker.country_code);
            }
            city.push(this.reservation.booker.postal_code);
            city.push(this.reservation.booker.city);
            address.push(city.join(" "));
            if (this.reservation.booker.company_tax_id) {
                address.push(this.reservation.booker.company_tax_id);
            }
            return address;
        },
    },
    props: {
        reservation: {
            required: true,
            type: Object,
        },
        informationIconClass: {
            required: false,
            default: "w-5 h-6 stroke-r-black",
        },
        isAwaitingReservationUpdate: {
            required: false,
            default: false,
        },
    },
    mounted() {},
};
</script>
