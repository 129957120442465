<template>
    <div>
        <full-width-container class="bg-r-gray-ultralight">
            <title-bar>
                {{ $t("login.lead") }}
            </title-bar>
        </full-width-container>
        <full-width-container class="bg-r-gray-ultralight">
            <content-container
                class="flex justify-center px-5 pb-5"
                :default-padding="false"
            >
                <div
                    class="w-full md:w-[595px] bg-white p-5 lg:p-16 rounded-lg border border-r-gray-medium grid gap-12"
                >
                    <div class="grid gap-4">
                        <h2
                            class="font-sans font-black italic text-r-green text-2xl"
                        >
                            {{ $t("login.heading") }}
                        </h2>
                        <p>
                            {{ $t("login.body") }}
                        </p>
                    </div>
                    <div class="grid gap-4">
                        <app-input
                            :required="true"
                            :label="$t('models.account.email')"
                            :placeholder="
                                $t('models.account.email_placeholder')
                            "
                            v-model="user.email"
                            :errors="errors"
                            name="email"
                        >
                        </app-input>
                        <app-input
                            :required="true"
                            type="password"
                            :label="$t('models.account.password')"
                            :placeholder="
                                $t('models.account.password_placeholder')
                            "
                            v-model="user.password"
                            :errors="errors"
                            name="password"
                        >
                        </app-input>
                        <div class="text-sm text-r-gray-medium">
                            <router-link
                                class="underline"
                                :to="{ name: 'forgot-password' }"
                            >
                                {{ $t("login.forgot_password") }}
                            </router-link>
                        </div>
                    </div>

                    <div class="flex justify-center">
                        <div>
                            <app-button
                                @click="submit"
                                color="green"
                                :outline="false"
                                size="lg"
                            >
                                {{ $t("new_reservation.login.button_login") }}
                            </app-button>
                        </div>
                    </div>
                </div>
            </content-container>
        </full-width-container>
        <full-width-container class="bg-r-gray-ultralight pb-24">
            <content-container padding="p-0">
                <div class="flex justify-center">
                    <div class="gap-2 text-sm">
                        <router-link
                            :to="{ name: 'register' }"
                            class="inline-flex items-center gap-5"
                        >
                            <span>
                                {{ $t("login.register") }}
                            </span>
                            <icon
                                class="w-6 h-6 stroke-r-black"
                                icon="chevron-right"
                            ></icon>
                        </router-link>
                    </div>
                </div>
            </content-container>
        </full-width-container>
    </div>
</template>

<script>
import AppInput from "@guest/common/Input";
import AppButton from "@guest/common/Button";
import Alert from "@guest/common/Alert";
import { mapActions, mapGetters, mapState } from "vuex";
import ClipContainer from "@guest/common/ClipContainer";
import ContentContainer from "@guest/common/ContentContainer";
import FullWidthContainer from "@guest/common/FullWidthContainer";
import RequiredFieldInfo from "@guest/common/RequiredFieldInfo";
import Icon from "@guest/common/Icon";
import TitleBar from "@guest/common/TitleBar";

export default {
    components: {
        AppInput,
        AppButton,
        Alert,
        ClipContainer,
        ContentContainer,
        FullWidthContainer,
        RequiredFieldInfo,
        Icon,
        TitleBar,
    },
    data: function () {
        return {
            errors: {},
            user: {
                email: "",
                password: "",
            },
            error: null,
        };
    },
    methods: {
        submit() {
            this.error = null;
            let temp = this.login(this.user)
                .then((state) => {
                    let next_path = { name: "home" };
                    if (this.return_to_path !== null) {
                        //reset return to path
                        next_path = this.return_to_path.fullPath;
                        this.setReturnToPath(null);
                    }
                    this.$router.push(next_path);
                })
                .catch((error) => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    }
                    this.error = this.$t("login.error");
                });
            console.log("Promise", temp);
        },
        ...mapActions({
            login: "login",
            setReturnToPath: "setReturnToPath",
        }),
    },
    computed: {
        ...mapGetters({
            isLoggedIn: "isLoggedIn",
        }),
        ...mapState({
            return_to_path: (state) => state.return_to_path,
        }),
    },
    mounted() {
        console.log("mounting login");
        console.log("is logged in", this.isLoggedIn);
        if (this.isLoggedIn) {
            this.$router.push({ name: "profile" });
        }
    },
};
</script>

<style></style>
