<template>
    <div class="w-full relative cutter-small">
        <slot>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sed
            expedita necessitatibus a dolores quibusdam cupiditate deleniti
            error, ea dolore animi, rem libero beatae repellat aut explicabo
            perspiciatis officiis sunt consequatur?
        </slot>
        <div
            v-if="disabled"
            class="inset-0 absolute bg-white opacity-50 cursor-not-allowed"
            @click="clickDisabled"
        ></div>
    </div>
</template>

<script>
export default {
    computed: {
        colors() {
            return [];
        },
        cutter() {
            return this.$refs.cutter;
        },
        cutPolygon() {
            // if (!this.$refs.hasOwnProperty("cutter")) {
            //     return "";
            // }
            let size = this.cutter;
            if (size === undefined) {
                size = this.cutSize;
            }
            console.log("offset", this);
            console.log("offset cutter", size);
            let polygon =
                "clip-path: polygon(calc(0% + " +
                // "calc(100w/5)" +
                this.cutSize +
                "px) 0%, 100% 0%, 100% calc(100% - " +
                // "calc(100w/5)" +
                this.cutSize +
                "px), calc(100% - " +
                // "calc(100w/5)" +
                this.cutSize +
                "px) 100%, 0% 100%, 0% calc(0% + " +
                // "calc(100w/5)" +
                this.cutSize +
                "px))";
            console.log("cutPolygon", polygon);
            return polygon;
        },
    },
    methods: {
        clickDisabled() {
            console.log("Click Disabled Container");
        },
    },
    props: {
        cutSize: {
            type: String,
            required: false,
            default: "25",
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>

<style></style>
