//import {mapActions} from 'vuex'

export default {
    computed: {
        uuid() {
            return this.$route.params.uuid
        },
        secret() {
            return this.$route.params.secret ?? null
        },
        reservationQueryPayload() {
            return {
                uuid: this.uuid,
                secret: this.secret,
            }
        },
    },
}
