<template>
    <div>
        <full-width-container class="bg-r-green">
            <content-container padding="lg:py-4 lg:px-12 py-3">
                <stepper :current_step="current_step">
                    {{ $t("navigation.footer.book_roatel") }}
                </stepper>
            </content-container>
        </full-width-container>
        <booking-property-label
            :property="property"
            back-route-name="bookings.new.search_properties"
        ></booking-property-label>
        <full-width-container class="bg-r-gray-ultralight pb-20">
            <content-container padding="px-3 lg:px-12 py-4 lg:py-0">
                <div class="flex flex-col md:flex-row gap-5 lg:gap-8">
                    <!-- property card and image {{{ -->
                    <div class="w-full lg:w-1/3 flex flex-col gap-5 lg:gap-10">
                        <new-booking-summary-sidebar
                            :property="property"
                            :booking="booking"
                            :show-imprint-link="true"
                            :show-address="true"
                            :show-carousel="true"
                            :show-checkin-until="true"
                            :show-open-in-map="true"
                            :swiper-toggle-visible="true"
                            :swiper-initial-status="false"
                        >
                        </new-booking-summary-sidebar>
                    </div>
                    <!-- }}} -->
                    <div
                        class="w-full lg:w-2/3 flex flex-col gap-5 lg:gap-10 text-r-black"
                        id="datescontainer"
                    >
                        <!-- Dates {{{ -->
                        <div
                            class="relative bg-white border border-r-gray-light rounded-xl text-r-black"
                        >
                            <div class="relative lg:p-20 px-5 py-8">
                                <h2
                                    class="font-sans text-2xl lg:text-3xl italic pb-4 font-black text-r-green text-center"
                                >
                                    {{ $t("new_reservation.dates.heading") }}
                                </h2>
                                <div
                                    class="grid grid-cols-1 gap-5 lg:gap-10 lg:px-20 text-r-black"
                                >
                                    <div class="flex flex-col space-y-4">
                                        <app-input
                                            :required="true"
                                            name="checkIn"
                                            v-model="checkIn"
                                            ref="dp"
                                            type="datepicker"
                                            :errors="errors"
                                            :locale="currentLocale.key"
                                            :datepicker-min-date="
                                                dayjs()
                                                    .startOf('day')
                                                    .add(0, 'day')
                                                    .toDate()
                                            "
                                            :placeholder="
                                                $t(
                                                    'new_reservation.dates.check_in_placeholder'
                                                )
                                            "
                                            :label="
                                                $t(
                                                    'new_reservation.dates.check_in_label_with_time'
                                                )
                                            "
                                        >
                                        </app-input>
                                        <app-input
                                            type="datepicker"
                                            v-model="checkOut"
                                            :required="true"
                                            :locale="currentLocale.key"
                                            name="checkOut"
                                            :errors="errors"
                                            :datepicker-min-date="
                                                dayjs(checkIn)
                                                    .startOf('day')
                                                    .add(1, 'day')
                                                    .toDate()
                                            "
                                            :placeholder="
                                                $t(
                                                    'new_reservation.dates.check_out_placeholder'
                                                )
                                            "
                                            :label="
                                                $t(
                                                    'new_reservation.dates.check_out_label_with_time'
                                                )
                                            "
                                        >
                                        </app-input>
                                        <div
                                            v-if="!showCodeInput"
                                            class="underline text-sm cursor-pointer text-r-gray-light"
                                            @click="
                                                showCodeInput = !showCodeInput
                                            "
                                        >
                                            {{
                                                $t(
                                                    "new_reservation.dates.code_text"
                                                )
                                            }}
                                        </div>
                                        <app-input
                                            v-if="showCodeInput"
                                            type="text"
                                            v-model="code"
                                            :required="false"
                                            :locale="currentLocale.key"
                                            name="code"
                                            :placeholder="
                                                $t(
                                                    'new_reservation.dates.code_placeholder'
                                                )
                                            "
                                            :label="
                                                $t(
                                                    'new_reservation.dates.code_label'
                                                )
                                            "
                                        >
                                        </app-input>
                                        <div class="mt-5">
                                            <alert v-if="error" type="error">
                                                {{ error }}
                                            </alert>
                                        </div>
                                    </div>
                                    <!-- purposes {{{ -->
                                    <div>
                                        <app-input
                                            type="onlyValue"
                                            name="purpose"
                                            :required="true"
                                            :label="
                                                $t(
                                                    'new_reservation.dates.purpose_label'
                                                )
                                            "
                                        >
                                            <div class="grid grid-cols-2 gap-3">
                                                <div
                                                    v-for="purpose in purposes"
                                                    :key="purpose.uuid"
                                                    @click="
                                                        setBookingDraftPurpose(
                                                            purpose
                                                        )
                                                    "
                                                    :class="[
                                                        {
                                                            'opacity-100':
                                                                booking.travel_purpose_id ===
                                                                purpose.id,

                                                            'opacity-50':
                                                                booking.travel_purpose_id !==
                                                                purpose.id,
                                                        },
                                                    ]"
                                                    class="rounded-lg text-white truncate cursor-pointer px-2 py-3 text-center font-black bg-r-darkgreen transition font-sans duration-150"
                                                >
                                                    {{
                                                        $t(
                                                            "new_reservation.purposes." +
                                                                purpose.trans_label
                                                        )
                                                    }}
                                                </div>
                                            </div>
                                        </app-input>
                                        <alert v-if="travel_purpose_error">{{
                                            $t(
                                                "new_reservation.dates.purpose_error"
                                            )
                                        }}</alert>
                                    </div>
                                    <!-- }}} -->
                                    <div
                                        v-if="
                                            errors.hasOwnProperty('checkIn') ||
                                            errors.hasOwnProperty('checkOut')
                                        "
                                    >
                                        <alert>{{
                                            $t("common.form_error")
                                        }}</alert>
                                    </div>
                                    <div class="flex justify-center">
                                        <div class="w-full lg:flex">
                                            <app-button
                                                @click="getOffers"
                                                color="green"
                                            >
                                                {{
                                                    $t(
                                                        "new_reservation.dates.button_search"
                                                    )
                                                }}
                                            </app-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- }}} -->
                        <!-- offer cards {{{ -->
                        <div
                            :class="[
                                localStep > 1
                                    ? 'border-r-gray-medium'
                                    : 'border-r-gray-light',
                            ]"
                            class="relative bg-white border rounded-xl text-r-black"
                            id="offers"
                        >
                            <div class="grid gap-5" v-if="localStep > 1">
                                <offer-card
                                    v-for="(offer, offer_index) in offers"
                                    :key="offer_index"
                                    :selected="booking.offer"
                                    :property="property"
                                    @select="submit($event)"
                                    @continue="submit"
                                    :rooms="booking.rooms"
                                    :offer="offer"
                                >
                                </offer-card>
                            </div>
                            <offer-card
                                v-if="localStep == 1"
                                :dummy="localStep == 1"
                                :selected="selected"
                                :offer="dummyOffer"
                                :property="property"
                                :rooms="4"
                            >
                            </offer-card>
                        </div>
                        <!-- }}} -->
                        <div id="login" v-if="!isLoggedIn">
                            <login
                                @services-list="$emit('services-list')"
                                @booker="$emit('summary')"
                                :dummy="3 !== localStep"
                            >
                            </login>
                        </div>
                    </div>
                </div>
                <!-- // }}} -->
                <property-imprint-modal ref="propertyImprintModal">
                </property-imprint-modal>
            </content-container>
        </full-width-container>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import ContentContainer from "@guest/common/ContentContainer";
import FullWidthContainer from "@guest/common/FullWidthContainer.vue";
import AppInput from "@guest/common/Input";
import AppButton from "@guest/common/Button";
import DayJS from "@mixins/DayJS";
import BookingDraftState from "@guest/bookings/new/State";
import Stepper from "@guest/bookings/new/Stepper";
import ClipContainer from "@guest/common/ClipContainer";
import PropertyCard from "@guest/bookings/new/PropertyCard.vue";
import OfferCard from "@guest/bookings/new/OfferCard";
import Login from "@guest/bookings/new/Login";
import Alert from "@guest/common/Alert";
import BookingPropertyLabel from "@guest/common/BookingPropertyLabel";
import BookingBackLink from "@guest/common/BookingBackLink";
import PropertyImageCard from "@guest/bookings/new/PropertyImageCard.vue";
import PropertyImprintModal from "@guest/common/PropertyImprintModal.vue";
import PropertyImageCardNew from "@guest/bookings/new/PropertyImageCardNew.vue";
import NewBookingSummarySidebar from "@guest/common/NewBookingSummarySidebar.vue";

// var VueScrollTo = require("vue-scrollto");

export default {
    mixins: [DayJS],
    components: {
        PropertyImageCard,
        Alert,
        Login,
        FullWidthContainer,
        ContentContainer,
        AppInput,
        AppButton,
        BookingDraftState,
        Stepper,
        ClipContainer,
        PropertyCard,
        OfferCard,
        BookingBackLink,
        PropertyImprintModal,
        PropertyImageCardNew,
        BookingPropertyLabel,
        NewBookingSummarySidebar,
    },
    data: function () {
        return {
            showCheckOut: true,
            showCodeInput: false,
            nights: 1,
            checkIn: this.dayjs().startOf("day").toDate(),
            checkOut: "",
            // offers: [],
            error: null,
            errors: {},
            purposes: [],
            travel_purpose_error: false,
            code: "",
            // purposes: [
            //     {
            //         label: "business",
            //         key: "Business",
            //     },
            //     {
            //         label: "leisure",
            //         key: "Leisure",
            //     },
            //     {
            //         label: "business_trucker",
            //         key: "Business",
            //         purpose_label: "",
            //     },
            //     {
            //         label: "business_craftsman",
            //         key: "Business",
            //     },
            // ],
            rooms: 1,
            selected: {},
            dummyOffer: {
                availableUnits: 4,
                roatel: {
                    unit_group: {
                        name_trans: "Einzelzimmer",
                        description_trans: "Einzelzimmer",
                    },
                },
                cancellationFee: {
                    code: "XXX",
                },
                totalGrossAmount: {
                    amount: 49,
                    currency: "EUR",
                },
            },
            localSteps: {
                1: "datescontainer",
                2: "offers",
                3: "login",
            },
            localStep: 1,
        };
    },
    watch: {
        localStep: {
            handler: function (val, old) {
                if (val !== old) {
                    console.log("Scrolling to localStep", val);
                    this.$scrollTo("#" + this.localSteps[val], 500);
                }
            },
        },
        rooms: {
            handler: function () {
                console.log("Change rooms");
                this.setBookingDraftRooms(this.rooms);
            },
        },
        checkIn: {
            handler: function (val) {
                console.log("change checkIn Date", val);
                //this.dates.checkIn = this.dayjs(val).hour(14).toDate()
                this.localStep = 1;
                if (this.showCheckOut === true) {
                    console.log("Current Checkout Date", this.checkOut);
                    if (
                        this.dayjs(val).isSameOrAfter(this.dayjs(this.checkOut))
                    ) {
                        this.checkOut = this.dayjs(val)
                            .add(1, "day")
                            .hour(12)
                            .toDate();
                        console.log("have to change checkout date");
                    }
                }
                this.setCheckOut();
            },
        },
        code: {
            handler: function (val) {
                this.localStep = 1;
            },
        },
        checkOut: {
            handler: function (val) {
                this.localStep = 1;
                console.log("change checkOut Date", val);
                if (this.showCheckOut === true) {
                    if (
                        this.dayjs(val).isSameOrBefore(this.dayjs(this.checkIn))
                    ) {
                        this.checkIn = this.dayjs(val)
                            .subtract(1, "day")
                            .hour(14)
                            .toDate();
                        console.log("have to change checkin date");
                    }
                }
                console.log("current value of checkout", this.checkOut);
                this.setCheckOut();
            },
        },
        nights: {
            handler: function () {
                this.setCheckOut();
            },
            immediate: true,
        },
    },
    methods: {
        showCheckOutDatepicker() {
            this.showCheckOut = !this.showCheckOut;
        },
        setCheckOut() {
            console.log("setCheckOut()", this.showCheckOut);
            if (this.checkOut == "") {
                this.checkOut = this.dayjs(this.checkIn)
                    .add(this.nights, "day")
                    .format();
                return;
            }
            let checkIn = this.dayjs(this.checkIn);
            let checkOut = this.dayjs(this.checkOut);
            this.nights = checkOut.diff(checkIn, "days");
            console.log("Datediff", checkOut.diff(checkIn, "days"));
        },
        ...mapActions({
            setBookingDraftDates: "setBookingDraftDates",
            getBookingDraftOffers: "getBookingDraftOffers",
            setBookingDraftPurpose: "setBookingDraftPurpose",
            setBookingDraftOffer: "setBookingDraftOffer",
            setBookingDraftRooms: "setBookingDraftRooms",
            setBookingDraftProperty: "setBookingDraftProperty",
            getBookingDraftTravelPurposes: "getBookingDraftTravelPurposes",
        }),
        submit({ offer, rooms }) {
            this.setBookingDraftOffer(offer);
            this.setBookingDraftRooms(rooms);
            this.localStep = 3;
            // this.$emit("summary");
            if (this.isLoggedIn) {
                this.$emit("summary");
            }
        },
        async getOffers() {
            this.travel_purpose_error = false;
            this.errors = {};
            if (this.booking.travel_purpose_id === null) {
                this.travel_purpose_error = true;
                return;
            }
            this.localStep = 1;
            this.error = null;
            this.setBookingDraftDates({
                checkIn: this.dayjs(this.checkIn).format(),
                checkOut: this.checkOut,
                nights: this.nights,
                code: this.code,
            });
            await this.getBookingDraftOffers()
                .then((offers) => {
                    console.log("found offers", offers);
                    if (offers.data.data.length > 0) {
                        this.localStep = 2;
                        // this.$emit("offers-list");
                        return;
                    }
                    this.error = this.$t(
                        "new_reservation.dates.offers_not_found"
                    );
                })
                .catch((error) => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    }
                    console.log("getOffers Error", error.response);
                });
        },
        async setTravelPurposes() {
            this.purposes = this.getBookingDraftTravelPurposes().then(
                (response) => {
                    this.purposes = response.data.data;
                }
            );
        },
        async setPropertyFromUrl() {
            console.log("setPropertyFromUrl", this.$route);
            if (
                this.$route.params.hasOwnProperty("property") &&
                this.$route.params.property
            ) {
                const property = await axios
                    .get(
                        "bookings/get_property_by_id/" +
                            this.$route.params.property
                    )
                    .then((response) => {
                        this.setBookingDraftProperty(response.data.data);
                    })
                    .catch((error) => {
                        console.error("property not found", error);
                        this.$router.push({
                            name: "bookings.new.search_properties",
                        });
                    });
                await this.setDatesFromUrl();
            }
        },
        async setDatesFromUrl() {
            console.log("setDatesFromUrl", this.$route);
            if (
                this.$route.params.hasOwnProperty("checkin") &&
                this.dayjs(this.$route.params.checkin).isValid() &&
                this.$route.params.hasOwnProperty("checkout") &&
                this.dayjs(this.$route.params.checkout).isValid()
            ) {
                if (
                    this.dayjs(this.$route.params.checkin).isSameOrBefore(
                        this.dayjs(this.$route.params.checkout)
                    )
                ) {
                    this.checkIn = this.dayjs(this.$route.params.checkin)
                        .startOf("day")
                        .toDate();
                    this.checkOut = this.dayjs(this.$route.params.checkout)
                        .startOf("day")
                        .toDate();
                    // this.getOffers();
                }
            }
        },
    },
    computed: {
        hasTravelPurposeId() {
            return this.booking.travel_purpose_id === null;
        },
        submitDisabled() {
            return this.checkIn == "" ? true : false;
        },
        ...mapState({
            property: (state) => state.bookingDraft.property,
            booking: (state) => state.bookingDraft,
            dates: (state) => state.bookingDraft.dates,
            offers: (state) => state.bookingDraft.offers,
        }),
        ...mapGetters({
            currentLocale: "currentLocale",
            isLoggedIn: "isLoggedIn",
        }),
    },
    props: {
        current_step: {
            required: false,
            default: 2,
        },
    },
    mounted() {
        this.setTravelPurposes();
        this.$emit("step", 2);
        console.log("Booking Offers on mount", this.offers);
        // if (this.offers.length > 0) {
        //     this.localStep = 2;
        // } else {
        // }
        this.setPropertyFromUrl();
        if (this.booking.dates.code !== null) {
            this.code = this.booking.dates.code;
            this.showCodeInput = true;
        }
        this.$nextTick(() => {
            this.$scrollTo("#datescontainer", 500);
        });
        // this.setDatesFromUrl();
    },
};
</script>

<style></style>
