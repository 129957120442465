<template>
    <div>
        <div v-if="hasLabel" class="mb-2">
            <input-label
                :required="true"
                id="birth_date"
                label-classes="text-r-green tracking-wide text-sm font-bold"
            >
                {{ $t("models.guest.birth_date") }}
            </input-label>
        </div>
        <div class="flex flex-row space-x-2">
            <!-- :label="$t('models.guest.birthdate')" -->
            <app-input
                :required="false"
                ref="input_day"
                name="day"
                type="selectClassic"
                v-model="day"
                :label="$t('models.guest.day')"
                :selectOptions="days"
                selectLabelProperty="label"
                selectValueProperty="key"
                placeholder="Day of birth"
                :has-label="true"
                :errors="customErrors"
                :loading="loading"
                select-nullable-label="lala"
            >
            </app-input>
            <app-input
                :required="false"
                name="month"
                type="selectClassic"
                :label="$t('models.guest.month')"
                v-model="month"
                :selectOptions="months"
                selectLabelProperty="label"
                selectValueProperty="key"
                placeholder="Month of birth"
                :has-label="true"
                :errors="customErrors"
                :loading="loading"
            >
            </app-input>
            <app-input
                :required="false"
                name="month"
                type="selectClassic"
                v-model="year"
                :label="$t('models.guest.year')"
                :selectOptions="years"
                selectLabelProperty="label"
                selectValueProperty="key"
                placeholder="Year of birth"
                :errors="customErrors"
                :has-label="true"
                :loading="loading"
            >
            </app-input>
        </div>
        <div
            v-if="hasErrors"
            class="flex flex-row gap-1 mt-3 text-r-red items-start text-sm"
        >
            <icon class="stroke-r-red w-5 h-5" icon="alert"></icon>
            <div class="gap-1">
                <div
                    v-for="(error, index) in errors[name]"
                    :key="index"
                    class="flex flex-col"
                >
                    {{ error }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import AppInput from "@guest/common/Input";
import InputLabel from "@guest/common/InputLabel.vue";
import Icon from "@guest/common/Icon";
import _ from "lodash";

// const _ = require("lodash");

export default {
    components: {
        AppInput,
        InputLabel,
        Icon,
    },
    data: function () {
        return {
            day: null,
            month: null,
            year: null,
            date: null,
        };
    },
    mounted() {
        console.log("mounted birthdate");
    },
    watch: {
        birthdate: function (newValue, oldValue) {
            console.log("watcher birthdate", newValue, oldValue);
            if (this.birthdate === null) {
                return null;
            }
            this.month = _.split(this.birthdate, "-")[1];
            this.day = _.split(this.birthdate, "-")[2];
            this.year = _.split(this.birthdate, "-")[0];
        },
        day: function () {
            this.checkDateValue();
        },
        month: function () {
            this.checkDateValue();
        },
        year: function () {
            this.checkDateValue();
        },
    },
    methods: {
        checkDateValue() {
            console.log("checking date", this.day, this.month, this.year);
            this.date = null;
            if (
                this.day === null ||
                this.month === null ||
                this.year === null
            ) {
                this.date = null;
                return;
            }
            console.log("current date value", this.date);
            const date = this.dayjs(
                this.year + "-" + this.month + "-" + this.day,
                "YYYY-MM-DD"
            );
            console.log("current state", this.year, this.month, this.day);
            console.log("date", date);
            if (date.isValid()) {
                this.date = date.format("YYYY-MM-DD");
                this.day = date.format("DD");
                this.month = date.format("MM");
                this.year = date.format("YYYY");
                this.$emit("date", this.date);
                return;
            }
        },
    },
    computed: {
        days() {
            return Array.from(Array(31)).map((v, i) => {
                return {
                    key: _.padStart(i + 1, 2, "0"),
                    label: i + 1,
                };
            });
        },
        hasErrors() {
            return this.errors.hasOwnProperty(this.name);
        },
        customErrors() {
            if (!this.hasErrors) {
                return {};
            }
            return {
                day: [],
                month: [],
                year: [],
            };
        },
        months() {
            this.dayjs.locale(this.locale);
            return Array.from(this.dayjs.months()).map((v, i) => {
                return {
                    key: _.padStart(i + 1, 2, "0"),
                    label: v,
                };
            });
        },
        years() {
            let years = [];
            let end = this.dayjs().subtract(10, "year").year();
            let start = this.dayjs().subtract(120, "year").year();
            for (let i = start; i <= end; i++) {
                years.push(
                    { key: i, label: i }
                    // { key: i, label: i }
                );
            }
            return years;
        },
    },
    props: {
        birthdate: {
            required: true,
        },
        name: {
            required: true,
        },
        label: {
            required: true,
        },
        errors: {
            required: true,
        },
        locale: {
            required: true,
        },
        loading: {
            required: true,
        },
        hasLabel: {
            required: false,
            default: true,
        },
    },
};
</script>
<style></style>
