<template>
    <div class="md:text-base text-sm" :class="[align]">
        <span class="font-bold text-white">*</span>
        {{ $t("common.required_fields_label") }}
    </div>
</template>

<script>
export default {
    props: {
        align: {
            required: false,
            default: "text-right",
        },
    },
};
</script>

<style></style>
