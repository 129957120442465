<template>
    <div
        :class="[defaultPadding ? padding : '']"
        class="w-full md:max-w-[1440px] items-center"
    >
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        defaultPadding: {
            required: false,
            type: Boolean,
            default: true,
        },
        padding: {
            required: false,
            type: String,
            default: "px-3 lg:px-12 py-4 lg:py-[50px]",
        },
    },
};
</script>

<style></style>
