var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.reservation.property)?_c('property-card',{attrs:{"show-imprint-link":true,"property":_vm.reservation.property,"show-address":true,"show-open-in-map":true,"borderClass":"border border-r-gray-light"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_vm._t("top")]},proxy:true},{key:"after",fn:function(){return [_c('div',{staticClass:"grid gap-5"},[_c('div',{staticClass:"border-r-gray-light border rounded-lg grid grid-cols-2 divide-x divide-r-gray-light"},[_c('div',{staticClass:"p-3 grid gap-2"},[_c('div',{staticClass:"text-r-gray-medium"},[_vm._v("\n                        "+_vm._s(_vm.$t("reservations.card.booking_number"))+"\n                    ")]),_vm._v(" "),_c('div',[_c('div',{staticClass:"font-bold"},[_vm._v("\n                            "+_vm._s(_vm.reservation.apaleo_id)+"\n                        ")])])]),_vm._v(" "),_c('div',{staticClass:"p-3 grid gap-2"},[_c('div',{staticClass:"text-r-gray-medium"},[_vm._v("\n                        "+_vm._s(_vm.$t("reservations.card.status"))+"\n                    ")]),_vm._v(" "),_c('div',[_c('div',[_c('reservation-status',{attrs:{"status":_vm.reservation.status}})],1)])])]),_vm._v(" "),_c('div',{staticClass:"border-r-gray-light border rounded-lg grid grid-cols-2 divide-x divide-r-gray-light"},[_c('div',{staticClass:"p-3 grid gap-2"},[_c('div',{staticClass:"text-r-gray-medium"},[_vm._v("\n                        "+_vm._s(_vm.$t("new_reservation.dates.check_in_label"))+"\n                    ")]),_vm._v(" "),_c('div',[_c('div',[_vm._v("\n                            "+_vm._s(_vm.date(_vm.reservation.arrival, "date_localized"))+"\n                        ")]),_vm._v(" "),_c('div',[_vm._v("\n                            "+_vm._s(_vm.$t("common.checkin_time", {
                                    time: _vm.date(
                                        _vm.reservation.arrival,
                                        "time_localized"
                                    ),
                                }))+"\n                        ")])])]),_vm._v(" "),_c('div',{staticClass:"p-3 grid gap-2"},[_c('div',{staticClass:"text-r-gray-medium"},[_vm._v("\n                        "+_vm._s(_vm.$t("new_reservation.dates.check_out_label"))+"\n                    ")]),_vm._v(" "),_c('div',[_c('div',[_vm._v("\n                            "+_vm._s(_vm.date(
                                    _vm.reservation.departure,
                                    "date_localized"
                                ))+"\n                        ")]),_vm._v(" "),_c('div',[_vm._v("\n                            "+_vm._s(_vm.$t("common.checkout_time", {
                                    time: _vm.date(
                                        _vm.reservation.departure,
                                        "time_localized"
                                    ),
                                }))+"\n                        ")])])])]),_vm._v(" "),_c('div',{staticClass:"border-r-gray-light border rounded-lg grid"},[_c('div',{staticClass:"p-3 grid gap-2"},[_c('div',{staticClass:"text-r-gray-medium flex flex-row items-center justify-between"},[_c('div',{staticClass:"select-none"},[_vm._v("\n                            "+_vm._s(_vm.$tc(
                                    "new_reservation.booking_summary.guest.guest_list_heading",
                                    1
                                ))+"\n                        ")])]),_vm._v(" "),_c('div',[_c('div',[_vm._v("\n                            "+_vm._s(_vm.reservation.primary_guest.first_name)+"\n                            "+_vm._s(_vm.reservation.primary_guest.last_name)+"\n                        ")])])])]),_vm._v(" "),_c('div',{staticClass:"border-r-gray-light border rounded-lg grid"},[_c('div',{staticClass:"p-3 grid gap-2"},[_c('div',{staticClass:"text-r-gray-medium flex flex-row items-center justify-between"},[_c('div',[_vm._v("\n                            "+_vm._s(_vm.$t("new_reservation.booker.heading"))+"\n                        ")]),_vm._v(" "),_c('div',{staticClass:"stroke-r-black hover:stroke-r-green"},[(
                                    _vm.$route.name !==
                                        'bookings.edit.booker' &&
                                    [
                                        'Confirmed',
                                        'InHouse',
                                        'CheckedOut',
                                        'NoShow',
                                        'Canceled',
                                    ].includes(_vm.reservation.status)
                                )?_c('router-link',{attrs:{"to":{
                                    name: 'bookings.edit.booker',
                                    params: _vm.reservationQueryPayload,
                                }}},[_c('icon',{staticClass:"w-6 h-6",attrs:{"icon":"edit"}})],1):_vm._e()],1)]),_vm._v(" "),_c('div',_vm._l((_vm.bookerAddress),function(line){return _c('div',[_vm._v("\n                            "+_vm._s(line)+"\n                        ")])}),0)])]),_vm._v(" "),_c('div',{staticClass:"grid gap-2 py-5"},[_c('div',{staticClass:"text-r-green text-3xl font-bold text-center"},[_vm._v("\n                    "+_vm._s(_vm.currency(_vm.payment_amount, _vm.payment_currency))+"\n                ")]),_vm._v(" "),_c('div',{staticClass:"text-r-gray-medium flex flex-row items-center gap-1 justify-center"},[_c('span',[_vm._v("\n                        "+_vm._s(_vm.$t(
                                "new_reservation.booking_summary.total_amount.total_amount_label"
                            ))+"\n                    ")]),_vm._v(" "),_c('span',[_vm._v("\n                        "+_vm._s(_vm.$tc(
                                "new_reservation.booking_summary.total_amount.total_nights",
                                _vm.reservation.nights
                            ))+"\n                    ")])])]),_vm._v(" "),_c('div',{staticClass:"grid gap-2 py-5 border-t border-r-gray-light"},[_c('div',{staticClass:"font-bold text-sm"},[_vm._v("\n                    "+_vm._s(_vm.$t("new_reservation.offers.offer_card.information"))+"\n                ")]),_vm._v(" "),_c('div',{staticClass:"text-xs flex flex-col"},[_c('div',{staticClass:"inline-flex gap-2 items-center"},[_c('icon',{class:_vm.informationIconClass,attrs:{"icon":"check-circle"}}),_vm._v(" "),_c('span',[_vm._v("\n                            "+_vm._s(_vm.$t(
                                    "apaleo.cancellationFee." +
                                        _vm.reservation.cancellation_fee.code +
                                        ".description"
                                ))+"\n                        ")])],1),_vm._v(" "),_c('div',{staticClass:"inline-flex gap-2 items-center"},[_c('icon',{class:_vm.informationIconClass,attrs:{"icon":"check-circle"}}),_vm._v(" "),_c('span',[_vm._v("\n                            "+_vm._s(_vm.$t(
                                    "new_reservation.offers.offer_card.price_information"
                                ))+"\n                        ")])],1),_vm._v(" "),(_vm.reservation.has_city_tax)?_c('div',{staticClass:"inline-flex gap-2 items-center"},[_c('icon',{class:_vm.informationIconClass,attrs:{"icon":"check-circle"}}),_vm._v(" "),_c('span',[_vm._v("\n                            "+_vm._s(_vm.$t(
                                    "new_reservation.payment.including_city_tax",
                                    {
                                        amount: _vm.currency(
                                            _vm.reservation.city_tax_amount,
                                            _vm.reservation.balance_currency
                                        ),
                                    }
                                ))+"\n                        ")])],1):_vm._e()])])])]},proxy:true}],null,true)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }