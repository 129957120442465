<template>
    <content-container>
        <div class="flex flex-col space-y-6">
            <h1 class="text-xl font-bold text-center">
                {{ $t("languages.title") }}
            </h1>
            <p class="text-lg tracking-wider leading-2">
                {{ $t("languages.lead") }}
            </p>
            <div class="grid grid-cols-2 gap-4">
                <div
                    class="flex flex-row items-center px-3 py-3 bg-gray-100 border border-gray-200 rounded cursor-pointer hover:bg-green-100 transition duration-150 space-x-2"
                    @click="setLanguage(lang.key)"
                    :class="{ 'bg-green-200 shadow-lg': lang == locale }"
                    v-for="lang in locales"
                    :key="lang.key"
                >
                    <div>
                        <img class="w-8" :src="lang.flag.default" alt="" />
                    </div>
                    <div class="m-0 uppercase">
                        {{ lang.original }}
                    </div>
                </div>
            </div>
        </div>
    </content-container>
</template>

<script>
import ContentContainer from "@admin/common/ContentContainer";
import { mapGetters, mapState, mapActions } from "vuex";

export default {
    components: {
        ContentContainer,
    },
    methods: {
        ...mapActions({
            setLocale: "setLocale",
            setReturnToPath: "setReturnToPath",
        }),
        setLanguage(lang) {
            this.setLocale(lang);
            let next_path = { name: "home" };
            if (this.return_to_path !== null) {
                //reset return to path
                next_path = this.return_to_path.fullPath;
                this.setReturnToPath(null);
            }
            this.$router.push(next_path);
        },
    },
    computed: {
        ...mapState({
            locales: "locales",
            return_to_path: (state) => state.return_to_path,
        }),
        ...mapGetters({
            locale: "currentLocale",
        }),
    },
    created() {
        if (this.$route.params.hasOwnProperty("preselect")) {
            let preselect_locale = this.locales.find((item) => {
                return item.key === this.$route.params.preselect;
            });
            if (preselect_locale !== undefined) {
                this.setLanguage(preselect_locale.key);
            }
        }
    },
};
</script>

<style></style>
