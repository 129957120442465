<template>
    <div>
        <full-width-container class="bg-r-gray-ultralight">
            <content-container class="flex justify-center">
                <div
                    v-if="!completed"
                    class="bg-white border rounded-lg border-r-gray-light px-5 py-8 lg:p-16 lg:w-[595px]"
                >
                    <div
                        class="grid gap-8 text-r-gray mb-8 lg:mb-16 px-5 lg:px-0"
                    >
                        <h2
                            class="text-2xl md:text-4xl italic pb-2 md:pb-4 font-black flex-grow text-r-green"
                        >
                            {{ $t("reservations.review.heading") }}
                        </h2>
                        <div>
                            <div class="font-bold">
                                {{ $t("reservations.review.lead") }} <br />
                                {{ $t("reservations.review.body") }}
                            </div>
                        </div>
                        <div class="grid gap-3">
                            <div>
                                {{ $t("reservations.review.questions.room") }}
                            </div>
                            <stars
                                :stars="input.room"
                                @input="input.room = $event"
                            ></stars>
                        </div>
                        <div class="grid gap-3">
                            <div>
                                {{
                                    $t("reservations.review.questions.booking")
                                }}
                            </div>
                            <stars
                                :stars="input.booking"
                                @input="input.booking = $event"
                            ></stars>
                        </div>
                        <div class="grid gap-3">
                            <div>
                                {{
                                    $t("reservations.review.questions.pricing")
                                }}
                            </div>
                            <stars
                                :stars="input.pricing"
                                @input="input.pricing = $event"
                            ></stars>
                        </div>
                        <div>
                            <div>
                                {{
                                    $t(
                                        "reservations.review.questions.recommend"
                                    )
                                }}
                            </div>
                            <yes-no-checkbox
                                class="py-3"
                                :value="input.recommend"
                                @input="input.recommend = $event"
                            ></yes-no-checkbox>
                        </div>
                        <div>
                            <div>
                                {{ $t("reservations.review.questions.liked") }}
                            </div>
                            <div class="w-full">
                                <app-input
                                    v-model="input.liked"
                                    name="liked"
                                    :label="null"
                                >
                                </app-input>
                            </div>
                        </div>
                        <div class="flex justify-center">
                            <div>
                                <app-button
                                    :disabled="!submitEnabled"
                                    color="green"
                                    @click="submitRating"
                                    >{{
                                        $t("reservations.review.button")
                                    }}</app-button
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="completed">
                    <div class="px-5 py-8 md:p-16 flex justify-center">
                        <div
                            class="w-full grid justify-center gap-8 lg:gap-10 flex-col text-center text-xl"
                        >
                            <div class="flex justify-center">
                                <icon
                                    class="h-20 w-20 stroke-r-green"
                                    icon="check-circle"
                                ></icon>
                            </div>
                            <div
                                class="text-2xl md:text-4xl font-black italic text-r-green"
                            >
                                {{
                                    $t("reservations.review.completed_heading")
                                }}
                            </div>
                            <div>
                                {{ $t("reservations.review.completed_body") }}
                            </div>
                            <div class="flex justify-center">
                                <app-button
                                    class="w-1/2 text-base"
                                    @click="$router.push({ name: 'home' })"
                                    color="green"
                                >
                                    {{
                                        $t(
                                            "new_reservation.completed.home_button"
                                        )
                                    }}
                                </app-button>
                            </div>
                        </div>
                    </div>
                </div>
            </content-container>
        </full-width-container>
    </div>
</template>

<script>
import ContentContainer from "@guest/common/ContentContainer";
import AppInput from "@guest/common/Input";
import AppButton from "@guest/common/Button";
import FullWidthContainer from "@guest/common/FullWidthContainer.vue";
import Stars from "@guest/common/Stars.vue";
import Icon from "@guest/common/Icon";
import YesNoCheckbox from "@guest/common/YesNoCheckbox.vue";
import { mapActions } from "vuex";
export default {
    components: {
        Icon,
        YesNoCheckbox,
        AppInput,
        AppButton,
        FullWidthContainer,
        ContentContainer,
        Stars,
    },
    data: function () {
        return {
            initialized: false,
            completed: false,
            input: {
                room: 0,
                booking: 0,
                pricing: 0,
                recommend: null,
                liked: "",
            },
        };
    },
    methods: {
        ...mapActions({
            setLocale: "setLocale",
        }),
        canRating() {
            axios
                .get(
                    "bookings/reservation_can_rating/" +
                        this.$route.params.reservation
                )
                .then((response) => {
                    this.setLocale(response.data.locale);
                })
                .catch((error) => {
                    console.log("need to redirect");
                    console.log(error.response);
                    this.$router.push({ name: "home" });
                });
        },
        submitRating() {
            axios
                .post(
                    "bookings/reservation_rating/" +
                        this.$route.params.reservation,
                    this.input
                )
                .then((response) => {
                    console.log(response.data);
                    this.completed = true;
                })
                .catch((error) => {
                    console.log("need to redirect");
                    console.log(error.response);
                });
        },
    },
    computed: {
        submitEnabled() {
            return (
                this.input.room > 0 &&
                this.input.booking > 0 &&
                this.input.pricing > 0 &&
                this.input.recommend !== null
            );
        },
    },
    mounted() {
        if (
            !this.$route.params.hasOwnProperty("reservation") ||
            this.$route.params.reservation == null
        ) {
            this.$router.push({ name: "home" });
        }
        this.canRating();
    },
};
</script>

<style></style>
