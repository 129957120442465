<template>
    <div
        v-if="reservation.status === 'Confirmed' && checkInEnabled === false"
        class="lg:pl-10 pt-5 lg:pt-0 flex flex-col"
    >
        <div class="grid gap-10">
            <h2
                class="text-2xl italic font-black text-r-green text-center lg:text-left"
            >
                {{ $t("reservations.summary.pre_check_in.heading") }}
            </h2>
            <div class="text-center lg:text-left">
                {{ $t("reservations.summary.pre_check_in.lead") }}
            </div>
            <div class="flex justify-center">
                <div>
                    <app-button @click="preCheckIn" color="green">
                        {{ $t("reservations.summary.pre_check_in.button") }}
                    </app-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ClipContainer from "@guest/common/ClipContainer";
import AppButton from "@guest/common/Button";
import ReservationQueryPayload from "@mixins/ReservationQueryPayload";
import DayJS from "@mixins/DayJS";
import { mapActions, mapState, mapGetters } from "vuex";

export default {
    mixins: [DayJS, ReservationQueryPayload],
    components: {
        ClipContainer,
        AppButton,
    },
    props: {
        reservation: {
            required: true,
            type: Object,
        },
    },
    computed: {
        checkInToday() {
            return this.dayjs(
                this.reservation.earliest_check_in_time
            ).isToday();
        },
        // checkIn() {
        //     return this.dayjs().isAfter(this.reservation.arrival);
        // },
        checkInEnabled() {
            return (
                this.reservation.status == "Confirmed" &&
                this.dayjs().isAfter(this.reservation.earliest_check_in_time)
            );
        },
        ...mapState({
            locales: "locales",
        }),
        ...mapGetters({
            locale: "currentLocale",
        }),
    },
    methods: {
        setLanguage(lang) {
            this.setLocale(lang);
        },
        ...mapActions({
            // getUserReservation: "getUserReservation",
            getUserReservationCanCheckIn: "getUserReservationCanCheckIn",
            // setReturnToPath: "setReturnToPath",
            setLocale: "setLocale",
            // putUserReservationCancel: "putUserReservationCancel",
        }),
        preCheckIn() {
            this.$router.push({
                name: "bookings.edit.precheckin",
                params: this.reservationQueryPayload,
            });
        },
    },
};
</script>

<style></style>
