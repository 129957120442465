<template>
    <div class="p-3">
        <div class="font-sans flex flex-col space-y-3">
            <div class="flex flex-row justify-between">
                <div
                    class="font-sans text-xl md:text-2xl font-black text-r-green italic"
                >
                    Roatel {{ property.name }}
                </div>
            </div>
            <div class="flex text-sm leading-6">
                <div>
                    <div>
                        {{ property.address_line1 }}
                    </div>
                    <div>{{ property.postal_code }} {{ property.city }}</div>
                </div>
            </div>
            <div class="flex justify-center">
                <div>
                    <app-button @click="$emit('property-select', property)">
                        {{ buttonLabel }}
                    </app-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import AppButton from "@guest/common/Button.vue";
export default {
    components: {
        AppButton,
    },
    props: {
        property: {
            required: true,
        },
        buttonLabel: {
            required: false,
            default: "Book now",
        },
    },
};
</script>
