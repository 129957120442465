<template>
    <div
        :class="[size, background]"
        class="flex-inline justify-center items-center"
    >
        <span v-html="getSvg"> </span>
    </div>
</template>

<script>
import {
    roatel_only_r,
    roatel,
    bed,
    user,
    questionmark,
    globe,
    target,
    calendar,
    check_circle,
    map,
    chevron_right,
    search,
    chevron_down,
    circle_x,
    alert,
    map_pin,
    file_euro,
    currency_euro,
    tv,
    propeller,
    wifi,
    user_plus,
    user_x,
    user_check,
    chevron_up,
    arrow_narrow_left,
    edit,
    mail,
    phone,
    user_no_circle,
    id,
    signature,
    key,
    slide_show,
    menu,
    x,
    arrow_up_right,
    bed_off,
    arrow_narrow_right,
} from "@/guest/common/icons";

export default {
    data: function () {
        return {
            icons: [
                { name: "roatel", svg: roatel },
                { name: "roatel_only_r", svg: roatel_only_r },
                { name: "bed", svg: bed },
                { name: "user", svg: user },
                { name: "questionmark", svg: questionmark },
                { name: "globe", svg: globe },
                { name: "target", svg: target },
                { name: "calendar", svg: calendar },
                { name: "check-circle", svg: check_circle },
                { name: "map", svg: map },
                { name: "chevron-right", svg: chevron_right },
                { name: "search", svg: search },
                { name: "chevron-down", svg: chevron_down },
                { name: "chevron-up", svg: chevron_up },
                { name: "circle-x", svg: circle_x },
                { name: "alert", svg: alert },
                { name: "map-pin", svg: map_pin },
                { name: "file-euro", svg: file_euro },
                { name: "currency-euro", svg: currency_euro },
                { name: "tv", svg: tv },
                { name: "propeller", svg: propeller },
                { name: "wifi", svg: wifi },
                { name: "user-x", svg: user_x },
                { name: "user-plus", svg: user_plus },
                { name: "user-check", svg: user_check },
                { name: "arrow-narrow-left", svg: arrow_narrow_left },
                { name: "edit", svg: edit },
                { name: "mail", svg: mail },
                { name: "phone", svg: phone },
                { name: "user-no-circle", svg: user_no_circle },
                { name: "id", svg: id },
                { name: "signature", svg: signature },
                { name: "key", svg: key },
                { name: "slide-show", svg: slide_show },
                { name: "menu", svg: menu },
                { name: "x", svg: x },
                { name: "arrow-up-right", svg: arrow_up_right },
                { name: "bed-off", svg: bed_off },
                { name: "arrow-narrow-right", svg: arrow_narrow_right },
            ],
        };
    },
    computed: {
        getSvg() {
            return this.icons.find((item) => item.name == this.icon).svg;
        },
    },
    props: {
        icon: {
            default: "cancel",
            required: true,
            type: String,
        },
        size: {
            // default: "w-8 h-8",
            type: String,
            required: false,
        },
        // padding: {
        //     default: "p-1",
        //     type: String,
        //     required: false,
        // },
        background: {
            default: "bg-transparent",
            required: false,
            tyoe: String,
        },
        // isRounded: {
        //     default: true,
        //     type: Boolean,
        //     required: false,
        // },
    },
};
</script>

<style></style>
