var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col gap-5 p-3 lg:p-5 bg-r-gray-ultralight rounded-lg border border-r-gray-light"},[_c('div',{staticClass:"font-black inline-flex items-center gap-3"},[_c('icon',{staticClass:"w-8 h-8",class:[
                {
                    'stroke-r-black': !_vm.completed && !_vm.hasErrors,
                    'stroke-r-green': _vm.completed && !_vm.hasErrors,
                    'stroke-r-red': _vm.hasErrors,
                },
            ],attrs:{"icon":_vm.userIcon}}),_vm._v(" "),_c('div',{staticClass:"flex flex-col gap-1 flex-grow leading-none font-bold truncate",class:[
                {
                    'text-r-black': !_vm.completed && !_vm.hasErrors,
                    'text-r-green': _vm.completed && !_vm.hasErrors,
                    'text-r-red': _vm.hasErrors,
                },
            ]},[_c('div',[_vm._v("\n                "+_vm._s(_vm.$t(
                        "new_reservation.summary.guest_reservation_card.details_guest",
                        {
                            count: _vm.index + 1,
                        }
                    ))+"\n            ")]),_vm._v(" "),(_vm.completed)?_c('div',{staticClass:"truncate"},[_vm._v("\n                "+_vm._s(_vm.room.primaryGuest.firstName)+"\n                "+_vm._s(_vm.room.primaryGuest.lastName)+"\n            ")]):_c('div',{staticClass:"text-r-gray-medium"},[_vm._v("\n                "+_vm._s(_vm.$t("models.person.firstName"))+"\n                "+_vm._s(_vm.$t("models.person.lastName"))+"\n            ")])]),_vm._v(" "),_c('div',{staticClass:"justify-self-end",on:{"click":function($event){_vm.open = !_vm.open}}},[_c('icon',{staticClass:"w-8 h-8 stroke-r-black cursor-pointer",attrs:{"icon":_vm.open ? 'chevron-up' : 'chevron-down'}})],1)],1),_vm._v(" "),(!_vm.room.is_booker && _vm.open)?_c('div',{staticClass:"grid grid-cols-1 lg:grid-cols-2 gap-4"},[_c('app-input',{attrs:{"label":_vm.$t('models.person.firstName'),"placeholder":_vm.$t('models.person.firstName'),"required":true,"errors":_vm.errors,"name":`reservations.${_vm.index}.primaryGuest.firstName`,"type":"text"},model:{value:(_vm.room.primaryGuest.firstName),callback:function ($$v) {_vm.$set(_vm.room.primaryGuest, "firstName", $$v)},expression:"room.primaryGuest.firstName"}}),_vm._v(" "),_c('app-input',{attrs:{"label":_vm.$t('models.person.lastName'),"placeholder":_vm.$t('models.person.lastName'),"required":true,"errors":_vm.errors,"name":`reservations.${_vm.index}.primaryGuest.lastName`,"type":"text"},model:{value:(_vm.room.primaryGuest.lastName),callback:function ($$v) {_vm.$set(_vm.room.primaryGuest, "lastName", $$v)},expression:"room.primaryGuest.lastName"}}),_vm._v(" "),_c('app-input',{attrs:{"label":_vm.$t(
                    'new_reservation.summary.guest_reservation_card.email_label',
                    { number: _vm.index + 1 }
                ),"placeholder":_vm.$t(
                    'new_reservation.summary.guest_reservation_card.email_placeholder'
                ),"required":true,"labelUppercase":false,"errors":_vm.errors,"name":`reservations.${_vm.index}.primaryGuest.email`,"type":"text"},model:{value:(_vm.room.primaryGuest.email),callback:function ($$v) {_vm.$set(_vm.room.primaryGuest, "email", $$v)},expression:"room.primaryGuest.email"}}),_vm._v(" "),_c('app-input',{attrs:{"label":_vm.$t(
                    'new_reservation.summary.guest_reservation_card.phone_label',
                    { number: _vm.index + 1 }
                ),"placeholder":_vm.$t(
                    'new_reservation.summary.guest_reservation_card.phone_placeholder'
                ),"errors":_vm.errors,"labelUppercase":false,"required":true,"type":"phone","name":`reservations.${_vm.index}.primaryGuest.phone`},model:{value:(_vm.room.primaryGuest.phone),callback:function ($$v) {_vm.$set(_vm.room.primaryGuest, "phone", $$v)},expression:"room.primaryGuest.phone"}}),_vm._v(" "),(_vm.room.primaryGuest.locale === null)?_c('div',{staticClass:"flex justify-end pt-2"}):_vm._e(),_vm._v(" "),(_vm.room.primaryGuest.locale !== null)?_c('app-input',{attrs:{"type":"selectClassic","errors":_vm.errors,"required":true,"selectOptions":_vm.locales,"selectValueProperty":"locale","selectLabelProperty":"label","selectSearchKeys":['locale', 'label'],"label":_vm.$t('models.account.preferred_locale'),"name":"preferred_locale"},on:{"select":function($event){_vm.data.preferred_locale = $event.locale}},model:{value:(_vm.room.primaryGuest.locale),callback:function ($$v) {_vm.$set(_vm.room.primaryGuest, "locale", $$v)},expression:"room.primaryGuest.locale"}}):_vm._e(),_vm._v(" "),(_vm.reservations.length > 1)?_c('div',{staticClass:"col-span-full flex justify-center"},[_c('div',{staticClass:"pt-4"},[_c('app-button',{attrs:{"color":"gray"},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.$t("buttons.save")))])],1)]):_vm._e()],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }