<template>
    <span class="font-black" :class="[translations[status].css, classes]">
        {{ $t("reservations.status." + status) }}
    </span>
</template>

<script>
export default {
    data: function () {
        return {
            translations: {
                CheckedOut: {
                    label: "Checked Out",
                    css: "text-r-gray",
                },
                Confirmed: {
                    label: "Bestätigt",
                    css: "text-r-green",
                },
                InHouse: {
                    label: "Im Hotel",
                    css: "text-r-darkgreen",
                },
                Canceled: {
                    label: "Storniert",
                    css: "text-r-red",
                },
                NoShow: {
                    label: "No Show",
                    css: "text-r-red",
                },
            },
        };
    },
    computed: {},
    props: {
        status: {
            required: true,
            type: String,
        },
        classes: {
            required: false,
        },
    },
};
</script>

<style></style>
