<template>
    <div class="relative">
        <div v-if="no_access" class="flex justify-center px-4 py-10">
            <alert type="error">
                {{ $t("reservations.edit.no_access") }}
            </alert>
        </div>

        <full-width-container class="bg-r-gray-ultralight">
            <content-container v-if="reservation">
                <div
                    class="flex flex-col-reverse lg:flex-row gap-5 text-r-black"
                >
                    <div class="w-full lg:w-1/3 flex flex-col gap-10">
                        <edit-booking-summary-sidebar
                            :reservation="reservation"
                            :is-awaiting-reservation-update="
                                isAwaitingReservationUpdate
                            "
                            @cancel="
                                reservation.channel_code === 'Ibe'
                                    ? $refs.cancelConfirmation.show(reservation)
                                    : $refs.cancelConfirmationBookingCom.show(
                                          reservation
                                      )
                            "
                        >
                            <template v-slot:top> </template>
                        </edit-booking-summary-sidebar>
                    </div>
                    <div
                        class="flex flex-col gap-4 w-full lg:w-2/3"
                        id="summary_container"
                    >
                        <div v-if="isAwaitingReservationUpdate">
                            <alert type="success">
                                <div class="flex flex-row items-center gap-3">
                                    <font-awesome-icon
                                        class="animate-spin-slow"
                                        icon="spinner"
                                    ></font-awesome-icon>
                                    <div>
                                        {{
                                            $t(
                                                "reservations.edit.reservation_updating"
                                            )
                                        }}
                                    </div>
                                </div>
                            </alert>
                        </div>
                        <router-view
                            v-if="reservation.hasOwnProperty('uuid')"
                            @reservation="updateReservation"
                            @cancel="
                                reservation.channel_code === 'Ibe'
                                    ? $refs.cancelConfirmation.show(reservation)
                                    : $refs.cancelConfirmationBookingCom.show(
                                          reservation
                                      )
                            "
                            :isAwaitingReservationUpdate="
                                isAwaitingReservationUpdate
                            "
                            :reservation="reservation"
                        >
                        </router-view>
                        <!-- </div> -->
                    </div>
                </div>
            </content-container>
        </full-width-container>
        <modal
            ref="cancelConfirmationBookingCom"
            confirmSize="sm"
            cancelSize="sm"
            width="w-full md:w-1/2"
            :isConfirmation="false"
        >
            <template v-slot:title>
                {{ $t("reservations.summary.cancel_bookingcom_title") }}
            </template>
            <template v-slot:body="model">
                {{ $t("reservations.summary.cancel_bookingcom_body") }}
            </template>
            <template v-slot:footer="model">
                <app-button
                    color="gray"
                    @click="$refs.cancelConfirmationBookingCom.close()"
                >
                    {{ $t("buttons.close") }}
                </app-button>
            </template>
        </modal>
        <modal
            ref="cancelConfirmation"
            confirmSize="sm"
            cancelSize="sm"
            width="w-full md:w-1/2"
            :confirmLabel="$t('reservations.summary.button_reservation_cancel')"
            :cancelLabel="$t('buttons.cancel')"
            @confirmed="reservationCancel"
            :isConfirmation="true"
        >
            <template v-slot:title>
                {{ $t("reservations.summary.cancel_confirm_title") }}
            </template>
            <template v-slot:body="model">
                <span v-if="isCancellationFree === false">
                    {{
                        $t("reservations.summary.cancel_confirm_body", {
                            apaleo_id: model.model.apaleo_id,
                            fee: currency(
                                model.model.cancellation_fee.fee.amount,
                                model.model.cancellation_fee.fee.currency
                            ),
                        })
                    }}
                </span>
                <span v-else>
                    {{
                        $t("reservations.summary.cancel_confirm_body_free", {
                            apaleo_id: model.model.apaleo_id,
                            datetime: date(
                                reservation.cancellation_fee.dueDateTime,
                                "datetime"
                            ),
                        })
                    }}
                </span>
            </template>
        </modal>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import DayJS from "@mixins/DayJS";
import ReservationStatus from "@guest/bookings/ReservationStatus";
import AppButton from "@guest/common/Button";
import ContentContainer from "@guest/common/ContentContainer";
import FullWidthContainer from "@guest/common/FullWidthContainer";
import ReservationQueryPayload from "@mixins/ReservationQueryPayload";
import Alert from "@guest/common/Alert";
import PropertyCard from "@guest/bookings/new/PropertyCard.vue";
import BookingBackLink from "@guest/common/BookingBackLink.vue";
import EditBookingSummarySidebar from "@guest/common/EditBookingSummarySidebar";
import Modal from "@guest/common/Modal";
import Currency from "@mixins/Currency";
import _ from "lodash";

export default {
    components: {
        ReservationStatus,
        ContentContainer,
        AppButton,
        Alert,
        PropertyCard,
        EditBookingSummarySidebar,
        BookingBackLink,
        Modal,
        FullWidthContainer,
    },
    mixins: [DayJS, ReservationQueryPayload, Currency],
    data: function () {
        return {
            reservation: {},
            initialized: false,
            no_access: false,
        };
    },
    watch: {
        "$route.params.uuid": {
            handler: function (val) {
                this.loadReservation(this.reservationQueryPayload);
            },
            immediate: true,
        },
    },
    computed: {
        isCancellationFree() {
            console.log(
                "isCancellationFreer",
                this.reservation.cancellation_fee
            );
            return this.dayjs().isSameOrBefore(
                this.reservation.cancellation_fee.dueDateTime
            );
        },
        ...mapState({
            isCheckingIn: (state) => state.isCheckingIn,
            isAwaitingReservationUpdate: (state) =>
                state.isAwaitingReservationUpdate,
        }),
        ...mapGetters({
            isLoggedIn: "isLoggedIn",
            currentLocale: "currentLocale",
        }),
        isBeforeCheckInTime() {},
        timeToCheckIn() {
            let now = this.dayjs();
            return this.date(
                this.reservation.earliest_check_in_time,
                null,
                true
            );
        },
        broadcastChannel() {
            let channel = `reservations.${this.reservation.uuid}`;
            if (this.reservationQueryPayload.secret !== null) {
                channel = `reservations.${this.reservation.uuid}.${this.reservationQueryPayload.secret}`;
            }
            return channel;
        },
    },
    beforeRouteLeave(to, from, next) {
        console.log("leaving channel");
        console.log("Echo in route leave", Echo, this.reservation);
        this.leaveBroadcast();
        next();
    },
    methods: {
        leaveBroadcast() {
            if (this.reservationQueryPayload.secret !== null) {
                Echo.leave(this.broadcastChannel);
            }
            if (this.reservationQueryPayload.secret === null) {
                Echo.leave(this.broadcastChannel);
            }
        },
        joinBroadcast() {
            let channel;
            if (this.reservationQueryPayload.secret !== null) {
                channel = Echo.channel(this.broadcastChannel);
            } else {
                channel = Echo.private(this.broadcastChannel);
            }
            channel.listen(
                "GuestReservationUpdatedEvent",
                _.debounce((e) => {
                    this.loadReservation(this.reservationQueryPayload);
                    console.log("received reload event for", e);
                }, 3000)
            );
            // channel.listen(
            //     "GuestReservationUpdatedEvent",
            //     _.debounce(() => {
            //         this.loadReservation(this.reservationQueryPayload);
            //         console.log("received reload event for", e);
            //     }, 5000)
            // );
        },
        loadReservation(reservationQueryPayload) {
            this.leaveBroadcast();
            this.getUserReservation(reservationQueryPayload)
                .then((response) => {
                    this.reservation = response.data.data;
                    this.initialized = true;
                    console.log(
                        "current_locale",
                        this.currentLocale,
                        this.reservation.booker.preferred_language
                    );
                    if (!this.isLoggedIn) {
                        //Get locale
                        if (this.reservation.acl.is_booker) {
                            // console.log('lang booker', this.reservation.booker)
                            if (
                                this.currentLocale.key !=
                                this.reservation.booker.preferred_language
                            ) {
                                this.setLocale(
                                    this.reservation.booker.preferred_language
                                );
                            }
                        }
                        if (!this.reservation.acl.is_booker) {
                            if (
                                this.currentLocale.key !=
                                this.reservation.primary_guest
                                    .preferred_language
                            ) {
                                this.setLocale(
                                    this.reservation.primary_guest
                                        .preferred_language
                                );
                            }
                        }
                    }
                    console.log(this.reservation);
                    this.joinBroadcast();
                })
                .catch((error) => {
                    console.log("get reservation error", error);
                    // if (error.response.status === 403) {
                    //     this.setReturnToPath(this.$route)
                    //     console.log('You have to login first')
                    //
                    //     this.$router.push({name: 'login'})
                    // }
                    if (error.response.status === 401) {
                        this.no_access = true;
                    }
                });
        },
        ...mapActions({
            getUserReservation: "getUserReservation",
            getUserReservationCanCheckIn: "getUserReservationCanCheckIn",
            setReturnToPath: "setReturnToPath",
            setLocale: "setLocale",
            putUserReservationCancel: "putUserReservationCancel",
        }),
        reservationCancel() {
            console.log("cancel", this.reservation);
            this.putUserReservationCancel(this.reservationQueryPayload);
        },
        updateReservation(reservation) {
            console.log("update reservation event", reservation);
            this.reservation = reservation;
        },
        canCheckIn() {
            console.log("can check in");
            this.getUserReservationCanCheckIn(this.reservationQueryPayload)
                .then((response) => {
                    this.$router.push({
                        name: "bookings.edit.checkin.name",
                        params: this.reservationQueryPayload,
                    });
                })
                .catch((error) => {
                    console.log(error.response);
                });
        },
    },
    mounted() {
        //this.getUserReservation(this.$route.params.uuid)
    },
};
</script>

<style></style>
