var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('full-width-container',{staticClass:"bg-r-green"},[_c('content-container',{attrs:{"padding":"lg:py-4 lg:px-12 py-3"}},[_c('stepper',{attrs:{"current_step":_vm.current_step}},[_vm._v("\n                "+_vm._s(_vm.$t("navigation.footer.book_roatel"))+"\n            ")])],1)],1),_vm._v(" "),_c('booking-property-label',{attrs:{"property":_vm.property,"back-route-name":"bookings.new.search_properties"}}),_vm._v(" "),_c('full-width-container',{staticClass:"bg-r-gray-ultralight pb-20"},[_c('content-container',{attrs:{"padding":"px-3 lg:px-12 py-4 lg:py-0"}},[_c('div',{staticClass:"flex flex-col md:flex-row gap-5 lg:gap-8"},[_c('div',{staticClass:"w-full lg:w-1/3 flex flex-col gap-5 lg:gap-10"},[_c('new-booking-summary-sidebar',{attrs:{"property":_vm.property,"booking":_vm.booking,"show-imprint-link":true,"show-address":true,"show-carousel":true,"show-checkin-until":true,"show-open-in-map":true,"swiper-toggle-visible":true,"swiper-initial-status":false}})],1),_vm._v(" "),_c('div',{staticClass:"w-full lg:w-2/3 flex flex-col gap-5 lg:gap-10 text-r-black",attrs:{"id":"datescontainer"}},[_c('div',{staticClass:"relative bg-white border border-r-gray-light rounded-xl text-r-black"},[_c('div',{staticClass:"relative lg:p-20 px-5 py-8"},[_c('h2',{staticClass:"font-sans text-2xl lg:text-3xl italic pb-4 font-black text-r-green text-center"},[_vm._v("\n                                "+_vm._s(_vm.$t("new_reservation.dates.heading"))+"\n                            ")]),_vm._v(" "),_c('div',{staticClass:"grid grid-cols-1 gap-5 lg:gap-10 lg:px-20 text-r-black"},[_c('div',{staticClass:"flex flex-col space-y-4"},[_c('app-input',{ref:"dp",attrs:{"required":true,"name":"checkIn","type":"datepicker","errors":_vm.errors,"locale":_vm.currentLocale.key,"datepicker-min-date":_vm.dayjs()
                                                .startOf('day')
                                                .add(0, 'day')
                                                .toDate(),"placeholder":_vm.$t(
                                                'new_reservation.dates.check_in_placeholder'
                                            ),"label":_vm.$t(
                                                'new_reservation.dates.check_in_label_with_time'
                                            )},model:{value:(_vm.checkIn),callback:function ($$v) {_vm.checkIn=$$v},expression:"checkIn"}}),_vm._v(" "),_c('app-input',{attrs:{"type":"datepicker","required":true,"locale":_vm.currentLocale.key,"name":"checkOut","errors":_vm.errors,"datepicker-min-date":_vm.dayjs(_vm.checkIn)
                                                .startOf('day')
                                                .add(1, 'day')
                                                .toDate(),"placeholder":_vm.$t(
                                                'new_reservation.dates.check_out_placeholder'
                                            ),"label":_vm.$t(
                                                'new_reservation.dates.check_out_label_with_time'
                                            )},model:{value:(_vm.checkOut),callback:function ($$v) {_vm.checkOut=$$v},expression:"checkOut"}}),_vm._v(" "),(!_vm.showCodeInput)?_c('div',{staticClass:"underline text-sm cursor-pointer text-r-gray-light",on:{"click":function($event){_vm.showCodeInput = !_vm.showCodeInput}}},[_vm._v("\n                                        "+_vm._s(_vm.$t(
                                                "new_reservation.dates.code_text"
                                            ))+"\n                                    ")]):_vm._e(),_vm._v(" "),(_vm.showCodeInput)?_c('app-input',{attrs:{"type":"text","required":false,"locale":_vm.currentLocale.key,"name":"code","placeholder":_vm.$t(
                                                'new_reservation.dates.code_placeholder'
                                            ),"label":_vm.$t(
                                                'new_reservation.dates.code_label'
                                            )},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"mt-5"},[(_vm.error)?_c('alert',{attrs:{"type":"error"}},[_vm._v("\n                                            "+_vm._s(_vm.error)+"\n                                        ")]):_vm._e()],1)],1),_vm._v(" "),_c('div',[_c('app-input',{attrs:{"type":"onlyValue","name":"purpose","required":true,"label":_vm.$t(
                                                'new_reservation.dates.purpose_label'
                                            )}},[_c('div',{staticClass:"grid grid-cols-2 gap-3"},_vm._l((_vm.purposes),function(purpose){return _c('div',{key:purpose.uuid,staticClass:"rounded-lg text-white truncate cursor-pointer px-2 py-3 text-center font-black bg-r-darkgreen transition font-sans duration-150",class:[
                                                    {
                                                        'opacity-100':
                                                            _vm.booking.travel_purpose_id ===
                                                            purpose.id,

                                                        'opacity-50':
                                                            _vm.booking.travel_purpose_id !==
                                                            purpose.id,
                                                    },
                                                ],on:{"click":function($event){return _vm.setBookingDraftPurpose(
                                                        purpose
                                                    )}}},[_vm._v("\n                                                "+_vm._s(_vm.$t(
                                                        "new_reservation.purposes." +
                                                            purpose.trans_label
                                                    ))+"\n                                            ")])}),0)]),_vm._v(" "),(_vm.travel_purpose_error)?_c('alert',[_vm._v(_vm._s(_vm.$t(
                                            "new_reservation.dates.purpose_error"
                                        )))]):_vm._e()],1),_vm._v(" "),(
                                        _vm.errors.hasOwnProperty('checkIn') ||
                                        _vm.errors.hasOwnProperty('checkOut')
                                    )?_c('div',[_c('alert',[_vm._v(_vm._s(_vm.$t("common.form_error")))])],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex justify-center"},[_c('div',{staticClass:"w-full lg:flex"},[_c('app-button',{attrs:{"color":"green"},on:{"click":_vm.getOffers}},[_vm._v("\n                                            "+_vm._s(_vm.$t(
                                                    "new_reservation.dates.button_search"
                                                ))+"\n                                        ")])],1)])])])]),_vm._v(" "),_c('div',{staticClass:"relative bg-white border rounded-xl text-r-black",class:[
                            _vm.localStep > 1
                                ? 'border-r-gray-medium'
                                : 'border-r-gray-light',
                        ],attrs:{"id":"offers"}},[(_vm.localStep > 1)?_c('div',{staticClass:"grid gap-5"},_vm._l((_vm.offers),function(offer,offer_index){return _c('offer-card',{key:offer_index,attrs:{"selected":_vm.booking.offer,"property":_vm.property,"rooms":_vm.booking.rooms,"offer":offer},on:{"select":function($event){return _vm.submit($event)},"continue":_vm.submit}})}),1):_vm._e(),_vm._v(" "),(_vm.localStep == 1)?_c('offer-card',{attrs:{"dummy":_vm.localStep == 1,"selected":_vm.selected,"offer":_vm.dummyOffer,"property":_vm.property,"rooms":4}}):_vm._e()],1),_vm._v(" "),(!_vm.isLoggedIn)?_c('div',{attrs:{"id":"login"}},[_c('login',{attrs:{"dummy":3 !== _vm.localStep},on:{"services-list":function($event){return _vm.$emit('services-list')},"booker":function($event){return _vm.$emit('summary')}}})],1):_vm._e()])]),_vm._v(" "),_c('property-imprint-modal',{ref:"propertyImprintModal"})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }