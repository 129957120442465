<template>
    <full-width-container class="bg-white">
        <content-container>
            <div
                class="border-t border-r-black pt-8 text-sm flex md:flex-row flex-col-reverse space-y-6 space-y-reverse md:space-y-0 justify-between text-r-black"
            >
                <div class="text-center lg:text-left">
                    &copy; 2024 Roatel GmbH - All rights reserved
                </div>
                <div class="flex md:flex-row flex-col items-center gap-4">
                    <a
                        href="https://roatel.com/impressum-booking"
                        class="px-1 underline underline-offset-1 cursor-pointer"
                        target="_blank"
                        >{{ $t("navigation.sideNavigation.imprint") }}</a
                    >
                    <a
                        href="https://roatel.com/datenschutz-booking"
                        class="px-1 underline underline-offset-1"
                        target="_blank"
                        >{{ $t("navigation.sideNavigation.privacy") }}</a
                    >
                    <a
                        href="https://roatel.com/agb"
                        class="px-1 underline underline-offset-1"
                        target="_blank"
                        >{{ $t("new_reservation.summary.terms") }}</a
                    >
                    <button
                        @click="restartCookieConsent()"
                        class="px-1 underline underline-offset-1"
                        target="_blank"
                    >
                        {{ $t("cookie_consent.not_enabled_title") }}
                    </button>
                </div>
            </div>
        </content-container>
    </full-width-container>
</template>

<script>
import FullWidthContainer from "@guest/common/FullWidthContainer";
import ContentContainer from "@guest/common/ContentContainer";
import AppButton from "@guest/common/Button";
import GuestSideNavigationItems from "@/mixins/GuestSideNavigationItems";
import { mapGetters, mapActions } from "vuex";
// import NavigationItems from "@/guest/routes/navigation";

export default {
    mixins: [GuestSideNavigationItems],
    components: {
        FullWidthContainer,
        ContentContainer,
        AppButton,
    },
    methods: {
        ...mapActions({
            setCookieConsent: "setCookieConsent",
        }),
        restartCookieConsent() {
            this.setCookieConsent({
                isDecided: false,
                isAllowed: false,
            });
        },
    },
    computed: {
        ...mapGetters({
            getCookieConsent: "getCookieConsent",
        }),
    },
};
</script>

<style></style>
