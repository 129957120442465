<template>
    <div
        :class="[hoverPointerClass]"
        @click="$emit('property-select', property)"
    >
        <clip-container>
            <div
                class="transition-all duration-150 px-5 py-8 md:p-10 bg-r-black-5 group text-r-gray"
                :class="[hoverClasses ? hoverClasses : '']"
            >
                <slot name="before"></slot>

                <div class="flex flex-col space-y-3">
                    <div class="flex flex-row justify-between">
                        <div
                            class="text-xl md:text-2xl font-black slanted uppercase text-r-gray italic"
                            :class="[hoverClasses ? groupHoverClasses : '']"
                        >
                            roatel {{ property.name }}
                        </div>
                    </div>
                    <div
                        :class="[hoverClasses ? groupHoverClasses : '']"
                        class="flex text-sm leading-6"
                    >
                        <div>
                            <div>
                                {{ property.address_line1 }}
                            </div>
                            <div>
                                {{ property.postal_code }} {{ property.city }}
                            </div>
                            <div
                                class="flex flex-row space-x-3 text-r-green items-center underline hover:cursor-pointer"
                                :class="
                                    hoverClasses ? 'group-hover:text-white' : ''
                                "
                                v-if="showImprintButton"
                                @click.stop="
                                    $emit('property-imprint', property)
                                "
                            >
                                <div>
                                    {{
                                        $t(
                                            "new_reservation.property_card.imprint.link"
                                        )
                                    }}
                                </div>
                            </div>
                            <div
                                class="mt-2 inline-flex flex-row space-x-3 text-r-green items-center underline hover:cursor-pointer"
                                :class="
                                    hoverClasses ? 'group-hover:text-white' : ''
                                "
                                v-if="showRoomInformationButton"
                            >
                                <font-awesome-icon
                                    class="text-2xl"
                                    icon="bed"
                                ></font-awesome-icon>
                                <a href="https://day.roatel.de" target="_blank">
                                    {{ $t("reservations.summary.room_manual") }}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div v-if="showDistance || showNavigation">
                        <div
                            :class="
                                hoverClasses ? 'group-hover:text-white' : ''
                            "
                            class="text-r-green inline-flex group flex-row cursor-pointer font-bold text-sm space-x-3 items-center"
                            @click.stop="openPropertyInMaps()"
                        >
                            <div>
                                <icon
                                    icon="location"
                                    class="text-r-black-5"
                                    :class="
                                        hoverClasses
                                            ? 'group-hover:text-r-green group-hover:bg-white'
                                            : ''
                                    "
                                    background="bg-r-green "
                                    padding=""
                                ></icon>
                            </div>
                            <div
                                :class="
                                    hoverClasses ? 'group-hover:text-white' : ''
                                "
                            >
                                <div v-if="showDistance && property.distance">
                                    {{
                                        $t(
                                            "new_reservation.property_card.distance",
                                            {
                                                distance: number(
                                                    property.distance,
                                                    0
                                                ),
                                            }
                                        )
                                    }}
                                </div>
                                <div class="underline">
                                    {{ $t("new_reservation.show_map") }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div v-if="showNavigation"> -->
                    <!--     <div -->
                    <!--         @click="openPropertyInMaps()" -->
                    <!--         class="flex flex-row text-sm space-x-3" -->
                    <!--     > -->
                    <!--         <div> -->
                    <!--             {{ $t("new_reservation.show_map") }} -->
                    <!--         </div> -->
                    <!--     </div> -->
                    <!-- </div> -->
                </div>
                <slot name="after"></slot>
            </div>
        </clip-container>
    </div>
</template>

<script>
import Currency from "@mixins/Currency";
import ClipContainer from "@guest/common/ClipContainer";
import Icon from "@guest/common/Icon.vue";
import Modal from "@guest/common/Modal.vue";

export default {
    mixins: [Currency],
    components: {
        ClipContainer,
        Icon,
        Modal,
    },
    methods: {
        openPropertyInMaps() {
            let url =
                "https://www.google.com/maps/dir/?api=1&destination=" +
                this.property.lat +
                "," +
                this.property.lng;
            window.open(url, "_blank").focus();
            console.log(url);
        },
    },
    props: {
        property: {
            required: true,
            type: Object,
        },
        hoverClasses: {
            required: false,
            default: "hover:bg-r-green hover:text-white",
            type: String | null,
        },
        groupHoverClasses: {
            required: false,
            default: "group-hover:text-white",
        },
        hoverPointerClass: {
            required: false,
            default: "",
        },
        showDistance: {
            required: false,
            default: true,
            type: Boolean,
        },
        showNavigation: {
            required: false,
            default: false,
            type: Boolean,
        },
        showImprintButton: {
            required: false,
            default: false,
            type: Boolean,
        },
        showRoomInformationButton: {
            required: false,
            default: false,
            type: Boolean,
        },
    },
};
</script>

<style></style>
