<template>
    <transition
        enter-active-class="transition-all transform ease-in-out
        duration-300"
        leave-active-class="transition-all transform ease-in-out
        duration-300"
        enter-class="opacity-75 translate-x-full"
        enter-to-class="opacity-100 translate-x-0"
        leave-class="opacity-100 translate-x-0"
        leave-to-class="opacity-75 translate-x-full"
        @after-enter="afterEnter"
        @after-leave="afterLeave"
    >
        <div
            v-if="showHamburger"
            tabindex="0"
            class="text-white fixed px-10 py-20 md:p-20 inset-y-0 right-0 w-full md:w-1/3 z-40 bg-r-gray focus:outline-r-gray"
            @keyup.esc.stop="closeOutsideEsc"
            v-click-outside.stop="closeOutsideEsc"
            ref="main"
        >
            <div
                class="relative flex justify-end items-center space-x-4 space-x-reverse"
            >
                <div
                    @click="setShowHamburgerMenu(!showHamburger)"
                    class="text-2xl justify-self-end hover:cursor-pointer"
                >
                    <icon
                        icon="cancel"
                        class="text-r-black-10"
                        :background="null"
                        padding="p-0"
                    ></icon>
                </div>
                <!-- <div -->
                <!--     class="items-start flex-wrap flex text-sm uppercase font-black font-slanted italic" -->
                <!-- > -->
                <!--     <div -->
                <!--         v-for="(lang, index) in locales" -->
                <!--         :key="lang.key" -->
                <!--         class="flex flex-row" -->
                <!--     > -->
                <!--         <div -->
                <!--             @click="setLanguage(lang.key)" -->
                <!--             :class="[ -->
                <!--                 lang == locale -->
                <!--                     ? 'text-white' -->
                <!--                     : 'text-r-black-50', -->
                <!--             ]" -->
                <!--             class="px-2 cursor-pointer hover:text-white" -->
                <!--         > -->
                <!--             {{ lang.key }} -->
                <!--         </div> -->
                <!--         <span -->
                <!--             v-if="index + 1 < locales.length" -->
                <!--             class="text-r-black-50" -->
                <!--             >/</span -->
                <!--         > -->
                <!--     </div> -->
                <!-- </div> -->
            </div>
            <div
                class="mt-16 flex flex-col uppercase space-y-14 text-2xl font-slanted italic font-black"
            >
                <div
                    v-for="(group, group_index) in sideNavigationItemsGrouped"
                    class="flex flex-col space-y-2"
                    :key="'group_' + group_index"
                >
                    <div
                        :key="'item_' + item.order"
                        v-for="item in sortSideNavigationItems(group)"
                    >
                        <a
                            :href="item.href"
                            v-if="item.hasOwnProperty('href')"
                            class="py-2"
                            target="_blank"
                            >{{ $t(item.label) }}</a
                        >
                        <router-link
                            :to="item.route"
                            class="py-2"
                            v-if="item.hasOwnProperty('route')"
                            >{{ $t(item.label) }}</router-link
                        >
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import NavigationItems from "@/guest/routes/navigation";
import { mapGetters, mapActions, mapState } from "vuex";
import * as vClickOutside from "v-click-outside-x";
import GuestSideNavigationItems from "@/mixins/GuestSideNavigationItems";
import Icon from "@guest/common/Icon.vue";
export default {
    mixins: [GuestSideNavigationItems],
    directives: {
        clickOutside: vClickOutside.directive,
    },
    data: function () {
        return {
            currentItem: null,
            registerClickOutside: false,
        };
    },
    methods: {
        setLanguage(lang) {
            this.setLocale(lang);
            this.closeOutsideEsc();
        },
        afterEnter() {
            this.registerClickOutside = true;
            this.$refs.main.focus();
        },
        afterLeave() {
            this.registerClickOutside = false;
        },
        closeOutsideEsc(event) {
            console.log("closeOutsideEsc registered", event);
            if (this.registerClickOutside) {
                console.log("Click outside from sidebar navi");
                this.setShowHamburgerMenu(!this.showHamburger);
            }
        },
        ...mapActions({
            setShowHamburgerMenu: "setShowHamburgerMenu",
            setLocale: "setLocale",
        }),
        changeRoute(item) {
            this.currentItem = item.route;
            if (!item.children) {
                this.$router.push({ name: item.route });
            }
        },
        navigationItemInMatchedPath(item) {
            let hit = this.$route.matched.find(
                (route) => route.name == item.route
            );
            return hit !== undefined ? true : false;
        },
    },
    computed: {
        ...mapState({
            locales: "locales",
            showHamburger: "showHamburgerMenu",
            return_to_path: (state) => state.return_to_path,
        }),
        ...mapGetters({
            locale: "currentLocale",
        }),
        navigationFiltered() {
            return NavigationItems.filter((item) => {
                let show = true;
                if (item.show) {
                    if (item.show.requiresAuth) {
                        show = this.isLoggedIn;
                    }
                    if (item.show.requiresGuest) {
                        show = !this.isLoggedIn;
                    }
                }
                return show;
            });
        },
        ...mapGetters({
            isLoggedIn: "isLoggedIn",
            isGuest: "isGuest",
        }),
    },
    components: {
        Icon,
    },
    mounted() {
        // this.currentItem = this.$route.matched[0].name
    },
};
</script>

<style></style>
