<template>
    <div
        id="checkin_container"
        class="bg-white rounded-lg border border-r-gray-light p-5 lg:p-10 grid gap-5"
    >
        <div class="flex flex-col lg:flex-row gap-8">
            <div class="flex flex-col gap-3">
                <h2
                    class="text-2xl italic font-black text-r-green text-center lg:text-left"
                >
                    {{ $t("reservations.checkin.heading_identification") }}
                </h2>
                <div>
                    {{ $t("reservations.checkin.lead_identification") }}
                </div>
            </div>
            <div class="flex lg:justify-end justify-center" v-if="isLoggedIn">
                <div>
                    <app-button @click="setDataFromProfile" color="darkgreen">
                        {{
                            $t("reservations.checkin.button_fill_profile_data")
                        }}
                    </app-button>
                </div>
            </div>
        </div>

        <div class="grid gap-5 md:grid-cols-2">
            <app-input
                class="self-start lg:mt-8"
                :required="true"
                name="nationality_country_code"
                :label="$t('models.guest.nationality_country_code')"
                type="selectClassic"
                v-model="guest.nationality_country_code"
                :selectOptions="countries"
                selectLabelProperty="name"
                selectValueProperty="alpha-2"
                :errors="errors"
                :loading="loading"
            >
            </app-input>
            <birthdate-vue
                :required="true"
                name="birth_date"
                :birthdate="guest.birth_date"
                @date="guest.birth_date = $event"
                :label="$t('models.guest.birth_date')"
                :errors="errors"
                :locale="currentLocale.key"
                :loading="loading"
            >
            </birthdate-vue>
            <div class="col-start-1">
                <app-input
                    v-if="guest.nationality_country_code !== 'DE'"
                    name="identification_number"
                    v-model="guest.identification_number"
                    :required="true"
                    :label="$t('models.guest.identification_number')"
                    type="text"
                    :errors="errors"
                    :loading="loading"
                >
                </app-input>
                <div
                    v-if="guest.nationality_country_code !== 'DE'"
                    class="text-sm mt-2"
                >
                    {{ $t("reservations.checkin.foreign_citizen_id_text") }}
                </div>
            </div>
        </div>
        <div v-if="isLoggedIn" class="flex justify-start">
            <app-input
                name="updateProfile"
                v-model="guest.updateProfile"
                :stacked="false"
                :stackedReversed="true"
                :label="$t('models.guest.store_changes_in_profile')"
                labelClasses="text-sm"
                type="checkbox"
                :errors="errors"
                :loading="loading"
            >
            </app-input>
        </div>
        <div v-if="error" class="col-span-full">
            <alert>{{ $t("common.form_error") }}</alert>
        </div>
        <div class="flex justify-center">
            <div>
                <app-button
                    @click="submit"
                    color="green"
                    :outline="false"
                    size="xl"
                >
                    {{ $t("buttons.continue") }}
                </app-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import AppInput from "@guest/common/Input";
import AppButton from "@guest/common/Button";
import Countries from "@mixins/Countries";
import DayJS from "@mixins/DayJS";
import Titles from "@mixins/Titles";
import Genders from "@mixins/Genders";
import IdentificationTypes from "@mixins/IdentificationTypes";
import ReservationQueryPayload from "@mixins/ReservationQueryPayload";
import ClipContainer from "@guest/common/ClipContainer";
import RequiredFieldInfo from "@guest/common/RequiredFieldInfo.vue";
import Alert from "@guest/common/Alert.vue";
import BirthdateVue from "@guest/common/Birthdate.vue";

export default {
    mixins: [
        DayJS,
        Countries,
        Titles,
        Genders,
        IdentificationTypes,
        ReservationQueryPayload,
    ],
    components: {
        AppInput,
        AppButton,
        ClipContainer,
        RequiredFieldInfo,
        Alert,
        BirthdateVue,
    },
    data: function () {
        return {
            errors: {},
            error: false,
            storeChangesToProfile: false,
            guest: {
                nationality_country_code: "DE",
                identification_number: "",
                identification_issue_date: "",
                identification_type: "",
                birth_date: "",
                birth_place: "",
                updateProfile: true,
            },
        };
    },
    methods: {
        ...mapActions({
            postUserReservationCheckInIdentification:
                "postUserReservationCheckInIdentification",
        }),
        submit() {
            this.errors = {};
            this.error = false;
            this.postUserReservationCheckInIdentification({
                id: this.reservationQueryPayload,
                data: this.guest,
            })
                .then((response) => {
                    this.$emit("reservation", response.data.data);
                    this.$router.push({
                        name: "bookings.edit.checkin.signature",
                        params: this.reservationQueryPayload,
                    });
                })
                .catch((error) => {
                    this.error = true;
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    }
                });
        },
        setDataFromReservation() {
            for (const [key, val] of Object.entries(this.guest)) {
                if (this.reservation.primary_guest.hasOwnProperty(key)) {
                    console.log(
                        "setting from reservation",
                        key,
                        this.reservation.primary_guest[key]
                    );
                    this.guest[key] = this.reservation.primary_guest[key];
                    if (
                        key === "birth_date" &&
                        this.reservation.primary_guest[key] != null
                    ) {
                        this.guest[key] = this.dayjs(
                            this.reservation.primary_guest[key]
                        ).format("YYYY-MM-DD");
                    }
                }
            }
            if (this.guest.nationality_country_code === null) {
                this.guest.nationality_country_code = "DE";
            }
        },
        setDataFromProfile() {
            for (const [key, val] of Object.entries(this.guest)) {
                if (this.profile.guest_detail.hasOwnProperty(key)) {
                    console.log(
                        "setting from profile",
                        key,
                        this.profile.guest_detail[key]
                    );
                    this.guest[key] = this.profile.guest_detail[key];
                    if (key === "birth_date") {
                        this.guest[key] = this.dayjs(
                            this.profile.guest_detail[key]
                        ).format("YYYY-MM-DD");
                    }
                }
            }
        },
    },
    computed: {
        ...mapState({
            loading: (state) => state.loading,
            profile: (state) => state.profile,
        }),
        ...mapGetters({
            isLoggedIn: "isLoggedIn",
            profile: "profile",
            currentLocale: "currentLocale",
        }),
    },
    mounted() {
        this.setDataFromReservation();
        console.log("identification");
        this.$scrollTo("#checkin_container", 300);
    },
    props: {
        reservation: {
            required: true,
            type: Object,
        },
    },
};
</script>

<style></style>
