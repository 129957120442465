//import {de} from './de.json'
//import {en} from './en.json'

const de = require('./de.json')
const en = require('./en.json')
const tr = require('./tr.json')
const pl = require('./pl.json')
const es = require('./es.json')
const fr = require('./fr.json')
const nl = require('./nl.json')
const pt = require('./pt.json')
const it = require('./it.json')
const ru = require('./ru.json')
const bg = require('./bg.json')

export const i18n = {
    de: de,
    en: en,
    tr: tr,
    pl: pl,
    es: es,
    fr: fr,
    nl: nl,
    pt: pt,
    it: it,
    ru: ru,
    bg: bg,
}
