<template>
    <div>
        <full-width-container class="bg-r-green">
            <content-container padding="lg:py-4 lg:px-12 py-3">
                <stepper :current_step="current_step">
                    {{ $t("navigation.footer.book_roatel") }}
                </stepper>
            </content-container>
        </full-width-container>
        <full-width-container class="bg-r-gray-ultralight">
            <content-container>
                <div class="flex flex-col lg:flex-row gap-5 lg:gap-8">
                    <div class="w-full lg:w-1/3 flex flex-col gap-10">
                        <new-booking-summary-sidebar
                            :property="property"
                            :booking="booking"
                            :show-dates="true"
                            :show-room-count="true"
                            :show-total-amount="true"
                            :show-information="true"
                            :show-imprint-link="true"
                            :show-address="true"
                            :show-guests="true"
                            :show-open-in-map="true"
                            :show-carousel="true"
                            :swiper-toggle-visible="true"
                            :swiper-initial-status="false"
                        >
                            <template v-slot:before>
                                <router-link
                                    class="group inline-flex gap-3 text-r-gray items-center"
                                    :to="{
                                        name: 'bookings.new.summary',
                                    }"
                                >
                                    <icon
                                        icon="arrow-narrow-left"
                                        class="stroke-r-gray w-6 h-6 group-hover:stroke-r-green"
                                    ></icon>
                                    <span
                                        class="group-hover:text-r-green leading-none"
                                        >{{
                                            $t(
                                                "new_reservation.summary.heading"
                                            )
                                        }}</span
                                    ></router-link
                                >
                            </template>
                        </new-booking-summary-sidebar>
                    </div>
                    <div
                        class="w-full lg:w-2/3 flex flex-col gap-5 lg:gap-10 border rounded-lg border-r-gray-light text-r-black bg-white"
                        id="booker_container"
                    >
                        <div>
                            <div class="px-3 py-2 lg:p-8">
                                <h2
                                    class="text-xl lg:text-2xl py-7 italic pb-4 font-black break-words text-r-green text-center"
                                >
                                    {{ $t("new_reservation.booker.heading") }}
                                </h2>
                                <div
                                    class="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-6"
                                >
                                    <app-input
                                        name="companyName"
                                        v-model="booker.companyName"
                                        :label="$t('models.person.companyName')"
                                        :placeholder="
                                            $t('models.person.companyName')
                                        "
                                        type="text"
                                        :errors="errors"
                                        :loading="loading"
                                    >
                                    </app-input>

                                    <app-input
                                        name="companyTaxId"
                                        v-model="booker.companyTaxId"
                                        :label="
                                            $t('models.person.companyTaxId')
                                        "
                                        :placeholder="
                                            $t('models.person.companyTaxId')
                                        "
                                        type="text"
                                        :errors="errors"
                                        :loading="loading"
                                    >
                                    </app-input>

                                    <div class="col-span-full">
                                        <hr class="border-r-gray-light my-2" />
                                    </div>

                                    <app-input
                                        name="firstName"
                                        v-model="booker.firstName"
                                        :label="$t('models.person.firstName')"
                                        :placeholder="
                                            $t('models.person.firstName')
                                        "
                                        :required="true"
                                        type="text"
                                        :errors="errors"
                                        :loading="loading"
                                    >
                                    </app-input>
                                    <app-input
                                        name="lastName"
                                        v-model="booker.lastName"
                                        :label="$t('models.person.lastName')"
                                        :placeholder="
                                            $t('models.person.lastName')
                                        "
                                        :required="true"
                                        type="text"
                                        :errors="errors"
                                        :loading="loading"
                                    >
                                    </app-input>
                                    <app-input
                                        name="email"
                                        v-model="booker.email"
                                        :label="$t('models.person.email')"
                                        :placeholder="$t('models.person.email')"
                                        type="text"
                                        :required="true"
                                        :errors="errors"
                                        :loading="loading"
                                    >
                                    </app-input>
                                    <app-input
                                        :errors="errors"
                                        :loading="loading"
                                        type="phone"
                                        name="phone"
                                        :required="true"
                                        :placeholder="$t('models.person.phone')"
                                        :label="$t('models.person.phone')"
                                        v-model="booker.phone"
                                    />

                                    <div class="col-span-full">
                                        <hr class="border-r-gray-light my-2" />
                                    </div>

                                    <div class="lg:col-span-full">
                                        <div
                                            class="flex flex-col lg:flex-row items-center gap-5"
                                        >
                                            <div class="lg:w-2/3 w-full">
                                                <app-input
                                                    name="addressLine1"
                                                    v-model="
                                                        booker.addressLine1
                                                    "
                                                    :label="
                                                        $t(
                                                            'models.person.addressLine1'
                                                        )
                                                    "
                                                    :placeholder="
                                                        $t(
                                                            'models.person.addressLine1'
                                                        )
                                                    "
                                                    :required="true"
                                                    type="text"
                                                    :errors="errors"
                                                    :loading="loading"
                                                >
                                                </app-input>
                                            </div>
                                            <div class="lg:w-1/3 w-full">
                                                <app-input
                                                    name="postalCode"
                                                    v-model="booker.postalCode"
                                                    :label="
                                                        $t(
                                                            'models.person.postalCode'
                                                        )
                                                    "
                                                    :placeholder="
                                                        $t(
                                                            'models.person.postalCode'
                                                        )
                                                    "
                                                    :required="true"
                                                    type="text"
                                                    :errors="errors"
                                                    :loading="loading"
                                                >
                                                </app-input>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <app-input
                                            name="city"
                                            v-model="booker.city"
                                            :label="$t('models.person.city')"
                                            :placeholder="
                                                $t('models.person.city')
                                            "
                                            :required="true"
                                            type="text"
                                            :errors="errors"
                                            :loading="loading"
                                        >
                                        </app-input>
                                    </div>

                                    <div>
                                        <app-input
                                            v-model="booker.countryCode"
                                            name="countryCode"
                                            :label="
                                                $t('models.person.countryCode')
                                            "
                                            :placeholder="
                                                $t('models.person.countryCode')
                                            "
                                            :value="booker.countryCode"
                                            :selectOptions="countries"
                                            selectLabelProperty="name"
                                            selectValueProperty="alpha-2"
                                            :required="true"
                                            type="selectClassic"
                                            :errors="errors"
                                            :loading="loading"
                                        >
                                        </app-input>
                                    </div>

                                    <div class="col-span-full">
                                        <div v-if="!isLoggedIn">
                                            <app-input
                                                type="checkbox"
                                                :stacked="false"
                                                :stackedReversed="true"
                                                size="lg"
                                                :label="
                                                    $t(
                                                        'models.person.create_account'
                                                    )
                                                "
                                                name="create_account"
                                                v-if="!isLoggedIn"
                                                v-model="booker.create_account"
                                            >
                                            </app-input>
                                            <div class="ml-8 text-sm">
                                                {{
                                                    $t(
                                                        "models.person.account_advantage"
                                                    )
                                                }}
                                            </div>
                                        </div>
                                        <div v-if="isLoggedIn">
                                            <app-input
                                                :stacked="false"
                                                :stackedReversed="true"
                                                type="checkbox"
                                                :label="
                                                    $t(
                                                        'models.person.store_changes_in_profile'
                                                    )
                                                "
                                                :placeholder="
                                                    $t(
                                                        'models.person.store_changes_in_profile'
                                                    )
                                                "
                                                name="storeChangesToProfile"
                                                v-if="isLoggedIn"
                                                v-model="storeChangesToProfile"
                                            >
                                            </app-input>
                                        </div>
                                    </div>

                                    <div class="col-span-full" v-if="error">
                                        <alert>{{
                                            $t("common.form_error")
                                        }}</alert>
                                    </div>

                                    <div
                                        class="col-span-full flex justify-center"
                                    >
                                        <div>
                                            <app-button
                                                @click="submit"
                                                color="green"
                                                :outline="false"
                                                size="xl"
                                                >{{
                                                    $t("buttons.continue")
                                                }}</app-button
                                            >
                                        </div>
                                        <!-- <required-field-info></required-field-info> -->
                                    </div>

                                    <!-- <div class="col-span-full"> -->
                                    <!--     <hr class="border-r-gray-light my-2" /> -->
                                    <!-- </div> -->
                                    <!--  -->
                                    <!-- <div class="flex flex-col space-y-4"></div> -->
                                    <!-- <div class="flex flex-col space-y-4"></div> -->
                                    <div
                                        class="md:col-span-2 flex justify-center"
                                    >
                                        <div
                                            class="flex justify-center"
                                            v-if="!isProductionEnvironment"
                                        >
                                            <app-button
                                                @click="setDefaultData"
                                                color="gray"
                                                :outline="false"
                                                size="lg"
                                                >Testdaten setzen</app-button
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <property-imprint-modal ref="propertyImprintModal">
                </property-imprint-modal>
            </content-container>
        </full-width-container>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import AppInput from "@guest/common/Input";
import AppButton from "@guest/common/Button";
import Countries from "@mixins/Countries";
import RequiredFieldInfo from "@guest/common/RequiredFieldInfo";
import Alert from "@guest/common/Alert";
import Stepper from "@guest/bookings/new/Stepper";
import ClipContainer from "@guest/common/ClipContainer";
import ContentContainer from "@guest/common/ContentContainer";
import FullWidthContainer from "@guest/common/FullWidthContainer.vue";
import PropertyCard from "@guest/bookings/new/PropertyCard";
import BookingBackLink from "@guest/common/BookingBackLink.vue";
import BookingSummarySidebar from "@guest/bookings/new/BookingSummarySidebar.vue";
import PropertyImprintModal from "@guest/common/PropertyImprintModal.vue";
import NewBookingSummarySidebar from "@guest/common/NewBookingSummarySidebar.vue";
import Icon from "@guest/common/Icon";

export default {
    mixins: [Countries],
    components: {
        ContentContainer,
        AppInput,
        AppButton,
        // BookingDraftState,
        RequiredFieldInfo,
        Alert,
        Stepper,
        ClipContainer,
        FullWidthContainer,
        PropertyCard,
        BookingBackLink,
        BookingSummarySidebar,
        PropertyImprintModal,
        NewBookingSummarySidebar,
        Icon,
    },
    watch: {
        bookerStore: {
            handler: function (val) {
                console.log("Booker from store changed", val);
                this.booker = val;
            },
        },
    },
    data: function () {
        return {
            errors: {},
            storeChangesToProfile: true,
            showAllInputs: false,
            error: false,
            booker: {
                firstName: "",
                middleInitial: "",
                lastName: "",
                companyName: "",
                companyTaxId: "",
                addressLine1: "",
                addressLine2: "",
                city: "",
                postalCode: "",
                countryCode: "DE",
                email: "",
                phone: "",
                create_account: false,
            },
        };
    },
    methods: {
        submit() {
            this.errors = {};
            this.error = false;
            this.validateBookingDraftOfferBooker({
                ...this.booker,
                preferredLanguage: this.currentLocale.key,
            })
                .then((response) => {
                    console.log("response submit", response);
                    this.getProfile();
                    let guest_detail = {};
                    if (this.isLoggedIn && this.storeChangesToProfile) {
                        for (const [key, value] of Object.entries(
                            this.booker
                        )) {
                            console.log(`${key}: ${value}`);
                            if (
                                key !== "addressLine1" &&
                                key !== "addressLine2"
                            ) {
                                guest_detail[_.snakeCase(key)] = value;
                                continue;
                            }
                            if (key === "addressLine1") {
                                guest_detail["address_line1"] = value;
                            }
                            if (key === "addressLine2") {
                                guest_detail["address_line2"] = value;
                            }
                        }
                        this.postProfileGuestDetail(guest_detail);
                        console.log("converted booker", guest_detail);
                    }
                    this.$emit("payment");
                })
                .catch((error) => {
                    console.log("error response", error);
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                        this.error = true;
                    }
                })
                .then(() => {
                    //if storing is required then post to profile
                });
        },
        async setDefaultData() {
            await axios.get("faker/user").then((response) => {
                this.booker = response.data;
                this.booker.email = null;
            });
        },
        ...mapActions({
            validateBookingDraftOfferBooker: "validateBookingDraftOfferBooker",
            postProfileGuestDetail: "postProfileGuestDetail",
            getProfile: "getProfile",
        }),
        setDataFromProfile() {
            this.booker.firstName = this.profile.guest_detail.first_name;
            this.booker.middleInitial =
                this.profile.guest_detail.middle_initial;
            this.booker.lastName = this.profile.guest_detail.last_name;
            this.booker.companyName = this.profile.guest_detail.company_name;
            this.booker.addressLine1 = this.profile.guest_detail.address_line1;
            this.booker.addressLine2 = this.profile.guest_detail.address_line2;
            this.booker.city = this.profile.guest_detail.city;
            this.booker.postalCode = this.profile.guest_detail.postal_code;
            this.booker.email = this.profile.guest_detail.email;
            this.booker.phone = this.profile.guest_detail.phone;
            this.booker.countryCode = this.profile.guest_detail.country_code;
            this.booker.create_account = false;
        },
    },
    computed: {
        ...mapState({
            bookerStore: (state) => state.bookingDraft.booker,
            booking: (state) => state.bookingDraft,
            loading: (state) => state.loading,
            profile: (state) => state.profile,
            property: (state) => state.bookingDraft.property,
        }),
        ...mapGetters({
            isLoggedIn: "isLoggedIn",
            currentLocale: "currentLocale",
        }),
        isProductionEnvironment() {
            return process.env.MIX_ENV === "production" ? true : false;
        },
    },
    mounted() {
        this.$emit("step", 4);
        if (this.booking.booker && this.booking.booker.firstName != "") {
            this.booker = this.booking.booker;
        }
        if (this.booker.firstName == "" && this.isLoggedIn) {
            this.setDataFromProfile();
        }
        console.log("Mounted Booker");
        this.$scrollTo("#booker_container", 1000, { offset: -10 });
    },
    props: {
        current_step: {
            required: false,
            default: 4,
        },
    },
};
</script>

<style lang="postcss"></style>
