<template>
    <div>
        <full-width-container class="md:hidden">
            <!-- <content-container> -->
            <!--     <div class="mb-2 md:mb-10 lg:hidden" id="dates"> -->
            <!--         <stepper :current_step="current_step"> </stepper> -->
            <!--     </div> -->
            <!-- </content-container> -->
        </full-width-container>
        <find-roatel-bar
            :search="address"
            @search="getPropertiesByAddress"
            @input="address = $event"
            @geosearch="locate"
            ref="findRoatelBar"
        >
        </find-roatel-bar>
        <app-map
            v-if="properties.length > 0 && $route.params.show === 'map'"
            :properties="properties"
            @property-select="propertySelected($event)"
        >
            MAPS
        </app-map>
        <full-width-container class="bg-r-gray-ultralight">
            <content-container :default-padding="false" class="px-5">
                <div
                    class="flex flex-col w-full"
                    v-if="$route.params.show !== 'map'"
                >
                    <title-bar v-if="properties.length > 0">
                        <template v-if="hasGeolocation && location">
                            {{ $t("new_reservation.find_roatel_geo") }}
                        </template>
                        <template v-else-if="location && !hasGeolocation">
                            {{
                                $t("new_reservation.find_roatel_search", {
                                    location: location,
                                })
                            }}
                        </template>
                        <template v-else>
                            {{ $t("new_reservation.find_roatel_all") }}
                        </template>
                    </title-bar>
                    <transition
                        mode="in-out"
                        enter-active-class="transition-all duration-300 ease-in-out"
                        leave-active-class="transition-all duration-300 ease-in-out"
                        enter-class="h-0 opacity-50 transform"
                        enter-to-class="h-auto opacity-100"
                        leave-class="h-auto opacity-100"
                        leave-to-class="h-0 opacity-50 transform"
                    >
                        <alert v-if="geolocationError">
                            <div class="font-medium">
                                {{
                                    $t("new_reservation.geosearch_error.error")
                                }}
                            </div>
                            <div>
                                {{
                                    $t(
                                        "new_reservation.geosearch_error." +
                                            geolocationErrorCodes
                                    )
                                }}
                            </div>
                        </alert>
                    </transition>
                    <transition
                        mode="out-in"
                        enter-active-class="transition-all duration-150 ease-in-out"
                        leave-active-class="transition-all duration-150 ease-in-out"
                        enter-class="opacity-0 scale-75 transform translate-y-48"
                        enter-to-class="opacity-100 scale-100"
                        leave-class="opacity-100 scale-100"
                        leave-to-class="opacity-0 scale-75 transform -translate-y-48"
                    >
                        <div
                            class="w-full grid grid-cols-1 md:grid-cols-3 gap-5 mb-20"
                            v-if="properties.length > 0"
                        >
                            <property-card
                                :key="property.uuid"
                                :property="property"
                                @property-select="propertySelected($event)"
                                hoverPointerClass="hover:cursor-pointer"
                                :show-image="true"
                                :show-address="true"
                                :show-open-in-map="true"
                                :show-checkin-until="true"
                                :show-distance="true"
                                :show-book-room-button="true"
                                show-book-room-button-class="lg:hidden"
                                v-for="property in properties"
                                @property-imprint="
                                    $refs.propertyImprintModal.$refs.modal_imprint.show(
                                        $event
                                    )
                                "
                                :show-room-information-button="true"
                            >
                                <template v-slot:after="{ property }">
                                </template>
                            </property-card>
                        </div>
                        <div v-else class="md:cutter-big">
                            <div
                                class="flex flex-col space-y-6 justify-center items-center py-10 px-6 bg-r-black-5 text-r-gray md:p-16 px-5 py-8"
                            >
                                <div>
                                    <font-awesome-icon
                                        icon="spinner"
                                        class="text-4xl text-r-green"
                                        spin
                                    ></font-awesome-icon>
                                </div>
                                <div
                                    class="font-slanted text-center text-r-green italic uppercase text-2xl font-black"
                                >
                                    Roatels werden geladen
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
                <property-imprint-modal ref="propertyImprintModal">
                </property-imprint-modal>
            </content-container>
        </full-width-container>
    </div>
</template>

<script>
import ContentContainer from "@guest/common/ContentContainer";
import ClipContainer from "@guest/common/ClipContainer";
import AppInput from "@admin/common/Input";
import AppButton from "@admin/common/Button";
import { mapActions, mapState } from "vuex";
import PropertyCard from "@guest/PropertyCard";
import FindRoatelBar from "@guest/layout/FindRoatelBar";
import TitleBar from "@guest/common/TitleBar";
import FullWidthContainer from "@guest/common/FullWidthContainer.vue";
import Alert from "@guest/common/Alert";
import Stepper from "@guest/bookings/new/Stepper.vue";
import AppMap from "@guest/bookings/new/Map.vue";
import PropertyImprintModal from "@guest/common/PropertyImprintModal.vue";

export default {
    components: {
        ClipContainer,
        ContentContainer,
        AppButton,
        AppInput,
        PropertyCard,
        FindRoatelBar,
        TitleBar,
        FullWidthContainer,
        Alert,
        Stepper,
        AppMap,
        PropertyImprintModal,
    },
    data: function () {
        return {
            loc: "",
            geolocation: {},
            address: "",
            isLocating: false,
            hasGeolocation: false,
            geolocationError: false,
            geolocationErrorCode: 0,
            hasResults: false,
            searchLocation: null,
            error: "",
        };
    },
    watch: {
        hasGeolocation: {
            handler: function (value) {
                console.log("hasGeolocation has changed", value);
                if (value === true) {
                    this.getCloseProperties();
                }
            },
        },
    },
    computed: {
        geolocationErrorCodes() {
            let type;
            switch (this.geolocationErrorCode) {
                case 1:
                    type = "PERMISSION_DENIED";
                    break;
                case 2:
                    type = "POSITION_UNAVAILABLE";
                    break;
                case 3:
                    type = "POSITION_UNAVAILABLE";
                    break;
                default:
                    constant = null;
            }
            return type;
        },
        hasProperties() {
            return this.properties.length > 0 ? true : false;
        },
        ...mapState({
            properties: (state) => state.search.properties,
            location: (state) => state.search.location,
        }),
    },
    methods: {
        ...mapActions({
            setSearchResult: "setSearchResult",
            resetSearchResult: "resetSearchResult",
            resetBookingDraft: "resetBookingDraft",
        }),
        async getPropertiesByAddress() {
            this.setSearchResult({ data: [], location: null });
            this.hasGeolocation = false;
            this.setLoading(true);
            await axios
                .get("bookings/get_properties_by_address/" + this.address)
                .then((response) => {
                    this.setSearchResult(response.data);
                    //this.$emit('properties-list')
                })
                .catch((error) => {
                    console.log(error);
                })
                .then(() => {
                    this.setLoading(false);
                    if (this.$route.params.show === "map") {
                        this.$router.push({ params: { show: "cards" } });
                    }
                });
        },
        // async getAllProperties() {
        //     this.setSearchResult({ data: [], location: null });
        //     this.setLoading(true);
        //     await axios
        //         .get(
        //             "bookings/close_properties/" +
        //                 this.geolocation.latitude +
        //                 "/" +
        //                 this.geolocation.longitude
        //         )
        //         .then((response) => {
        //             this.setSearchResult(response.data);
        //             //this.$emit('properties-list')
        //         })
        //         .catch((error) => {
        //             console.log(error);
        //         })
        //         .then(() => {
        //             this.setLoading(false);
        //         });
        // },
        async getCloseProperties() {
            this.setSearchResult({ data: [], location: null });
            this.setLoading(true);
            await axios
                .get(
                    "bookings/close_properties/" +
                        this.geolocation.latitude +
                        "/" +
                        this.geolocation.longitude
                )
                .then((response) => {
                    this.setSearchResult(response.data);
                    //this.$emit('properties-list')
                })
                .catch((error) => {
                    console.log(error);
                })
                .then(() => {
                    if (this.$route.params.show === "map") {
                        this.$router.push({ params: { show: "cards" } });
                    }
                    this.setLoading(false);
                });
        },
        async getLocation() {
            return new Promise((resolve, reject) => {
                if (!("geolocation" in navigator)) {
                    reject(new Error("Geolocation is not available."));
                }
                navigator.geolocation.getCurrentPosition(
                    (pos) => {
                        resolve(pos);
                    },
                    (err) => {
                        reject(err);
                    }
                );
            });
        },
        ...mapActions({
            setLoading: "setLoading",
            setBookingDraftProperty: "setBookingDraftProperty",
        }),
        async locate() {
            this.address = "";
            this.setSearchResult({ data: [], location: null });
            this.setLoading(true);
            this.geolocationError = false;
            this.geolocationErrorCode = 0;
            this.isLocating = true;
            this.hasGeolocation = false;
            await this.getLocation()
                .then((loc) => {
                    console.log(loc.coords);
                    this.$set(this, "geolocation", {
                        latitude: loc.coords.latitude,
                        longitude: loc.coords.longitude,
                    });
                    this.hasGeolocation = true;
                })
                .catch((error) => {
                    console.log(error);
                    this.hasGeolocation = false;
                    this.geolocationError = true;
                    this.geolocationErrorCode = error.code;
                    this.address = "";
                    this.getPropertiesByAddress();
                })
                .then(() => {
                    this.setLoading(false);
                    this.isLocating = false;
                });
        },
        search() {},
        propertySelected(property) {
            this.setBookingDraftProperty(property);
            this.$router.push({ name: "bookings.new.dates" });

            console.log("selected property", property);
        },
    },
    props: {
        current_step: {
            required: true,
            default: 1,
        },
    },
    async mounted() {
        this.resetBookingDraft();
        this.$emit("step", 1);
        // this.locate();
        console.log("route", this.$route);
        if (this.$route.name === "bookings.new.search_properties.geosearch") {
            this.address = this.$route.params.search;
        }
        this.getPropertiesByAddress();
        if (
            this.$route.name === "bookings.new.search_properties.locationsearch"
        ) {
            await this.locate();
        }
        this.$scrollTo("#top");
        console.log("route", this.$route);
    },
};
</script>

<style></style>
