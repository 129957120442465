<template>
    <div>
        <full-width-container class="bg-r-green">
            <content-container padding="lg:py-4 lg:px-12 py-3">
                <stepper :current_step="current_step">
                    {{ $t("navigation.footer.book_roatel") }}
                </stepper>
            </content-container>
        </full-width-container>
        <full-width-container class="bg-r-gray-ultralight">
            <content-container>
                <div class="flex justify-center mb-20">
                    <div
                        class="border border-r-gray-light p-5 lg:p-16 grid gap-8 bg-white rounded-lg w-full lg:w-[610px] text-center"
                    >
                        <div class="flex flex-col gap-4 justify-center">
                            <div class="items-center mx-auto">
                                <icon
                                    icon="check-circle"
                                    class="w-12 h-12 stroke-r-green"
                                ></icon>
                            </div>
                            <h2
                                class="text-2xl md:text-3xl font-black italic text-r-green text-center"
                            >
                                {{ $t("new_reservation.completed.confirmed") }}
                            </h2>
                        </div>
                        <div class="leading-relaxed">
                            <div>
                                {{
                                    $t(
                                        "new_reservation.completed.confirmation_message"
                                    )
                                }}
                            </div>
                        </div>
                        <div>
                            <div class="grid gap-2">
                                <div>
                                    {{
                                        $t(
                                            "new_reservation.completed.booking_number"
                                        )
                                    }}
                                </div>
                                <div class="font-bold">
                                    {{
                                    $route.params.id,
                                    }}
                                </div>
                            </div>
                        </div>
                        <div class="flex justify-center mt-5">
                            <div>
                                <app-button
                                    v-if="!isLoggedIn"
                                    class="text-base"
                                    @click="$router.push({ name: 'home' })"
                                    color="green"
                                >
                                    {{
                                        $t(
                                            "new_reservation.completed.home_button"
                                        )
                                    }}
                                </app-button>
                                <app-button
                                    v-else
                                    class="text-base"
                                    @click="
                                        $router.push({ name: 'bookings.list' })
                                    "
                                    color="green"
                                >
                                    {{ $t("navigation.my_reservations") }}
                                </app-button>
                            </div>
                        </div>
                    </div>
                </div>
            </content-container>
        </full-width-container>
    </div>
</template>

<script>
import FullWidthContainer from "@guest/common/FullWidthContainer.vue";
import ContentContainer from "@guest/common/ContentContainer";
import AppButton from "@guest/common/Button";
import { mapGetters } from "vuex";
import ClipContainer from "@guest/common/ClipContainer";
import Stepper from "@guest/bookings/new/Stepper.vue";
import Icon from "@guest/common/Icon";

export default {
    computed: {
        ...mapGetters({
            currentLocale: "currentLocale",
            isLoggedIn: "isLoggedIn",
        }),
    },
    components: {
        ContentContainer,
        Icon,
        AppButton,
        ClipContainer,
        FullWidthContainer,
        Stepper,
    },
    props: {
        current_step: {
            required: true,
            default: 6,
        },
    },
    mounted() {
        this.$emit("step", 6);
        this.$scrollTo("#top");
    },
};
</script>

<style></style>
