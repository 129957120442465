var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col mt-5"},[_c('div',{staticClass:"text-sm flex flex-row space-x-2 border-t-2 last:border-b-2 border-r-black-50 py-4"},[_c('div',{staticClass:"w-1/2 font-bold truncate"},[_vm._v("\n            "+_vm._s(_vm.$t("reservations.summary.booking"))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"w-1/2"},[_vm._v("\n            "+_vm._s(_vm.reservation.apaleo_id)+"\n        ")])]),_vm._v(" "),_c('div',{staticClass:"text-sm flex flex-row space-x-2 border-t-2 last:border-b-2 border-r-black-50 py-4"},[_c('div',{staticClass:"w-1/2 font-bold"},[_vm._v("\n            "+_vm._s(_vm.$t("reservations.summary.guest_heading"))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"w-1/2"},[_vm._v("\n            "+_vm._s(_vm.reservation.primary_guest.first_name)+"\n            "+_vm._s(_vm.reservation.primary_guest.last_name)+"\n        ")])]),_vm._v(" "),_c('div',{staticClass:"text-sm flex flex-row space-x-2 border-t-2 last:border-b-2 border-r-black-50 py-4"},[_c('div',{staticClass:"w-1/2 font-bold"},[_vm._v("\n            "+_vm._s(_vm.$t("reservations.card.status"))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"w-1/2"},[_c('reservation-status',{attrs:{"status":_vm.reservation.status}})],1)]),_vm._v(" "),_c('div',{staticClass:"text-sm flex flex-row space-x-2 border-t-2 last:border-b-2 border-r-black-50 py-4"},[_c('div',{staticClass:"w-1/2 font-bold"},[_vm._v("\n            "+_vm._s(_vm.$t("new_reservation.dates.check_in_label"))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"w-1/2"},[_vm._v("\n            "+_vm._s(_vm.$t("common.checkin_datetime", {
                    date: _vm.date(_vm.reservation.arrival, "date_localized"),
                    time: _vm.date(_vm.reservation.arrival, "time_localized"),
                }))+"\n        ")])]),_vm._v(" "),_c('div',{staticClass:"text-sm flex flex-row space-x-2 border-t-2 last:border-b-2 border-r-black-50 py-4"},[_c('div',{staticClass:"w-1/2 font-bold"},[_vm._v("\n            "+_vm._s(_vm.$t("new_reservation.dates.check_out_label_automatic"))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"w-1/2"},[_vm._v("\n            "+_vm._s(_vm.$t("common.checkout_datetime", {
                    date: _vm.date(_vm.reservation.departure, "date_localized"),
                    time: _vm.date(_vm.reservation.departure, "time_localized"),
                }))+"\n        ")])]),_vm._v(" "),_c('div',{staticClass:"text-sm flex flex-row space-x-2 border-t-2 border-r-black-50 py-4"},[_c('div',{staticClass:"w-1/2 font-bold truncate"},[_vm._v("\n            "+_vm._s(_vm.$t("new_reservation.booker.heading"))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"w-1/2 flex flex-col"},[_c('div',{staticClass:"font-bold"},[_vm._v("\n                "+_vm._s(_vm.reservation.booker.first_name)+"\n                "+_vm._s(_vm.reservation.booker.last_name)+"\n            ")]),_vm._v(" "),(_vm.reservation.booker.company_name)?_c('div',[_vm._v("\n                "+_vm._s(_vm.reservation.booker.company_name)+"\n            ")]):_vm._e(),_vm._v(" "),(_vm.reservation.booker.company_tax_id)?_c('div',[_vm._v("\n                "+_vm._s(_vm.$t("models.person.companyTaxId"))+":\n                "+_vm._s(_vm.reservation.booker.company_tax_id)+"\n            ")]):_vm._e(),_vm._v(" "),_c('div',[_vm._v("\n                "+_vm._s(_vm.reservation.booker.address_line1)+"\n            ")]),_vm._v(" "),_c('div',[_vm._v("\n                "+_vm._s(_vm.reservation.booker.postal_code)+"\n                "+_vm._s(_vm.reservation.booker.city)+"\n            ")]),_vm._v(" "),(_vm.reservation.booker.country_code != 'DE')?_c('div',[_vm._v("\n                "+_vm._s(_vm.reservation.booker.country_code)+"\n            ")]):_vm._e(),_vm._v(" "),(
                    _vm.reservation.status == 'Confirmed' ||
                    _vm.reservation.status == 'InHouse'
                )?_c('div',{staticClass:"flex justify-center pt-2"}):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"pb-4"},[(
                _vm.$route.name !== 'bookings.edit.booker' &&
                [
                    'Confirmed',
                    'InHouse',
                    'CheckedOut',
                    'NoShow',
                    'Canceled',
                ].includes(_vm.reservation.status)
            )?_c('app-button',{attrs:{"color":"gray"},on:{"click":function($event){return _vm.$router.push({
                    name: 'bookings.edit.booker',
                    params: _vm.reservationQueryPayload,
                })}}},[_vm._v("\n            "+_vm._s(_vm.$t("reservations.summary.button_booker_change"))+"\n        ")]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"text-r-green text-xl md:text-2xl font-bold flex flex-row space-x-2 border-t-2 last:border-b-2 border-r-black-50 py-4"},[_c('div',{staticClass:"w-1/2 truncate"},[_vm._v("\n            "+_vm._s(_vm.$t("new_reservation.payment.amount"))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"w-1/2"},[_vm._v("\n            "+_vm._s(_vm.currency(
                    _vm.reservation.total_gross_amount +
                        _vm.reservation.city_tax_amount,
                    _vm.reservation.total_gross_currency
                ))+"\n        ")])]),_vm._v(" "),_c('ul',{staticClass:"text-sm list-disc list-outside pl-5"},[(_vm.reservation.has_city_tax)?_c('li',[_vm._v("\n            "+_vm._s(_vm.$t("new_reservation.payment.including_city_tax", {
                    amount: _vm.currency(_vm.reservation.city_tax_amount),
                }))+"\n        ")]):_vm._e(),_vm._v(" "),_c('li',[_vm._v("\n            "+_vm._s(_vm.$t("new_reservation.offers.offer_card.price_information"))+"\n        ")]),_vm._v(" "),_c('li',[_vm._v("\n            "+_vm._s(_vm.$t(
                    "apaleo.cancellationFee." +
                        _vm.reservation.cancellation_fee.code +
                        ".description"
                ))+"\n        ")])]),_vm._v(" "),(
            _vm.checkInEnabled &&
            _vm.$route.name.substring(0, 21) !== 'bookings.edit.checkin'
        )?_c('div',{staticClass:"mt-10"},[_c('app-button',{attrs:{"disabled":!_vm.checkInEnabled && !_vm.isCheckingIn,"color":"green"},on:{"click":_vm.canCheckIn}},[_vm._v("\n            "+_vm._s(_vm.$t("reservations.edit.button_check_in"))+"\n        ")])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }