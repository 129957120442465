<template>
    <div class="flex"
         :class="[stackedClass]"
            >
        <div class="border-transparent" v-if="hasLabel">
            <slot name="label"></slot>
        </div>
        <div class="border-transparent">
            <slot></slot>
        </div>
    </div>

</template>

<script>
export default {
    computed: {
        stackedClass() {
            if (!this.stacked && this.stackedReversed) {
                return 'flex-row-reverse space-x-2 space-x-reverse'
            }
            return this.stacked === true ? 'flex-col space-y-2' : 'flex-row space-x-2'
        },
    },
    props: {
        stacked: {
            required: false,
            default: true
        },
        stackedReversed: {
            required: false,
            default: false,
        },
        hasLabel: {
            required: true,
        }
    }

}
</script>

<style>

</style>
