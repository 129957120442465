var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('full-width-container',{staticClass:"bg-r-green"},[_c('content-container',{attrs:{"padding":"lg:py-4 lg:px-12 py-3"}},[_c('stepper',{attrs:{"current_step":_vm.current_step}},[_vm._v("\n                "+_vm._s(_vm.$t("navigation.footer.book_roatel"))+"\n            ")])],1)],1),_vm._v(" "),_c('full-width-container',{staticClass:"bg-r-gray-ultralight"},[_c('content-container',[_c('div',{staticClass:"flex flex-col lg:flex-row gap-5 text-r-black"},[_c('div',{staticClass:"w-full lg:w-1/3 flex flex-col gap-10"},[_c('new-booking-summary-sidebar',{attrs:{"property":_vm.property,"booking":_vm.booking,"show-dates":true,"show-room-count":true,"show-total-amount":true,"show-information":true,"show-imprint-link":true,"show-open-in-map":true,"show-address":true,"show-carousel":true,"swiper-toggle-visible":true,"swiper-initial-status":false},scopedSlots:_vm._u([{key:"before",fn:function(){return [_c('router-link',{staticClass:"group inline-flex gap-3 text-r-gray items-center",attrs:{"to":{
                                    name: 'bookings.new.dates',
                                    params: {
                                        property: _vm.property.apaleo_id,
                                        checkin: _vm.date(
                                            _vm.dates.checkIn,
                                            'date_iso'
                                        ),
                                        checkout: _vm.date(
                                            _vm.dates.checkOut,
                                            'date_iso'
                                        ),
                                    },
                                }}},[_c('icon',{staticClass:"stroke-r-gray w-6 h-6 group-hover:stroke-r-green",attrs:{"icon":"arrow-narrow-left"}}),_vm._v(" "),_c('span',{staticClass:"group-hover:text-r-green leading-none"},[_vm._v(_vm._s(_vm.$t("new_reservation.dates.heading")))])],1)]},proxy:true}])})],1),_vm._v(" "),_c('div',{staticClass:"w-full flex flex-col lg:w-2/3",attrs:{"id":"summary_container"}},[_c('div',{staticClass:"bg-white rounded-lg border border-r-gray-light"},[_c('div',{staticClass:"px-3 py-2 lg:p-6"},[_c('h2',{staticClass:"text-xl lg:text-2xl italic font-black text-r-green lg:py-12 py-7 text-center"},[_vm._v("\n                                "+_vm._s(_vm.$t("new_reservation.summary.heading"))+"\n                            ")]),_vm._v(" "),_c('div',{staticClass:"grid grid-cols-1 gap-3 lg:gap-5"},[_vm._l((_vm.reservations),function(reservation,reservation_index){return _c('guest-reservation-card',{key:reservation_index,attrs:{"booker":_vm.booker,"errors":_vm.errors,"index":reservation_index,"reservations":_vm.reservations,"reservation":reservation},on:{"change":function($event){_vm.reservations[reservation_index] =
                                            $event}}})}),_vm._v(" "),_c('div',[_c('div',{staticClass:"flex flex-col gap-3"},[_c('div',{staticClass:"flex flex-row items-start gap-3"},[_c('div',{staticClass:"w-8"},[_c('app-input',{attrs:{"name":"accepted_terms","type":"checkbox","hasLabel":false},model:{value:(_vm.accepted_terms),callback:function ($$v) {_vm.accepted_terms=$$v},expression:"accepted_terms"}})],1),_vm._v(" "),_c('i18n',{staticClass:"text-sm",attrs:{"path":"new_reservation.summary.terms_accept","tag":"label"},on:{"click":function($event){_vm.accepted_terms =
                                                        !_vm.accepted_terms}}},[_c('a',{staticClass:"underline underline-offset-1",attrs:{"href":_vm.property.final_terms_url,"target":"_blank"},on:{"click":function($event){$event.stopPropagation();return null.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t(
                                                            "new_reservation.summary.terms"
                                                        )))])])],1),_vm._v(" "),_c('div',{staticClass:"flex flex-row items-start gap-3"},[_c('div',{staticClass:"w-8"},[_c('app-input',{attrs:{"name":"accepted_privacy","type":"checkbox","hasLabel":false},model:{value:(
                                                        _vm.accepted_privacy
                                                    ),callback:function ($$v) {
                                                        _vm.accepted_privacy
                                                    =$$v},expression:"\n                                                        accepted_privacy\n                                                    "}})],1),_vm._v(" "),_c('i18n',{staticClass:"text-sm",attrs:{"path":"new_reservation.summary.privacy_accept","tag":"label"},on:{"click":function($event){_vm.accepted_privacy =
                                                        !_vm.accepted_privacy}}},[_c('a',{staticClass:"underline underline-offset-1",attrs:{"href":_vm.property.final_privacy_url,"target":"_blank"},on:{"click":function($event){$event.stopPropagation();return null.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t(
                                                            "new_reservation.summary.privacy"
                                                        )))])])],1),_vm._v(" "),_c('div',{staticClass:"flex flex-row gap-3 items-start"},[_c('div',{staticClass:"w-8"},[_c('app-input',{attrs:{"name":"marketing_signup","type":"checkbox","has-label":false},model:{value:(
                                                        _vm.marketing_signup
                                                    ),callback:function ($$v) {
                                                        _vm.marketing_signup
                                                    =$$v},expression:"\n                                                        marketing_signup\n                                                    "}})],1),_vm._v(" "),_c('div',{staticClass:"w-full inline-flex"},[_c('i18n',{staticClass:"text-sm",attrs:{"path":"new_reservation.summary.marketing_signup","tag":"label"},on:{"click":function($event){_vm.marketing_signup =
                                                            !_vm.marketing_signup}}},[_c('a',{staticClass:"underline underline-offset-1",attrs:{"href":"https://www.roatel.com/datenschutz-booking","target":"_blank"},on:{"click":function($event){$event.stopPropagation();return null.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t(
                                                                "new_reservation.summary.privacy"
                                                            )))])])],1)])])]),_vm._v(" "),(_vm.error)?_c('div',[_c('alert',[_vm._v(_vm._s(_vm.$t("common.form_error")))])],1):_vm._e(),_vm._v(" "),_c('div',[_c('div',{staticClass:"flex justify-center"},[_c('div',[_c('app-button',{attrs:{"disabled":!_vm.accepted_all,"color":"green","size":"lg","outline":false},on:{"click":_vm.submit}},[_vm._v("\n                                                "+_vm._s(_vm.$t("buttons.continue"))+"\n                                            ")])],1)])])],2)])])])]),_vm._v(" "),_c('property-imprint-modal',{ref:"propertyImprintModal"})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }