import Countries from '@/common/countries'

export default {
    data: function () {
        return {
            countries: Countries,
        }
    },
    methods: {
        getCountryLabel(code) {
            let result = this.countries.find(item => item['alpha-2'] === code)
            return result !== undefined ? result.name : code
        }
    }
}
