<template>
    <div class="px-4 py-2 bg-gray-100 border-b border-gray-300">
        <div class="flex flex-row items-center justify-between">
            <app-button @click="$emit('back')" color="transparent">
                <font-awesome-icon icon="arrow-left"></font-awesome-icon>
            </app-button>
            <div class="font-bold" v-if="property && property.hasOwnProperty('uuid')">
                roatel {{property.name}}
            </div>
            <app-button @click="$emit('reset')" color="transparent">
                <font-awesome-icon icon="undo-alt"></font-awesome-icon>
            </app-button>
        </div>
    </div>
</template>

<script>

import {mapState, mapActions} from 'vuex'
import AppButton from '@admin/common/Button'
import DayJS from '@mixins/DayJS'

export default {
    mixins: [
        DayJS,
    ],
    components: {
        AppButton,
    },
    computed: {
        ...mapState({
            booking: 'bookingDraft',
            property: state => state.bookingDraft.property,
            dates: state => state.bookingDraft.dates,
            services: state => state.bookingDraft.services,
            offer: state => state.bookingDraft.offer,
            booker: state => state.bookingDraft.booker,
            dates: state => state.bookingDraft.dates,
            reservationsStore: state => state.bookingDraft.reservations,
        }),
    },
    mounted() {
    }
}
</script>

<style>

</style>
