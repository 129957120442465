import {mapActions} from 'vuex'
import ReservationQueryPayload from '@mixins/ReservationQueryPayload'

export default {
    mixins: [
        ReservationQueryPayload,
    ],
    methods: {
        openLock() {
            this.postUserReservationOpenLock(
                {
                    id: this.reservationQueryPayload
                })
                .then(response => {
                    console.log(response.data)
                })
                .catch(error => {
                    console.log('cant open lock', error.response)
                })
        },
        ...mapActions({
            postUserReservationOpenLock: 'postUserReservationOpenLock',
        }),
    },
}
