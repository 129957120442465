<template>
    <div
        class="hidden md:bottom-10 md:flex md:flex-col md:items-center md:space-y-4 md:justify-center">
        <div>
            <user-image :user="profile"></user-image>
        </div>
        <div>
            {{profile.name}}
        </div>
    </div>
</template>

<script>

import {mapState} from 'vuex'
import UserImage from '@admin/common/UserImage'

export default {
    components: {
        UserImage,
    },
    data: function() {
        return {

        }
    },
    computed: {
        ...mapState({
            profile: 'profile'
        })
    }
}
</script>

<style>

</style>
