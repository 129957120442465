<template>
    <div>
        <full-width-container class="bg-r-gray-ultralight">
            <title-bar>
                {{ $t("password.forgot.title") }}
            </title-bar>
            <!-- <div class="w-full"> -->
            <!--     <clip-container class="md:cutter-big"> -->
            <!--         <div -->
            <!--             class="bg-r-green text-white px-5 py-8 md:p-16 flex flex-col space-y-6" -->
            <!--         > -->
            <!--             <h2 -->
            <!--                 class="font-slanted text-2xl md:text-4xl italic font-black uppercase" -->
            <!--             > -->
            <!--                 {{ $t("password.forgot.heading") }} -->
            <!--             </h2> -->
            <!--             <div> -->
            <!--                 <div> -->
            <!--                     {{ $t("password.forgot.lead") }} -->
            <!--                 </div> -->
            <!--             </div> -->
            <!--             <div> -->
            <!--                 <app-input -->
            <!--                     :placeholder="$t('models.account.email')" -->
            <!--                     :required="true" -->
            <!--                     :errors="errors" -->
            <!--                     v-model="email" -->
            <!--                     type="text" -->
            <!--                     :label="$t('models.account.email')" -->
            <!--                     name="email" -->
            <!--                 > -->
            <!--                 </app-input> -->
            <!--             </div> -->
            <!--             <app-button -->
            <!--                 @click="submit" -->
            <!--                 color="green" -->
            <!--                 :outline="false" -->
            <!--             > -->
            <!--                 {{ $t("password.forgot.button_forgot") }} -->
            <!--             </app-button> -->
            <!--             <alert type="success" v-if="success"> -->
            <!--                 {{ $t("password.forgot.success") }} -->
            <!--             </alert> -->
            <!--         </div> -->
            <!--     </clip-container> -->
            <!-- </div> -->
        </full-width-container>
        <full-width-container class="bg-r-gray-ultralight">
            <content-container class="flex justify-center" padding="">
                <div
                    class="p-16 bg-white border border-gray-light grid gap-8 rounded-lg w-[600px]"
                >
                    <div class="grid gap-4">
                        <h2 class="text-r-green text-2xl italic font-black">
                            {{ $t("password.forgot.heading") }}
                        </h2>
                        <div>{{ $t("password.forgot.lead") }}</div>
                    </div>
                    <div>
                        <app-input
                            :placeholder="$t('models.account.email')"
                            :required="true"
                            :errors="errors"
                            v-model="email"
                            type="text"
                            :label="$t('models.account.email')"
                            name="email"
                        >
                        </app-input>
                    </div>
                    <div class="flex justify-center gap-5 flex-col">
                        <alert type="success" v-if="success">
                            {{ $t("password.forgot.success") }}
                        </alert>
                        <div class="flex justify-center">
                            <div>
                                <app-button
                                    @click="submit"
                                    color="green"
                                    :outline="false"
                                >
                                    {{ $t("password.forgot.button_forgot") }}
                                </app-button>
                            </div>
                        </div>
                    </div>
                </div>
            </content-container>
        </full-width-container>
        <full-width-container class="bg-r-gray-ultralight pt-6 pb-24">
            <content-container padding="p-0">
                <div class="flex justify-center">
                    <div class="gap-2 text-sm">
                        <router-link
                            :to="{ name: 'register' }"
                            class="inline-flex items-center gap-5"
                        >
                            <span>
                                {{ $t("login.register") }}
                            </span>
                            <icon
                                class="w-6 h-6 stroke-r-black"
                                icon="chevron-right"
                            ></icon>
                        </router-link>
                    </div>
                </div>
            </content-container>
        </full-width-container>
    </div>
</template>
<script>
import ContentContainer from "@guest/common/ContentContainer";
import ClipContainer from "@guest/common/ClipContainer.vue";
import FullWidthContainer from "@guest/common/FullWidthContainer.vue";
import AppInput from "@guest/common/Input";
import AppButton from "@guest/common/Button";
import Alert from "@guest/common/Alert";
import TitleBar from "@guest/common/TitleBar";
import { mapActions } from "vuex";
import Icon from "@guest/common/Icon";

export default {
    components: {
        TitleBar,
        Icon,
        ContentContainer,
        ClipContainer,
        AppInput,
        AppButton,
        FullWidthContainer,
        Alert,
    },
    data: function () {
        return {
            email: "",
            errors: {},
            status: "",
            success: false,
        };
    },
    methods: {
        ...mapActions({
            setLoading: "setLoading",
        }),
        submit() {
            this.errors = {};
            this.status = "";
            this.success = false;
            this.setLoading(true);
            axios
                .post("forgot-password", { email: this.email })
                .then((response) => {
                    console.log("response", response.data);
                    this.success = true;
                })
                .catch((error) => {
                    this.errors = error.response.data.errors;
                    (this.status = error.response.data.message),
                        console.log("error", error);
                })
                .then(() => {
                    this.setLoading(false);
                });
        },
    },
};
</script>

<style></style>
