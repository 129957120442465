export default {
    data: function () {
        return {
            genders: [
                { id: null, label: '-' },
                { id: 'Male', label: 'Male' },
                { id: 'Female', label: 'Female' },
                { id: 'Other', label: 'Other' },
            ]
        }
    },
}
