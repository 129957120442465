<template>
    <label :class="labelClass" :for="id">
        <slot></slot>
        <template v-if="required">
            <span class="font-bold">*</span>
        </template>
    </label>
</template>

<script>
export default {
    computed: {
        labelClass() {
            let classes = [];
            classes.push(this.labelClasses);
            if (this.error) {
                // classes.push("text-red-600");
            }
            if (this.required) {
                // classes.push("font-bold");
            }
            if (this.labelUppercase) {
                classes.push("uppercase");
            }
            return classes;
        },
    },
    props: {
        error: {
            required: false,
            default: false,
        },
        disabled: {
            required: false,
            default: false,
        },
        labelWidth: {
            required: false,
            default: null,
        },
        id: {
            required: true,
        },
        required: {
            required: false,
            default: false,
        },
        labelUppercase: {
            required: false,
            default: false,
        },
        labelClasses: {
            required: false,
            type: String | null,
            default: "",
        },
    },
};
</script>

<style></style>
