<template>
    <div class="flex flex-col mt-5">
        <!-- Checkin -->
        <div
            class="text-sm flex flex-row space-x-2 border-t-2 last:border-b-2 border-r-black-50 py-4"
        >
            <div class="w-1/2 font-bold truncate">
                {{ $t("reservations.summary.booking") }}
            </div>
            <div class="w-1/2">
                {{ reservation.apaleo_id }}
            </div>
        </div>
        <div
            class="text-sm flex flex-row space-x-2 border-t-2 last:border-b-2 border-r-black-50 py-4"
        >
            <div class="w-1/2 font-bold">
                {{ $t("reservations.summary.guest_heading") }}
            </div>
            <div class="w-1/2">
                {{ reservation.primary_guest.first_name }}
                {{ reservation.primary_guest.last_name }}
            </div>
        </div>
        <div
            class="text-sm flex flex-row space-x-2 border-t-2 last:border-b-2 border-r-black-50 py-4"
        >
            <div class="w-1/2 font-bold">
                {{ $t("reservations.card.status") }}
            </div>
            <div class="w-1/2">
                <reservation-status
                    :status="reservation.status"
                ></reservation-status>
            </div>
        </div>
        <div
            class="text-sm flex flex-row space-x-2 border-t-2 last:border-b-2 border-r-black-50 py-4"
        >
            <div class="w-1/2 font-bold">
                {{ $t("new_reservation.dates.check_in_label") }}
            </div>
            <div class="w-1/2">
                {{
                    $t("common.checkin_datetime", {
                        date: date(reservation.arrival, "date_localized"),
                        time: date(reservation.arrival, "time_localized"),
                    })
                }}
            </div>
        </div>
        <!-- Checkout -->
        <div
            class="text-sm flex flex-row space-x-2 border-t-2 last:border-b-2 border-r-black-50 py-4"
        >
            <div class="w-1/2 font-bold">
                {{ $t("new_reservation.dates.check_out_label_automatic") }}
            </div>
            <div class="w-1/2">
                {{
                    $t("common.checkout_datetime", {
                        date: date(reservation.departure, "date_localized"),
                        time: date(reservation.departure, "time_localized"),
                    })
                }}
            </div>
        </div>
        <!-- booker -->
        <div
            class="text-sm flex flex-row space-x-2 border-t-2 border-r-black-50 py-4"
        >
            <div class="w-1/2 font-bold truncate">
                {{ $t("new_reservation.booker.heading") }}
            </div>
            <div class="w-1/2 flex flex-col">
                <div class="font-bold">
                    {{ reservation.booker.first_name }}
                    {{ reservation.booker.last_name }}
                </div>
                <div v-if="reservation.booker.company_name">
                    {{ reservation.booker.company_name }}
                </div>
                <div v-if="reservation.booker.company_tax_id">
                    {{ $t("models.person.companyTaxId") }}:
                    {{ reservation.booker.company_tax_id }}
                </div>
                <div>
                    {{ reservation.booker.address_line1 }}
                </div>
                <div>
                    {{ reservation.booker.postal_code }}
                    {{ reservation.booker.city }}
                </div>
                <div v-if="reservation.booker.country_code != 'DE'">
                    {{ reservation.booker.country_code }}
                </div>
                <div
                    v-if="
                        reservation.status == 'Confirmed' ||
                        reservation.status == 'InHouse'
                    "
                    class="flex justify-center pt-2"
                ></div>
            </div>
        </div>
        <div class="pb-4">
            <app-button
                color="gray"
                v-if="
                    $route.name !== 'bookings.edit.booker' &&
                    [
                        'Confirmed',
                        'InHouse',
                        'CheckedOut',
                        'NoShow',
                        'Canceled',
                    ].includes(reservation.status)
                "
                @click="
                    $router.push({
                        name: 'bookings.edit.booker',
                        params: reservationQueryPayload,
                    })
                "
            >
                {{ $t("reservations.summary.button_booker_change") }}
            </app-button>
        </div>
        <!-- total -->
        <div
            class="text-r-green text-xl md:text-2xl font-bold flex flex-row space-x-2 border-t-2 last:border-b-2 border-r-black-50 py-4"
        >
            <div class="w-1/2 truncate">
                {{ $t("new_reservation.payment.amount") }}
            </div>
            <div class="w-1/2">
                {{
                    currency(
                        reservation.total_gross_amount +
                            reservation.city_tax_amount,
                        reservation.total_gross_currency
                    )
                }}
            </div>
        </div>

        <ul class="text-sm list-disc list-outside pl-5">
            <li v-if="reservation.has_city_tax">
                {{
                    $t("new_reservation.payment.including_city_tax", {
                        amount: currency(reservation.city_tax_amount),
                    })
                }}
            </li>
            <li>
                {{ $t("new_reservation.offers.offer_card.price_information") }}
            </li>
            <li>
                {{
                    $t(
                        "apaleo.cancellationFee." +
                            reservation.cancellation_fee.code +
                            ".description"
                    )
                }}
            </li>
        </ul>
        <div
            class="mt-10"
            v-if="
                checkInEnabled &&
                $route.name.substring(0, 21) !== 'bookings.edit.checkin'
            "
        >
            <app-button
                :disabled="!checkInEnabled && !isCheckingIn"
                @click="canCheckIn"
                color="green"
            >
                {{ $t("reservations.edit.button_check_in") }}
            </app-button>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import DayJS from "@mixins/DayJS";
import Currency from "@mixins/Currency";
import BookingPaymentAmount from "@mixins/BookingPaymentAmount";
import PropertyCard from "@guest/bookings/new/PropertyCard.vue";
import ReservationStatus from "@guest/bookings/ReservationStatus";
import { mapActions } from "vuex";
import AppButton from "@guest/common/Button";
export default {
    components: {
        PropertyCard,
        ReservationStatus,
        AppButton,
    },
    mixins: [DayJS, BookingPaymentAmount, Currency],
    computed: {
        ...mapState({
            booking: "bookingDraft",
            isCheckingIn: "isCheckingIn",
        }),
        checkInEnabled() {
            return (
                this.reservation.status == "Confirmed" &&
                this.dayjs().isAfter(this.reservation.earliest_check_in_time) &&
                this.hasCheckinBalanceError === false
            );
        },
        hasCheckinBalanceError() {
            return (
                this.reservation.balance_amount < 0 &&
                this.reservation.has_balance_checkin_overwrite === false
            );
        },
    },
    props: {
        reservation: {
            required: true,
            type: Object,
        },
        reservationQueryPayload: {
            required: true,
        },
    },
    methods: {
        ...mapActions({
            getUserReservationCanCheckIn: "getUserReservationCanCheckIn",
        }),
        canCheckIn() {
            console.log("can check in");
            this.getUserReservationCanCheckIn(this.reservationQueryPayload)
                .then((response) => {
                    this.$router.push({
                        name: "bookings.edit.checkin.name",
                        params: this.reservationQueryPayload,
                    });
                })
                .catch((error) => {
                    console.log(error.response);
                });
        },
    },
};
</script>

<style></style>
