var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col gap-10 lg:pr-8 pb-5 lg:pb-0"},[(_vm.reservation.status == 'Confirmed')?[_c('h2',{staticClass:"text-2xl italic font-black text-r-green",class:[
                _vm.checkInEnabled
                    ? 'text-center'
                    : 'lg:pr-8 text-center lg:text-left',
            ]},[(_vm.checkInEnabled)?[_vm._v("\n                "+_vm._s(_vm.$t("reservations.summary.check_in.can_heading"))+"\n            ")]:(_vm.checkInToday)?[_vm._v("\n                "+_vm._s(_vm.$t("reservations.summary.check_in.today_heading", {
                        time: _vm.date(
                            _vm.reservation.earliest_check_in_time,
                            "time_localized"
                        ),
                    }))+"\n            ")]:[_vm._v("\n                "+_vm._s(_vm.$t("reservations.summary.check_in.cannot_heading", {
                        date: _vm.date(
                            _vm.reservation.earliest_check_in_time,
                            "date_localized"
                        ),
                        time: _vm.date(
                            _vm.reservation.earliest_check_in_time,
                            "time_localized"
                        ),
                    }))+"\n            ")]],2),_vm._v(" "),_c('div',[_c('div',{class:[
                    _vm.checkInEnabled
                        ? 'text-center'
                        : 'text-center lg:text-left',
                ]},[(_vm.checkInEnabled)?_c('span',[_vm._v("\n                    "+_vm._s(_vm.$t("reservations.summary.check_in.can_body"))+"\n                ")]):(_vm.checkInToday)?_c('span',[_vm._v("\n                    "+_vm._s(_vm.$t("reservations.summary.check_in.today_body", {
                            time: _vm.date(
                                _vm.reservation.earliest_check_in_time,
                                "time_localized"
                            ),
                        }))+"\n                ")]):_c('span',[_vm._v("\n                    "+_vm._s(_vm.$t("reservations.summary.check_in.cannot_body", {
                            time: _vm.date(
                                _vm.reservation.earliest_check_in_time,
                                "time_localized"
                            ),
                        }))+"\n                ")])])]),_vm._v(" "),_c('div',{staticClass:"flex justify-center"},[_c('div',[_c('app-button',{attrs:{"disabled":!_vm.checkInEnabled,"color":"green"},on:{"click":_vm.canCheckIn}},[_vm._v("\n                    "+_vm._s(_vm.$t("reservations.edit.button_check_in"))+"\n                ")])],1)])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }