<template>
    <div>
        <full-width-container class="bg-r-gray-ultralight">
            <content-container :default-padding="false" class="px-5 pb-20">
                <title-bar class="text-r-green">
                    {{ $t("reservations.list.heading") }}
                </title-bar>
                <div class="grid grid-cols-1 gap-4">
                    <reservation-card
                        v-for="reservation in currentReservations"
                        :key="reservation.uuid"
                        :archived="false"
                        @selected="selected(reservation)"
                        :reservation="reservation"
                    >
                    </reservation-card>
                    <reservation-card
                        v-for="reservation in archivedReservations"
                        :key="'archived_' + reservation.uuid"
                        :archived="true"
                        @selected="selected(reservation)"
                        :reservation="reservation"
                    >
                    </reservation-card>
                </div>
            </content-container>
        </full-width-container>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import ContentContainer from "@guest/common/ContentContainer";
import FullWidthContainer from "@guest/common/FullWidthContainer.vue";
import ReservationCard from "@guest/bookings/list/ReservationCard";
import DayJS from "@mixins/DayJS";
import AppButton from "@guest/common/Button";
import TitleBar from "@guest/common/TitleBar";

export default {
    data: function () {
        return {
            reservations: [],
            initialized: false,
            //showArchived: false,
        };
    },
    mixins: [DayJS],
    components: {
        ContentContainer,
        ReservationCard,
        AppButton,
        FullWidthContainer,
        TitleBar,
    },
    computed: {
        showArchived() {
            return !!this.$route.params.archived ? true : false;
        },
        currentReservations() {
            if (Array.isArray(this.reservations)) {
                const archived_after = this.dayjs().subtract(2, "day");
                // console.log(archived_after.format("DD.MM.YYYY HH:mm"));
                return this.reservations.filter((item) => {
                    let departure = this.dayjs(item.departure);
                    // console.log("departure current", departure);
                    return archived_after.isSameOrAfter(departure)
                        ? false
                        : true;
                });
            }
            return [];
        },
        archivedReservations() {
            if (Array.isArray(this.reservations)) {
                //const archived_after = this.dayjs().add(2, 'day')
                const archived_after = this.dayjs().subtract(2, "day");
                console.log(archived_after.format("DD.MM.YYYY HH:mm"));
                return this.reservations.filter((item) => {
                    let departure = this.dayjs(item.departure);
                    // console.log("departure", departure);
                    return archived_after.isBefore(departure) ? false : true;
                });
            }
            return [];
        },
    },
    methods: {
        selected(reservation) {
            this.$router.push({
                name: "bookings.edit.summary",
                params: {
                    uuid: reservation.uuid,
                },
            });
            console.log("selected", reservation);
        },
        ...mapActions({
            getUserReservations: "getUserReservations",
        }),
    },
    mounted() {
        this.getUserReservations().then((response) => {
            this.reservations = response.data.data;
            this.initialized = true;
        });
    },
};
</script>

<style></style>
