var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-white border border-r-gray-light rounded-lg text-r-black relative p-4 lg:p-8 grid grid-cols-1 lg:grid-cols-12 gap-5 lg:gap-3",class:{ 'opacity-50': _vm.archived }},[_c('div',{staticClass:"grid gap-3 lg:col-span-5 place-content-start"},[_c('div',{staticClass:"font-bold text-lg"},[_c('div',{staticClass:"leading-5"},[_vm._v("\n                Roatel"),_c('br'),_vm._v(" "),_c('span',{staticClass:"truncate"},[_vm._v("\n                    "+_vm._s(_vm.reservation.property?.name)+"\n                ")])])]),_vm._v(" "),_c('div',{staticClass:"text-base text-r-gray-medium"},[_vm._v("\n            "+_vm._s(_vm.reservation.property?.address_line1)),_c('br'),_vm._v("\n            "+_vm._s(_vm.reservation.property?.postal_code)+"\n            "+_vm._s(_vm.reservation.property?.city)+"\n        ")])]),_vm._v(" "),_c('div',{staticClass:"lg:col-span-3 border border-r-gray-light grid grid-cols-2 rounded-lg"},[_c('div',{staticClass:"grid p-3 border-r border-r-gray-light h-[92px]"},[_c('div',{staticClass:"text-r-gray-medium"},[_vm._v("\n                "+_vm._s(_vm.$t("reservations.card.arrival"))+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"leading-5"},[_vm._v("\n                "+_vm._s(_vm.date(_vm.reservation.arrival, "date_localized"))),_c('br'),_vm._v("\n                "+_vm._s(_vm.$t("common.checkin_time", {
                        time: _vm.date(_vm.reservation.arrival, "time_localized"),
                    }))+"\n            ")])]),_vm._v(" "),_c('div',{staticClass:"grid gap-1 p-3 h-[92px]"},[_c('div',{staticClass:"text-r-gray-medium"},[_vm._v("\n                "+_vm._s(_vm.$t("reservations.card.departure", "date_localized"))+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"leading-5"},[_vm._v("\n                "+_vm._s(_vm.date(_vm.reservation.departure))),_c('br'),_vm._v("\n                "+_vm._s(_vm.$t("common.checkout_time", {
                        time: _vm.date(_vm.reservation.departure, "time_localized"),
                    }))+"\n            ")])]),_vm._v(" "),_c('div',{staticClass:"p-3 col-span-2 border-t border-r-gray-light"},[_c('div',{staticClass:"text-r-gray-medium"},[_vm._v("\n                "+_vm._s(_vm.$t("reservations.summary.guest_heading"))+"\n            ")]),_vm._v(" "),_c('div',[_c('span',[_vm._v("\n                    "+_vm._s(_vm.reservation.primary_guest?.first_name +
                        " " +
                        _vm.reservation.primary_guest?.last_name)+"\n                ")])])])]),_vm._v(" "),_c('div',{staticClass:"rounded-lg border border-r-gray-light grid lg:col-span-2 items-start"},[_c('div',{staticClass:"lg:grid p-3 items-start flex-grow lg:h-[92px]"},[_c('div',{staticClass:"text-r-gray-medium"},[_vm._v("\n                "+_vm._s(_vm.$t("reservations.card.booking_number"))+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"leading-5"},[_vm._v("\n                "+_vm._s(_vm.reservation.apaleo_id)+"\n            ")])]),_vm._v(" "),_c('div',{staticClass:"grid p-3 border-t border-r-gray-light"},[_c('div',{staticClass:"text-r-gray-medium"},[_vm._v("\n                "+_vm._s(_vm.$t("reservations.card.status"))+"\n            ")]),_vm._v(" "),_c('div',[_c('reservation-status',{attrs:{"status":_vm.reservation.status}})],1)])]),_vm._v(" "),_c('div',{staticClass:"lg:col-span-2 lg:justify-end justify-center flex"},[_c('app-button',{on:{"click":function($event){return _vm.$emit('selected')}}},[_vm._v(_vm._s(_vm.$t("reservations.card.link_label")))])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }