var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full min-h-full relative"},[(
            _vm.getCookieConsent &&
            _vm.getCookieConsent.isDecided &&
            _vm.getCookieConsent.isAllowed === false
        )?_c('content-container',[_c('clip-container',[_c('div',{staticClass:"transition-all duration-150 px-5 py-8 md:p-10 bg-r-black-5 group text-r-gray"},[_c('div',{staticClass:"flex flex-col space-y-3"},[_c('div',{staticClass:"flex flex-row justify-between"},[_c('div',{staticClass:"text-xl md:text-2xl font-black slanted uppercase text-r-gray italic"},[_vm._v("\n                            "+_vm._s(_vm.$t("cookie_consent.not_enabled_title"))+"\n                        ")])]),_vm._v(" "),_c('div',{staticClass:"flex text-sm leading-6"},[_vm._v("\n                        "+_vm._s(_vm.$t("cookie_consent.not_enabled_body"))+"\n                    ")]),_vm._v(" "),_c('div',[_c('app-button',{on:{"click":function($event){return _vm.setCookieConsent({
                                    isDecided: false,
                                    isAllowed: false,
                                })}}},[_vm._v("\n                            "+_vm._s(_vm.$t("cookie_consent.not_enabled_button"))+"\n                        ")])],1)])])])],1):_vm._e(),_vm._v(" "),(_vm.getCookieConsent && _vm.getCookieConsent.isAllowed)?_c('div',{ref:"map",staticClass:"w-full h-screen relative",attrs:{"id":"map"}},[_c('div',{staticClass:"w-full h-screen text-center text-xl font-slanted text-black"},[_vm._v("\n            "+_vm._s(_vm.$t("loading.message"))+"\n        ")])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }