<template>
    <div
        @click="loadSelector"
        class="flex justify-center p-2 cursor-pointer md:w-full transition-all duration-150 md:hover:bg-gray-600 rounded-md md:flex-row md:items-center md:justify-self-start md:space-x-2">
        <div class="w-8 border border-white">
            <img :src="flag.flag.default" />
        </div>
        <div class="hidden uppercase md:flex text-gray-50">
            {{ locale.original }}
        </div>
    </div>
</template>

<script>
import {mapGetters, mapState, mapActions} from 'vuex'
import Locales from '@/guest/common/locales'

export default {
    computed: {
        locales() {
            return Locales
        },
        ...mapGetters({
            locale: 'currentLocale',
        }),
        flag() {
            return this.locales.find(item => item.locale === this.locale.key)
        }
    },
    methods: {
        ...mapActions({
            setReturnToPath: 'setReturnToPath',
        }),
        loadSelector() {
            this.setReturnToPath(this.$route)
            this.$router.push({name: 'lang'})
        }

    }
}
</script>

<style>

</style>
