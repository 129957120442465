export default {
    data: function () {
        return {
            identification_types: [
                { id: 'SocialInsuranceNumber', label: 'SocialInsuranceNumber' },
                { id: 'PassportNumber', label: 'PassportNumber' },
                { id: 'IdNumber', label: 'IdNumber' },
                { id: 'DriverLicenseNumber', label: 'DriverLicenseNumber' },
            ]
        }
    },
}
