<template>
    <button
        @click="$emit('clicked')"
        class="text-r-black-50 uppercase font-bold transition-all duration-100 flex flex-row space-x-2 hover:text-r-gray items-center text-sm md:text-base"
    >
        <font-awesome-icon v-if="icon" :icon="icon"> </font-awesome-icon>
        <span>
            <slot></slot>
        </span>
    </button>
</template>

<script>
export default {
    props: {
        icon: {
            type: String | null,
            required: false,
            default: "chevron-left",
        },
    },
};
</script>

<style></style>
