<template>
    <div>
        <div
            class="bg-white rounded-lg border border-r-gray-light p-5 lg:p-10 grid gap-5 lg:gap-8 items-start"
            id="booker_container"
        >
            <h2 class="text-2xl italic font-black text-r-green text-center">
                {{ $t("new_reservation.booker.heading") }}
            </h2>
            <div class="grid grid-cols-1 lg:grid-cols-2 gap-5">
                <app-input
                    name="company_name"
                    v-model="booker.company_name"
                    :label="$t('models.person.companyName')"
                    :placeholder="$t('models.person.companyName')"
                    type="text"
                    :errors="errors"
                    :loading="loading"
                >
                </app-input>
                <app-input
                    name="company_tax_id"
                    v-model="booker.company_tax_id"
                    :label="$t('models.person.companyTaxId')"
                    :placeholder="$t('models.person.companyTaxId')"
                    type="text"
                    :errors="errors"
                    :loading="loading"
                >
                </app-input>
                <div class="col-span-full">
                    <hr class="border-r-gray-medium" />
                </div>
                <app-input
                    name="first_name"
                    v-model="booker.first_name"
                    :label="$t('models.person.firstName')"
                    :placeholder="$t('models.person.firstName')"
                    :required="true"
                    type="text"
                    :errors="errors"
                    :loading="loading"
                >
                </app-input>
                <app-input
                    name="last_name"
                    v-model="booker.last_name"
                    :label="$t('models.person.lastName')"
                    :placeholder="$t('models.person.lastName')"
                    :required="true"
                    type="text"
                    :errors="errors"
                    :loading="loading"
                >
                </app-input>
                <app-input
                    name="email"
                    v-model="booker.email"
                    :label="$t('models.person.email')"
                    :placeholder="$t('models.person.email')"
                    type="text"
                    :required="true"
                    :errors="errors"
                    :loading="loading"
                >
                </app-input>
                <app-input
                    :errors="errors"
                    :loading="loading"
                    type="phone"
                    name="phone"
                    :required="true"
                    :placeholder="$t('models.person.phone')"
                    :label="$t('models.person.phone')"
                    v-model="booker.phone"
                />
                <div
                    class="flex flex-col lg:flex-row items-center gap-5 col-span-full"
                >
                    <app-input
                        class="flex-grow"
                        name="address_line1"
                        v-model="booker.address_line1"
                        :label="$t('models.person.addressLine1')"
                        :placeholder="$t('models.person.addressLine1')"
                        :required="true"
                        type="text"
                        :errors="errors"
                        :loading="loading"
                    >
                    </app-input>
                    <app-input
                        class="w-full lg:w-1/3"
                        name="postal_code"
                        v-model="booker.postal_code"
                        :label="$t('models.person.postalCode')"
                        :placeholder="$t('models.person.postalCode')"
                        :required="true"
                        type="text"
                        :errors="errors"
                        :loading="loading"
                    >
                    </app-input>
                </div>
                <app-input
                    name="city"
                    v-model="booker.city"
                    :label="$t('models.person.city')"
                    :placeholder="$t('models.person.city')"
                    :required="true"
                    type="text"
                    :errors="errors"
                    :loading="loading"
                >
                </app-input>

                <app-input
                    v-model="booker.country_code"
                    name="country_code"
                    :label="$t('models.person.countryCode')"
                    :placeholder="$t('models.person.countryCode')"
                    :value="booker.countryCode"
                    :selectOptions="countries"
                    selectLabelProperty="name"
                    selectValueProperty="alpha-2"
                    :required="true"
                    type="selectClassic"
                    :errors="errors"
                    :loading="loading"
                >
                </app-input>
            </div>
            <div class="col-span-full" v-if="error">
                <alert>{{ $t("common.form_error") }}</alert>
            </div>
            <div class="flex justify-center">
                <div>
                    <app-button
                        @click="postBooker"
                        color="green"
                        :outline="false"
                        size="xl"
                        >{{ $t("buttons.save") }}</app-button
                    >
                </div>
            </div>
            <!-- <div class="md:col-span-2 flex justify-center"> -->
            <!--     <div class="flex justify-center"> -->
            <!--         <app-button -->
            <!--             @click=" -->
            <!--                 $router.push({ -->
            <!--                     name: 'bookings.edit.summary', -->
            <!--                     params: reservationQueryPayload, -->
            <!--                 }) -->
            <!--             " -->
            <!--             color="gray" -->
            <!--             :outline="true" -->
            <!--             size="lg" -->
            <!--             >{{ $t("buttons.cancel") }}</app-button -->
            <!--         > -->
            <!--     </div> -->
            <!-- </div> -->
        </div>
    </div>
</template>

<script>
import ContentContainer from "@admin/common/ContentContainer";
import DayJS from "@mixins/DayJS";
import Currency from "@mixins/Currency";
import AppButton from "@guest/common/Button";
import { mapActions, mapState } from "vuex";
import OpenLock from "@mixins/OpenLock";
import Modal from "@guest/common/Modal";
import AppAlert from "@guest/common/Alert";
import AppInput from "@guest/common/Input";
import Countries from "@mixins/Countries";
import ClipContainer from "@guest/common/ClipContainer";
import RequiredFieldInfo from "@guest/common/RequiredFieldInfo";
import Alert from "@guest/common/Alert.vue";

export default {
    mixins: [DayJS, Currency, OpenLock, Countries],
    components: {
        AppAlert,
        Modal,
        ContentContainer,
        AppButton,
        AppInput,
        ClipContainer,
        Alert,
        RequiredFieldInfo,
    },
    data: function () {
        return {
            booker: {},
            errors: {},
            error: false,
        };
    },
    methods: {
        ...mapActions({
            getUserReservationBooker: "getUserReservationBooker",
            postUserReservationBooker: "postUserReservationBooker",
        }),
        async getBooker() {
            await this.getUserReservationBooker(this.reservationQueryPayload)
                .then((response) => {
                    this.booker = response.data.data;
                    console.log(response);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async postBooker() {
            this.errors = {};
            this.postUserReservationBooker({
                id: this.reservationQueryPayload,
                data: this.booker,
            })
                .then((response) => {
                    this.$router.push({
                        name: "bookings.edit.summary",
                        params: this.reservationQueryPayload,
                    });
                })
                .catch((error) => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    }
                    console.log(error);
                });
        },
    },
    computed: {
        ...mapState({
            loading: (state) => state.loading,
        }),
        isBookerChangeable() {
            return (
                this.reservation.status === "Confirmed" ||
                this.reservation.status === "InHouse"
            );
        },
    },
    mounted() {
        this.getBooker();
        this.$scrollTo("#booker_container", 300);
    },
    props: {
        reservation: {
            required: true,
            type: Object,
        },
        isAwaitingReservationUpdate: {
            required: true,
            type: Boolean,
        },
    },
};
</script>

<style></style>
