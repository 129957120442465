<template>
    <div class="grid gap-8">
        <div class="flex justify-center">
            <icon icon="check-circle" class="stroke-r-green w-20 h-20"></icon>
        </div>
        <div class="text-4xl font-black italic text-r-green text-center">
            {{ $t("reservations.summary.checking_in_heading") }}
        </div>
        <div class="text-center">
            {{ $t("reservations.summary.checking_in_body") }}
        </div>
    </div>
</template>

<script>
import ClipContainer from "@guest/common/ClipContainer";
import AppButton from "@guest/common/Button";
import DayJS from "@mixins/DayJS";
import { mapActions } from "vuex";
import Icon from "@guest/common/Icon";

export default {
    mixins: [DayJS],
    components: {
        Icon,
        ClipContainer,
        AppButton,
    },
    props: {
        reservation: {
            required: true,
            type: Object,
        },
        reservationQueryPayload: {
            required: true,
        },
    },
    computed: {
        checkInToday() {
            return this.dayjs(
                this.reservation.earliest_check_in_time
            ).isToday();
        },
        // checkIn() {
        //     return this.dayjs().isAfter(this.reservation.arrival);
        // },
        checkInEnabled() {
            return (
                this.reservation.status == "Confirmed" &&
                this.dayjs().isAfter(this.reservation.earliest_check_in_time)
            );
        },
    },
    methods: {
        ...mapActions({
            // getUserReservation: "getUserReservation",
            getUserReservationCanCheckIn: "getUserReservationCanCheckIn",
            // setReturnToPath: "setReturnToPath",
            // setLocale: "setLocale",
            // putUserReservationCancel: "putUserReservationCancel",
        }),
        canCheckIn() {
            console.log("can check in");
            this.getUserReservationCanCheckIn(this.reservationQueryPayload)
                .then((response) => {
                    this.$router.push({
                        name: "bookings.edit.checkin.name",
                        params: this.reservationQueryPayload,
                    });
                })
                .catch((error) => {
                    console.log(error.response);
                });
        },
    },
};
</script>

<style></style>
