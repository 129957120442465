<template>
    <div class="w-full min-h-full relative">
        <content-container
            v-if="
                getCookieConsent &&
                getCookieConsent.isDecided &&
                getCookieConsent.isAllowed === false
            "
        >
            <clip-container>
                <div
                    class="transition-all duration-150 px-5 py-8 md:p-10 bg-r-black-5 group text-r-gray"
                >
                    <div class="flex flex-col space-y-3">
                        <div class="flex flex-row justify-between">
                            <div
                                class="text-xl md:text-2xl font-black slanted uppercase text-r-gray italic"
                            >
                                {{ $t("cookie_consent.not_enabled_title") }}
                            </div>
                        </div>
                        <div class="flex text-sm leading-6">
                            {{ $t("cookie_consent.not_enabled_body") }}
                        </div>
                        <div>
                            <app-button
                                @click="
                                    setCookieConsent({
                                        isDecided: false,
                                        isAllowed: false,
                                    })
                                "
                            >
                                {{ $t("cookie_consent.not_enabled_button") }}
                            </app-button>
                        </div>
                    </div>
                </div>
            </clip-container>
        </content-container>
        <div
            id="map"
            class="w-full h-screen relative"
            ref="map"
            v-if="getCookieConsent && getCookieConsent.isAllowed"
        >
            <div
                class="w-full h-screen text-center text-xl font-slanted text-black"
            >
                {{ $t("loading.message") }}
            </div>
        </div>
    </div>
</template>
<script>
import { Loader } from "@googlemaps/js-api-loader";
import MapInfoWindowComponent from "./MapInfoWindow.vue";
import Vue from "vue";
import AppButton from "@guest/common/Button";
import ClipContainer from "@guest/common/ClipContainer";
import ContentContainer from "@guest/common/ContentContainer";
import { mapActions, mapGetters, mapState } from "vuex";

const markers = [];

export default {
    components: {
        AppButton,
        ClipContainer,
        ContentContainer,
    },
    data: function () {
        return {
            map: {},
            markers: [],
            loader: null,
        };
    },
    watch: {
        getCookieConsent: {
            handler: function () {
                if (
                    this.getCookieConsent &&
                    this.getCookieConsent.isDecided &&
                    this.getCookieConsent.isAllowed
                ) {
                    this.loadMap();
                }
            },
            immediate: true,
        },
        map() {
            this.setMarkers();
            this.setCurrentPosition();
        },
    },
    computed: {
        ...mapGetters({
            getCookieConsent: "getCookieConsent",
        }),
    },
    methods: {
        ...mapActions({
            setCookieConsent: "setCookieConsent",
        }),
        removeMarkers() {
            // console.log(this.map.getBounds());
            console.log("markers in removemarker", markers);
            console.log(
                "markers in removemarker",
                markers.splice(0, markers.length)
            );
            markers.forEach((marker) => {
                console.log(marker);
                marker.setMap(null);
                marker.delete();
            });
            markers.splice(0, markers.length);
        },
        async setCurrentPosition() {
            console.log("setting properties on map");
            const { AdvancedMarkerElement, PinView } =
                await this.loader.importLibrary("marker");
            const { LatLngBounds } = await google.maps.importLibrary("core");
            console.log("AdvancedMarker", AdvancedMarkerElement);
            const bounds = new LatLngBounds();
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        console.log(
                            "geolocation for current location",
                            position
                        );
                        const pos = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        };

                        const pinViewScaled = new google.maps.marker.PinView({
                            background: "#7F7F7F",
                            glyphColor: "white",
                            borderColor: "#7F7F7F",
                        });

                        const location =
                            new google.maps.marker.AdvancedMarkerElement({
                                map: this.map,
                                position: pos,
                                content: pinViewScaled.element,
                                title: this.$t(
                                    "new_reservation.current_position"
                                ),
                            });
                    },
                    () => {}
                );
            }
        },
        async setMarkers() {
            console.log("setting properties on map");
            const { AdvancedMarkerElement } = await this.loader.importLibrary(
                "marker"
            );
            const { LatLngBounds } = await google.maps.importLibrary("core");
            const bounds = new LatLngBounds();

            const infoWindow = new google.maps.InfoWindow();
            this.properties.forEach((item) => {
                if (item.lat && item.lng) {
                    console.log("adding", item);
                    bounds.extend({
                        lat: parseFloat(item.lat),
                        lng: parseFloat(item.lng),
                    });
                    const roatel_image = document.createElement("img");
                    roatel_image.className = "w-12 h-12";

                    roatel_image.src =
                        process.env.MIX_ASSET_URL +
                        "/favicon/apple-touch-icon.png";
                    const marker = new AdvancedMarkerElement({
                        map: this.map,
                        position: {
                            lat: parseFloat(item.lat),
                            lng: parseFloat(item.lng),
                        },
                        content: roatel_image,
                        title: item.name,
                    });
                    // Add a click listener for each marker, and set up the info window.
                    marker.addEventListener("click", ({ domEvent, latLng }) => {
                        console.log("marker clicked", domEvent, latLng);
                        infoWindow.close();
                        var MapInfoWindow = Vue.extend(MapInfoWindowComponent);
                        var instance = new MapInfoWindow({
                            propsData: {
                                property: item,
                                buttonLabel: this.$t(
                                    "new_reservation.property_card.book_property"
                                ),
                            },
                        });
                        instance.$on("property-select", (selected_property) => {
                            this.$emit("property-select", selected_property);
                        });
                        instance.$mount();
                        infoWindow.setContent(instance.$el);
                        infoWindow.open(marker.map, marker);
                    });
                    console.log();
                    markers.push(marker);
                }
            });
            console.log(markers);
            this.map.fitBounds(bounds);
        },
        async loadMap() {
            this.loader = new Loader({
                apiKey: process.env.MIX_GOOGLE_MAPS_API_KEY,
                version: "beta",
                libraries: ["maps", "marker"],
                mapIds: ["9005f8c5aeabf2d4"],
            });
            console.log("google loader", this.loader);

            this.loader.load().then(async () => {
                const { Map } = await google.maps.importLibrary("maps");
                this.map = new Map(document.getElementById("map"), {
                    center: { lat: -34.397, lng: 150.644 },
                    zoom: 8,
                    mapId: "9005f8c5aeabf2d4",
                });
            });
        },
    },
    props: {
        properties: {
            type: Array,
            required: true,
        },
    },
    async mounted() {
        console.log("Mounting Map");
    },
};
</script>
