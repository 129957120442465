var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative"},[(_vm.no_access)?_c('div',{staticClass:"flex justify-center px-4 py-10"},[_c('alert',{attrs:{"type":"error"}},[_vm._v("\n            "+_vm._s(_vm.$t("reservations.edit.no_access"))+"\n        ")])],1):_vm._e(),_vm._v(" "),_c('full-width-container',{staticClass:"bg-r-gray-ultralight"},[(_vm.reservation)?_c('content-container',[_c('div',{staticClass:"flex flex-col-reverse lg:flex-row gap-5 text-r-black"},[_c('div',{staticClass:"w-full lg:w-1/3 flex flex-col gap-10"},[_c('edit-booking-summary-sidebar',{attrs:{"reservation":_vm.reservation,"is-awaiting-reservation-update":_vm.isAwaitingReservationUpdate},on:{"cancel":function($event){_vm.reservation.channel_code === 'Ibe'
                                ? _vm.$refs.cancelConfirmation.show(_vm.reservation)
                                : _vm.$refs.cancelConfirmationBookingCom.show(
                                      _vm.reservation
                                  )}},scopedSlots:_vm._u([{key:"top",fn:function(){return undefined},proxy:true}],null,false,3922782306)})],1),_vm._v(" "),_c('div',{staticClass:"flex flex-col gap-4 w-full lg:w-2/3",attrs:{"id":"summary_container"}},[(_vm.isAwaitingReservationUpdate)?_c('div',[_c('alert',{attrs:{"type":"success"}},[_c('div',{staticClass:"flex flex-row items-center gap-3"},[_c('font-awesome-icon',{staticClass:"animate-spin-slow",attrs:{"icon":"spinner"}}),_vm._v(" "),_c('div',[_vm._v("\n                                    "+_vm._s(_vm.$t(
                                            "reservations.edit.reservation_updating"
                                        ))+"\n                                ")])],1)])],1):_vm._e(),_vm._v(" "),(_vm.reservation.hasOwnProperty('uuid'))?_c('router-view',{attrs:{"isAwaitingReservationUpdate":_vm.isAwaitingReservationUpdate,"reservation":_vm.reservation},on:{"reservation":_vm.updateReservation,"cancel":function($event){_vm.reservation.channel_code === 'Ibe'
                                ? _vm.$refs.cancelConfirmation.show(_vm.reservation)
                                : _vm.$refs.cancelConfirmationBookingCom.show(
                                      _vm.reservation
                                  )}}}):_vm._e()],1)])]):_vm._e()],1),_vm._v(" "),_c('modal',{ref:"cancelConfirmationBookingCom",attrs:{"confirmSize":"sm","cancelSize":"sm","width":"w-full md:w-1/2","isConfirmation":false},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("\n            "+_vm._s(_vm.$t("reservations.summary.cancel_bookingcom_title"))+"\n        ")]},proxy:true},{key:"body",fn:function(model){return [_vm._v("\n            "+_vm._s(_vm.$t("reservations.summary.cancel_bookingcom_body"))+"\n        ")]}},{key:"footer",fn:function(model){return [_c('app-button',{attrs:{"color":"gray"},on:{"click":function($event){return _vm.$refs.cancelConfirmationBookingCom.close()}}},[_vm._v("\n                "+_vm._s(_vm.$t("buttons.close"))+"\n            ")])]}}])}),_vm._v(" "),_c('modal',{ref:"cancelConfirmation",attrs:{"confirmSize":"sm","cancelSize":"sm","width":"w-full md:w-1/2","confirmLabel":_vm.$t('reservations.summary.button_reservation_cancel'),"cancelLabel":_vm.$t('buttons.cancel'),"isConfirmation":true},on:{"confirmed":_vm.reservationCancel},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("\n            "+_vm._s(_vm.$t("reservations.summary.cancel_confirm_title"))+"\n        ")]},proxy:true},{key:"body",fn:function(model){return [(_vm.isCancellationFree === false)?_c('span',[_vm._v("\n                "+_vm._s(_vm.$t("reservations.summary.cancel_confirm_body", {
                        apaleo_id: model.model.apaleo_id,
                        fee: _vm.currency(
                            model.model.cancellation_fee.fee.amount,
                            model.model.cancellation_fee.fee.currency
                        ),
                    }))+"\n            ")]):_c('span',[_vm._v("\n                "+_vm._s(_vm.$t("reservations.summary.cancel_confirm_body_free", {
                        apaleo_id: model.model.apaleo_id,
                        datetime: _vm.date(
                            _vm.reservation.cancellation_fee.dueDateTime,
                            "datetime"
                        ),
                    }))+"\n            ")])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }