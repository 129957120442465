<template>
    <div
        :class="size"
        class="flex overflow-hidden border border-gray-300 rounded-full shadow">
        <img v-if="user.image" :src="'/admin/users/' + user.uuid + '/image'" alt=""/>
        <div class="flex items-center justify-center w-full text-2xl font-bold text-center text-white bg-indigo-400" v-else>
            <span>{{initials}}</span>
        </div>
    </div>

</template>

<script>
export default {
    computed: {
        initials() {
            if (!this.user.name) {
                return '??'
            }
            let name = this.user.name
            name = name.trim()
            console.log(this.user.name, name)
            const words = name.split(' ')
            const modified = words.map(item => {
                return item[0].toUpperCase()
            })
            return modified.join('')
        }
    },
    props: {
        user: {
            required: true,
            type: Object,
        },
        size: {
            required: false,
            default: 'w-16 h-16',
        },
    }
}
</script>

<style>

</style>
