export default {
    methods: {
        currency(amount, currency = 'EUR') {
            return new Intl.NumberFormat(
                'de-DE',
                {
                    style: 'currency',
                    currency: currency
                }
            ).format(amount)
        },
        percent(amount) {
            return new Intl.NumberFormat(
                'de-DE',
                {
                    style: 'percent',
                    maximumFractionDigits: 2,
                }
            ).format(amount / 100)
        },
        number(number, fraction_digits = 2) {
            return new Intl.NumberFormat(
                'de-DE',
                {
                    //style: 'percent',
                    maximumFractionDigits: fraction_digits,
                }
            ).format(number)
        },
        percentToNumber(val) {
            return val.replaceAll(' ', '')
                .replaceAll('%', '')
                .replaceAll('.', '')
                .replaceAll(',', '.')
                .trim()
        },
        currencyToNumber(val) {
            return val.replaceAll(' ', '')
                .replaceAll('EUR', '')
                .replaceAll('€', '')
                .replaceAll('.', '')
                .replaceAll(',', '.')
                .trim()
        },
    }
}
