<template>
    <full-width-container class="bg-white text-r-black pt-16">
        <content-container>
            <div class="grid grid-cols-1 md:grid-cols-2">
                <div class="justify-start">
                    <div class="justify-center lg:justify-start flex mb-12">
                        <logo imageClass="h-9 w-auto self-start"></logo>
                    </div>
                    <div class="text-center lg:text-left">
                        <h4 class="font-bold">Roatel GmbH</h4>
                        <div>Königsallee 63</div>
                        <div>40215 Düsseldorf</div>
                        <div>Deutschland</div>
                    </div>
                </div>
                <div class="grid grid-cols-1 md:grid-cols-2">
                    <div v-for="nav in navigations" class="mt-12 lg:mt-0">
                        <h4 class="mb-3 lg:mb-10 font-bold text-center lg:text-left">{{ $t(nav.label) }}</h4>
                        <div class="grid gap-1 text-center lg:text-left">
                            <div v-for="item in nav.items">
                                <template v-if="item.hasOwnProperty('route')">
                                    <router-link :to="item.route">{{
                                        $t(item.key)
                                    }}</router-link>
                                </template>
                                <template v-else>
                                    <a :href="item.href" target="_blank">{{
                                        $t(item.key)
                                    }}</a>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

           <!-- <div class="md:p-28 md:px-48 py-10"> -->
            <!--     <div -->
            <!--         class="grid md:grid-cols-3 text-white gap-6 md:gap-0 font-slanted italic uppercase text-xl md:text-2xl font-black" -->
            <!--     > -->
            <!--         <div -->
            <!--             v-for="( -->
            <!--                 group, group_index -->
            <!--             ) in sideNavigationItemsGrouped" -->
            <!--             class="flex flex-col md:space-y-6 space-y-6" -->
            <!--             :key="'group_' + group_index" -->
            <!--         > -->
            <!--             <div -->
            <!--                 :key="'item_' + item.order" -->
            <!--                 v-for="item in sortSideNavigationItems(group)" -->
            <!--             > -->
            <!--                 <a -->
            <!--                     :href="item.href" -->
            <!--                     v-if="item.hasOwnProperty('href')" -->
            <!--                     class="md:py-2 py-0.5" -->
            <!--                     target="_blank" -->
            <!--                     >{{ $t(item.label) }}</a -->
            <!--                 > -->
            <!--                 <router-link -->
            <!--                     :to="item.route" -->
            <!--                     class="md:py-2 py-0.5" -->
            <!--                     v-if="item.hasOwnProperty('route')" -->
            <!--                     >{{ $t(item.label) }}</router-link -->
            <!--                 > -->
            <!--             </div> -->
            <!--         </div> -->
            <!--     </div> -->
            </div>
        </content-container>
    </full-width-container>
</template>

<script>
import FullWidthContainer from "@guest/common/FullWidthContainer";
import ContentContainer from "@guest/common/ContentContainer";
import AppButton from "@guest/common/Button";
import GuestSideNavigationItems from "@/mixins/GuestSideNavigationItems";
import Logo from "@guest/layout/Logo";
// import NavigationItems from "@/guest/routes/navigation";

export default {
    mixins: [GuestSideNavigationItems],
    components: {
        FullWidthContainer,
        ContentContainer,
        AppButton,
        Logo,
    },
    data: function () {
        return {
            navigations: [
                {
                    label: "navigation.footer.book_roatel",
                    items: [
                        {
                            key: "navigation.footer.book",
                            route: { name: "bookings.new.search_properties" },
                        },
                        {
                            key: "navigation.footer.map",
                            route: { name: "bookings.new.search_properties",params:{show:'map'} },
                        },
                        {
                            key: "navigation.footer.discover",
                            route: { name: "home" },
                        },
                    ],
                },
                {
                    label: "navigation.footer.about",
                    items: [
                        {
                            key: "navigation.footer.corporate_website",
                            href: "https://www.roatel.com",
                        },
                        {
                            key: "navigation.footer.franchise",
                            href: "https://www.roatel.com/franchise-partner",
                        },
                        {
                            key: "navigation.footer.become_partner",
                            href: "https://www.roatel.com/standort-partner",
                        },
                        {
                            key: "navigation.footer.jobs",
                            href: "https://www.roatel.com/karriere",
                        },
                    ],
                },
            ],
        };
    },
};
</script>

<style></style>
