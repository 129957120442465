<template>
    <div>
        <full-width-container class="bg-r-green">
            <content-container padding="lg:py-4 lg:px-12 py-3">
                <stepper :current_step="current_step">
                    {{ $t("navigation.footer.book_roatel") }}
                </stepper>
            </content-container>
        </full-width-container>
        <full-width-container class="bg-r-gray-ultralight">
            <content-container>
                <div class="flex flex-col lg:flex-row gap-5 text-r-black">
                    <div class="w-full lg:w-1/3 flex flex-col gap-10">
                        <new-booking-summary-sidebar
                            :property="property"
                            :booking="booking"
                            :show-dates="true"
                            :show-room-count="true"
                            :show-total-amount="true"
                            :show-information="true"
                            :show-imprint-link="true"
                            :show-open-in-map="true"
                            :show-address="true"
                            :show-carousel="true"
                            :swiper-toggle-visible="true"
                            :swiper-initial-status="false"
                        >
                            <template v-slot:before>
                                <router-link
                                    class="group inline-flex gap-3 text-r-gray items-center"
                                    :to="{
                                        name: 'bookings.new.dates',
                                        params: {
                                            property: property.apaleo_id,
                                            checkin: date(
                                                dates.checkIn,
                                                'date_iso'
                                            ),
                                            checkout: date(
                                                dates.checkOut,
                                                'date_iso'
                                            ),
                                        },
                                    }"
                                >
                                    <icon
                                        icon="arrow-narrow-left"
                                        class="stroke-r-gray w-6 h-6 group-hover:stroke-r-green"
                                    ></icon>
                                    <span
                                        class="group-hover:text-r-green leading-none"
                                        >{{
                                            $t("new_reservation.dates.heading")
                                        }}</span
                                    ></router-link
                                >
                            </template>
                        </new-booking-summary-sidebar>
                    </div>
                    <div
                        class="w-full flex flex-col lg:w-2/3"
                        id="summary_container"
                    >
                        <div
                            class="bg-white rounded-lg border border-r-gray-light"
                        >
                            <div class="px-3 py-2 lg:p-6">
                                <h2
                                    class="text-xl lg:text-2xl italic font-black text-r-green lg:py-12 py-7 text-center"
                                >
                                    {{ $t("new_reservation.summary.heading") }}
                                </h2>
                                <div class="grid grid-cols-1 gap-3 lg:gap-5">
                                    <guest-reservation-card
                                        v-for="(
                                            reservation, reservation_index
                                        ) in reservations"
                                        :key="reservation_index"
                                        :booker="booker"
                                        :errors="errors"
                                        :index="reservation_index"
                                        :reservations="reservations"
                                        @change="
                                            reservations[reservation_index] =
                                                $event
                                        "
                                        :reservation="reservation"
                                    >
                                    </guest-reservation-card>
                                    <div>
                                        <div class="flex flex-col gap-3">
                                            <div
                                                class="flex flex-row items-start gap-3"
                                            >
                                                <div class="w-8">
                                                    <app-input
                                                        name="accepted_terms"
                                                        type="checkbox"
                                                        :hasLabel="false"
                                                        v-model="accepted_terms"
                                                    ></app-input>
                                                </div>
                                                <i18n
                                                    path="new_reservation.summary.terms_accept"
                                                    tag="label"
                                                    @click="
                                                        accepted_terms =
                                                            !accepted_terms
                                                    "
                                                    class="text-sm"
                                                >
                                                    <a
                                                        :href="
                                                            property.final_terms_url
                                                        "
                                                        class="underline underline-offset-1"
                                                        target="_blank"
                                                        @click.stop="null"
                                                        >{{
                                                            $t(
                                                                "new_reservation.summary.terms"
                                                            )
                                                        }}</a
                                                    >
                                                </i18n>
                                            </div>
                                            <div
                                                class="flex flex-row items-start gap-3"
                                            >
                                                <div class="w-8">
                                                    <app-input
                                                        name="accepted_privacy"
                                                        type="checkbox"
                                                        :hasLabel="false"
                                                        v-model="
                                                            accepted_privacy
                                                        "
                                                    ></app-input>
                                                </div>
                                                <i18n
                                                    path="new_reservation.summary.privacy_accept"
                                                    tag="label"
                                                    @click="
                                                        accepted_privacy =
                                                            !accepted_privacy
                                                    "
                                                    class="text-sm"
                                                >
                                                    <a
                                                        :href="
                                                            property.final_privacy_url
                                                        "
                                                        class="underline underline-offset-1"
                                                        target="_blank"
                                                        @click.stop="null"
                                                        >{{
                                                            $t(
                                                                "new_reservation.summary.privacy"
                                                            )
                                                        }}</a
                                                    >
                                                </i18n>
                                            </div>
                                            <div
                                                class="flex flex-row gap-3 items-start"
                                            >
                                                <div class="w-8">
                                                    <app-input
                                                        name="marketing_signup"
                                                        type="checkbox"
                                                        :has-label="false"
                                                        v-model="
                                                            marketing_signup
                                                        "
                                                    ></app-input>
                                                </div>
                                                <div class="w-full inline-flex">
                                                    <i18n
                                                        path="new_reservation.summary.marketing_signup"
                                                        tag="label"
                                                        @click="
                                                            marketing_signup =
                                                                !marketing_signup
                                                        "
                                                        class="text-sm"
                                                    >
                                                        <a
                                                            href="https://www.roatel.com/datenschutz-booking"
                                                            class="underline underline-offset-1"
                                                            target="_blank"
                                                            @click.stop="null"
                                                            >{{
                                                                $t(
                                                                    "new_reservation.summary.privacy"
                                                                )
                                                            }}</a
                                                        >
                                                    </i18n>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="error">
                                        <alert>{{
                                            $t("common.form_error")
                                        }}</alert>
                                    </div>
                                    <div>
                                        <div class="flex justify-center">
                                            <div>
                                                <app-button
                                                    @click="submit"
                                                    :disabled="!accepted_all"
                                                    color="green"
                                                    size="lg"
                                                    :outline="false"
                                                >
                                                    {{ $t("buttons.continue") }}
                                                </app-button>
                                            </div>
                                            <!-- <required-field-info></required-field-info> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <property-imprint-modal ref="propertyImprintModal">
                </property-imprint-modal>
            </content-container>
        </full-width-container>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import ContentContainer from "@guest/common/ContentContainer";
import AppInput from "@guest/common/Input";
import AppButton from "@guest/common/Button";
import Alert from "@guest/common/Alert.vue";
import DayJS from "@mixins/DayJS";
import Currency from "@mixins/Currency";
import GuestReservationCard from "@guest/bookings/new/GuestReservationCard";
import BookingDraftState from "@guest/bookings/new/State";
import FullWidthContainer from "@guest/common/FullWidthContainer.vue";
import PropertyCard from "@guest/bookings/new/PropertyCard";
import Stepper from "@guest/bookings/new/Stepper";
import ClipContainer from "@guest/common/ClipContainer";
import RequiredFieldInfo from "@guest/common/RequiredFieldInfo";
import BookingBackLink from "@guest/common/BookingBackLink.vue";
import BookingSummarySidebar from "@guest/bookings/new/BookingSummarySidebar.vue";
import PropertyImprintModal from "@guest/common/PropertyImprintModal.vue";
import BookingPropertyLabel from "@guest/common/BookingPropertyLabel.vue";
import NewBookingSummarySidebar from "@guest/common/NewBookingSummarySidebar.vue";
import Icon from "@guest/common/Icon";

export default {
    mixins: [DayJS, Currency],
    components: {
        Alert,
        BookingDraftState,
        ContentContainer,
        AppInput,
        AppButton,
        GuestReservationCard,
        FullWidthContainer,
        PropertyCard,
        Stepper,
        ClipContainer,
        RequiredFieldInfo,
        BookingBackLink,
        BookingSummarySidebar,
        PropertyImprintModal,
        BookingPropertyLabel,
        NewBookingSummarySidebar,
        Icon,
    },
    data: function () {
        return {
            reservations: [],
            accepted_terms: false,
            accepted_privacy: false,
            marketing_signup: false,
            errors: {},
            error: false,
        };
    },
    watch: {
        reservationsStore: {
            handler: function (val) {
                console.log("executing reservationStore watcher", val);
                this.reservations = val;
                this.setGuestDataFromProfile();
            },
            deep: true,
            immediate: true,
        },
        marketing_signup(val) {
            console.log("marketing_signup change", val);
            this.setBookingDraftMarketingSignup(val);
        },
    },
    mounted() {
        this.$scrollTo("#summary_container", 1000);
        this.$emit("step", 3);
        // this.reservations = this.reservationsStore;
        console.log("guest-card reservationstore", this.reservationsStore);
        console.log("reservations", this.reservations, this.reservationsStore);
    },
    computed: {
        errors_terms() {
            let result = {};
            if (!this.accepted_terms) {
                result.accepted_terms = ["Please acceept"];
            }
            if (!this.accepted_privacy) {
                result.accepted_privacy = ["Please accept privacy"];
            }
            return result;
        },
        accepted_all() {
            return this.accepted_terms && this.accepted_privacy;
        },
        ...mapState({
            booking: "bookingDraft",
            property: (state) => state.bookingDraft.property,
            offer: (state) => state.bookingDraft.offer,
            booker: (state) => state.bookingDraft.booker,
            dates: (state) => state.bookingDraft.dates,
            reservationsStore: (state) => state.bookingDraft.reservations,
            profile: (state) => state.profile,
        }),
        ...mapGetters({}),
    },
    methods: {
        setGuestDataFromProfile() {
            console.log("profile data", this.profile, this.reservations);
            if (
                this.reservations[0].primaryGuest.firstName == "" &&
                this.profile.hasOwnProperty("guest_detail")
            ) {
                console.log(
                    "Empty first name",
                    this.profile.hasOwnProperty("guest_detail")
                );
                this.reservations[0].primaryGuest.firstName =
                    this.profile.guest_detail.first_name;
                this.reservations[0].primaryGuest.lastName =
                    this.profile.guest_detail.last_name;
                this.reservations[0].primaryGuest.email =
                    this.profile.guest_detail.email;
                this.reservations[0].primaryGuest.phone =
                    this.profile.guest_detail.phone;
            }
        },
        submit() {
            this.errors = {};
            this.error = false;
            this.setBookingDraftReservations(this.reservations);
            this.validateBookingDraftReservations(this.booking)
                .then((response) => {
                    console.log(response);
                    this.$emit("booker");
                })
                .catch((error) => {
                    this.error = true;
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    }
                    console.log(error.response);
                });
        },
        ...mapActions({
            validateBookingDraftReservations:
                "validateBookingDraftReservations",
            setBookingDraftReservations: "setBookingDraftReservations",
            setBookingDraftMarketingSignup: "setBookingDraftMarketingSignup",
        }),
    },
    props: {
        current_step: {
            required: false,
            default: 3,
        },
    },
};
</script>

<style></style>
