<template>
    <div>
        <full-width-container class="bg-r-gray-ultralight">
            <title-bar class="text-r-green">
                {{ $t("profile.heading_section") }}
            </title-bar>
        </full-width-container>
        <full-width-container class="bg-r-gray-ultralight">
            <content-container class="px-5 pb-20" :default-padding="false">
                <div class="grid lg:grid-cols-8 gap-5 w-full">
                    <div
                        class="p-5 lg:p-8 bg-white border border-r-gray-light col-span-full lg:col-span-5 rounded-lg grid gap-10"
                    >
                        <div class="grid gap-3">
                            <h2
                                class="text-r-green text-xl font-black italic text-center lg:text-left"
                            >
                                {{ $t("profile.nav.personal") }}
                            </h2>
                            <p class="text-center lg:text-left">
                                {{ $t("profile.lead") }}
                            </p>
                        </div>

                        <div class="grid lg:grid-cols-2 gap-5">
                            <app-input
                                name="company_name"
                                v-model="data.company_name"
                                :label="$t('models.guest.company_name')"
                                type="text"
                                :errors="errors"
                                :loading="loading"
                            >
                            </app-input>
                            <app-input
                                name="company_tax_id"
                                v-model="data.company_tax_id"
                                :label="$t('models.guest.company_tax_id')"
                                type="text"
                                :errors="errors"
                                :loading="loading"
                            >
                            </app-input>

                            <div class="col-span-full">
                                <hr class="border border-r-gray-light my-5" />
                            </div>

                            <app-input
                                name="first_name"
                                v-model="data.first_name"
                                :required="true"
                                :label="$t('models.guest.first_name')"
                                type="text"
                                :errors="errors"
                                :loading="loading"
                            >
                            </app-input>
                            <app-input
                                name="last_name"
                                v-model="data.last_name"
                                :label="$t('models.guest.last_name')"
                                type="text"
                                :required="true"
                                :errors="errors"
                                :loading="loading"
                            >
                            </app-input>
                            <app-input
                                name="email"
                                v-model="data.email"
                                :label="$t('models.guest.email')"
                                type="text"
                                :errors="errors"
                                :loading="loading"
                            >
                            </app-input>
                            <app-input
                                :label="$t('models.guest.phone')"
                                :errors="errors"
                                name="phone"
                                type="phone"
                                v-model="data.phone"
                            />

                            <div class="col-span-full">
                                <hr class="border border-r-gray-light my-5" />
                            </div>

                            <div
                                class="col-span-full grid lg:grid-cols-3 gap-5"
                            >
                                <app-input
                                    class="lg:col-span-2"
                                    name="address_line1"
                                    v-model="data.address_line1"
                                    :label="$t('models.guest.address_line1')"
                                    type="text"
                                    :errors="errors"
                                    :loading="loading"
                                >
                                </app-input>
                                <app-input
                                    name="postal_code"
                                    v-model="data.postal_code"
                                    :label="$t('models.guest.postal_code')"
                                    type="text"
                                    :errors="errors"
                                    :loading="loading"
                                >
                                </app-input>
                            </div>

                            <app-input
                                name="city"
                                v-model="data.city"
                                :label="$t('models.guest.city')"
                                type="text"
                                :errors="errors"
                                :loading="loading"
                            >
                            </app-input>
                            <app-input
                                name="country_code"
                                v-model="data.country_code"
                                :label="$t('models.guest.country_code')"
                                type="selectClassic"
                                :selectNullable="true"
                                :selectOptions="countries"
                                selectLabelProperty="name"
                                selectValueProperty="alpha-2"
                                :errors="errors"
                            >
                            </app-input>

                            <div class="col-span-full">
                                <hr class="border border-r-gray-light my-5" />
                            </div>

                            <div class="grid gap-3 col-span-full">
                                <h2
                                    class="text-r-green text-xl font-black italic"
                                >
                                    {{
                                        $t("profile.guest_data.identification")
                                    }}
                                </h2>
                                <p>
                                    {{
                                        $t(
                                            "profile.guest_data.identification_lead"
                                        )
                                    }}
                                </p>
                            </div>

                            <app-input
                                name="nationality_country_code"
                                :label="
                                    $t('models.guest.nationality_country_code')
                                "
                                type="selectClassic"
                                v-model="data.nationality_country_code"
                                :selectOptions="countries"
                                selectLabelProperty="name"
                                :selectNullable="true"
                                selectValueProperty="alpha-2"
                                :errors="errors"
                                :loading="loading"
                            >
                            </app-input>
                            <app-input
                                name="birth_date"
                                v-model="data.birth_date"
                                :label="$t('models.guest.birth_date')"
                                type="datepicker"
                                :locale="currentLocale.key"
                                :errors="errors"
                                :loading="loading"
                            >
                            </app-input>

                            <app-input
                                name="identification_number"
                                v-model="data.identification_number"
                                :label="
                                    $t('models.guest.identification_number')
                                "
                                type="text"
                                :errors="errors"
                                :loading="loading"
                            >
                            </app-input>
                        </div>
                        <div class="flex justify-center">
                            <div>
                                <app-button @click="submitProfile">{{
                                    $t("buttons.save")
                                }}</app-button>
                            </div>
                        </div>
                    </div>
                    <div
                        class="flex flex-col col-span-full lg:col-span-3 gap-8"
                    >
                        <div
                            class="bg-white border border-r-gray-light p-8 rounded-lg h-auto grid gap-10 items-start"
                        >
                            <div class="grid gap-3">
                                <h2
                                    class="text-r-green text-xl font-black italic"
                                >
                                    {{ $t("profile.password_change.heading") }}
                                </h2>
                                <p>{{ $t("profile.password_change.lead") }}</p>
                            </div>
                            <div class="grid gap-5">
                                <app-input
                                    type="password"
                                    :errors="errors"
                                    :label="$t('models.account.password')"
                                    :placeholder="
                                        $t(
                                            'models.account.password_placeholder'
                                        )
                                    "
                                    v-model="password.password"
                                    name="password"
                                >
                                </app-input>
                                <app-input
                                    type="password"
                                    :errors="errors"
                                    :label="
                                        $t('models.account.password_repeat')
                                    "
                                    :placeholder="
                                        $t(
                                            'models.account.password_repeat_placeholder'
                                        )
                                    "
                                    v-model="password.password_repeat"
                                    name="password_repeat"
                                >
                                </app-input>
                            </div>
                            <div class="flex justify-center">
                                <div>
                                    <app-button @click="submitPasswordChange">{{
                                        $t("buttons.save")
                                    }}</app-button>
                                </div>
                            </div>
                        </div>
                        <div
                            class="bg-white border border-r-gray-light p-8 rounded-lg h-auto grid gap-10 items-start"
                        >
                            <div class="grid gap-3">
                                <h2
                                    class="text-r-green text-xl font-black italic"
                                >
                                    {{ $t("languages.my_language") }}
                                </h2>
                                <p>{{ $t("languages.lead_profile") }}</p>
                            </div>
                            <app-input
                                type="selectClassic"
                                :errors="errors"
                                :selectOptions="locales"
                                selectValueProperty="locale"
                                selectLabelProperty="label"
                                :label="$t('models.account.preferred_locale')"
                                v-model="data.preferred_language"
                                name="preferred_locale"
                            >
                            </app-input>
                            <div class="flex justify-center">
                                <div>
                                    <app-button @click="submitLocale">{{
                                        $t("buttons.save")
                                    }}</app-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </content-container>
        </full-width-container>
        <!-- <full-width-container> -->
        <!--     <content-container> -->
        <!--         <div -->
        <!--             class="flex flex-col md:flex-row items-center justify-between py-10" -->
        <!--         > -->
        <!--             <h2 -->
        <!--                 class="w-full md:w-auto font-slanted text-2xl md:text-4xl italic pb-4 font-black uppercase md:flex-grow text-r-green" -->
        <!--             > -->
        <!--                 {{ $t("profile.heading_section") }} -->
        <!--             </h2> -->
        <!--             <div -->
        <!--                 class="w-full md:w-auto flex flex-row space-x-5 text-r-black-50" -->
        <!--             > -->
        <!--                 <router-link -->
        <!--                     class="uppercase font-bold underline underline-offset-4" -->
        <!--                     active-class="text-r-green" -->
        <!--                     v-for="item in nav" -->
        <!--                     :to="{ name: item.name }" -->
        <!--                     :key="item.name" -->
        <!--                 > -->
        <!--                     {{ $t(item.label) }} -->
        <!--                 </router-link> -->
        <!--             </div> -->
        <!--         </div> -->
        <!--         <div> -->
        <!--             <transition -->
        <!--                 mode="out-in" -->
        <!--                 enter-active-class="transition-all duration-150 ease-in-out" -->
        <!--                 leave-active-class="transition-all duration-150 ease-in-out" -->
        <!--                 enter-class="opacity-0 scale-75 transform -translate-x-48" -->
        <!--                 enter-to-class="opacity-100 scale-100" -->
        <!--                 leave-class="opacity-100 scale-100" -->
        <!--                 leave-to-class="opacity-0 scale-75 transform translate-x-48" -->
        <!--             > -->
        <!--                 <router-view></router-view> -->
        <!--             </transition> -->
        <!--         </div> -->
        <!--     </content-container> -->
        <!-- </full-width-container> -->
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import FullWidthContainer from "@guest/common/FullWidthContainer.vue";
import ContentContainer from "@guest/common/ContentContainer.vue";
import AppInput from "@guest/common/Input";
import AppButton from "@guest/common/Button";
import DayJS from "@mixins/DayJS";
import Countries from "@mixins/Countries";
import Titles from "@mixins/Titles";
import Genders from "@mixins/Genders";
import IdentificationTypes from "@mixins/IdentificationTypes";
import PreferredLanguages from "@mixins/PreferredLanguages";
import TitleBar from "@guest/common/TitleBar";
import BirthdateVue from "@guest/common/Birthdate.vue";
import Locales from "@/guest/common/locales";

export default {
    mixins: [
        DayJS,
        Countries,
        Titles,
        Genders,
        PreferredLanguages,
        IdentificationTypes,
    ],
    components: {
        ContentContainer,
        FullWidthContainer,
        AppButton,
        AppInput,
        TitleBar,
        BirthdateVue,
        // LayoutWithHeader,
        // SectionHeading,
        // AppButton,
    },
    data: function () {
        return {
            errors: {},
            password: {
                password: null,
                password_repeat: null,
            },
            data: {
                first_name: "",
                last_name: "",
                middle_initial: "",
                company_name: "",
                email: "",
                phone: "",
                address_line1: "",
                address_line2: "",
                postal_code: "",
                city: "",
                country_code: "DE",

                nationality_country_code: "DE",
                identification_number: "",
                identification_issue_date: "",
                identification_type: "",
                preferred_language: "de",
                birth_date: "",
                birth_place: "",
            },
        };
    },
    watch: {
        isLoggedIn: {
            handler(val) {
                if (!val) {
                    this.$router.push({ name: "login" });
                }
            },
            immediate: true,
        },
    },
    computed: {
        locales() {
            return Locales;
        },
        ...mapGetters({
            isLoggedIn: "isLoggedIn",
            currentLocale: "currentLocale",
        }),
        ...mapState({
            profile: "profile",
            loading: "loading",
        }),
    },
    methods: {
        submitProfile() {
            this.errors = {};
            this.setLoading(true);
            this.postProfile(this.data)
                .catch((error) => {
                    if (error.response.status === 422) {
                        console.log(error.response.data.errors);
                        this.errors = error.response.data.errors;
                    }
                })
                .then(() => {
                    this.setLoading(false);
                });
        },
        submitPasswordChange() {
            this.errors = {};
            this.setLoading(true);
            this.postProfilePasswordChange(this.password)
                .then((response) => {
                    this.password.password = null;
                    this.password.password_repeat = null;
                })
                .catch((error) => {
                    if (error.response.status === 422) {
                        console.log(error.response.data.errors);
                        this.errors = error.response.data.errors;
                    }
                })
                .then(() => {
                    this.setLoading(false);
                });
        },
        submitLocale() {
            this.errors = {};
            this.setLoading(true);
            this.postLocale(this.data.preferred_language)
                .then((response) => {
                    // this.password.password = null;
                    // this.password.password_repeat = null;
                })
                .catch((error) => {
                    if (error.response.status === 422) {
                        console.log(error.response.data.errors);
                        this.errors = error.response.data.errors;
                    }
                })
                .then(() => {
                    this.setLoading(false);
                });
        },

        ...mapActions({
            postProfile: "postProfile",
            postProfilePasswordChange: "postProfilePasswordChange",
            setLoading: "setLoading",
            getProfile: "getProfile",
            postLocale: "postLocale",
        }),
    },
    props: {
        // person: {
        //     required: true,
        //     type: Object,
        // },
    },
    mounted() {
        if (this.isLoggedIn) {
            this.data = this.profile.guest_detail;
            this.data.first_name = this.profile.first_name;
            this.data.last_name = this.profile.last_name;
            this.data.email = this.profile.email;
            console.log("profile", this.profile);
        }
    },
};
</script>

<style></style>
