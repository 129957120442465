<template>
    <div
        class="bg-white text-r-black px-6 py-10 lg:p-16 rounded-lg border relative overflow-hidden"
        :class="[dummy ? 'border-r-gray-light' : 'border-r-gray-medium']"
    >
        <div
            class="grid md:grid-cols-2 gap-5 divide-y-2 md:divide-x-2 md:divide-y-0 divide-r-gray-ultralight"
        >
            <div class="flex flex-col gap-7 lg:pr-6">
                <h2 class="text-2xl md:text-2xl italic font-black text-r-green">
                    {{ $t("new_reservation.login.heading") }}
                </h2>
                <div>
                    <!-- <div class="font-bold uppercase"> -->
                    <!--     {{ $t("new_reservation.login.subtitle_account") }} -->
                    <!-- </div> -->
                    <div>
                        {{ $t("new_reservation.login.lead_account") }}
                    </div>
                </div>
                <div class="flex flex-col space-y-4">
                    <app-input
                        :required="true"
                        :label="$t('models.account.email')"
                        :placeholder="$t('models.account.email_placeholder')"
                        v-model="user.email"
                        :errors="errors"
                        name="email"
                    >
                    </app-input>
                    <app-input
                        :required="true"
                        type="password"
                        :label="$t('models.account.password')"
                        :placeholder="$t('models.account.password_placeholder')"
                        v-model="user.password"
                        :errors="errors"
                        name="password"
                    >
                    </app-input>
                </div>
                <div class="text-sm text-r-gray">
                    <router-link
                        class="underline"
                        :to="{ name: 'forgot-password' }"
                    >
                        {{ $t("login.forgot_password") }}
                    </router-link>
                </div>
                <!-- <div class="text-white text-sm flex justify-end"> -->
                <!--     <required-field-info></required-field-info> -->
                <!-- </div> -->
                <div class="flex justify-center">
                    <div>
                        <app-button @click="submit" color="green">
                            {{ $t("new_reservation.login.button_login") }}
                        </app-button>
                    </div>
                </div>
            </div>

            <div class="flex flex-col space-y-7 pt-5 md:pt-0 md:pl-10">
                <h2 class="text-2xl md:text-2xl italic font-black text-r-green">
                    {{ $t("new_reservation.login.heading_without_login") }}
                </h2>
                <div>
                    <!-- <div class="font-bold uppercase"> -->
                    <!--     {{ -->
                    <!--         $t("new_reservation.login.subtitle_without_account") -->
                    <!--     }} -->
                    <!-- </div> -->
                    <div>
                        {{ $t("new_reservation.login.lead_without_account") }}
                    </div>
                </div>
                <div class="flex justify-center">
                    <div>
                        <app-button @click="continueWithoutLogin" size="lg">
                            {{
                                $t("new_reservation.login.button_without_login")
                            }}
                        </app-button>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="dummy"
            class="inset-0 absolute bg-white opacity-50 cursor-not-allowed"
            @click="clickDisabled"
        ></div>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import AppButton from "@guest/common/Button";
import AppInput from "@guest/common/Input";
import BookingDraftState from "@guest/bookings/new/State";
import ClipContainer from "@guest/common/ClipContainer";
import RequiredFieldInfo from "@guest/common/RequiredFieldInfo.vue";

export default {
    components: {
        AppButton,
        ClipContainer,
        AppInput,
        BookingDraftState,
        RequiredFieldInfo,
    },
    data: function () {
        return {
            errors: {},
            user: {
                email: "",
                password: "",
            },
            error: null,
        };
    },
    watch: {
        isLoggedIn: {
            handler(val) {
                console.log("isLoggedIn watcher", val);
            },
        },
        immediate: true,
    },
    methods: {
        clickDisabled() {
            console.log("Click Disabled Container");
        },
        submit() {
            this.errors = {};
            this.error = null;
            let temp = this.login(this.user)
                .then((state) => {
                    this.$emit("booker");
                    // this.$emit("services-list");
                })
                .catch((error) => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    }
                    console.error("Es ist ein Fehler beim Login aufgetreten");
                    this.error = "Es ist ein Fehler beim Login aufgetreten";
                });
            console.log("Promise", temp);
        },
        ...mapActions({
            login: "login",
            setReturnToPath: "setReturnToPath",
        }),
        continueWithoutLogin() {
            console.log("continue without login");
            // this.$emit("services-list");
            this.$emit("booker");
        },
    },
    computed: {
        ...mapGetters({
            isLoggedIn: "isLoggedIn",
        }),
        ...mapState({
            bookingDraft: "bookingDraft",
        }),
    },
    async mounted() {
        console.log("mounted isLoggedIn", this.isLoggedIn);

        // if (this.isLoggedIn) {
        //     this.$emit("booker");
        // }
    },
    props: {
        dummy: {
            required: false,
            default: false,
            type: Boolean,
        },
    },
};
</script>

<style></style>
