<template>
    <div
        :class="{ 'opacity-50': archived }"
        class="bg-white border border-r-gray-light rounded-lg text-r-black relative p-4 lg:p-8 grid grid-cols-1 lg:grid-cols-12 gap-5 lg:gap-3"
    >
        <div class="grid gap-3 lg:col-span-5 place-content-start">
            <div class="font-bold text-lg">
                <div class="leading-5">
                    Roatel<br />
                    <span class="truncate">
                        {{ reservation.property?.name }}
                    </span>
                </div>
            </div>
            <div class="text-base text-r-gray-medium">
                {{ reservation.property?.address_line1 }}<br />
                {{ reservation.property?.postal_code }}
                {{ reservation.property?.city }}
            </div>
        </div>
        <div
            class="lg:col-span-3 border border-r-gray-light grid grid-cols-2 rounded-lg"
        >
            <div class="grid p-3 border-r border-r-gray-light h-[92px]">
                <div class="text-r-gray-medium">
                    {{ $t("reservations.card.arrival") }}
                </div>
                <div class="leading-5">
                    {{ date(reservation.arrival, "date_localized") }}<br />
                    {{
                        $t("common.checkin_time", {
                            time: date(reservation.arrival, "time_localized"),
                        })
                    }}
                </div>
            </div>
            <div class="grid gap-1 p-3 h-[92px]">
                <div class="text-r-gray-medium">
                    {{ $t("reservations.card.departure", "date_localized") }}
                </div>
                <div class="leading-5">
                    {{ date(reservation.departure) }}<br />
                    {{
                        $t("common.checkout_time", {
                            time: date(reservation.departure, "time_localized"),
                        })
                    }}
                </div>
            </div>
            <div class="p-3 col-span-2 border-t border-r-gray-light">
                <div class="text-r-gray-medium">
                    {{ $t("reservations.summary.guest_heading") }}
                </div>
                <div>
                    <span>
                        {{
                            reservation.primary_guest?.first_name +
                            " " +
                            reservation.primary_guest?.last_name
                        }}
                    </span>
                </div>
            </div>
        </div>
        <div
            class="rounded-lg border border-r-gray-light grid lg:col-span-2 items-start"
        >
            <div class="lg:grid p-3 items-start flex-grow lg:h-[92px]">
                <div class="text-r-gray-medium">
                    {{ $t("reservations.card.booking_number") }}
                </div>
                <div class="leading-5">
                    {{ reservation.apaleo_id }}
                </div>
            </div>
            <div class="grid p-3 border-t border-r-gray-light">
                <div class="text-r-gray-medium">
                    {{ $t("reservations.card.status") }}
                </div>
                <div>
                    <reservation-status
                        :status="reservation.status"
                    ></reservation-status>
                </div>
            </div>
        </div>
        <div class="lg:col-span-2 lg:justify-end justify-center flex">
            <app-button @click="$emit('selected')">{{
                $t("reservations.card.link_label")
            }}</app-button>
        </div>
    </div>
</template>

<script>
import DayJS from "@mixins/DayJS";
import ReservationStatus from "@guest/bookings/ReservationStatus";
import AppButton from "@guest/common/Button";

export default {
    components: {
        ReservationStatus,
        AppButton,
    },
    mixins: [DayJS],

    props: {
        reservation: {
            required: true,
            type: Object,
        },
        archived: {
            required: false,
            default: false,
            type: Boolean,
        },
    },
    beforeMount() {
        if (this.reservation.property === null) {
            console.log("Empty Property", this.reservation);
        }
    },
};
</script>

<style></style>
