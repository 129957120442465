<template>
    <transition
        enter-active-class="transition-all ease-in-out"
        leave-active-class="transition-all ease-in-out"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
        :duration="300"
    >
        <div v-show="loading || !initialized" class="relative z-50">
            <div
                class="fixed inset-0 bg-black opacity-75 transition-all duration-300"
            ></div>
            <div
                class="fixed inset-0 flex flex-col items-center justify-center text-2xl text-white md:flex-row md:text-6xl space-y-4 md:space-x-10 animate-pulse"
            >
                <div>
                    <font-awesome-icon
                        icon="cloud-arrow-down"
                    ></font-awesome-icon>
                </div>
                <div v-if="locale">
                    {{ $t("loading.message") }}
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
    computed: {
        ...mapState({
            loading: "loading",
            initialized: "initialized",
        }),
        ...mapGetters({
            locale: "currentLocale",
        }),
    },
};
</script>

<style></style>
