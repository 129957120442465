<template>
    <div>
        <full-width-container class="bg-r-gray-ultralight">
            <title-bar>
                {{ $t("password.reset.title") }}
            </title-bar>
        </full-width-container>
        <full-width-container class="bg-r-gray-ultralight">
            <content-container class="flex justify-center" padding="pb-32">
                <div
                    class="p-16 bg-white border border-gray-light grid gap-10 rounded-lg w-[600px]"
                >
                    <div class="grid gap-4">
                        <h2 class="text-r-green text-2xl italic font-black">
                            {{ $t("password.reset.heading") }}
                        </h2>
                        <div>{{ $t("password.reset.lead") }}</div>
                    </div>
                    <div class="grid gap-4">
                        <app-input
                            :placeholder="$t('models.account.email')"
                            :required="false"
                            :errors="errors"
                            v-model="$route.query.email"
                            :readonly="true"
                            type="onlyValue"
                            :label="$t('models.account.email')"
                            name="email"
                        >
                        </app-input>
                        <app-input
                            :placeholder="$t('models.account.password')"
                            :required="true"
                            :errors="errors"
                            v-model="password"
                            type="password"
                            :label="$t('models.account.password')"
                            name="password"
                        >
                        </app-input>
                        <app-input
                            :placeholder="
                                $t('models.account.password_repeat_placeholder')
                            "
                            :required="true"
                            :errors="errors"
                            v-model="password_repeat"
                            type="password"
                            :label="$t('models.account.password_repeat')"
                            name="password_confirmation"
                        >
                        </app-input>
                    </div>
                    <div class="flex justify-center gap-5 flex-col">
                        <alert type="success" v-if="success">
                            {{ $t("password.reset.success") }}
                        </alert>

                        <div class="flex justify-center">
                            <div>
                                <app-button
                                    v-if="success"
                                    @click="$router.push({ name: 'login' })"
                                    color="green"
                                    :outline="false"
                                >
                                    {{ $t("navigation.login") }}
                                </app-button>
                                <app-button
                                    v-if="!success"
                                    @click="submit"
                                    color="green"
                                    :outline="false"
                                >
                                    {{ $t("password.forgot.button_forgot") }}
                                </app-button>
                            </div>
                        </div>
                    </div>
                </div>
            </content-container>
        </full-width-container>
    </div>
</template>
<script>
import FullWidthContainer from "@guest/common/FullWidthContainer";
import ContentContainer from "@guest/common/ContentContainer";
import AppInput from "@guest/common/Input";
import AppButton from "@guest/common/Button";
import { mapActions } from "vuex";
import Topbar from "@guest/layout/Topbar";
import Icon from "@guest/common/Icon";
import TitleBar from "@guest/common/TitleBar";
import Alert from "@guest/common/Alert";

export default {
    components: {
        ContentContainer,
        Alert,
        TitleBar,
        Icon,
        Topbar,
        FullWidthContainer,
        AppInput,
        AppButton,
    },
    data: function () {
        return {
            password: "",
            password_repeat: "",
            errors: {},
            status: "",
            success: false,
        };
    },
    methods: {
        ...mapActions({
            setLoading: "setLoading",
        }),
        submit() {
            this.errors = {};
            this.status = "";
            this.success = false;
            this.setLoading(true);
            axios
                .post("reset-password", {
                    email: this.$route.query.email,
                    password: this.password,
                    password_confirmation: this.password_repeat,
                    token: this.$route.params.token,
                })
                .then((response) => {
                    console.log("response", response.data);
                    this.success = true;
                })
                .catch((error) => {
                    this.errors = error.response.data.errors;
                    (this.status = error.response.data.message),
                        console.log("error", error);
                })
                .then(() => {
                    this.setLoading(false);
                });
        },
    },
};
</script>

<style></style>
