<template>
    <div
        class="flex flex-col gap-5 p-3 lg:p-5 bg-r-gray-ultralight rounded-lg border border-r-gray-light"
    >
        <div class="font-black inline-flex items-center gap-3">
            <icon
                :icon="userIcon"
                :class="[
                    {
                        'stroke-r-black': !completed && !hasErrors,
                        'stroke-r-green': completed && !hasErrors,
                        'stroke-r-red': hasErrors,
                    },
                ]"
                class="w-8 h-8"
            >
            </icon>
            <div
                :class="[
                    {
                        'text-r-black': !completed && !hasErrors,
                        'text-r-green': completed && !hasErrors,
                        'text-r-red': hasErrors,
                    },
                ]"
                class="flex flex-col gap-1 flex-grow leading-none font-bold truncate"
            >
                <div>
                    {{
                        $t(
                            "new_reservation.summary.guest_reservation_card.details_guest",
                            {
                                count: index + 1,
                            }
                        )
                    }}
                </div>
                <div v-if="completed" class="truncate">
                    {{ room.primaryGuest.firstName }}
                    {{ room.primaryGuest.lastName }}
                </div>
                <div v-else class="text-r-gray-medium">
                    {{ $t("models.person.firstName") }}
                    {{ $t("models.person.lastName") }}
                </div>
            </div>
            <div @click="open = !open" class="justify-self-end">
                <icon
                    :icon="open ? 'chevron-up' : 'chevron-down'"
                    class="w-8 h-8 stroke-r-black cursor-pointer"
                ></icon>
            </div>
        </div>
        <div
            class="grid grid-cols-1 lg:grid-cols-2 gap-4"
            v-if="!room.is_booker && open"
        >
            <app-input
                :label="$t('models.person.firstName')"
                :placeholder="$t('models.person.firstName')"
                :required="true"
                v-model="room.primaryGuest.firstName"
                :errors="errors"
                :name="`reservations.${index}.primaryGuest.firstName`"
                type="text"
            >
            </app-input>
            <app-input
                :label="$t('models.person.lastName')"
                :placeholder="$t('models.person.lastName')"
                :required="true"
                :errors="errors"
                v-model="room.primaryGuest.lastName"
                :name="`reservations.${index}.primaryGuest.lastName`"
                type="text"
            >
            </app-input>
            <app-input
                :label="
                    $t(
                        'new_reservation.summary.guest_reservation_card.email_label',
                        { number: index + 1 }
                    )
                "
                :placeholder="
                    $t(
                        'new_reservation.summary.guest_reservation_card.email_placeholder'
                    )
                "
                :required="true"
                :labelUppercase="false"
                :errors="errors"
                v-model="room.primaryGuest.email"
                :name="`reservations.${index}.primaryGuest.email`"
                type="text"
            >
            </app-input>
            <app-input
                :label="
                    $t(
                        'new_reservation.summary.guest_reservation_card.phone_label',
                        { number: index + 1 }
                    )
                "
                :placeholder="
                    $t(
                        'new_reservation.summary.guest_reservation_card.phone_placeholder'
                    )
                "
                :errors="errors"
                :labelUppercase="false"
                :required="true"
                type="phone"
                :name="`reservations.${index}.primaryGuest.phone`"
                v-model="room.primaryGuest.phone"
            />
            <div
                v-if="room.primaryGuest.locale === null"
                class="flex justify-end pt-2"
            ></div>
            <app-input
                v-if="room.primaryGuest.locale !== null"
                type="selectClassic"
                :errors="errors"
                :required="true"
                :selectOptions="locales"
                @select="data.preferred_locale = $event.locale"
                selectValueProperty="locale"
                selectLabelProperty="label"
                :selectSearchKeys="['locale', 'label']"
                :label="$t('models.account.preferred_locale')"
                v-model="room.primaryGuest.locale"
                name="preferred_locale"
            >
            </app-input>
            <div
                class="col-span-full flex justify-center"
                v-if="reservations.length > 1"
            >
                <div class="pt-4">
                    <app-button color="gray" @click="save">{{
                        $t("buttons.save")
                    }}</app-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AppInput from "@guest/common/Input";
import AppButton from "@guest/common/Button";
import AppInputLabel from "@guest/common/InputLabel";
import Locales from "@/guest/common/locales";
import Icon from "@guest/common/Icon";

export default {
    components: {
        AppInput,
        AppButton,
        AppInputLabel,
        Icon,
    },
    computed: {
        locales() {
            return Locales;
        },
        hasErrors() {
            const fields = [
                `reservations.${this.index}.primaryGuest.firstName`,
                `reservations.${this.index}.primaryGuest.lastName`,
                `reservations.${this.index}.primaryGuest.email`,
                `reservations.${this.index}.primaryGuest.locale`,
                `reservations.${this.index}.primaryGuest.phone`,
            ];
            console.log("fields to check for icon", fields);
            for (let i = 0; i < fields.length; i++) {
                if (this.errors.hasOwnProperty(fields[i])) {
                    this.completed = false;
                    this.open = true;
                    return true;
                }
            }
            return false;
        },
        userIcon() {
            if (this.hasErrors) {
                return "user-x";
            }
            if (this.completed) {
                return "user-check";
            }
            return "user-plus";
        },
    },
    data: function () {
        return {
            open: true,
            completed: false,
            room: {
                primaryGuest: {
                    firstName: "",
                    lastName: "",
                    email: "",
                    locale: null,
                },
                is_booker: false,
                is_admin: true,
            },
        };
    },
    mounted() {
        console.log(
            "guest-card initiales state from props",
            this.reservation.primaryGuest
        );
        if (this.reservation.hasOwnProperty("primaryGuest")) {
            this.room.primaryGuest = this.reservation.primaryGuest;
            this.room.primaryGuest.locale = this.locales[0].locale;
        }
    },
    methods: {
        save() {
            if (
                this.room.primaryGuest.firstName != "" &&
                this.room.primaryGuest.lastName != "" &&
                this.room.primaryGuest.email != "" &&
                this.room.primaryGuest.locale != ""
            ) {
                this.open = false;
                this.completed = true;
            }
        },
    },
    watch: {
        reservation: {
            handler: function (val) {
                console.log("guest-card reservaton watcher", val, this.room);

                if (val.hasOwnProperty("primaryGuest")) {
                    console.log(
                        "guest-card reservation has primaryGuest",
                        val.primaryGuest
                    );
                    this.room = val;
                }
            },
            immediate: true,
        },
        room: {
            handler: function (val) {
                console.log("guest-card change in room", val);
                this.$emit("change", val);
            },
            deep: true,
        },
        // "room.is_booker": {
        //     handler: function (val) {
        //         if (val) {
        //             this.room.primaryGuest.firstName = this.booker.lastName;
        //             this.room.primaryGuest.lastName = this.booker.firstName;
        //             this.room.primaryGuest.email = this.booker.email;
        //             this.room.primaryGuest.email = this.booker.phone;
        //             this.room.primaryGuest.locale = null;
        //         }
        //         if (val === false) {
        //             this.room.primaryGuest.firstName = "";
        //             this.room.primaryGuest.lastName = "";
        //             this.room.primaryGuest.email = "";
        //             this.room.primaryGuest.phone = "";
        //             this.room.primaryGuest.locale = this.locales[0].locale;
        //         }
        //         console.log("is_booker change", val);
        //     },
        //     immediate: true,
        // },
    },
    props: {
        reservation: {
            required: true,
            type: Object,
        },
        index: {
            required: true,
        },
        booker: {
            required: true,
            type: Object,
        },
        reservations: {
            required: true,
            type: Array,
        },
        errors: {
            required: true,
            type: Object,
        },
    },
};
</script>

<style></style>
