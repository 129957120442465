var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative"},[_c('full-width-container',{staticClass:"bg-r-green relative lg:py-2 z-30"},[_c('content-container',{attrs:{"padding":"px-3 py-2 lg:py-4 lg:px-12 z-30"}},[_c('div',{staticClass:"w-full flex flex-col lg:flex-row gap-5 md:justify-between md:items-center"},[_c('div',{staticClass:"flex flex-row justify-between items-center"},[_c('h2',{staticClass:"text-white font-black italic truncate flex-grow"},[_vm._v("\n                        "+_vm._s(_vm.$t("login_bar.title"))+"\n                    ")]),_vm._v(" "),_c('div',{staticClass:"lg:hidden",on:{"click":function($event){_vm.showHamburgerMenu = !_vm.showHamburgerMenu}}},[_c('icon',{staticClass:"stroke-white w-8 h-8",attrs:{"icon":_vm.showHamburgerMenu
                                    ? 'chevron-up'
                                    : 'chevron-down'}})],1)]),_vm._v(" "),(_vm.showHamburgerMenu)?_c('div',{staticClass:"divide-y divide-white z-30"},_vm._l((['login', 'register']),function(route){return _c('div',{key:route,staticClass:"font-bold py-3 px-1",class:[
                            _vm.$route.name === route
                                ? 'text-r-darkgreen'
                                : 'text-white',
                        ],on:{"click":function($event){route.hasOwnProperty('click')
                                ? route.click()
                                : _vm.navigateItem({ name: route })}}},[_vm._v("\n                        "+_vm._s(_vm.$t("login_bar." + route))+"\n                    ")])}),0):_vm._e(),_vm._v(" "),_c('div',{staticClass:"lg:flex-row items-center gap-8 hidden lg:flex"},_vm._l((['login', 'register']),function(route){return _c('router-link',{key:route,staticClass:"font-bold text-white",attrs:{"to":{ name: route },"active-class":"text-r-darkgreen"}},[_vm._v("\n                        "+_vm._s(_vm.$t("login_bar." + route))+"\n                    ")])}),1)])])],1),_vm._v(" "),(_vm.showHamburgerMenu)?_c('div',{staticClass:"inset-0 fixed bg-r-black opacity-60 z-10 top-20 transition-all duration-300",on:{"click":function($event){_vm.showHamburgerMenu = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }