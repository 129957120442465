var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[_vm.hoverPointerClass],on:{"click":function($event){return _vm.$emit('property-select', _vm.property)}}},[_c('clip-container',[_c('div',{staticClass:"transition-all duration-150 px-5 py-8 md:p-10 bg-r-black-5 group text-r-gray",class:[_vm.hoverClasses ? _vm.hoverClasses : '']},[_vm._t("before"),_vm._v(" "),_c('div',{staticClass:"flex flex-col space-y-3"},[_c('div',{staticClass:"flex flex-row justify-between"},[_c('div',{staticClass:"text-xl md:text-2xl font-black slanted uppercase text-r-gray italic",class:[_vm.hoverClasses ? _vm.groupHoverClasses : '']},[_vm._v("\n                        roatel "+_vm._s(_vm.property.name)+"\n                    ")])]),_vm._v(" "),_c('div',{staticClass:"flex text-sm leading-6",class:[_vm.hoverClasses ? _vm.groupHoverClasses : '']},[_c('div',[_c('div',[_vm._v("\n                            "+_vm._s(_vm.property.address_line1)+"\n                        ")]),_vm._v(" "),_c('div',[_vm._v("\n                            "+_vm._s(_vm.property.postal_code)+" "+_vm._s(_vm.property.city)+"\n                        ")]),_vm._v(" "),(_vm.showImprintButton)?_c('div',{staticClass:"flex flex-row space-x-3 text-r-green items-center underline hover:cursor-pointer",class:_vm.hoverClasses ? 'group-hover:text-white' : '',on:{"click":function($event){$event.stopPropagation();return _vm.$emit('property-imprint', _vm.property)}}},[_c('div',[_vm._v("\n                                "+_vm._s(_vm.$t(
                                        "new_reservation.property_card.imprint.link"
                                    ))+"\n                            ")])]):_vm._e(),_vm._v(" "),(_vm.showRoomInformationButton)?_c('div',{staticClass:"mt-2 inline-flex flex-row space-x-3 text-r-green items-center underline hover:cursor-pointer",class:_vm.hoverClasses ? 'group-hover:text-white' : ''},[_c('font-awesome-icon',{staticClass:"text-2xl",attrs:{"icon":"bed"}}),_vm._v(" "),_c('a',{attrs:{"href":"https://day.roatel.de","target":"_blank"}},[_vm._v("\n                                "+_vm._s(_vm.$t("reservations.summary.room_manual"))+"\n                            ")])],1):_vm._e()])]),_vm._v(" "),(_vm.showDistance || _vm.showNavigation)?_c('div',[_c('div',{staticClass:"text-r-green inline-flex group flex-row cursor-pointer font-bold text-sm space-x-3 items-center",class:_vm.hoverClasses ? 'group-hover:text-white' : '',on:{"click":function($event){$event.stopPropagation();return _vm.openPropertyInMaps()}}},[_c('div',[_c('icon',{staticClass:"text-r-black-5",class:_vm.hoverClasses
                                        ? 'group-hover:text-r-green group-hover:bg-white'
                                        : '',attrs:{"icon":"location","background":"bg-r-green ","padding":""}})],1),_vm._v(" "),_c('div',{class:_vm.hoverClasses ? 'group-hover:text-white' : ''},[(_vm.showDistance && _vm.property.distance)?_c('div',[_vm._v("\n                                "+_vm._s(_vm.$t(
                                        "new_reservation.property_card.distance",
                                        {
                                            distance: _vm.number(
                                                _vm.property.distance,
                                                0
                                            ),
                                        }
                                    ))+"\n                            ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"underline"},[_vm._v("\n                                "+_vm._s(_vm.$t("new_reservation.show_map"))+"\n                            ")])])])]):_vm._e()]),_vm._v(" "),_vm._t("after")],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }