<template>
    <input-layout
        :stacked="stacked"
        :stackedReversed="stackedReversed"
        :label="label"
        :hasLabel="hasLabel"
        :labelUppercase="labelUppercase"
    >
        <template v-slot:label>
            <input-label
                :id="id"
                :error="hasError"
                :labelUppercase="labelUppercase"
                :required="required"
            >
                {{ label }}
            </input-label>
        </template>
        <div v-if="initialized">
            <template v-if="showInputElement">
                <template v-if="typeText">
                    <input
                        ref="input"
                        :type="type"
                        :value="value"
                        :size="size"
                        :step="numberStep"
                        @keyup.enter="$emit('keyup-enter', $event)"
                        class="w-full rounded shadow duration-150 transition-all"
                        :class="
                            hasError
                                ? 'ring ring-red-200 ring-offset-2 focus:border-red-600 focus:ring focus:ring-red-200 focus:ring-offset-2 active:border-red-600 transition-all duration-150'
                                : ''
                        "
                        :placeholder="placeholder"
                        @input="$emit('input', $event.target.value)"
                        :id="id"
                    />
                </template>
                <template v-if="typeCheckbox">
                    <input
                        type="checkbox"
                        :size="size"
                        class="shadow duration-150 transition-all"
                        :class="
                            hasError
                                ? 'ring ring-red-200 ring-offset-2 focus:border-red-600 focus:ring focus:ring-red-200 focus:ring-offset-2 active:border-red-600 transition-all duration-150'
                                : ''
                        "
                        :placeholder="placeholder"
                        :value="value"
                        :checked="value"
                        @input="$emit('input', $event.target.checked)"
                        :id="id"
                    />
                </template>
                <template v-if="typeDatepicker">
                    <v-date-picker
                        ref="input"
                        :value="value"
                        @input="inputDate($event)"
                        :min-date="datepickerMinDate"
                        :max-date="datepickerMaxDate"
                        :mode="datepickerMode"
                        :locale="locale"
                        :is24hr="datepickerIs24hr"
                        :minute-increment="datepickerMinuteIncrement"
                    >
                        <template v-slot="{ inputValue, inputEvents }">
                            <div class="relative flex flex-row items-center">
                                <input
                                    type="text"
                                    :value="inputValue"
                                    v-on="inputEvents"
                                    :size="size"
                                    class="w-full rounded shadow duration-150 transition-all"
                                    :class="
                                        hasError
                                            ? 'ring ring-red-200 ring-offset-2 focus:border-red-600 focus:ring focus:ring-red-200 focus:ring-offset-2 active:border-red-600 transition-all duration-150'
                                            : ''
                                    "
                                    :placeholder="placeholder"
                                    :id="id"
                                />
                                <div
                                    class="absolute right-0 flex items-center h-full px-2 my-auto bg-transparent pointer-cursor"
                                >
                                    <font-awesome-icon
                                        icon="calendar-day"
                                        class="mr-2 text-xl text-gray-400"
                                    >
                                    </font-awesome-icon>
                                </div>
                            </div>
                        </template>
                    </v-date-picker>
                </template>
                <template v-if="typeSelect">
                    <div class="relative" v-click-outside.stop="selectClose">
                        <div class="relative flex flex-row items-center">
                            <input
                                type="text"
                                :size="size"
                                class="w-full rounded shadow duration-150 transition-all"
                                :class="
                                    hasError
                                        ? 'ring ring-red-200 ring-offset-2 focus:border-red-600 focus:ring focus:ring-red-200 focus:ring-offset-2 active:border-red-600 transition-all duration-150'
                                        : ''
                                "
                                :placeholder="placeholder"
                                v-model="selectValue"
                                @focus="$event.target.select()"
                                @click="selectOpen = true"
                                @input="selectSearch"
                                :id="id"
                                ref="selectInput"
                            />
                            <div
                                @click="selectOpen = !selectOpen"
                                class="absolute right-0 flex items-center h-full px-2 my-auto bg-transparent pointer-cursor"
                            >
                                <font-awesome-icon
                                    :icon="
                                        selectOpen
                                            ? 'chevron-up'
                                            : 'chevron-down'
                                    "
                                    class="mr-2 text-xl text-gray-600"
                                >
                                </font-awesome-icon>
                            </div>
                        </div>
                        <div
                            v-show="selectOpen"
                            ref="selectDropdown"
                            class="absolute z-50 w-full mt-2 overflow-hidden overflow-y-auto border border-gray-600 rounded-lg shadow-lg max-h-80"
                        >
                            <div
                                @click.stop="select(option)"
                                v-for="option in selectOptionsFiltered.slice(
                                    0,
                                    10
                                )"
                                :key="option[selectValueProperty]"
                                :class="{
                                    'bg-yellow-100':
                                        option[selectValueProperty] === value,
                                }"
                                class="px-4 py-3 bg-white border-b cursor-pointer transition-all duration-150 hover:bg-yellow-100 last:border-0"
                            >
                                <slot :item="option" name="listItem">
                                    {{ option[selectLabelProperty] }}
                                </slot>
                            </div>
                            <div
                                class="px-4 py-3 text-gray-500 bg-white border-b transition-all duration-150"
                                v-if="selectOptionsFiltered.length < 1"
                            >
                                keine Treffer
                            </div>
                        </div>
                    </div>
                </template>
                <template v-if="typeSelectClassic">
                    <div class="relative">
                        <div class="relative flex flex-row items-center">
                            <select
                                :size="size"
                                class="w-full rounded shadow duration-150 transition-all"
                                :class="
                                    hasError
                                        ? 'ring ring-red-200 ring-offset-2 focus:border-red-600 focus:ring focus:ring-red-200 focus:ring-offset-2 active:border-red-600 transition-all duration-150'
                                        : ''
                                "
                                :placeholder="placeholder"
                                v-model="selectClassicValue"
                                @change.stop="
                                    $emit('input', $event.target.value)
                                "
                                :id="id"
                                ref="selectClassic"
                            >
                                <option
                                    v-for="(
                                        option, index
                                    ) in selectOptionsEdited"
                                    :key="id + '_' + index"
                                    :value="option[selectValueProperty]"
                                >
                                    <slot
                                        name="selectClassicOption"
                                        :option="option"
                                    >
                                        {{ option[selectLabelProperty] }}
                                    </slot>
                                </option>
                            </select>
                        </div>
                    </div>
                </template>
                <template v-if="typeTextarea">
                    <textarea
                        :type="type"
                        :value="value"
                        :size="size"
                        :rows="textAreaRows"
                        class="w-full rounded shadow duration-150 transition-all"
                        :placeholder="placeholder"
                        @input="$emit('input', $event.target.value)"
                        :id="id"
                    ></textarea>
                </template>
                <template v-if="typeSlider">
                    <input-slider
                        :type="type"
                        :value="value"
                        :size="size"
                        class="duration-150 transition-all"
                        :placeholder="placeholder"
                        :id="id"
                        @input="$emit('input', $event)"
                    ></input-slider>
                </template>
                <div
                    v-if="hasError"
                    class="flex flex-col mt-2 text-base text-red-600 space-y-1"
                >
                    <div v-for="(error, index) in errorMessages" :key="index">
                        {{ error }}
                    </div>
                </div>
            </template>
            <template v-else>
                <div
                    type="text"
                    class="px-0 bg-transparent border-0"
                    v-if="$slots.default === undefined"
                    v-html="value"
                >
                    {{ $slots.default }}
                </div>
                <div
                    type="text"
                    v-if="$slots.default !== undefined"
                    class="px-0 bg-transparent border-0"
                >
                    <slot></slot>
                </div>
            </template>
        </div>
        <div v-if="!initialized" class="pulse">
            <div type="text" class="border-gray-300">
                <div class="w-full h-6 bg-gray-300"></div>
            </div>
        </div>
    </input-layout>
</template>

<script>
import InputLayout from "@admin/common/InputLayout";
import InputLabel from "@admin/common/InputLabel";
import InputSlider from "@admin/common/Slider";
import Fuse from "fuse.js";
import { createPopper } from "@popperjs/core";
import * as vClickOutside from "v-click-outside-x";
import { mapGetters } from "vuex";

export default {
    directives: {
        clickOutside: vClickOutside.directive,
    },
    components: {
        InputLayout,
        InputLabel,
        InputSlider,
    },
    data: function () {
        return {
            id: null,
            selectOpen: false,
            selectSearchEngine: null,
            selectValue: null,
            selectSearchResult: [],
            selectClassicValue: null,
            popper: null,
            popperConfig: {
                strategy: "fixed",
                placement: "auto",
                modifiers: [
                    {
                        name: "offset",
                        options: {
                            offset: [0, 6],
                        },
                    },
                    {
                        name: "sameWidth",
                        enabled: true,
                        phase: "beforeWrite",
                        requires: ["computeStyles"],
                        fn: ({ state }) => {
                            state.styles.popper.width = `${state.rects.reference.width}px`;
                        },
                        effect: ({ state }) => {
                            state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`;
                        },
                    },
                ],
            },
        };
    },
    watch: {
        selectOpen: {
            handler(val) {
                if (val) {
                    this.popper.update();
                }
            },
        },
        value: {
            handler: function (val, old) {
                //console.log('value set input for ' + this.name, val, old)
                if (this.typeSelectClassic) {
                    this.selectClassicValue = val;
                }
                //console.log('Value handler this.selectValue', val)
                //this.selectValue = val
                //console.log('select hitter from options', this.selectOptions.filter(item => item['alpha-2'] == val))
                if (val !== null && this.typeSelect) {
                    let hit = this.selectOptions.find(
                        (item) => item[this.selectValueProperty] === val
                    );
                    if (hit) {
                        this.select(hit);
                    }
                }
            },
            immediate: true,
        },
        selectOptions: {
            handler: function (val) {
                console.log("selectOptions set input", val);
                //this.selectValue = this.value
                //console.log('SelectOptions', this.selectOptions)
                if (
                    this.selectOptions !== null &&
                    this.selectOptions.length > 0 &&
                    this.typeSelect
                ) {
                    this.selectSearchEngine.setCollection(this.selectOptions);
                    if (this.typeSelect) {
                        if (this.value !== null) {
                            let selected = this.selectOptions.find(
                                (item) =>
                                    item[this.selectValueProperty] ===
                                    this.value
                            );
                            console.log("selected ", selected);
                            if (selected !== undefined) {
                                this.select(selected);
                            }
                        }
                    }
                }
            },
            //immediate: true,
        },
    },
    mounted() {
        this.id = this._uid;
        if (this.typeSelect) {
            const options = {
                includeMatches: true,
                shouldSort: true,
                includeScore: true,
                threshold: 0.3,
                keys: this.selectSearchKeys,
                minMatchCharLength: 2,
            };
            this.selectSearchEngine = new Fuse(this.selectOptions, options);
            this.popper = createPopper(
                this.$refs.selectInput,
                this.$refs.selectDropdown,
                this.popperConfig
            );
        }
        if (this.typeSelectClassic) {
            console.log("mounted selectClassic", this.name, this.value);
        }
        if (this.autofocus && ["text", "datepicker"].includes(this.type)) {
            console.log("try autofocus", this.$refs);
            this.$refs.input.focus();
        }
    },
    methods: {
        selectClose() {
            this.selectOpen = false;
        },
        input() {
            this.$emit("input", this.value);
            //console.log('input')
        },
        inputDate(value) {
            this.$emit("input", value);
            //console.log('dateinput')
        },
        select(option) {
            this.$emit("select", option);
            this.selectOpen = false;
            this.selectValue = option[this.selectLabelProperty];
            //console.log('this.selectValue option', option['label'])
            //console.log('this.selectLabelProperty ', this.selectLabelProperty)
            //console.log('this.selectValue', option[this.selectLabelProperty])
            //this.selectSearch(this.selectValue)
        },
        selectEnter() {
            console.log();
        },
        selectSearch(query) {
            //Reset value after change until select
            this.$emit("input", null);
            //console.log(this.selectValue, query)
            //console.log('select search engine',this.selectSearchEngine)
            this.selectSearchResult = this.selectSearchEngine.search(
                this.selectValue
            );
        },
    },
    computed: {
        selectOptionsEdited() {
            let options = _.cloneDeep(this.selectOptions);
            if (this.selectNullable) {
                let nullable = {};
                nullable[this.selectLabelProperty] = this.selectNullableLabel;
                nullable[this.selectValueProperty] = this.selectNullableValue;
                options.unshift(nullable);
            }
            return options;
        },
        //selectSearchResult() {
        //console.log('selectValue', this.selectValue)
        //if (this.selectValue !== null) {
        //return this.selectSearchEngine.search(this.selectValue)
        //}
        //return []
        //},
        /*
        selectInputText() {
            if (this.value === null) {
                return null
            }
            let hit = this.selectOptions.find(item => {
                return item[this.selectValueProperty] === this.value
            })
            console.log('HIT', hit)
            if (hit !== undefined) {
                return hit[this.selectLabelProperty]
            }
            return null
        },
        */
        selectOptionsFiltered() {
            //console.log('selectSearchResult', this.selectSearchResult)
            //console.log('selectOptions', this.selectOptions)
            //console.log('selectSearchResultOptions.map', this.selectSearchResult.map(item => item.item))
            //console.log('selectValue', this.selectValue)
            if (
                this.selectValue === null ||
                this.selectValue === "" ||
                this.selectValue.length < 2
            ) {
                //console.log('(this.selectSearchResult === null)')
                return this.selectOptions;
                //if (this.selectSearchResult.length < 1) {
                //console.log('this.selectSearchResult.length < 1')
                //}
            }
            //console.log('Mapping')
            return this.selectSearchResult.map((item) => {
                return item.item;
            });
        },
        hasError() {
            if (this.errors === undefined) {
                return false;
            }
            //console.log('HAS_ERROR', this.errors)
            return this.errors.hasOwnProperty(this.name) ? true : false;
        },
        errorMessages() {
            return this.errors[this.name];
        },
        typeTextarea() {
            return this.type === "textarea";
        },
        typeSelect() {
            return this.type === "select";
        },
        typeSelectClassic() {
            return this.type === "selectClassic";
        },
        typeSlider() {
            return this.type === "slider";
        },
        typeDatepicker() {
            return this.type === "datepicker";
        },
        typeCheckbox() {
            return this.type === "checkbox";
        },
        typeText() {
            const types = ["text", "email", "password", "number"];
            if (types.includes(this.type)) {
                return true;
            }
            return false;
        },
        showInputElement() {
            if (this.type === "onlyValue") {
                return false;
            }
            return true;
        },
    },
    props: {
        stacked: { required: false, default: true },
        stackedReversed: { required: false, default: false },
        label: { required: false, default: "Label not set" },
        hasLabel: { required: false, default: true },
        type: { required: false, default: "text" /* showOnlyValue */ },
        value: { required: false, default: null },
        placeholder: { required: false },
        initialized: { required: false, default: true },
        size: { required: false, default: "base" },
        errors: { required: false, type: Object, default: () => {} },
        name: { required: true, type: String },
        required: { required: false, default: false },
        selectOptions: {
            required: false,
            default: () => [],
            type: Array,
        },
        selectValueProperty: {
            required: false,
            default: "id",
        },
        selectLabelProperty: {
            required: false,
            default: "name",
        },
        selectSearchKeys: {
            required: false,
            default: () => ["name", "description", "location.city"],
        },
        selectNullable: {
            required: false,
            default: false,
        },
        selectNullableLabel: {
            required: false,
            default: "-",
        },
        selectNullableValue: {
            required: false,
            default: "",
        },
        datepickerMinDate: {
            required: false,
            default: null,
        },
        datepickerMaxDate: {
            required: false,
            default: null,
        },
        datepickerMode: {
            required: false,
            default: "date",
        },
        datepickerIs24hr: {
            required: false,
            default: true,
        },
        datepickerMinuteIncrement: {
            required: false,
            default: 15,
            type: Number,
        },
        textAreaRows: {
            required: false,
            default: 10,
        },
        labelUppercase: {
            required: false,
            default: true,
        },
        locale: {
            required: false,
            default: "de",
        },
        autofocus: {
            required: false,
            default: false,
        },
        numberStep: {
            required: false,
            default: 0.01,
        },
        // numberStep: {
        //     required: false,
        //     default: 1,
        // },
    },
};
</script>

<style lang="css" scoped>
div.vc-time-picker div.vc-select div.vc-select-arrow {
    color: red;
    background-color: blue;
    display: none;
}
</style>
