<template>
    <full-width-container class="bg-r-black-5">
        <content-container>
            <div class="grid grid-cols-2">
                <div class="py-10">image</div>
                <div class="py-10 flex space-y-6 flex-col justify-center">
                    <div
                        class="font-slanted italic font-black text-r-green text-5xl uppercase"
                    >
                        Jetzt roatel buchen
                    </div>
                    <div>
                        Die smarten Container-Hotels bieten Komnfort für einen
                        erholsamen Aufenthalte zum günstigen
                        Preis-Leistungs-Verhältnis
                    </div>
                    <div class="w-1/2">
                        <app-button color="green">Roatel suchen</app-button>
                    </div>
                </div>
            </div>
        </content-container>
    </full-width-container>
</template>

<script>
import FullWidthContainer from "@guest/common/FullWidthContainer";
import ContentContainer from "@guest/common/ContentContainer";
import AppButton from "@guest/common/Button";
import Icon from "@guest/common/Icon.vue";
export default {
    components: {
        FullWidthContainer,
        ContentContainer,
        AppButton,
        Icon,
    },
};
</script>

<style></style>
