<template>
    <div>
        <div
            class="flex flex-row gap-4 justify-between md:justify-start text-r-green-5"
        >
            <div
                @click="$emit('input', val)"
                v-for="val in range"
                :key="val"
                class="text-4xl cursor-pointer"
            >
                <font-awesome-icon
                    icon="star"
                    class="transition-all duration-150"
                    :class="[
                        val <= stars ? 'text-r-gold' : 'text-r-gray opacity-50',
                    ]"
                ></font-awesome-icon>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        stars: {
            default: 0,
            required: true,
        },
        range: {
            default: () => [1, 2, 3, 4, 5],
            required: false,
        },
    },
};
</script>

<style></style>
