<template>
    <div class="w-full">
        <div class="relative">
            <div class="absolute inset-0 h-full w-full">
                <clip-container class="h-full">
                    <div
                        class="bg-r-green text-white w-full h-full lg:p-16 px-5 py-8"
                    ></div>
                </clip-container>
            </div>
            <div class="relative text-white px-7 py-10 lg:pb-20">
                <h2
                    class="font-sans text-4xl italic font-black mb-6 text-center lg:text-left"
                >
                    {{ $t("new_reservation.dates.heading") }}
                </h2>
                <div class="grid md:flex md:flex-row items-center gap-5">
                    <div
                        class="lg:relative lg:flex-grow lg:flex grid gap-5"
                        @blur="showPropertySelector = false"
                        tabindex="0"
                        v-click-outside.stop="
                            () => (showPropertySelector = false)
                        "
                    >
                        <app-input
                            icon="target"
                            icon-click-emit="location"
                            :required="true"
                            name="property"
                            v-model="property_input"
                            input-rounded=""
                            @focus="showPropertySelector = true"
                            ref="property"
                            label-classes="text-white tracking-wide text-sm font-bold"
                            type="text"
                            @location="
                                $router.push({
                                    name: 'bookings.new.search_properties.locationsearch',
                                })
                            "
                            @input="
                                property = null;
                                property_set_by_selection = false;
                            "
                            :errors="errors"
                            :placeholder="
                                $t(
                                    'new_reservation.dates.property_input_placeholder'
                                )
                            "
                            :label="
                                $t('new_reservation.dates.property_input_label')
                            "
                        >
                            <template v-slot:afterInput>
                                <div
                                    class="text-black absolute border border-r-darkgreen bg-white w-3/4 lg:w-full lg:top-20 shadow-lg max-h-60 lg:max-h-80 overflow-y-auto z-20"
                                    v-if="showPropertySelector"
                                >
                                    <div
                                        class="group hover:bg-r-green hover:text-white"
                                        @click="
                                            $router.push({
                                                name: 'bookings.new.search_properties.geosearch',
                                                params: {
                                                    search: property_input,
                                                },
                                            })
                                        "
                                    >
                                        <i18n
                                            path="home.booking.search_around"
                                            tag="div"
                                            class="py-3 px-4 font-bold"
                                            :class="[propertyListClasses]"
                                            v-if="
                                                property_input !== '' &&
                                                property === null
                                            "
                                        >
                                            <span
                                                class="group-hover:text-white text-r-green hover:text-white underline underline-offset-2 font-bold"
                                                >{{ property_input }}</span
                                            >
                                            >
                                        </i18n>
                                    </div>
                                    <div
                                        v-if="
                                            property_set_by_selection ||
                                            property_input === ''
                                        "
                                        class=""
                                        v-for="(
                                            group, index
                                        ) in propertiesGroupedByState"
                                        :key="index"
                                    >
                                        <div
                                            :class="[propertyListClasses]"
                                            class="py-3 px-4 font-bold"
                                        >
                                            {{ index }} -
                                            {{
                                                $t(
                                                    "new_reservation.find_roatel_all"
                                                )
                                            }}
                                        </div>
                                        <div
                                            v-for="property in group"
                                            class="group flex flex-row gap-2 items-center hover:bg-r-green hover:text-white hover:cursor-pointer"
                                            :class="[propertyListClasses]"
                                            @click.stop="
                                                selectProperty(property)
                                            "
                                        >
                                            <div>
                                                <icon
                                                    icon="roatel"
                                                    padding=""
                                                    :is-rounded="false"
                                                    size="w-5"
                                                    background="bg-transparent"
                                                    class="text-r-green group-hover:text-white stroke-transparent"
                                                ></icon>
                                            </div>
                                            <div class="text-lg leading-4">
                                                {{ property.name }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </app-input>
                    </div>
                    <div>
                        <app-input
                            :required="true"
                            name="checkIn"
                            v-model="checkIn"
                            label-classes="text-white tracking-wide text-sm font-bold"
                            input-rounded=""
                            ref="dp"
                            type="datepicker"
                            :disabled="property === null"
                            :order="50"
                            :errors="errors"
                            :locale="currentLocale.key"
                            :datepicker-min-date="
                                dayjs().startOf('day').add(0, 'day').toDate()
                            "
                            :placeholder="
                                $t('new_reservation.dates.check_in_placeholder')
                            "
                            :label="
                                $t(
                                    'new_reservation.dates.check_in_label_with_time'
                                )
                            "
                        >
                        </app-input>
                    </div>
                    <div>
                        <app-input
                            type="datepicker"
                            v-model="checkOut"
                            label-classes="text-white tracking-wide text-sm font-bold"
                            :required="true"
                            input-rounded=""
                            :locale="currentLocale.key"
                            :disabled="property === null"
                            name="checkOut"
                            :errors="errors"
                            :datepicker-min-date="
                                dayjs(checkIn)
                                    .startOf('day')
                                    .add(1, 'day')
                                    .toDate()
                            "
                            :placeholder="
                                $t(
                                    'new_reservation.dates.check_out_placeholder'
                                )
                            "
                            :label="
                                $t(
                                    'new_reservation.dates.check_out_label_with_time'
                                )
                            "
                        >
                        </app-input>
                    </div>
                    <div class="self-end mb-0.5">
                        <app-button
                            color="darkgreen"
                            @click="
                                $router.push({
                                    name: 'bookings.new.dates',
                                    params: {
                                        property: property.apaleo_id,
                                        checkin: checkIn,
                                        checkout: checkOut,
                                    },
                                })
                            "
                            button-rounded=""
                        >
                            {{ $t("new_reservation.dates.button_search") }}
                        </app-button>
                    </div>
                </div>
                <div class="mt-2">
                    <router-link
                        class="inline-flex gap-2 items-center"
                        :to="{
                            name: 'bookings.new.search_properties',
                            params: { show: 'map' },
                        }"
                    >
                        <icon icon="map" class="stroke-white w-6 h-6"></icon>
                        <div>
                            {{ $t("new_reservation.show_map") }}
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
import ClipContainer from "./common/ClipContainer.vue";
import AppInput from "@guest/common/Input.vue";
import AppButton from "@guest/common/Button.vue";
import Icon from "@guest/common/Icon.vue";
import _ from "lodash";
import * as vClickOutside from "v-click-outside-x";
export default {
    directives: {
        clickOutside: vClickOutside.directive,
    },
    components: {
        ClipContainer,
        AppInput,
        AppButton,
        Icon,
    },
    data: function () {
        return {
            nights: 1,
            checkIn: this.dayjs().startOf("day").toDate(),
            checkOut: "",
            errors: {},
            address: "",
            property: null,
            property_input: "",
            property_set_by_selection: false,
            showPropertySelector: false,
            showCheckOut: true,
            propertyListClasses: "py-3 px-5 ",
        };
    },
    watch: {
        checkIn: {
            handler: function (val) {
                console.log("change checkIn Date", val);
                //this.dates.checkIn = this.dayjs(val).hour(14).toDate()
                this.localStep = 1;
                if (this.showCheckOut === true) {
                    console.log("Current Checkout Date", this.checkOut);
                    if (
                        this.dayjs(val).isSameOrAfter(this.dayjs(this.checkOut))
                    ) {
                        this.checkOut = this.dayjs(val)
                            .add(1, "day")
                            .hour(12)
                            .toDate();
                        console.log("have to change checkout date");
                    }
                }
                this.setCheckOut();
            },
        },
        code: {
            handler: function (val) {
                this.localStep = 1;
            },
        },
        checkOut: {
            handler: function (val) {
                this.localStep = 1;
                console.log("change checkOut Date", val);
                if (this.showCheckOut === true) {
                    if (
                        this.dayjs(val).isSameOrBefore(this.dayjs(this.checkIn))
                    ) {
                        this.checkIn = this.dayjs(val)
                            .subtract(1, "day")
                            .hour(14)
                            .toDate();
                        console.log("have to change checkin date");
                    }
                }
                console.log("current value of checkout", this.checkOut);
                this.setCheckOut();
            },
        },
        nights: {
            handler: function () {
                this.setCheckOut();
            },
            immediate: true,
        },
    },
    computed: {
        ...mapGetters({
            currentLocale: "currentLocale",
            isLoggedIn: "isLoggedIn",
        }),
        ...mapState({
            properties: (state) => state.search.properties,
            location: (state) => state.search.location,
        }),
        propertiesGroupedByState() {
            let properties = this.properties.map((property) => {
                return {
                    id: property.id,
                    uuid: property.uuid,
                    name: property.name,
                    apaleo_id: property.apaleo_id,
                    postal_code: property.postal_code,
                    city: property.city,
                    state: property.state,
                };
            });
            _.sortBy(properties, ["state", "name"]);
            return _.groupBy(properties, "state");
        },
    },
    methods: {
        selectProperty(property) {
            this.property = property;
            this.property_input = property.name;
            this.showPropertySelector = false;
            this.property_set_by_selection = true;
        },
        ...mapActions({
            setLoading: "setLoading",
            setBookingDraftProperty: "setBookingDraftProperty",
            setSearchResult: "setSearchResult",
            resetSearchResult: "resetSearchResult",
            resetBookingDraft: "resetBookingDraft",
        }),
        showCheckOutDatepicker() {
            this.showCheckOut = !this.showCheckOut;
        },
        setCheckOut() {
            console.log("setCheckOut()", this.showCheckOut);
            if (this.checkOut == "") {
                this.checkOut = this.dayjs(this.checkIn)
                    .add(this.nights, "day")
                    .format();
                return;
            }
            let checkIn = this.dayjs(this.checkIn);
            let checkOut = this.dayjs(this.checkOut);
            this.nights = checkOut.diff(checkIn, "days");
            console.log("Datediff", checkOut.diff(checkIn, "days"));
        },
        async getPropertiesByAddress() {
            this.setSearchResult({ data: [], location: null });
            this.hasGeolocation = false;
            this.setLoading(true);
            await axios
                .get("bookings/get_properties_by_address/" + this.address)
                .then((response) => {
                    this.setSearchResult(response.data);
                    //this.$emit('properties-list')
                })
                .catch((error) => {
                    console.log(error);
                })
                .then(() => {
                    this.setLoading(false);
                });
        },
    },
    async mounted() {
        this.resetBookingDraft();
        await this.getPropertiesByAddress();
    },
};
</script>
