export default {
    data: function () {
        return {
            sideNavigationItems: [
                {
                    order: 10,
                    block: 1,
                    label: "navigation.sideNavigation.book",
                    route: { name: "bookings" },
                    // href: 'https://www.roatel.com/'
                },
                {
                    order: 20,
                    block: 1,
                    label: "navigation.sideNavigation.partner",
                    href: "https://roatel.com/partner",
                },
                {
                    order: 30,
                    block: 1,
                    label: "navigation.sideNavigation.news",
                    href: "https://roatel.com/news",
                },
                {
                    order: 40,
                    block: 1,
                    label: "navigation.sideNavigation.about_us",
                    href: "https://roatel.com/ueber_uns",
                },

                {
                    order: 10,
                    block: 2,
                    label: "navigation.sideNavigation.about",
                    href: "https://roatel.com/about",
                },
                {
                    order: 20,
                    block: 2,
                    label: "navigation.sideNavigation.contact",
                    href: "https://roatel.com/kontakt",
                },
                {
                    order: 30,
                    block: 2,
                    label: "navigation.sideNavigation.faq",
                    href: "https://roatel.com/faq-service",
                },
                {
                    order: 10,
                    block: 3,
                    label: "navigation.sideNavigation.sitemap",
                    href: "https://roatel.com/abount#sitemap",
                },
                {
                    order: 20,
                    block: 3,
                    label: "navigation.sideNavigation.imprint",
                    href: "https://roatel.com/impressum-booking",
                },
                {
                    order: 30,
                    block: 3,
                    label: "navigation.sideNavigation.privacy",
                    href: "https://roatel.com/datenschutz-booking",
                },
            ],
        };
    },
    methods: {
        sortSideNavigationItems(array) {
            return _.sortBy(array, ["order"]);
        },
    },
    computed: {
        sideNavigationItemsGrouped() {
            return _.groupBy(this.sideNavigationItems, (items) => items.block);
        },
    },
};
