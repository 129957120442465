<template>
    <div>
        <button
            :class="classes"
            @click.stop="click"
            :type="type"
            v-if="isRouterLink === false"
        >
            <font-awesome-icon
                v-if="iconType !== null"
                :icon="iconType"
            ></font-awesome-icon>
            <span>
                <slot>ButtonLabel missing</slot>
            </span>
        </button>
        <router-link :to="routerLinkTo" :class="classes" v-else>
            <font-awesome-icon
                v-if="iconType !== null"
                :icon="iconType"
            ></font-awesome-icon>
            <span>
                <slot>ButtonLabel missing</slot>
            </span>
        </router-link>
    </div>
</template>

<script>
export default {
    methods: {
        click() {
            if (!this.disabled) {
                this.$emit("click");
            }
        },
    },
    computed: {
        iconType() {
            if (this.icon === null) {
                return this.icon;
            }
            let mappings = {
                new: "plus",
                edit: "pen",
                delete: "trash",
                "user-add": "user-plus",
                save: "floppy-disk",
                cancel: "xmark",
                back: "arrow-left",
                payment: "credit-card",
                danger: "skull-crossbones",
                bed: "bed",
                "lock-open": "lock-open",
                lock: "lock",
                history: "history",
                compass: "compass",
                "search-location": "search-location",
                search: "search",
                passport: "passport",
                home: "home",
                redo: "rotate-right",
                key: "key",
                check: "check",
                invoice: "file-invoice",
                filter: "filter",
                mail: "paper-plane",
                clipboard: "clipboard",
                bars: "bars",
                ban: "ban",
                phone: "phone",
                "clipboard-check": "clipboard-check",
                up: "chevron-up",
                down: "chevron-down",
                info: "info-circle",
            };
            return mappings[this.icon];
        },
        classes() {
            let classes = [];
            classes.push("shadow");
            classes.push("items-center");
            //classes.push('uppercase')
            classes.push("font-medium tracking-wider");
            classes.push("duration-200 transition-all");
            classes.push(
                "focus:outline-none focus:ring focus:border-indigo-600"
            );
            classes.push("whitespace-nowrap");

            if (this.disabled) {
                classes.push("opacity-50");
                classes.push("cursor-not-allowed");
            }
            if (this.outline === true) {
                classes.push("bg-" + this.color + "-200");
                classes.push("hover:bg-" + this.color + "-600");
                classes.push("hover:text-white");
                classes.push("border");
                classes.push("border-" + this.color + "-600");
                classes.push("text-" + this.color + "-600");
            }
            if (this.outline === false) {
                classes.push("bg-" + this.color + "-500");
                classes.push("hover:bg-" + this.color + "-600");
                classes.push("hover:text-white");
                classes.push("border");
                classes.push("border-transparent");
                classes.push("text-" + this.color + "-100");
            }

            if (this.color == "transparent") {
                let classes = [];
                classes.push("text-gray-600");
            }

            //List all colors for tailwindcss jit compiler
            let temp = [
                "bg-transparent border-transparent",
                "text-white",
                "hover:bg-green-700 hover:bg-gray-700 hover:bg-red-700 hover:bg-green-700 hover:bg-blue-700 hover:bg-purple-700",
                "hover:bg-green-600 hover:bg-gray-600 hover:bg-red-600 hover:bg-green-600 hover:bg-blue-600 hover:bg-purple-600",
                "bg-gray-200 bg-red-200 bg-green-200 bg-blue-200 bg-purple-200",
                "bg-gray-500 bg-red-500 bg-green-500 bg-blue-500 bg-purple-500",
                "text-gray-600 text-red-600 text-green-600 text-blue-600 text-purple-600",
                "border-gray-600 border-red-600 border-green-600 border-blue-600 border-purple-600",
                "text-gray-100 text-red-100 text-green-100 text-blue-100 text-purple-100",
                "py-0 py-1 py-2 py-3 py-4 py-5 py-6",
                "px-0 px-1 px-2 px-3 px-4 px-5 px-6",
                "text-base text-sm text-xs text-xl",
                "flex flex-row space-x-3 items-center",
            ];

            //Font sizes
            classes.push("text-" + this.size);
            classes.push("rounded");
            let padding_y = 1;
            let padding_x = 4;
            if (this.size == "xs") {
                padding_y = 0.5;
                padding_x = 2;
            }
            if (this.size == "sm") {
                padding_y = 0.5;
                padding_x = 3;
            }
            if (this.size == "lg") {
                padding_y = 1;
                padding_x = 4;
            }
            if (this.size == "xl") {
                padding_y = 1;
                padding_x = 6;
            }
            classes.push("py-" + padding_y);
            classes.push("px-" + padding_x);
            if (this.iconType !== null) {
                classes.push("flex flex-row space-x-2 items-center");
            }
            return classes;
        },
    },
    props: {
        size: {
            required: false,
            default: "base",
        },
        color: {
            required: false,
            default: "gray",
        },
        outline: {
            required: false,
            default: true,
        },
        icon: {
            required: false,
            default: null,
        },
        disabled: {
            required: false,
            default: false,
        },
        icon: {
            required: false,
            default: null,
        },
        type: {
            default: "button",
        },
        isRouterLink: {
            default: false,
        },
        routerLinkTo: {
            default: () => {},
        },
    },
};
</script>

<style></style>
