<template>
    <div>
        <full-width-container class="bg-r-gray-ultralight">
            <title-bar>
                {{ $t("login_bar.register") }}
            </title-bar>
        </full-width-container>
        <full-width-container class="bg-r-gray-ultralight">
            <content-container
                class="flex justify-center px-5 pb-5"
                :default-padding="false"
            >
                <div
                    class="w-full md:w-[595px] bg-white p-5 lg:p-16 rounded-lg border border-r-gray-medium grid gap-12"
                >
                    <div class="grid gap-4">
                        <h2
                            class="font-sans font-black italic text-r-green text-2xl"
                        >
                            {{ $t("register.heading") }}
                        </h2>
                        <p>
                            {{ $t("register.body") }}
                        </p>
                    </div>
                    <div class="grid gap-6 grid-cols-1">
                        <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
                            <app-input
                                v-model="user.first_name"
                                :required="true"
                                :errors="errors"
                                :placeholder="
                                    $t('models.account.first_name_placeholder')
                                "
                                :label="$t('models.account.first_name')"
                                name="first_name"
                            ></app-input>
                            <app-input
                                v-model="user.last_name"
                                :required="true"
                                :errors="errors"
                                :label="$t('models.account.last_name')"
                                :placeholder="
                                    $t('models.account.last_name_placeholder')
                                "
                                name="last_name"
                            ></app-input>
                        </div>
                        <app-input
                            v-model="user.email"
                            :required="true"
                            :errors="errors"
                            :label="$t('models.account.email')"
                            :placeholder="
                                $t('models.account.email_placeholder')
                            "
                            name="email"
                        ></app-input>
                        <div class="grid gap-3">
                            <app-input
                                v-model="user.password"
                                type="password"
                                :required="false"
                                :errors="errors"
                                :label="$t('models.account.password')"
                                :placeholder="
                                    $t('models.account.password_placeholder')
                                "
                                name="password"
                            ></app-input>
                            <app-input
                                v-model="user.password_repeat"
                                :has-label="false"
                                type="password"
                                :required="false"
                                :errors="errors"
                                :label="$t('models.account.password_repeat')"
                                :placeholder="
                                    $t(
                                        'models.account.password_repeat_placeholder'
                                    )
                                "
                                name="password_repeat"
                            ></app-input>
                        </div>
                        <div class="grid gap-4">
                            <div>
                                <div class="flex flex-row items-start gap-2">
                                    <div class="w-8">
                                        <app-input
                                            name="accepted_privacy"
                                            type="checkbox"
                                            :hasLabel="false"
                                            v-model="user.accepted_privacy"
                                        ></app-input>
                                    </div>
                                    <div
                                        class="grid gap-1 w-full"
                                        @click="
                                            user.accepted_privacy =
                                                !user.accepted_privacy
                                        "
                                    >
                                        <div
                                            class="text-r-green font-bold text-sm"
                                        >
                                            {{
                                                $t(
                                                    "navigation.sideNavigation.privacy"
                                                )
                                            }}
                                        </div>
                                        <i18n
                                            path="new_reservation.summary.privacy_accept"
                                            tag="label"
                                            class="text-sm"
                                        >
                                            <a
                                                href="https://www.roatel.com/datenschutz-booking"
                                                class="underline underline-offset-1"
                                                target="_blank"
                                                @click.stop="null"
                                                >{{
                                                    $t(
                                                        "new_reservation.summary.privacy"
                                                    )
                                                }}</a
                                            >
                                        </i18n>
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-row items-start gap-2">
                                <div class="w-8">
                                    <app-input
                                        name="marketing_signup"
                                        type="checkbox"
                                        :has-label="false"
                                        v-model="user.marketing_signup"
                                    ></app-input>
                                </div>
                                <div
                                    @click="
                                        user.marketing_signup =
                                            !user.marketing_signup
                                    "
                                    class="w-full grid gap-1"
                                >
                                    <div class="text-r-green font-bold text-sm">
                                        {{ $t("register.marketing_signup") }}
                                    </div>
                                    <i18n
                                        path="new_reservation.summary.marketing_signup"
                                        tag="label"
                                        class="text-sm"
                                    >
                                        <a
                                            href="https://www.roatel.com/datenschutz-booking"
                                            class="underline underline-offset-1"
                                            target="_blank"
                                            @click.stop="null"
                                            >{{
                                                $t(
                                                    "new_reservation.summary.privacy"
                                                )
                                            }}</a
                                        >
                                    </i18n>
                                </div>
                            </div>
                        </div>
                        <div class="col-span-full">
                            <alert v-if="error !== null">
                                {{ error }}
                            </alert>
                        </div>
                        <div class="flex justify-center">
                            <div>
                                <app-button
                                    @click="submit"
                                    color="green"
                                    size="xl"
                                    :disabled="!user.accepted_privacy"
                                >
                                    {{ $t("register.button_register") }}
                                </app-button>
                            </div>
                        </div>
                    </div>
                </div>
            </content-container>
        </full-width-container>
        <full-width-container class="bg-r-gray-ultralight pb-24">
            <content-container padding="p-0">
                <div class="flex justify-center">
                    <div class="gap-2 text-sm">
                        <router-link
                            :to="{ name: 'login' }"
                            class="inline-flex items-center gap-5"
                        >
                            <span>
                                {{ $t("register.got_account") }}
                            </span>
                            <icon
                                class="w-6 h-6 stroke-r-black"
                                icon="chevron-right"
                            ></icon>
                        </router-link>
                    </div>
                </div>
            </content-container>
        </full-width-container>
        <!-- <div -->
        <!--     class="w-full md:w-2/3 flex flex-col space-y-7 pt-8 md:pt-0 md:pl-10" -->
        <!-- > -->
        <!--     <h2 -->
        <!--         class="font-slanted text-2xl md:text-4xl italic font-black uppercase" -->
        <!--     > -->
        <!--         {{ $t("register.heading") }} -->
        <!--     </h2> -->
        <!--     <div> -->
        <!--         <div class="font-bold uppercase"> -->
        <!--             {{ $t("register.lead") }} -->
        <!--         </div> -->
        <!--         <div> -->
        <!--             {{ $t("register.body") }} -->
        <!--         </div> -->
        <!--     </div> -->
        <!--     <div class="w-full grid grid-cols-1 md:grid-cols-2 gap-4"> -->
        <!--         <div> -->
        <!--             <app-input -->
        <!--                 v-model="user.first_name" -->
        <!--                 :required="true" -->
        <!--                 :errors="errors" -->
        <!--                 :placeholder=" -->
        <!--                     $t('models.account.first_name_placeholder') -->
        <!--                 " -->
        <!--                 :label="$t('models.account.first_name')" -->
        <!--                 name="first_name" -->
        <!--             ></app-input> -->
        <!--         </div> -->
        <!--         <div> -->
        <!--             <app-input -->
        <!--                 v-model="user.last_name" -->
        <!--                 :required="true" -->
        <!--                 :errors="errors" -->
        <!--                 :label="$t('models.account.last_name')" -->
        <!--                 :placeholder=" -->
        <!--                     $t('models.account.last_name_placeholder') -->
        <!--                 " -->
        <!--                 name="last_name" -->
        <!--             ></app-input> -->
        <!--         </div> -->
        <!--         <div class="col-end-2"> -->
        <!--             <app-input -->
        <!--                 v-model="user.email" -->
        <!--                 :required="true" -->
        <!--                 :errors="errors" -->
        <!--                 :label="$t('models.account.email')" -->
        <!--                 :placeholder="$t('models.account.email_placeholder')" -->
        <!--                 name="email" -->
        <!--             ></app-input> -->
        <!--         </div> -->
        <!--         <div class="md:col-start-1"> -->
        <!--             <app-input -->
        <!--                 v-model="user.password" -->
        <!--                 type="password" -->
        <!--                 :required="false" -->
        <!--                 :errors="errors" -->
        <!--                 :label="$t('models.account.password')" -->
        <!--                 :placeholder="$t('models.account.password_placeholder')" -->
        <!--                 name="password" -->
        <!--             ></app-input> -->
        <!--         </div> -->
        <!--         <div> -->
        <!--             <app-input -->
        <!--                 v-model="user.password_repeat" -->
        <!--                 type="password" -->
        <!--                 :required="false" -->
        <!--                 :errors="errors" -->
        <!--                 :label="$t('models.account.password_repeat')" -->
        <!--                 :placeholder=" -->
        <!--                     $t('models.account.password_repeat_placeholder') -->
        <!--                 " -->
        <!--                 name="password_repeat" -->
        <!--             ></app-input> -->
        <!--         </div> -->
        <!--         <div class="md:col-span-2 grid grid-cols-2 gap-5"> -->
        <!--             <app-button @click="submit" color="green" size="xl"> -->
        <!--                 {{ $t("register.button_register") }} -->
        <!--             </app-button> -->
        <!--             <div> -->
        <!--                 <required-field-info></required-field-info> -->
        <!--             </div> -->
        <!--         </div> -->
        <!--         <div class="md:col-span-2"> -->
        <!--             <alert v-if="error !== null"> -->
        <!--                 {{ error }} -->
        <!--             </alert> -->
        <!--         </div> -->
        <!--     </div> -->
        <!-- </div> -->
    </div>
</template>

<script>
import AppInput from "@guest/common/Input";
import AppButton from "@guest/common/Button";
import Alert from "@guest/common/Alert";
import { mapActions, mapGetters } from "vuex";
import RequiredFieldInfo from "@guest/common/RequiredFieldInfo";
import FullWidthContainer from "@guest/common/FullWidthContainer";
import ContentContainer from "@guest/common/ContentContainer";
import Icon from "@guest/common/Icon";
import TitleBar from "@guest/common/TitleBar";

export default {
    components: {
        AppInput,
        AppButton,
        Alert,
        RequiredFieldInfo,
        FullWidthContainer,
        ContentContainer,
        Icon,
        TitleBar,
    },
    data: function () {
        return {
            errors: {},
            user: {
                first_name: "",
                last_name: "",
                email: "",
                phone: "",
                password: "",
                password_repeat: "",
                marketing_signup: false,
                accepted_privacy: false,
            },
            error: null,
        };
    },
    methods: {
        ...mapActions({
            setLoading: "setLoading",
            getProfile: "getProfile",
        }),
        async submit() {
            this.setLoading(true);
            this.errors = {};
            this.error = null;

            await axios
                .post("register", {
                    ...this.user,
                    preferred_locale: this.currentLocale.key,
                })
                .then((response) => {
                    console.log(response);
                    this.$router.push({ name: "register.success" });
                    this.getProfile();
                })
                .catch((error) => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                        this.error = error.response.data.message;
                        return;
                    }
                    console.log("Anderer fehler", error.response);
                    this.error = error.response.status;
                })
                .then(() => {
                    this.setLoading(false);
                });
        },
        async getFakeUser() {
            await axios.get("faker/user").then((response) => {
                let user = response.data;
                this.user.first_name = user.firstName;
                this.user.last_name = user.lastName;
                this.user.email = user.email;
                this.user.phone = user.phone;
            });
        },
    },
    computed: {
        ...mapGetters({
            currentLocale: "currentLocale",
            isLoggedIn: "isLoggedIn",
        }),
    },
    mounted() {
        if (this.isLoggedIn) {
            this.$router.push({ name: "profile" });
        }
    },
};
</script>

<style></style>
