<template>
    <clip-container class="md:cutter-big">
        <div class="bg-r-green px-5 py-8 md:p-16">
            <div class="flex flex-col space-y-6 text-white mb-8 md:mb-16">
                <h2
                    class="font-slanted text-2xl md:text-4xl italic pb-2 md:pb-4 font-black uppercase flex-grow text-white"
                >
                    {{ $t("profile.nav.guest_data") }}
                </h2>
            </div>
            <div
                class="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-10 mb-8 text-white"
            >
                <div class="md:col-span-2">
                    <div class="font-bold uppercase">
                        {{ $t("profile.lead_heading") }}
                    </div>
                    <div>
                        {{ $t("profile.guest_data.lead") }}
                    </div>
                </div>
                <div class="hidden md:flex md:flex-col">
                    <div class="font-bold uppercase">
                        {{ $t("profile.guest_data.identification") }}
                    </div>
                    <div>
                        {{ $t("profile.guest_data.identification_lead") }}
                    </div>
                </div>
                <div class="flex flex-col space-y-6">
                    <app-input
                        name="first_name"
                        v-model="data.first_name"
                        :required="true"
                        :label="$t('models.guest.first_name')"
                        type="text"
                        :errors="errors"
                        :loading="loading"
                        :initialized="initialized"
                    >
                    </app-input>
                    <app-input
                        name="last_name"
                        v-model="data.last_name"
                        :label="$t('models.guest.last_name')"
                        type="text"
                        :required="true"
                        :errors="errors"
                        :loading="loading"
                        :initialized="initialized"
                    >
                    </app-input>
                    <app-input
                        name="company_name"
                        v-model="data.company_name"
                        :label="$t('models.guest.company_name')"
                        type="text"
                        :errors="errors"
                        :loading="loading"
                        :initialized="initialized"
                    >
                    </app-input>
                    <app-input
                        name="email"
                        v-model="data.email"
                        :label="$t('models.guest.email')"
                        type="text"
                        :errors="errors"
                        :loading="loading"
                        :initialized="initialized"
                    >
                    </app-input>
                    <app-input
                        name="phone"
                        v-model="data.phone"
                        :label="$t('models.guest.phone')"
                        type="text"
                        :errors="errors"
                        :loading="loading"
                        :initialized="initialized"
                    >
                    </app-input>
                </div>
                <div class="flex flex-col space-y-6">
                    <app-input
                        name="address_line1"
                        v-model="data.address_line1"
                        :label="$t('models.guest.address_line1')"
                        type="text"
                        :errors="errors"
                        :loading="loading"
                        :initialized="initialized"
                    >
                    </app-input>
                    <app-input
                        name="postal_code"
                        v-model="data.postal_code"
                        :label="$t('models.guest.postal_code')"
                        type="text"
                        :errors="errors"
                        :loading="loading"
                        :initialized="initialized"
                    >
                    </app-input>
                    <app-input
                        name="city"
                        v-model="data.city"
                        :label="$t('models.guest.city')"
                        type="text"
                        :errors="errors"
                        :loading="loading"
                        :initialized="initialized"
                    >
                    </app-input>
                    <app-input
                        name="country_code"
                        v-model="data.country_code"
                        :label="$t('models.guest.country_code')"
                        type="selectClassic"
                        :selectNullable="true"
                        :selectOptions="countries"
                        selectLabelProperty="name"
                        selectValueProperty="alpha-2"
                        :errors="errors"
                    >
                    </app-input>
                </div>
                <div class="flex flex-col space-y-6">
                    <!-- <div class="md:hidden"> -->
                    <!--     <div class="font-bold uppercase"> -->
                    <!--         {{ $t("profile.guest_data.identification") }} -->
                    <!--     </div> -->
                    <!--     <div> -->
                    <!--         {{ $t("profile.guest_data.identification_lead") }} -->
                    <!--     </div> -->
                    <!-- </div> -->
                    <app-input
                        name="nationality_country_code"
                        :label="$t('models.guest.nationality_country_code')"
                        type="selectClassic"
                        v-model="data.nationality_country_code"
                        :selectOptions="countries"
                        selectLabelProperty="name"
                        :selectNullable="true"
                        selectValueProperty="alpha-2"
                        :errors="errors"
                        :loading="loading"
                        :initialized="initialized"
                    >
                    </app-input>
                    <app-input
                        name="birth_date"
                        v-model="data.birth_date"
                        :label="$t('models.guest.birth_date')"
                        type="datepicker"
                        :locale="currentLocale.key"
                        :errors="errors"
                        :loading="loading"
                        :initialized="initialized"
                    >
                    </app-input>
                    <app-input
                        name="identification_number"
                        v-model="data.identification_number"
                        :label="$t('models.guest.identification_number')"
                        type="text"
                        :errors="errors"
                        :loading="loading"
                        :initialized="initialized"
                    >
                    </app-input>
                </div>
            </div>
            <div class="flex flex-row justify-between">
                <div class="flex justify-end col-span-1 md:col-span-2">
                    <app-button @click="$emit('save', data)">
                        {{ $t("buttons.save") }}
                    </app-button>
                </div>
                <div>
                    <required-field-info
                        class="text-white"
                    ></required-field-info>
                </div>
            </div>
        </div>
    </clip-container>
</template>

<script>
import AppInput from "@guest/common/Input";
import AppButton from "@guest/common/Button";
import DayJS from "@mixins/DayJS";
import Countries from "@mixins/Countries";
import Titles from "@mixins/Titles";
import Genders from "@mixins/Genders";
import IdentificationTypes from "@mixins/IdentificationTypes";
import PreferredLanguages from "@mixins/PreferredLanguages";
import { mapGetters } from "vuex";
import ClipContainer from "@guest/common/ClipContainer.vue";
import RequiredFieldInfo from "@guest/common/RequiredFieldInfo.vue";

export default {
    mixins: [
        DayJS,
        Countries,
        Titles,
        Genders,
        PreferredLanguages,
        IdentificationTypes,
    ],
    data: function () {
        return {
            data: {
                first_name: "",
                last_name: "",
                middle_initial: "",
                company_name: "",
                email: "",
                phone: "",
                address_line1: "",
                address_line2: "",
                postal_code: "",
                city: "",
                country_code: "DE",

                nationality_country_code: "DE",
                identification_number: "",
                identification_issue_date: "",
                identification_type: "",
                preferred_language: "de",
                birth_date: "",
                birth_place: "",
            },
        };
    },
    computed: {
        ...mapGetters({
            currentLocale: "currentLocale",
        }),
    },
    components: {
        AppButton,
        RequiredFieldInfo,
        ClipContainer,
        AppInput,
    },
    props: {
        person: {
            required: true,
            type: Object,
        },
        initialized: {
            required: false,
            default: true,
        },
        errors: {
            required: false,
            default: () => {},
        },
        loading: {
            required: false,
            default: false,
        },
        showButtons: {
            required: false,
            default: true,
        },
    },
    mounted() {
        this.data = this.person;
        console.log("mounting PersonEdit", this.person, this.data);
        /*if (this.person) {
            this.data = this.$set(this.data, 'data', this.person)
        }
        */
    },
};
</script>

<style></style>
