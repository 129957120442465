<template>
    <div
        class="bg-white rounded-lg border border-r-gray-light p-5 lg:p-10"
        id="precheckin_container"
        v-if="this.reservation.hasOwnProperty('primary_guest')"
    >
        <div class="grid gap-6">
            <div class="lg:flex grid justify-between items-start gap-5">
                <div class="grid gap-4">
                    <h2
                        class="text-r-green text-2xl italic font-black text-center lg:text-left"
                    >
                        {{ $t("reservations.checkin.heading_precheckin_data") }}
                    </h2>
                    <div class="text-center lg:text-left">
                        {{ $t("reservations.checkin.lead_precheckin") }}
                    </div>
                </div>
                <div
                    class="lg:col-start-1 lg:col-end-1 flex lg:justify-end"
                    v-if="isLoggedIn"
                >
                    <app-button @click="setDataFromProfile" color="darkgreen">
                        {{
                            $t("reservations.checkin.button_fill_profile_data")
                        }}
                    </app-button>
                </div>
            </div>
            <div class="grid md:grid-cols-2 gap-5">
                <app-input
                    name="first_name"
                    v-model="guest.first_name"
                    :label="$t('models.guest.first_name')"
                    :placeholder="$t('models.guest.first_name')"
                    :required="true"
                    type="text"
                    :errors="errors"
                    :loading="loading"
                >
                </app-input>
                <app-input
                    name="last_name"
                    v-model="guest.last_name"
                    :label="$t('models.guest.last_name')"
                    :placeholder="$t('models.guest.last_name')"
                    :required="true"
                    type="text"
                    :errors="errors"
                    :loading="loading"
                >
                </app-input>
                <app-input
                    name="email"
                    v-model="guest.email"
                    :label="$t('models.guest.email')"
                    :placeholder="$t('models.guest.email')"
                    :required="true"
                    type="text"
                    :errors="errors"
                    :loading="loading"
                >
                </app-input>
                <app-input
                    name="phone"
                    v-model="guest.phone"
                    :label="$t('models.guest.phone')"
                    :placeholder="$t('models.guest.phone')"
                    type="phone"
                    :errors="errors"
                    :required="true"
                    :loading="loading"
                >
                </app-input>
                <div class="py-2 col-span-full">
                    <hr class="border-r-gray-light" />
                </div>
                <div
                    class="flex flex-col lg:flex-row items-center col-span-full gap-5"
                >
                    <app-input
                        class="flex-grow"
                        name="address_line1"
                        v-model="guest.address_line1"
                        :label="$t('models.guest.address_line1')"
                        :placeholder="$t('models.guest.address_line1')"
                        :required="true"
                        type="text"
                        :errors="errors"
                        :loading="loading"
                    >
                    </app-input>
                    <app-input
                        class="w-full lg:w-1/3"
                        name="postal_code"
                        v-model="guest.postal_code"
                        :label="$t('models.guest.postal_code')"
                        :placeholder="$t('models.guest.postal_code')"
                        type="text"
                        :required="true"
                        :errors="errors"
                        :loading="loading"
                    >
                    </app-input>
                </div>
                <app-input
                    name="city"
                    v-model="guest.city"
                    :label="$t('models.guest.city')"
                    :placeholder="$t('models.guest.city')"
                    :required="true"
                    type="text"
                    :errors="errors"
                    :loading="loading"
                >
                </app-input>
                <app-input
                    name="country_code"
                    v-model="guest.country_code"
                    :label="$t('models.guest.country_code')"
                    :placeholder="$t('models.guest.country_code')"
                    selectLabelProperty="name"
                    selectValueProperty="alpha-2"
                    :selectOptions="countries"
                    type="selectClassic"
                    :errors="errors"
                    :loading="loading"
                >
                </app-input>
                <app-input
                    class="self-start"
                    type="checkbox"
                    :stacked="false"
                    :stackedReversed="true"
                    labelClasses=""
                    size="lg"
                    :label="$t('models.guest.store_changes_in_profile')"
                    name="updateProfile"
                    v-if="isLoggedIn"
                    v-model="guest.updateProfile"
                >
                </app-input>
                <app-input
                    type="checkbox"
                    :label="$t('models.guest.create_account')"
                    :stacked="false"
                    :stackedReversed="true"
                    :labelUppercase="true"
                    size="lg"
                    name="create_account"
                    v-if="!isLoggedIn"
                    v-model="guest.createAccount"
                >
                </app-input>
                <div class="py-2 col-span-full col-start-1">
                    <hr class="border-r-gray-light" />
                </div>
                <app-input
                    class="self-start lg:mt-8"
                    :required="true"
                    name="nationality_country_code"
                    :label="$t('models.guest.nationality_country_code')"
                    type="selectClassic"
                    v-model="guest.nationality_country_code"
                    :selectOptions="countries"
                    selectLabelProperty="name"
                    selectValueProperty="alpha-2"
                    :errors="errors"
                    :loading="loading"
                >
                </app-input>
                <birthdate-vue
                    class="self-start"
                    :required="true"
                    name="birth_date"
                    :birthdate="guest.birth_date"
                    @date="guest.birth_date = $event"
                    :label="$t('models.guest.birth_date')"
                    :errors="errors"
                    :locale="currentLocale.key"
                    :loading="loading"
                >
                </birthdate-vue>
                <app-input
                    v-if="guest.nationality_country_code !== 'DE'"
                    name="identification_number"
                    v-model="guest.identification_number"
                    :required="true"
                    :label="$t('models.guest.identification_number')"
                    type="text"
                    :errors="errors"
                    :loading="loading"
                >
                </app-input>
                <div
                    v-if="guest.nationality_country_code !== 'DE'"
                    class="text-sm text-white"
                >
                    {{ $t("reservations.checkin.foreign_citizen_id_text") }}
                </div>
            </div>

            <div v-if="error">
                <alert>{{ $t("common.form_error") }}</alert>
            </div>
            <div class="flex justify-center">
                <div>
                    <app-button
                        @click="submit"
                        color="green"
                        :outline="false"
                        size="xl"
                    >
                        {{ $t("buttons.save") }}
                    </app-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import AppInput from "@guest/common/Input";
import AppButton from "@guest/common/Button";
import ReservationQueryPayload from "@mixins/ReservationQueryPayload";
import Countries from "@mixins/Countries";
import RequiredFieldInfo from "@guest/common/RequiredFieldInfo";
import Alert from "@guest/common/Alert.vue";
import _ from "lodash";
import BirthdateVue from "@guest/common/Birthdate.vue";

export default {
    mixins: [ReservationQueryPayload, Countries],
    components: {
        AppInput,
        AppButton,
        RequiredFieldInfo,
        Alert,
        BirthdateVue,
    },
    data: function () {
        return {
            errors: {},
            error: false,
            storeChangesToProfile: false,
            has_marketing_signup: false,
            guest: {
                first_name: "",
                middle_initial: "",
                last_name: "",
                company_name: "",
                address_line1: "",
                address_line2: "",
                city: "",
                postal_code: "",
                country_code: "",
                email: "",
                phone: "",
                nationality_country_code: "DE",
                identification_number: "",
                identification_issue_date: "",
                identification_type: "",
                birth_date: "",
                birth_place: "",
                createAccount: false,
                updateProfile: true,
                has_marketing_signup: false,
            },
        };
    },
    methods: {
        ...mapActions({
            postUserReservationPreCheckIn: "postUserReservationPreCheckIn",
            getProfile: "getProfile",
        }),
        submit() {
            this.errors = {};
            this.error = false;
            this.postUserReservationPreCheckIn({
                id: this.reservationQueryPayload,
                data: this.guest,
            })
                .then((response) => {
                    this.$emit("reload");
                    this.$router.push({
                        name: "bookings.edit.summary",
                        params: this.reservationQueryPayload,
                    });
                })
                .catch((error) => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    }
                    console.log(error);
                });
        },
        setDataFromReservation() {
            console.log(
                "precheckin setDataFromReservation",
                this.reservation,
                this.reservation.primary_guest
            );

            for (const [key, val] of Object.entries(this.guest)) {
                if (this.reservation.primary_guest?.hasOwnProperty(key)) {
                    console.log(
                        "setting from reservation",
                        key,
                        this.reservation.primary_guest[key]
                    );
                    this.guest[key] = this.reservation.primary_guest[key];
                    if (
                        key === "email" &&
                        _.endsWith(
                            this.reservation.primary_guest[key],
                            "guest.booking.com"
                        )
                    ) {
                        this.guest[key] = null;
                    }

                    if (key === "birth_date") {
                        this.guest[key] = this.dayjs(
                            this.reservation.primary_guest[key]
                        ).format("YYYY-MM-DD");
                    }
                }
            }
            if (this.guest.nationality_country_code === null) {
                this.guest.nationality_country_code = "DE";
            }
        },
        async setDataFromProfile() {
            await this.getProfile();
            for (const [key, val] of Object.entries(this.guest)) {
                if (this.profile.guest_detail.hasOwnProperty(key)) {
                    console.log(
                        "setting from profile",
                        key,
                        this.profile.guest_detail[key]
                    );
                    this.guest[key] = this.profile.guest_detail[key];
                }
            }
        },
    },
    computed: {
        ...mapState({
            loading: (state) => state.loading,
            profile: (state) => state.profile,
        }),
        ...mapGetters({
            isLoggedIn: "isLoggedIn",
            profile: "profile",
            currentLocale: "currentLocale",
        }),
    },
    watch: {
        reservation(val, old) {
            console.log("precheckin watch reservation", val, old);
        },
    },
    props: {
        reservation: {
            required: true,
            type: Object,
        },
    },
    mounted() {
        console.log("mounted precheckin", this.reservation);
        this.setDataFromReservation();
        this.$scrollTo("#precheckin_container", 300);
    },
};
</script>

<style></style>
