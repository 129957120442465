<template>
    <content-container class="text-r-green" :default-padding="false">
        <div class="relative py-12">
            <div
                v-if="backRoute !== null"
                class="absolute inset-y-0 left-0 flex items-center"
            >
                <router-link :to="backRoute">
                    <icon
                        icon="arrow-narrow-left"
                        class="w-12 h-12 stroke-r-green"
                    ></icon>
                </router-link>
            </div>
            <h2
                class="w-full text-2xl text-center lg:text-left lg:text-4xl font-black italic font-sans py-2 lg:py-6 px-3 flex justify-center"
            >
                <slot></slot>
            </h2>
        </div>
    </content-container>
</template>

<script>
import Icon from "@guest/common/Icon";
import ContentContainer from "@guest/common/ContentContainer";
export default {
    components: {
        Icon,
        ContentContainer,
    },
    props: {
        backRoute: {
            required: false,
            type: Object | null,
            default: null,
        },
    },
};
</script>

<style></style>
