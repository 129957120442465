<template>
    <div>
        <div
            class="relative inline-flex items-center border-2 rounded-full transition duration-200"
            :class="containerClasses"
            @click.capture="change('click')"
            >
            <input
                type="checkbox"
                :id="id"
                :value="value"
                class="border-0 rounded-full duration-150 transition-all transform"
                :class="dotClasses"
                ref="input"
                :checked="value"
                >

            </input>
        </div>
    </div>

</template>

<script>
export default {
    methods: {
        change(e) {
            if (!!this.disabled === false) {
                this.$emit('input', !this.castedValue)
            }
        },
    },
    computed: {
        containerClasses() {
            return {
                'opacity-50' : this.disabled,
                'cursor-not-allowed' : !!this.disabled,
                'cursor-pointer' : !!this.disabled === false,
                'bg-green-50 border-green-600': this.castedValue,
                'bg-gray-100 border-gray-400': !this.castedValue,
                'w-24 h-12' : this.size==='xl',
                'w-20 h-10' : this.size==='lg',
                'w-16 h-8' : this.size==='base',
                'w-12 h-6' : this.size==='sm',
                'w-10 h-5' : this.size==='xs',
            }

        },
        dotClasses() {
            return {
                'w-10 h-10' : this.size==='xl',
                'w-8 h-8' : this.size==='lg',
                'w-6 h-6' : this.size==='base',
                'w-3 h-3' : this.size==='sm',
                'w-3 h-3' : this.size==='xs',
                'left-1' : !this.castedValue,
                'left-2' : this.castedValue,
                'absolute' : true,
                'cursor-pointer':true,
                'translate-x-full' : this.castedValue,
                'translate-x-0' : !this.castedValue,
                'text-green-600': this.castedValue,
                'text-gray-400': !this.castedValue,
                'ring-0 ring-transparent ring-offset-0 ring-offset-transparent': true,
                'focus:ring-0 focus:ring-transparent focus:ring-offset-0 focus:ring-offset-transparent': true,
                'checked:ring-0 checked:ring-transparent checked:ring-offset-0 checked:ring-offset-transparent': true,
                'active:ring-0 active:ring-transparent active:ring-offset-0 active:ring-offset-transparent': true,
                'appearance-none focus:appearance-none active:appearance-none': true,
                'bg-gray-400' : !this.castedValue,
            }
        },
        castedValue() {
            return !!this.value
        },
    },
    props: {
        value: {
            required: true,
        },
        disabled: {
            required: false,
            default: false
        },
        id: {
            required: false,
            default: 'temp',
        },
        size: {
            required: false,
            default: 'base'
        }
    }

}
</script>

<style>

</style>
