<template>
    <div>
        <Modal
            :showHideButton="false"
            :isClickOutsideEnabled="false"
            v-show="!isDecided"
            ref="modal_cookie_consent"
            width="w-5/6 md:w-1/2"
        >
            <template v-slot:title>{{ $t("cookie_consent.title") }}</template>
            <template v-slot:body>
                <i18n path="cookie_consent.body" tag="p">
                    <a
                        href="https://www.roatel.com/datenschutz-booking"
                        class="text-r-green underline"
                        target="_blank"
                        >{{ $t("cookie_consent.privacy_label") }}</a
                    >
                </i18n>
            </template>
            <template v-slot:footer>
                <div class="flex flex-row items-center justify-between">
                    <div>
                        <button
                            class="text-sm text-gray-500"
                            @click="decide(false)"
                        >
                            {{ $t("cookie_consent.reject_label") }}
                        </button>
                    </div>
                    <div>
                        <app-button
                            color="green"
                            size="lg"
                            :outline="false"
                            @click="decide(true)"
                        >
                            {{ $t("cookie_consent.confirm_label") }}
                        </app-button>
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Modal from "@guest/common/Modal";
import AppButton from "@guest/common/Button";
import { bootstrap } from "vue-gtag";
import FbImporter from "@/fb_pixel";

export default {
    components: {
        Modal,
        AppButton,
    },
    data() {
        return {};
    },
    methods: {
        decide(payload) {
            this.setCookieConsent({
                isDecided: true,
                isAllowed: !!payload,
            });
            this.updateCookieState();
        },
        ...mapActions({
            setCookieConsent: "setCookieConsent",
        }),
        updateCookieState() {
            if (this.isDecided && this.isAllowed) {
                if (process.env.MIX_ENV === "production") {
                    bootstrap().then((gtag) => {
                        console.log("cookie: bootstrapped and enabled");
                        console.log("gtag", this.$gtag);
                        this.$gtag.query("consent", "default", {
                            ad_user_data: "denied",
                            ad_personalization: "denied",
                            ad_storage: "denied",
                            analytics_storage: "denied",
                            wait_for_update: 500,
                        });
                        this.$gtag.query("consent", "update", {
                            ad_user_data: "granted",
                            ad_personalization: "granted",
                            ad_storage: "granted",
                            analytics_storage: "granted",
                        });
                    });

                    // console.log("fb", FbImporter());
                    FbImporter();
                    fbq("init", "496397064766416");
                    fbq("track", "PageView");
                }
            } else {
                console.log("cookie: tracking disabled");
            }
        },
    },
    watch: {
        getCookieConsent() {
            console.log("watcher cookie consent", this.getCookieConsent);
            if (this.isDecided === false) {
                this.$refs.modal_cookie_consent.show();
            }
        },
    },
    computed: {
        tag() {
            return gtag;
        },
        ...mapGetters({
            getCookieConsent: "getCookieConsent",
        }),
        isDecided() {
            if (this.getCookieConsent) {
                return this.getCookieConsent.isDecided;
            }
            return false;
        },
        isAllowed() {
            return this.getCookieConsent.isAllowed;
        },
    },
    mounted() {
        console.log("cookie component state", this.getCookieConsent);
        if (this.isDecided === false) {
            this.$refs.modal_cookie_consent.show();
        }
        if (this.isDecided) {
            this.updateCookieState();
        }
    },
    props: {},
};
</script>

<style lang="scss"></style>
