<template>
    <div>
        <booking-draft-state
                @back="$emit('dates')"
                @reset="$emit('reset')"
                ></booking-draft-state>
        <content-container>
            <div class="justify-center grid grid-cols-1 md:grid-cols-3 gap-4">
                <h1 class="text-xl font-bold text-center col-span-1 md:col-span-3">
                    {{$t('new_reservation.offers.our_offers')}}
                </h1>
                <offer-card
                    v-for="(offer, offer_index) in offers"
                    :key="offer_index"
                    :selected="selected"
                    @select="submit($event)"
                    @continue="submit"
                    :rooms="rooms"
                    :offer="offer">

                </offer-card>
            </div>
        </content-container>
    </div>
</template>

<script>

import {mapActions, mapGetters, mapState} from 'vuex'
import ContentContainer from '@admin/common/ContentContainer'
import AppInput from '@admin/common/Input'
import AppButton from '@admin/common/Button'
import DayJS from '@mixins/DayJS'
import OfferCard from '@guest/bookings/new/OfferCard'
import BookingDraftState from '@guest/bookings/new/State'
import Modal from '@admin/common/Modal'

export default {
    data: function() {
        return {
            rooms: 1,
            selected: {},
        }
    },
    components: {
        Modal,
        BookingDraftState,
        ContentContainer,
        AppInput,
        AppButton,
        OfferCard,
    },
    mixins: [
        DayJS,
    ],
    watch: {
        rooms: {
            handler: function() {
                console.log('Change rooms')
                this.setBookingDraftRooms(this.rooms)
            }
        }
    },
    methods: {
        getRoomSelectorCount(offer) {
            console.log(offer.availableUnits)
            let rooms = [1,2,3,4]
            return offer.availableUnits >= 4 ? rooms : rooms.splice(0, offer.availableUnits)
        },
        ...mapActions({
            setBookingDraftOffer: 'setBookingDraftOffer',
            setBookingDraftRooms: 'setBookingDraftRooms',
        }),
        submit({offer, rooms}) {
            this.setBookingDraftOffer(offer)
            this.setBookingDraftRooms(rooms)
            this.$emit('services-list')
        },
    },
    computed: {
        submitDisabled() {
            return this.offer_index === null ? true : false
        },
        ...mapState({
            offers: state => state.bookingDraft.offers,
            property: state => state.bookingDraft.property,
            dates: state => state.bookingDraft.dates,
        })
    },
    mounted() {
        this.$emit('step', 3)
    }
}
</script>

<style>

</style>
