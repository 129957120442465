<template>
    <div>
        <full-width-container class="bg-r-green hidden md:flex py-2">
            <content-container padding="px-6 py-5 lg:px-12 lg:py-2">
                <div
                    class="w-full flex flex-row items-center justify-center gap-5 relative"
                >
                    <div
                        class="absolute inset-y-0 left-0 font-sans text-white font-black italic text-xl flex flex-row items-center"
                    >
                        {{ $t("new_reservation.find_roatel") }}
                    </div>
                    <div class="flex items-center flex-row gap-2">
                        <div
                            class="flex flex-row items-center space-x-4 flex-grow w-full"
                        >
                            <div class="w-[460px]">
                                <app-input
                                    :placeholder="
                                        $t(
                                            'new_reservation.find_roatel_input_placeholder'
                                        )
                                    "
                                    :hasLabel="false"
                                    :hasReset="search.length > 0 ? true : false"
                                    name="search"
                                    :value="search"
                                    @input="$emit('input', $event)"
                                    @keyup-enter="$emit('search')"
                                    icon="target"
                                    icon-click-emit="geosearch"
                                    @geosearch="geolocate"
                                >
                                    <template v-slot:before>
                                        <icon
                                            class="stroke-r-gray-medium w-6 h-6"
                                            icon="search"
                                        ></icon>
                                    </template>
                                </app-input>
                            </div>
                            <div>
                                <app-button
                                    color="darkgreen"
                                    :disabled="search.length < 2"
                                    @click="$emit('search')"
                                >
                                    {{ $t("new_reservation.button_search") }}
                                </app-button>
                            </div>
                        </div>
                    </div>
                    <div
                        class="absolute right-0 inset-y-0 flex flex-row space-x-5 text-white font-bold text-sm"
                    >
                        <router-link
                            class="flex flex-row space-x-2 cursor-pointer items-center"
                            :to="{
                                name: 'bookings.new.search_properties',
                                params: {
                                    show: 'map',
                                },
                            }"
                        >
                            <icon
                                icon="map"
                                class="stroke-white w-6 h-6"
                            ></icon>
                            <div>
                                {{ $t("new_reservation.show_map") }}
                            </div>
                        </router-link>
                        <button
                            @click="getAllProperties"
                            class="flex flex-row space-x-2 cursor-pointer items-center"
                        >
                            <div class="inline-flex items-center gap-3">
                                <div>
                                    {{ $t("new_reservation.complete_list") }}
                                </div>
                                <icon
                                    icon="chevron-right"
                                    class="stroke-white w-6 h-6"
                                ></icon>
                            </div>
                        </button>
                    </div>
                </div>
            </content-container>
        </full-width-container>
        <!-- Mobile -->
        <full-width-container class="bg-r-green lg:hidden">
            <content-container>
                <div class="bg-r-green px-0 py-5 grid gap-5">
                    <div
                        class="text-white font-black text-2xl text-center w-full italic"
                    >
                        {{ $t("new_reservation.find_roatel") }}
                    </div>
                    <div class="flex flex-col items-center gap-5 mb-6">
                        <app-input
                            :placeholder="
                                $t(
                                    'new_reservation.find_roatel_input_placeholder'
                                )
                            "
                            :hasLabel="false"
                            name="search"
                            :value="search"
                            @input="$emit('input', $event)"
                            @keyup-enter="$emit('search')"
                            icon="target"
                            iconClickEmit="location"
                            @location="$emit('geosearch')"
                        >
                            <template v-slot:before>
                                <icon
                                    class="stroke-r-gray-medium w-6 h-6"
                                    icon="search"
                                ></icon>
                            </template>
                        </app-input>
                        <app-button color="darkgreen" @click="$emit('search')">
                            {{ $t("new_reservation.button_search") }}
                        </app-button>
                    </div>

                    <div class="flex items-center justify-around text-white">
                        <router-link
                            class="flex flex-row gap-2 cursor-pointer items-center"
                            :to="{
                                name: 'bookings.new.search_properties',
                                params: {
                                    show: 'map',
                                },
                            }"
                        >
                            <icon
                                icon="map"
                                class="stroke-white w-6 h-6"
                            ></icon>
                            <div>
                                {{ $t("new_reservation.show_map") }}
                            </div>
                        </router-link>
                        <button
                            @click="getAllProperties"
                            class="flex flex-row space-x-2 cursor-pointer items-center"
                        >
                            <div class="inline-flex items-center gap-3">
                                <div>
                                    {{ $t("new_reservation.complete_list") }}
                                </div>
                                <icon
                                    icon="chevron-right"
                                    class="stroke-white w-6 h-6"
                                ></icon>
                            </div>
                        </button>
                    </div>
                </div>
            </content-container>
        </full-width-container>
    </div>
</template>

<script>
import Logo from "@guest/layout/Logo";
import AppInput from "@guest/common/Input";
import AppButton from "@guest/common/Button";
import FullWidthContainer from "@guest/common/FullWidthContainer";
import ContentContainer from "@guest/common/ContentContainer";
import Icon from "@guest/common/Icon";

export default {
    watch: {
        search: {
            handler: function (val) {
                console.log(val);
                this.$emit("input", val);
            },
            immediate: true,
        },
    },
    components: {
        Logo,
        AppInput,
        AppButton,
        FullWidthContainer,
        ContentContainer,
        Icon,
    },

    methods: {
        getAllProperties() {
            this.$emit("input", "");
            this.$emit("search");
            if (this.$route.params.show === "map") {
                this.$router.push({ params: { show: "cards" } });
            }
        },
        geolocate() {
            console.log("geolocate pressed");
            this.$emit("geosearch");
        },
    },
    data: function () {
        return {};
    },

    props: {
        search: {
            type: String,
            required: false,
            default: "",
        },
    },
};
</script>

<style></style>
