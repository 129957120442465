<template>
    <div
        class="flex flex-row gap-4 w-full px-3 lg:px-10 lg:w-[1440px] items-center justify-between relative z-30 bg-white"
    >
        <div
            @click="showHamburgerMenu = false"
            class="inset-0 fixed bg-r-black opacity-60 z-10 top-20 transition-all duration-300"
            v-if="showHamburgerMenu"
        ></div>
        <logo imageClass="h-9 w-auto"></logo>
        <nav
            class="w-full absolute top-12 lg:hidden bg-white z-30 grid px-3 h-auto left-0 border-t border-b border-r-gray-light gap-2 font-bold py-2"
            v-if="showHamburgerMenu"
        >
            <div
                @click.stop="mobileNavigation({ name: 'home' })"
                class="hover:text-r-green duration-100 transition-all py-3"
            >
                {{ $t("topbar.discover") }}
            </div>
            <div
                @click.stop="mobileNavigation({ name: 'bookings.new' })"
                class="hover:text-r-green duration-100 transition-all py-3"
            >
                {{ $t("topbar.booking") }}
            </div>
            <div
                v-if="isLoggedIn"
                @click.stop="mobileNavigation({ name: 'bookings.list' })"
                class="hover:text-r-green duration-100 transition-all py-3"
            >
                {{ $t("topbar.my_bookings") }}
            </div>
            <a
                href="https://roatel.com/ueber-uns"
                target="_blank"
                class="hover:text-r-green py-3 inline-flex items-center gap-2 stroke-r-black"
                >{{ $t("topbar.company") }}

                <icon icon="arrow-up-right" class="w-5 h-5"></icon>
            </a>
        </nav>
        <nav
            class="hidden lg:flex text-r-black grow flex-row space-x-6 justify-end font-slanted font-bold"
        >
            <router-link
                :to="{ name: 'home' }"
                class="hover:text-r-green duration-100 transition-all"
                active-class="text-r-green"
                >{{ $t("topbar.discover") }}</router-link
            >
            <router-link
                :to="{ name: 'bookings.new' }"
                class="hover:text-r-green duration-100 transition-all"
                active-class="text-r-green"
                >{{ $t("topbar.booking") }}</router-link
            >
            <router-link
                v-if="isLoggedIn"
                :to="{ name: 'bookings.list' }"
                class="hover:text-r-green duration-100 transition-all"
                active-class="text-r-green"
                >{{ $t("topbar.my_bookings") }}</router-link
            >
            <a
                href="https://roatel.com/ueber-uns"
                target="_blank"
                class="hover:text-r-green hover:stroke-r-green stroke-r-black inline-flex items-center gap-2"
            >
                {{ $t("topbar.company") }}
                <icon icon="arrow-up-right" class="w-5 h-5"></icon>
            </a>
        </nav>
        <div class="text-xl flex flex-row items-center gap-3">
            <router-link
                :to="{ name: !isLoggedIn ? 'login' : 'profile' }"
                class="hover:text-r-green duration-100 transition-all"
                :class="[isLoggedIn ? 'stroke-r-green' : 'stroke-r-black']"
            >
                <icon
                    class="hover:stroke-r-green"
                    icon="user"
                    size="w-7 h-7"
                    :class="[isLoggedIn ? 'stroke-r-green' : 'stroke-r-black']"
                >
                </icon>
            </router-link>
            <a
                href="https://www.roatel.com/support"
                target="_blank"
                class="duration-100 transition-all"
            >
                <icon
                    icon="questionmark"
                    size="w-7 h-7"
                    class="stroke-r-black hover:stroke-r-green"
                >
                </icon>
            </a>
            <button
                class="inline-flex items-center gap-2 duration-100 transition-all group"
                @click.stop="$emit('locale')"
            >
                <icon
                    icon="globe"
                    size="w-7 h-7"
                    :class="[
                        'stroke-r-black hover:stroke-r-green group-hover:stroke-r-green',
                    ]"
                >
                </icon>
                <span
                    v-if="locale"
                    class="uppercase font-bold text-base group-hover:text-r-green"
                    >{{ locale.key }}</span
                >
            </button>
            <button
                class="inline-flex items-center gap-2 duration-100 transition-all group lg:hidden ml-3"
                @click.stop="showHamburgerMenu = !showHamburgerMenu"
            >
                <icon
                    :icon="showHamburgerMenu ? 'x' : 'menu'"
                    size="w-7 h-7"
                    :class="['stroke-r-black']"
                >
                </icon>
            </button>
        </div>
    </div>
</template>

<script>
import Logo from "@guest/layout/Logo";
import { mapGetters, mapActions, mapState } from "vuex";
import Icon from "@guest/common/Icon.vue";

export default {
    components: {
        Logo,
        Icon,
    },
    data: function () {
        return {
            showHamburgerMenu: false,
        };
    },
    // beforeRouteLeave: function (to, from, next) {
    //     this.showHamburgerMenu = false;
    //     console.log("beforerouteleave", to, from, next);
    //     return next();
    // },
    // called when the route that renders this component is about to
    // be navigated away from.{
    methods: {
        mobileNavigation(target) {
            this.showHamburgerMenu = false;
            if (typeof target === "object") {
                this.$router.push(target);
                return;
            }
        },
        // ...mapActions({
        //     setShowHamburgerMenu: "setShowHamburgerMenu",
        // }),
    },
    computed: {
        ...mapState({
            locales: "locales",
        }),
        ...mapGetters({
            isLoggedIn: "isLoggedIn",
            // showHamburgerMenu: "showHamburgerMenu",
            locale: "currentLocale",
        }),
    },
};
</script>

<style></style>
