<template>
    <div>
        <!--<stepper
                    :current_step = "step"
                    :hide = "hide_stepper"
                    ></stepper>-->
        <router-view
            @properties-list="showPropertiesList"
            @search-properties="showSearchProperties"
            @offers-list="showOffersList"
            @services-list="showServicesList"
            @purpose="showPurpose"
            @booker="showBooker"
            @summary="showSummary"
            @dates="showDates"
            @payment="showPayment"
            @completed="showCompleted"
            @login="showLogin"
            @reset="reset"
            @step="setStep"
            :current_step="step"
        ></router-view>
    </div>
</template>

<script>
import Modal from "@admin/common/Modal";
import Stepper from "@guest/bookings/new/Stepper";

export default {
    components: {
        Modal,
        Stepper,
    },
    data: function () {
        return {
            step: 1,
            hide_stepper: false,
        };
    },
    computed: {
        hasProperties() {
            return this.properties.length > 0 ? true : false;
        },
    },
    methods: {
        setStep(step) {
            this.step = step;
        },
        reset() {
            this.$router.push({ name: "bookings.new.search_properties" });
            // this.step = 1;
        },
        showSummary() {
            this.$router.push({ name: "bookings.new.summary" });
        },
        showDates() {
            this.$router.push({ name: "bookings.new.dates" });
        },
        showOffersList() {
            this.$router.push({ name: "bookings.new.offers" });
        },
        showServicesList() {
            this.$router.push({ name: "bookings.new.services" });
        },
        showLogin() {
            this.$router.push({ name: "bookings.new.login" });
        },
        showPurpose() {
            this.$router.push({ name: "bookings.new.purpose" });
        },
        showBooker() {
            this.$router.push({ name: "bookings.new.booker" });
        },
        showPropertiesList() {
            this.$router.push({ name: "bookings.new.properties" });
        },
        showSearchProperties() {
            this.$router.push({ name: "bookings.new.search_properties" });
        },
        showPayment() {
            this.$router.push({ name: "bookings.new.payment" });
        },
        showCompleted(data) {
            this.$router.push({
                name: "bookings.new.completed",
                params: {
                    id: data.id,
                },
            });
        },
    },
};
</script>

<style></style>
