<template>
    <nav id="sidebar_navigation" class="relative flex items-center md:flex-grow">
        <div @click="setShowHamburgerMenu(!showHamburger)" class="relative flex items-center text-white cursor-pointer md:hidden">
            <font-awesome-icon icon="bars" class="text-lg text-white"></font-awesome-icon>
        </div>
        <div
            class="hidden md:flex md:flex-col space-y-3"
            >
            <div
                v-for="item in navigationFiltered"
                :key="item.label"
                class="font-medium leading-loose text-gray-300 uppercase"
                >
                <router-link
                    :to="{name: item.route }"
                    class="flex items-center px-2 py-1 tracking-wide rounded hover:text-white hover:bg-gray-700 duration-200 transition-all"
                    active-class="font-bold text-white bg-gray-700"
                    >
                    <font-awesome-icon v-if="item.icon" :icon="item.icon" class="mr-2 text-xl"></font-awesome-icon>
                    <span class="">{{$t('navigation.' + item.name)}}</span>
                </router-link>
            </div>
        </div>
        <transition
            enter-active-class="transition-all ease-in duration-300"
            leave-active-class="transition-all ease-out duration-300"

            enter-class="opacity-50"
            enter-to-class="opacity-100"

            leave-class="opacity-100"
            leave-to-class="opacity-50"

        >
            <div class="relative" v-if="showHamburger">
                <div class="fixed inset-0 z-40 bg-gray-900"></div>
                <div
                    class="fixed inset-0 z-40 flex flex-col items-center justify-center px-4 py-10 space-y-4">
                    <div @click="setShowHamburgerMenu(!showHamburger)" class="absolute flex flex-col items-center text-white cursor-pointer top-3 right-3 md:hidden">
                        <font-awesome-icon size="2x" :icon="hamburgerIcon" class="text-white"></font-awesome-icon>
                    </div>
                        <div
                            v-for="item in navigationFiltered"
                            :key="item.label"
                            class="font-medium leading-loose text-gray-300 uppercase"
                            >
                                <router-link
                                    :to="{name: item.route }"
                                    class="flex items-center px-2 py-1 tracking-wide rounded hover:text-white hover:bg-gray-700 duration-200 transition-all"
                                    active-class="font-bold text-white bg-gray-700"
                                    >
                                    <font-awesome-icon v-if="item.icon" :icon="item.icon" class="mr-2 text-xl"></font-awesome-icon>
                                    <span class="">{{$t('navigation.' + item.name)}}</span>
                                </router-link>
                        </div>
                </div>
            </div>
        </transition>
    </nav>
</template>

<script>

/*
Submenü direkt unter router-link vom parent
                <div v-if="item.children && navigationItemInMatchedPath(item)">
                    <div v-for="child in item.children" :key="child.route">
                        <router-link
                            :to="{name: child.route }"
                            class="flex items-center px-2 py-1 tracking-wide rounded hover:text-white hover:bg-gray-700 duration-200 transition-all"
                            active-class="font-bold text-white"
                            >
                            <span class="ml-10">{{child.label}}</span>
                        </router-link>
                    </div>
                </div>
*/

import NavigationItems from '@/guest/routes/navigation'
import {mapGetters, mapActions, mapState} from 'vuex'

export default {
    data: function() {
        return {
            currentItem: null,
        }
    },
    methods: {
        ...mapActions({
            setShowHamburgerMenu: 'setShowHamburgerMenu',
        }),
        changeRoute(item) {
            this.currentItem = item.route
            if (! item.children) {
                this.$router.push({name: item.route})
            }
        },
        navigationItemInMatchedPath(item) {
            let hit = this.$route.matched.find(route => route.name == item.route)
            return hit !== undefined ? true : false
        },
    },
    computed: {
        ...mapState({
            showHamburger: 'showHamburgerMenu',
        }),
        hamburgerIcon() {
            return this.showHamburger ? 'times' : 'bars'
        },
        navigationFiltered() {
            return NavigationItems.filter(item => {
                let show = true
                if (item.show) {
                    if (item.show.requiresAuth) {
                        show = this.isLoggedIn
                    }
                    if (item.show.requiresGuest) {
                        show = !this.isLoggedIn
                    }
                }
                return show
            })
        },
        ...mapGetters({
            isLoggedIn: 'isLoggedIn',
            isGuest: 'isGuest',
        })
    },
    components: {
    },
    mounted() {
        // this.currentItem = this.$route.matched[0].name
    }

}
</script>

<style scoped lang="scss">

</style>
