<template>
    <div class="grid gap-8">
        <h2
            class="text-2xl lg:text-3xl font-black italic text-r-green text-center leading-normal"
        >
            {{ $t("reservations.summary.room_widget.lead") }}<br />
            {{
                $t("reservations.summary.room_widget.heading", {
                    room: reservation.unit.name,
                })
            }}
        </h2>
        <div class="text-center lg:text-left">
            {{ $t("reservations.summary.room_widget.body") }}
        </div>
        <div>
            <app-button @click="$emit('open')" color="green">
                {{ $t("reservations.summary.room_widget.button") }}
            </app-button>
        </div>
        <div>
            <hr class="border-r-gray-light" />
        </div>
        <div class="grid gap-2 text-sm font-bold">
            <div>
                {{ $t("new_reservation.offers.offer_card.information") }}
            </div>
            <div class="flex gap-2 items-center">
                <icon icon="check-circle" class="stroke-r-black w-5 h-5"></icon>
                <span>
                    {{
                        $t("reservations.summary.room_widget.checkout_info1", {
                            time: date(reservation.departure, "time_localized"),
                        })
                    }}
                </span>
            </div>
            <div class="flex gap-2 items-center">
                <icon icon="check-circle" class="stroke-r-black w-5 h-5"></icon>
                <span>
                    {{ $t("reservations.summary.room_widget.checkout_info2") }}
                </span>
            </div>
        </div>
        <div class="w-full md:hidden">
            <app-button
                color="gray"
                @click="
                    $router.push({
                        name: 'bookings.edit.booker',
                        params: reservationQueryPayload,
                    })
                "
            >
                {{ $t("reservations.summary.button_booker_change") }}
            </app-button>
        </div>
    </div>
</template>

<script>
import ClipContainer from "@guest/common/ClipContainer";
import AppButton from "@guest/common/Button";
import DayJS from "@mixins/DayJS";
import Icon from "@guest/common/Icon";
import ReservationQueryPayload from "@mixins/ReservationQueryPayload";
// import { mapActions } from "vuex";

export default {
    mixins: [DayJS, ReservationQueryPayload],
    components: {
        ClipContainer,
        Icon,
        AppButton,
    },
    props: {
        reservation: {
            required: true,
            type: Object,
        },
        // reservationQueryPayload: {
        //     required: true,
        // },
    },
    computed: {
        // checkInToday() {
        //     return this.dayjs(
        //         this.reservation.earliest_check_in_time
        //     ).isToday();
        // },
        // // checkIn() {
        // //     return this.dayjs().isAfter(this.reservation.arrival);
        // // },
        // checkInEnabled() {
        //     return (
        //         this.reservation.status == "Confirmed" &&
        //         this.dayjs().isAfter(this.reservation.earliest_check_in_time)
        //     );
        // },
    },
    methods: {
        // ...mapActions({
        //     // getUserReservation: "getUserReservation",
        //     getUserReservationCanCheckIn: "getUserReservationCanCheckIn",
        //     // setReturnToPath: "setReturnToPath",
        //     // setLocale: "setLocale",
        //     // putUserReservationCancel: "putUserReservationCancel",
        // }),
        // canCheckIn() {
        //     console.log("can check in");
        //     this.getUserReservationCanCheckIn(this.reservationQueryPayload)
        //         .then((response) => {
        //             this.$router.push({
        //                 name: "bookings.edit.checkin.name",
        //                 params: this.reservationQueryPayload,
        //             });
        //         })
        //         .catch((error) => {
        //             console.log(error.response);
        //         });
        // },
    },
};
</script>

<style></style>
