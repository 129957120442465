<template>
    <div
        :class="{
            'border-r-red text-r-red': type === 'error',
            'border-r-green bg-r-green-5 text-r-green': type === 'success',
        }"
        class="flex flex-row items-center space-x-4 md:p-6 p-4 border-2 rounded-lg"
    >
        <div class="text-xl" v-if="icon">
            <font-awesome-icon
                v-if="icon !== null"
                :icon="icon"
            ></font-awesome-icon>
        </div>
        <div class="text-base">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        icon() {
            if (this.type === "error") {
                return "triangle-exclamation";
            }
            return null;
        },
    },
    props: {
        type: {
            required: false,
            default: "error",
            type: String,
        },
    },
};
</script>

<style></style>
