<template>
    <router-view :reservation="reservation"> </router-view>
</template>

<script>
import ContentContainer from "@admin/common/ContentContainer";

export default {
    components: {
        ContentContainer,
    },
    props: {
        reservation: {
            required: true,
            type: Object,
        },
    },
};
</script>

<style></style>
