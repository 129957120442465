<template>
    <div class="hidden lg:block">
        <full-width-container class="bg-r-gray-ultralight">
            <content-container :default-padding="false" class="px-3 lg:px-12">
                <title-bar :back-route="{ name: backRouteName }">
                    {{ property.name }}
                </title-bar>
            </content-container>
        </full-width-container>
        <!-- <full-width-container class="bg-r-gray-ultralight"> -->
        <!--     <content-container> -->
        <!--         <div class="relative py-5"> -->
        <!--             <div -->
        <!--                 v-if="backRouteName !== null" -->
        <!--                 class="absolute inset-y-0 left-0 flex items-center" -->
        <!--             > -->
        <!--                 <router-link -->
        <!--                     :to="{ name: backRouteName }" -->
        <!--                     class="text-r-green" -->
        <!--                 > -->
        <!--                     <font-awesome-icon -->
        <!--                         icon="chevron-left" -->
        <!--                         class="text-4xl" -->
        <!--                     ></font-awesome-icon> -->
        <!--                 </router-link> -->
        <!--             </div> -->
        <!--             <h2 -->
        <!--                 class="text-center text-4xl font-black italic text-r-green" -->
        <!--             ></h2> -->
        <!--         </div> -->
        <!--     </content-container> -->
        <!-- </full-width-container> -->
    </div>
</template>
<script>
import ContentContainer from "@guest/common/ContentContainer";
import FullWidthContainer from "@guest/common/FullWidthContainer.vue";
import TitleBar from "@guest/common/TitleBar";

export default {
    components: {
        ContentContainer,
        TitleBar,
        FullWidthContainer,
    },
    props: {
        property: {
            required: true,
            type: Object,
        },
        backRouteName: {
            required: false,
            default: null,
            type: Object | null,
        },
    },
};
</script>
