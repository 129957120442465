var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('modal',{ref:"modal_imprint",attrs:{"is-confirmation":false,"width":"w-full lg:w-1/3"},scopedSlots:_vm._u([{key:"title",fn:function({ model }){return undefined}},{key:"body",fn:function({ model }){return [_c('div',{staticClass:"grid gap-4 text-r-black"},[_c('div',{staticClass:"grid gap-4"},[_c('div',[_c('div',{staticClass:"text-2xl font-black italic text-r-green mb-2 text-center"},[_vm._v("\n                            "+_vm._s(_vm.$t(
                                    "new_reservation.property_card.imprint.imprint"
                                ))+"\n                        ")]),_vm._v(" "),_c('div',[_vm._v(_vm._s(model.name))]),_vm._v(" "),_c('div',[_vm._v(_vm._s(model.company_name))]),_vm._v(" "),_c('div',[_vm._v(_vm._s(model.invoice_address_line1))]),_vm._v(" "),(model.invoice_address_line2)?_c('div',[_vm._v("\n                            "+_vm._s(model.invoice_address_line2)+"\n                        ")]):_vm._e(),_vm._v(" "),_c('div',[_vm._v("\n                            "+_vm._s(model.invoice_country_code)+"\n                            "+_vm._s(model.invoice_postal_code)+"\n                            "+_vm._s(model.invoice_city)+"\n                        ")])]),_vm._v(" "),(model.commercial_register_entry)?_c('div',[_vm._v("\n                        "+_vm._s(_vm.$t(
                                "new_reservation.property_card.imprint.commercial_register"
                            ))+"\n                        "+_vm._s(model.commercial_register_entry)+"\n                    ")]):_vm._e(),_vm._v(" "),(model.tax_id)?_c('div',[_vm._v("\n                        "+_vm._s(_vm.$t(
                                "new_reservation.property_card.imprint.vat_id"
                            ))+"\n                        "+_vm._s(model.tax_id)+"\n                    ")]):_vm._e()]),_vm._v(" "),(model.managing_directors)?_c('div',[_c('div',{staticClass:"font-bold uppercase text-r-green mb-2"},[_vm._v("\n                        "+_vm._s(_vm.$t(
                                "new_reservation.property_card.imprint.managing_directors"
                            ))+"\n                    ")]),_vm._v(" "),_c('div',_vm._l((model.managing_directors.split(
                                ','
                            )),function(director){return _c('div',[_vm._v("\n                            "+_vm._s(director)+"\n                        ")])}),0)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex justify-center"},[_c('div',[_c('app-button',{attrs:{"color":"gray"},on:{"click":function($event){return _vm.$refs.modal_imprint.close()}}},[_vm._v("\n                            "+_vm._s(_vm.$t("buttons.close"))+"\n                        ")])],1)])])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }