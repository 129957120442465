import { mapGetters } from "vuex";

export default {
    data: function () {
        return {
            dayjs_formats: {
                date_short: "DD.MM.YY",
                date: "DD.MM.YYYY",
                time_short: "HH:mm",
                time: "HH:mm:ss",
                datetime: "DD.MM.YY [um] HH:mm [Uhr]",
                datetime_localized: "L LT",
                date_localized: "L",
                time_localized: "LT",
                weekday_full: "dddd",
                weekday_short: "ddd",
                date_iso: "YYYY-MM-DD",
            },
        };
    },
    computed: {
        ...mapGetters({
            currentLocale: "currentLocale",
        }),
    },
    methods: {
        date(date, format = "date", relative = false) {
            if (date === null) {
                return "keine Angabe";
            }
            let response = "keine Angabe";
            if (relative) {
                if (date) {
                    response = this.dayjs(date)
                        .locale(this.currentLocale.key)
                        .fromNow();
                }
                return response;
            }
            if (!this.dayjs_formats.hasOwnProperty(format)) {
                return "Invalid DayJS Format: " + format;
            }
            return this.dayjs(date)
                .locale(this.currentLocale.key)
                .format(this.dayjs_formats[format]);
        },
        date_difference(date1, date2, format = "day") {
            const d1 = this.dayjs(date1);
            const d2 = this.dayjs(date2);
            return d1.diff(d2, format);
        },
    },
};
