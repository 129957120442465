<template>
    <clip-container class="md:cutter-big">
        <div class="bg-r-green px-5 py-8 md:p-16">
            <div class="flex flex-col space-y-6 text-white mb-8 md:mb-16">
                <h2
                    class="font-slanted text-2xl md:text-4xl italic pb-2 md:pb-4 font-black uppercase flex-grow text-white"
                >
                    {{ $t("profile.nav.personal") }}
                </h2>
                <div>
                    <div class="font-bold uppercase">
                        {{ $t("profile.lead_heading") }}
                    </div>
                    <div>
                        {{ $t("profile.lead") }}
                    </div>
                </div>
            </div>
            <div
                class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-10 mb-8"
            >
                <div class="flex flex-col space-y-6">
                    <app-input
                        :errors="errors"
                        :label="$t('models.account.first_name')"
                        :placeholder="
                            $t('models.account.first_name_placeholder')
                        "
                        v-model="data.first_name"
                        name="first_name"
                    >
                    </app-input>
                    <app-input
                        :errors="errors"
                        :label="$t('models.account.last_name')"
                        :placeholder="
                            $t('models.account.last_name_placeholder')
                        "
                        v-model="data.last_name"
                        name="last_name"
                    >
                    </app-input>
                </div>
                <div class="flex flex-col space-y-6">
                    <app-input
                        type="selectClassic"
                        :errors="errors"
                        :selectOptions="locales"
                        selectValueProperty="locale"
                        selectLabelProperty="label"
                        :label="$t('models.account.preferred_locale')"
                        v-model="data.preferred_locale"
                        name="preferred_locale"
                    >
                    </app-input>
                </div>
                <div class="flex flex-col space-y-6">
                    <app-input
                        type="password"
                        :errors="errors"
                        :label="$t('models.account.password')"
                        :placeholder="$t('models.account.password_placeholder')"
                        v-model="data.password"
                        name="password"
                    >
                    </app-input>
                    <app-input
                        type="password"
                        :errors="errors"
                        :label="$t('models.account.password_repeat')"
                        :placeholder="
                            $t('models.account.password_repeat_placeholder')
                        "
                        v-model="data.password_repeat"
                        name="password_repeat"
                    >
                    </app-input>
                    <div class="text-sm text-white">
                        {{ $t("profile.password_change_description") }}
                    </div>
                </div>
            </div>
            <div class="flex flex-row justify-between">
                <div>
                    <app-button
                        @click="submit"
                        size="lg"
                        :outline="false"
                        color="green"
                        icon="save"
                    >
                        {{ $t("buttons.save") }}
                    </app-button>
                </div>
                <div>
                    <required-field-info
                        class="text-white"
                    ></required-field-info>
                </div>
            </div>
        </div>
    </clip-container>
</template>

<script>
import AppInput from "@guest/common/Input";
import AppButton from "@guest/common/Button";
import Locales from "@/guest/common/locales";
import { mapState, mapActions } from "vuex";
// import FullWidthContainer from "@guest/common/FullWidthContainer.vue";
// import ContentContainer from "@guest/common/ContentContainer.vue";
import ClipContainer from "@guest/common/ClipContainer.vue";
import RequiredFieldInfo from "@guest/common/RequiredFieldInfo.vue";

export default {
    components: {
        // FullWidthContainer,
        RequiredFieldInfo,
        AppInput,
        AppButton,
        ClipContainer,
    },
    watch: {
        profile: {
            handler: function () {
                this.copyProfile();
            },
        },
    },
    data: function () {
        return {
            data: {
                first_name: "",
                last_name: "",
                email: "",
                password: "",
                password_repeat: "",
                preferred_locale: "",
            },
            errors: {},
        };
    },
    computed: {
        ...mapState({
            profile: "profile",
        }),
        locales() {
            return Locales;
        },
    },
    methods: {
        ...mapActions({
            postProfile: "postProfile",
            getProfile: "getProfile",
            setLoading: "setLoading",
        }),
        submit() {
            this.errors = {};
            this.postProfile(this.data)
                .then((response) => {})
                .catch((error) => {
                    if (error.hasOwnProperty("errors")) {
                        this.errors = error.errors;
                    }
                    // console.log("errror", error);
                });
        },
        copyProfile() {
            if (this.profile.hasOwnProperty("uuid")) {
                this.data.first_name = this.profile.first_name;
                this.data.last_name = this.profile.last_name;
                this.data.email = this.profile.email;
                this.data.preferred_locale = this.profile.preferred_locale;
            }
        },
    },
    mounted() {
        this.getProfile();
        this.copyProfile();
    },
};
</script>

<style></style>
