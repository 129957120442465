<template>
    <div class="w-full flex justify-center">
        <slot> </slot>
    </div>
</template>

<script>
export default {};
</script>

<style></style>
