var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid gap-8"},[_c('h2',{staticClass:"text-2xl lg:text-3xl font-black italic text-r-green text-center leading-normal"},[_vm._v("\n        "+_vm._s(_vm.$t("reservations.summary.room_widget.lead"))),_c('br'),_vm._v("\n        "+_vm._s(_vm.$t("reservations.summary.room_widget.heading", {
                room: _vm.reservation.unit.name,
            }))+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"text-center lg:text-left"},[_vm._v("\n        "+_vm._s(_vm.$t("reservations.summary.room_widget.body"))+"\n    ")]),_vm._v(" "),_c('div',[_c('app-button',{attrs:{"color":"green"},on:{"click":function($event){return _vm.$emit('open')}}},[_vm._v("\n            "+_vm._s(_vm.$t("reservations.summary.room_widget.button"))+"\n        ")])],1),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('div',{staticClass:"grid gap-2 text-sm font-bold"},[_c('div',[_vm._v("\n            "+_vm._s(_vm.$t("new_reservation.offers.offer_card.information"))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"flex gap-2 items-center"},[_c('icon',{staticClass:"stroke-r-black w-5 h-5",attrs:{"icon":"check-circle"}}),_vm._v(" "),_c('span',[_vm._v("\n                "+_vm._s(_vm.$t("reservations.summary.room_widget.checkout_info1", {
                        time: _vm.date(_vm.reservation.departure, "time_localized"),
                    }))+"\n            ")])],1),_vm._v(" "),_c('div',{staticClass:"flex gap-2 items-center"},[_c('icon',{staticClass:"stroke-r-black w-5 h-5",attrs:{"icon":"check-circle"}}),_vm._v(" "),_c('span',[_vm._v("\n                "+_vm._s(_vm.$t("reservations.summary.room_widget.checkout_info2"))+"\n            ")])],1)]),_vm._v(" "),_c('div',{staticClass:"w-full md:hidden"},[_c('app-button',{attrs:{"color":"gray"},on:{"click":function($event){return _vm.$router.push({
                    name: 'bookings.edit.booker',
                    params: _vm.reservationQueryPayload,
                })}}},[_vm._v("\n            "+_vm._s(_vm.$t("reservations.summary.button_booker_change"))+"\n        ")])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('hr',{staticClass:"border-r-gray-light"})])
}]

export { render, staticRenderFns }