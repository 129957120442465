export const bookingDraftTemplate = {
    property: {},
    dates: {
        checkIn: null,
        checkOut: null,
        nights: 1,
        code: null,
    },
    availableServices: [],
    services: [],
    rooms: 1,
    adults: 1,
    offers: [],
    reservations: [],
    purpose: "Business",
    travel_purpose_id: null,
    offer: {},
    booker: {
        firstName: "",
        middleInitial: "",
        lastName: "",
        companyName: "",
        companyTaxId: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        postalCode: "",
        countryCode: "",
        email: "",
        phone: "",
        preferred_locale: "de",
    },
    payment: {},
    confirmation: {},
    amount: 0,
    currency: "EUR",
    uuid: null,
    marketing_signup: false,
};
