<template>
    <div class="relative">
        <full-width-container class="bg-r-green lg:flex lg:py-2 z-30 relative">
            <content-container padding="px-3 py-2 lg:px-12 lg:py-4 z-30">
                <div
                    class="w-full flex flex-col lg:flex-row gap-4 lg:justify-between lg:items-center"
                >
                    <div class="flex justify-between items-center">
                        <div
                            class="text-white font-black italic text-xl flex-grow truncate"
                        >
                            {{ $t("profile.heading", { name: user.name }) }}
                        </div>
                        <div
                            class="lg:hidden"
                            @click="showHamburgerMenu = !showHamburgerMenu"
                        >
                            <icon
                                :icon="
                                    showHamburgerMenu
                                        ? 'chevron-up'
                                        : 'chevron-down'
                                "
                                class="stroke-white w-8 h-8"
                            ></icon>
                        </div>
                    </div>
                    <div
                        class="grid text-white text-base font-bold text lg:hidden divide-y divide-white z-30"
                        v-if="showHamburgerMenu"
                    >
                        <div
                            class="truncate py-3 px-1"
                            v-for="item in items"
                            :key="item.route"
                        >
                            <div
                                v-if="item.hasOwnProperty('route')"
                                class="text-white flex flex-row space-x-2 items-center"
                                active-class="text-r-darkgreen"
                                @click="navigateItem({ name: item.route })"
                            >
                                <span>{{ $t(item.label) }}</span>
                            </div>
                            <button
                                @click="item.click"
                                v-if="item.hasOwnProperty('click')"
                                active-class="text-r-darkgreen"
                                class="text-white flex flex-row space-x-2 items-center"
                            >
                                <span>{{ $t(item.label) }}</span>
                            </button>
                        </div>
                    </div>
                    <div
                        class="lg:justify-self-end grid-cols-2 gap-4 lg:flex lg:flex-row lg:space-x-8 lg:items-center text-white font-bold text-sm lg:text-base hidden"
                    >
                        <div
                            class="flex flex-row gap-2 items-center truncate"
                            v-for="item in items"
                            :key="item.route"
                        >
                            <router-link
                                v-if="item.hasOwnProperty('route')"
                                class="text-white flex flex-row space-x-2 items-center"
                                active-class="text-r-darkgreen"
                                :to="{ name: item.route }"
                            >
                                <span>{{ $t(item.label) }}</span>
                            </router-link>
                            <button
                                @click="item.click"
                                v-if="item.hasOwnProperty('click')"
                                active-class="text-r-darkgreen"
                                class="text-white flex flex-row space-x-2 items-center"
                            >
                                <span>{{ $t(item.label) }}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </content-container>
        </full-width-container>
        <div
            @click="showHamburgerMenu = false"
            class="inset-0 fixed bg-r-black opacity-60 z-10 top-20 transition-all duration-300"
            v-if="showHamburgerMenu"
        ></div>
    </div>
</template>

<script>
import Logo from "@guest/layout/Logo";
import AppInput from "@guest/common/Input";
import AppButton from "@guest/common/Button";
import FullWidthContainer from "@guest/common/FullWidthContainer";
import ContentContainer from "@guest/common/ContentContainer";
import { mapActions, mapState } from "vuex";
import Icon from "@guest/common/Icon.vue";

export default {
    watch: {
        search: {
            handler: function (val) {
                console.log(val);
                this.$emit("input", val);
            },
            immediate: true,
        },
    },
    components: {
        Icon,
        Logo,
        AppInput,
        AppButton,
        FullWidthContainer,
        ContentContainer,
    },
    computed: {
        ...mapState({
            user: "profile",
        }),
    },
    methods: {
        ...mapActions({
            logout: "logout",
        }),
        navigateItem(target) {
            this.showHamburgerMenu = false;
            this.$router.push(target);
        },
    },
    data: function () {
        return {
            showHamburgerMenu: false,
            items: [
                {
                    icon: "calendar",
                    route: "bookings.list",
                    label: "navigation.my_reservations",
                },
                {
                    icon: "login",
                    route: "profile",
                    label: "navigation.profile",
                },
                {
                    icon: "cancel",
                    click: async () => {
                        this.showHamburgerMenu = false;
                        await this.logout();
                        this.$router.push({ name: "login" });
                    },
                    label: "navigation.logout",
                },
            ],
        };
    },
    props: {
        search: {
            type: String,
            required: false,
            default: null,
        },
    },
};
</script>

<style></style>
