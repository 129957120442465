<template>
    <full-width-container class="bg-r-darkgreen">
        <content-container>
            <div
                class="flex flex-row justify-center space-x-10 text-white md:text-xl text-3xl md:p-0 py-4"
            >
                <a href="https://twitter.com/roatelgmbh" target="_blank">
                    <font-awesome-icon
                        :icon="['fab', 'twitter']"
                    ></font-awesome-icon>
                </a>
                <a href="https://www.facebook.com/roatelgmbh" target="_blank">
                    <font-awesome-icon
                        :icon="['fab', 'facebook']"
                    ></font-awesome-icon>
                </a>
                <a href="https://www.instagram.com/roatelgmbh" target="_blank">
                    <font-awesome-icon
                        :icon="['fab', 'instagram']"
                    ></font-awesome-icon>
                </a>
                <a
                    href="https://www.youtube.com/channel/UCKVNkKcz7kgBrThcO0r4tUQ"
                    target="_blank"
                >
                    <font-awesome-icon
                        :icon="['fab', 'youtube']"
                    ></font-awesome-icon>
                </a>
            </div>
        </content-container>
    </full-width-container>
</template>

<script>
import FullWidthContainer from "@guest/common/FullWidthContainer";
import ContentContainer from "@guest/common/ContentContainer";
import AppButton from "@guest/common/Button";
import GuestSideNavigationItems from "@/mixins/GuestSideNavigationItems";
// import NavigationItems from "@/guest/routes/navigation";

export default {
    mixins: [GuestSideNavigationItems],
    components: {
        FullWidthContainer,
        ContentContainer,
        AppButton,
    },
};
</script>

<style></style>
