var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-white rounded-lg border border-r-gray-light p-5 lg:p-10 grid gap-5",attrs:{"id":"checkin_container"}},[_c('div',{staticClass:"flex flex-row items-start gap-8"},[_c('div',{staticClass:"flex flex-col gap-3"},[_c('h2',{staticClass:"text-2xl italic font-black text-r-green text-center lg:text-left"},[_vm._v("\n                "+_vm._s(_vm.$t("reservations.checkin.heading_signature"))+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"text-center lg:text-left"},[_vm._v("\n                "+_vm._s(_vm.$t("reservations.checkin.lead_signature"))+"\n            ")])])]),_vm._v(" "),_c('div',{staticClass:"grid gap-2 font-bold"},[_c('div',{staticClass:"text-r-green text-sm"},[_vm._v("\n            "+_vm._s(_vm.$t("reservations.checkin.heading_signature"))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"relative transition-all duration-300 aspect-w-16 aspect-h-6 border rounded-lg overflow-hidden",class:{
                'border-red-600 bg-red-50': _vm.error !== null,
                'bg-r-gray-ultralight border-r-gray-medium': _vm.error === null,
            }},[_c('VueSignaturePad',{ref:"pad",attrs:{"options":{
                    ..._vm.padOptions,
                    onBegin: _vm.padOnBegin,
                    onEnd: _vm.padOnEnd,
                    backgroundColor: '#F2F2F2',
                },"width":"100%","height":"100%"}})],1)]),_vm._v(" "),(_vm.error)?_c('alert',[_vm._v("\n        "+_vm._s(_vm.error)+"\n    ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"grid gap-4"},[_c('div',{staticClass:"flex justify-center lg:justify-end items-center"},[_c('button',{staticClass:"flex flex-row gap-2 items-center",on:{"click":function($event){return _vm.$refs.pad.clearSignature()}}},[_c('icon',{staticClass:"w-6 h-6 stroke-r-black",attrs:{"icon":"circle-x"}}),_vm._v(" "),_c('span',[_vm._v("\n                    "+_vm._s(_vm.$t("reservations.checkin.button_signature_restart"))+"\n                ")])],1)])]),_vm._v(" "),_c('div',{staticClass:"flex justify-center"},[_c('div',[_c('app-button',{attrs:{"color":"green","outline":false,"size":"xl"},on:{"click":_vm.submit}},[_vm._v("\n                "+_vm._s(_vm.$t("reservations.checkin.complete_checkin"))+"\n            ")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }