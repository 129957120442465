<template>
    <div>
        <booking-draft-state
            @reset="$emit('reset')"
            @back="$emit('offers-list')"
        ></booking-draft-state>
        <content-container>
            <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
                <h1 class="text-xl font-bold text-center md:col-span-3">
                    {{ $t("new_reservation.services.heading") }}
                </h1>
                <div class="text-sm text-center text-gray-500 md:col-span-3">
                    {{ $t("new_reservation.services.lead") }}
                </div>
                <service-card
                    v-for="(service, service_index) in availableServices"
                    :key="service_index"
                    :selectedServices="selectedServices"
                    @toggle="toggleService($event)"
                    :service="service"
                >
                </service-card>

                <div class="flex justify-center mt-4 text-center md:col-span-3">
                    <app-button
                        @click="submit"
                        color="green"
                        :outline="false"
                        size="lg"
                    >
                        {{ $t("buttons.continue") }}
                    </app-button>
                </div>
            </div>
        </content-container>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import ContentContainer from "@admin/common/ContentContainer";
import AppInput from "@admin/common/Input";
import AppButton from "@admin/common/Button";
import DayJS from "@mixins/DayJS";
import ServiceCard from "@guest/bookings/new/ServiceCard";
import BookingDraftState from "@guest/bookings/new/State";

export default {
    data: function () {
        return {
            selectedServices: [],
        };
    },
    components: {
        BookingDraftState,
        ContentContainer,
        AppInput,
        AppButton,
        ServiceCard,
    },
    mixins: [DayJS],
    watch: {
        offer: {
            handler: function (val) {
                if (val !== undefined) {
                    console.log("Change offer", val);
                    if (val.hasOwnProperty("arrival")) {
                        console.log("Change offer has proper value", val);
                        this.getBookingDraftOfferAvailableServices();
                    }
                }
            },
            immediate: true,
        },
        availableServices: {
            handler: function (val) {
                console.log("Change availableServices", val);
                if (Array.isArray(val)) {
                    console.log("Change offer has proper value", val);
                    if (val.length < 1) {
                        console.log("No Services Available, skipping");
                        this.$emit("login");
                    }
                }
            },
            immediate: false,
        },
    },
    methods: {
        toggleService(service) {
            if (
                this.selectedServices.find((item) => item === service) ===
                undefined
            ) {
                this.selectedServices.push(service);
                return;
            }
            let position = this.selectedServices.indexOf(service);
            if (position > -1) {
                this.selectedServices.splice(position, 1);
            }
        },
        ...mapActions({
            getBookingDraftOfferAvailableServices:
                "getBookingDraftOfferAvailableServices",
            setBookingDraftServices: "setBookingDraftServices",
            //setBookingDraftRooms: 'setBookingDraftRooms',
        }),
        submit() {
            this.setBookingDraftServices(this.selectedServices);
            //this.$emit('purpose')
            this.$emit("login");
        },
    },
    computed: {
        //submitDisabled() {
        //return this.offer_index === null ? true : false
        //},
        ...mapState({
            offers: (state) => state.bookingDraft.offers,
            offer: (state) => state.bookingDraft.offer,
            property: (state) => state.bookingDraft.property,
            dates: (state) => state.bookingDraft.dates,
            availableServices: (state) => state.bookingDraft.availableServices,
        }),
    },
    mounted() {
        this.$emit("step", 4);
        this.$emit("booker");
        //this.getBookingDraftOfferAvailableServices()
    },
};
</script>

<style></style>
