export default {
    data: function () {
        return {
            apaleoTranslateFallbackLanguage: 'de',
        }
    },
    methods: {
        apaleoTranslate(field, language) {
            if (field[language]) {
                console.log('Apaleo Translator direct hit ' + field, language)
                return field[language]
            }
            if (field[this.apaleoTranslateFallbackLanguage]) {
                console.log('Apaleo Translator fallback hit ' + field, language)
                return field[this.apaleoTranslateFallbackLanguage]
            }
            return 'Localization not found'
        }
    }
}
