<template>
    <guest-detail-edit
        @save="submit($event)"
        :errors="errors"
        :showButtons="false"
        :person="detail"
    >
    </guest-detail-edit>
</template>

<script>
// import ContentContainer from "@admin/common/ContentContainer";
// import AppInput from "@admin/common/Input";
// import AppButton from "@admin/common/Button";
// import Locales from "@/guest/common/locales";
import GuestDetailEdit from "@guest/profile/edit/GuestDetailEdit";
import { mapState, mapActions } from "vuex";

export default {
    components: {
        // ContentContainer,
        // AppInput,
        // AppButton,
        GuestDetailEdit,
    },
    watch: {
        profile: {
            handler: function () {
                this.copyDetail();
            },
        },
    },
    data: function () {
        return {
            detail: {
                title: "",
                gender: "",
                first_name: "",
                middle_initial: "",
                last_name: "",
                email: "",
                phone: "",
                address_line1: "",
                address_line2: "",
                postal_code: "",
                city: "",
                region_code: "",
                country_code: "",
                nationality_country_code: "",
                identification_number: "",
                identification_issue_date: "",
                identification_type: "",
                company_name: "",
                company_tax_id: "",
                preferred_language: "",
                birth_date: "",
                birth_place: "",
            },
            errors: {},
        };
    },
    computed: {
        ...mapState({
            profile: "profile",
        }),
    },
    methods: {
        submit(data) {
            this.errors = {};
            this.postProfileGuestDetail(data).catch((error) => {
                if (error.response.status === 422) {
                    console.log(error.response.data.errors);
                    this.errors = error.response.data.errors;
                }
            });
        },
        ...mapActions({
            postProfileGuestDetail: "postProfileGuestDetail",
            getProfile: "getProfile",
        }),
        copyDetail() {
            if (
                this.profile.hasOwnProperty("guest_detail") &&
                this.profile.guest_detail !== null
            ) {
                this.detail.first_name = this.profile.guest_detail.first_name;
                this.detail.middle_initial =
                    this.profile.guest_detail.middle_initial;
                this.detail.last_name = this.profile.guest_detail.last_name;
                this.detail.email = this.profile.guest_detail.email;
                this.detail.phone = this.profile.guest_detail.phone;
                this.detail.address_line1 =
                    this.profile.guest_detail.address_line1;
                this.detail.address_line2 =
                    this.profile.guest_detail.address_line2;
                this.detail.postal_code = this.profile.guest_detail.postal_code;
                this.detail.city = this.profile.guest_detail.city;
                this.detail.region_code = this.profile.guest_detail.region_code;
                this.detail.country_code =
                    this.profile.guest_detail.country_code;
                this.detail.nationality_country_code =
                    this.profile.guest_detail.nationality_country_code;
                this.detail.identification_number =
                    this.profile.guest_detail.identification_number;
                this.detail.identification_issue_date =
                    this.profile.guest_detail.identification_issue_date;
                this.detail.identification_type =
                    this.profile.guest_detail.identification_type;
                this.detail.company_name =
                    this.profile.guest_detail.company_name;
                this.detail.company_tax_id =
                    this.profile.guest_detail.company_tax_id;
                this.detail.preferred_language =
                    this.profile.guest_detail.preferred_language;
                this.detail.birth_date = this.profile.guest_detail.birth_date;
                this.detail.birth_place = this.profile.guest_detail.birth_place;
                this.detail.title = this.profile.guest_detail.title;
                this.detail.gender = this.profile.guest_detail.gender;
            }
        },
    },
    mounted() {
        this.getProfile();
        this.copyDetail();
    },
};
</script>

<style></style>
