//import AdminUser from '@admin/admin/user'
import EmptyRouterView from "@guest/layout/EmptyRouterView";
import UserRegister from "@guest/user/Register";
import UserRegisterSuccess from "@guest/user/RegisterSuccess";
import UserLogout from "@guest/user/Logout";
import UserLogin from "@guest/user/Login";
// import UserLoginForgotPassword from "@guest/user/LoginForgotPassword";
import UserHome from "@guest/Home";

import ForgotPassword from "@guest/password/ForgotPassword";
import ResetPassword from "@guest/password/ResetPassword";

import UserBookingsList from "@guest/bookings/list/List";
import UserBookingsNewSearchProperties from "@guest/bookings/new/SearchProperties";
import UserBookingsNewProperties from "@guest/bookings/new/Properties";
import UserBookingsNewDates from "@guest/bookings/new/Dates";
import UserBookingsNewOffers from "@guest/bookings/new/Offers";
import UserBookingsNewServices from "@guest/bookings/new/Services";
import UserBookingsNewPurpose from "@guest/bookings/new/Purpose";
import UserBookingsNewBooker from "@guest/bookings/new/Booker";
import UserBookingsNewSummary from "@guest/bookings/new/Summary";
import UserBookingsNewPayment from "@guest/bookings/new/Payment";
import UserBookingsNewCompleted from "@guest/bookings/new/Completed";
import UserBookingsNewLogin from "@guest/bookings/new/Login";
import UserBookingsNew from "@guest/bookings/new/New";
import UserBookingsNewSetProperty from "@guest/bookings/new/SetProperty";

import UserBookingsEdit from "@guest/bookings/edit/Edit";
import UserBookingsEditSummary from "@guest/bookings/edit/Summary";
import UserBookingsEditBooker from "@guest/bookings/edit/Booker";

import UserBookingsEditCheckIn from "@guest/bookings/edit/Checkin";
import UserBookingsEditCheckInName from "@guest/bookings/edit/CheckinName";
import UserBookingsEditPreCheckIn from "@guest/bookings/edit/PreCheckin";
import UserBookingsEditCheckInStatistics from "@guest/bookings/edit/CheckinStatistics";
import UserBookingsEditCheckInIdentification from "@guest/bookings/edit/CheckinIdentification";
import UserBookingsEditCheckInSignature from "@guest/bookings/edit/CheckinSignature";
import UserBookingsEditCheckInCompleted from "@guest/bookings/edit/CheckinCompleted";

import UserProfileEdit from "@guest/profile/Edit";
import UserProfileEditData from "@guest/profile/edit/Data";
import UserProfileEditGuestDetail from "@guest/profile/edit/GuestDetail";

import ReservationRating from "@guest/bookings/Rating.vue";

import LanguageSelector from "@guest/LanguageSelector";

import CrmSubscribed from "@guest/crm/Subscribed.vue";
import CrmUnknown from "@guest/crm/Unknown.vue";

import Home from "@guest/Home.vue";

import store from "@/guest/store/index";

export default [
    {
        path: "/",
        redirect: "/home",
        name: "index",
    },
    {
        path: "/home",
        component: Home,
        name: "home",
    },
    // {
    //     path: "/home",
    //     redirect: "/bookings/new",
    //     // component: UserHome,
    //     name: "home",
    // },
    {
        path: "/lang/:preselect?",
        component: LanguageSelector,
        name: "lang",
    },
    {
        path: "/forgot-password",
        component: ForgotPassword,
        name: "forgot-password",
    },
    {
        path: "/crm",
        component: EmptyRouterView,
        children: [
            {
                path: "subscribed",
                name: "crm.subscribed",
                component: CrmSubscribed,
            },
            {
                path: "unknown",
                name: "crm.unknown",
                component: CrmUnknown,
            },
        ],
    },
    {
        path: "/reset-password/:token",
        component: ResetPassword,
        name: "reset-password",
    },
    {
        path: "/bookings",
        component: EmptyRouterView,
        name: "bookings",
        redirect: "bookings/new",
        children: [
            {
                path: "rating/:reservation?",
                component: ReservationRating,
                name: "bookings.rating",
            },
            {
                path: "new",
                component: UserBookingsNew,
                name: "bookings.new",
                redirect: { name: "bookings.new.search_properties" },
                children: [
                    {
                        path: "search_properties/locationsearch",
                        component: UserBookingsNewSearchProperties,
                        name: "bookings.new.search_properties.locationsearch",
                    },
                    {
                        path: "property/:property",
                        component: UserBookingsNewSetProperty,
                        name: "bookings.new.set_property",
                    },
                    {
                        path: "search_properties/geosearch/:search",
                        component: UserBookingsNewSearchProperties,
                        name: "bookings.new.search_properties.geosearch",
                    },
                    {
                        path: "search_properties/show/:show?",
                        component: UserBookingsNewSearchProperties,
                        name: "bookings.new.search_properties",
                    },
                    {
                        path: "properties",
                        component: UserBookingsNewProperties,
                        name: "bookings.new.properties",
                    },
                    {
                        path: "dates/:property?/:checkin?/:checkout?",
                        component: UserBookingsNewDates,
                        name: "bookings.new.dates",
                    },
                    {
                        path: "offers",
                        component: UserBookingsNewOffers,
                        name: "bookings.new.offers",
                    },
                    {
                        path: "services",
                        component: UserBookingsNewServices,
                        name: "bookings.new.services",
                    },
                    {
                        path: "purpose",
                        component: UserBookingsNewPurpose,
                        name: "bookings.new.purpose",
                    },
                    {
                        path: "booker",
                        component: UserBookingsNewBooker,
                        name: "bookings.new.booker",
                    },
                    {
                        path: "summary",
                        component: UserBookingsNewSummary,
                        name: "bookings.new.summary",
                    },
                    {
                        path: "payment",
                        component: UserBookingsNewPayment,
                        name: "bookings.new.payment",
                    },
                    {
                        path: "login",
                        component: UserBookingsNewLogin,
                        name: "bookings.new.login",
                    },
                    {
                        path: "completed/:id",
                        component: UserBookingsNewCompleted,
                        name: "bookings.new.completed",
                    },
                ],
            },
            {
                path: "list/:archived?",
                component: UserBookingsList,
                name: "bookings.list",
                //redirect: 'properties/list',

                //children: [{ path: 'list', component: InventoryPropertiesList, name: 'inventory_properties_list' }, { path: 'edit/:uuid', component: InventoryPropertiesEdit, name: 'inventory.properties.edit', props: true, children: [ { path: 'pms', component: InventoryPropertiesEditPms, name: 'inventory.properties.edit.pms', props: true }, { path: 'settings', component: InventoryPropertiesEditSettings, name: 'inventory.properties.edit.settings', props: true }, { path: 'unit_groups', component: InventoryPropertiesEditUnitGroups, name: 'inventory.properties.edit.unit_groups', props: true }, { path: 'units', component: InventoryPropertiesEditUnits, name: 'inventory.properties.edit.units', props: true }, { path: 'history', component: InventoryPropertiesEditHistory, name: 'inventory.properties.edit.history', props: true },  ], }]
            },
            {
                path: "edit/:uuid/:secret?",
                component: UserBookingsEdit,
                name: "bookings.edit",
                redirect: "summary",

                children: [
                    {
                        path: "summary",
                        component: UserBookingsEditSummary,
                        name: "bookings.edit.summary",
                        props: true,
                        //children: [ { path: 'pms', component: InventoryPropertiesEditPms, name: 'inventory.properties.edit.pms', props: true }, { path: 'settings', component: InventoryPropertiesEditSettings, name: 'inventory.properties.edit.settings', props: true }, { path: 'unit_groups', component: InventoryPropertiesEditUnitGroups, name: 'inventory.properties.edit.unit_groups', props: true }, { path: 'units', component: InventoryPropertiesEditUnits, name: 'inventory.properties.edit.units', props: true }, { path: 'history', component: InventoryPropertiesEditHistory, name: 'inventory.properties.edit.history', props: true },  ]
                    },
                    {
                        path: "booker",
                        component: UserBookingsEditBooker,
                        name: "bookings.edit.booker",
                        props: true,
                        //children: [ { path: 'pms', component: InventoryPropertiesEditPms, name: 'inventory.properties.edit.pms', props: true }, { path: 'settings', component: InventoryPropertiesEditSettings, name: 'inventory.properties.edit.settings', props: true }, { path: 'unit_groups', component: InventoryPropertiesEditUnitGroups, name: 'inventory.properties.edit.unit_groups', props: true }, { path: 'units', component: InventoryPropertiesEditUnits, name: 'inventory.properties.edit.units', props: true }, { path: 'history', component: InventoryPropertiesEditHistory, name: 'inventory.properties.edit.history', props: true },  ]
                    },
                    {
                        path: "pre-checkin",
                        component: UserBookingsEditPreCheckIn,
                        name: "bookings.edit.precheckin",
                        props: true,
                        //children: [ { path: 'pms', component: InventoryPropertiesEditPms, name: 'inventory.properties.edit.pms', props: true }, { path: 'settings', component: InventoryPropertiesEditSettings, name: 'inventory.properties.edit.settings', props: true }, { path: 'unit_groups', component: InventoryPropertiesEditUnitGroups, name: 'inventory.properties.edit.unit_groups', props: true }, { path: 'units', component: InventoryPropertiesEditUnits, name: 'inventory.properties.edit.units', props: true }, { path: 'history', component: InventoryPropertiesEditHistory, name: 'inventory.properties.edit.history', props: true },  ]
                    },
                    {
                        path: "checkin",
                        component: UserBookingsEditCheckIn,
                        redirect: "name",
                        name: "bookings.edit.checkin",
                        props: true,
                        children: [
                            {
                                path: "name",
                                component: UserBookingsEditCheckInName,
                                name: "bookings.edit.checkin.name",
                                props: true,
                            },
                            {
                                path: "statistics",
                                component: UserBookingsEditCheckInStatistics,
                                name: "bookings.edit.checkin.statistics",
                                props: true,
                            },
                            {
                                path: "identification",
                                component:
                                    UserBookingsEditCheckInIdentification,
                                name: "bookings.edit.checkin.identification",
                                props: true,
                            },
                            {
                                path: "signature",
                                component: UserBookingsEditCheckInSignature,
                                name: "bookings.edit.checkin.signature",
                                props: true,
                            },
                            {
                                path: "completed",
                                component: UserBookingsEditCheckInCompleted,
                                name: "bookings.edit.checkin.completed",
                                props: true,
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        path: "/register",
        component: EmptyRouterView,
        redirect: "/register/data",
        name: "register.root",
        children: [
            {
                path: "data",
                component: UserRegister,
                name: "register",
            },
            {
                path: "success",
                component: UserRegisterSuccess,
                name: "register.success",
            },
        ],
    },
    {
        path: "/profile",
        component: UserProfileEdit,
        // redirect: "/profile/data",
        name: "profile",
        // children: [
        //     {
        //         path: "data",
        //         component: UserProfileEditData,
        //         name: "profile.edit.data",
        //         //children: [{ path: 'list', component: InventoryPropertiesList, name: 'inventory_properties_list' }, { path: 'edit/:uuid', component: InventoryPropertiesEdit, name: 'inventory.properties.edit', props: true, children: [ { path: 'pms', component: InventoryPropertiesEditPms, name: 'inventory.properties.edit.pms', props: true }, { path: 'settings', component: InventoryPropertiesEditSettings, name: 'inventory.properties.edit.settings', props: true }, { path: 'unit_groups', component: InventoryPropertiesEditUnitGroups, name: 'inventory.properties.edit.unit_groups', props: true }, { path: 'units', component: InventoryPropertiesEditUnits, name: 'inventory.properties.edit.units', props: true }, { path: 'history', component: InventoryPropertiesEditHistory, name: 'inventory.properties.edit.history', props: true },  ], }]
        //     },
        //     {
        //         path: "guestdetail",
        //         component: UserProfileEditGuestDetail,
        //         name: "profile.edit.guestdetail",
        //         //children: [{ path: 'list', component: InventoryPropertiesList, name: 'inventory_properties_list' }, { path: 'edit/:uuid', component: InventoryPropertiesEdit, name: 'inventory.properties.edit', props: true, children: [ { path: 'pms', component: InventoryPropertiesEditPms, name: 'inventory.properties.edit.pms', props: true }, { path: 'settings', component: InventoryPropertiesEditSettings, name: 'inventory.properties.edit.settings', props: true }, { path: 'unit_groups', component: InventoryPropertiesEditUnitGroups, name: 'inventory.properties.edit.unit_groups', props: true }, { path: 'units', component: InventoryPropertiesEditUnits, name: 'inventory.properties.edit.units', props: true }, { path: 'history', component: InventoryPropertiesEditHistory, name: 'inventory.properties.edit.history', props: true },  ], }]
        //     },
        // ],
    },
    {
        path: "/login",
        component: UserLogin,
        name: "login",
    },
    // {
    //     path: "/login/forgot",
    //     component: UserLoginForgotPassword,
    //     name: "login.forgot_password",
    // },
    {
        path: "/logout",
        component: UserLogout,
        name: "logout",
        children: [
            //{
            //path:'properties',
            //component: EmptyRouterView,
            //name: 'inventory_properties',
            //redirect: 'properties/list',
            //children: [{ path: 'list', component: InventoryPropertiesList, name: 'inventory_properties_list' }, { path: 'edit/:uuid', component: InventoryPropertiesEdit, name: 'inventory.properties.edit', props: true, children: [ { path: 'pms', component: InventoryPropertiesEditPms, name: 'inventory.properties.edit.pms', props: true }, { path: 'settings', component: InventoryPropertiesEditSettings, name: 'inventory.properties.edit.settings', props: true }, { path: 'unit_groups', component: InventoryPropertiesEditUnitGroups, name: 'inventory.properties.edit.unit_groups', props: true }, { path: 'units', component: InventoryPropertiesEditUnits, name: 'inventory.properties.edit.units', props: true }, { path: 'history', component: InventoryPropertiesEditHistory, name: 'inventory.properties.edit.history', props: true },  ], }]
            //},
        ],
    },
];
//{ path: '/admin', name: 'admin', children: [
//{ path: 'users', name: 'admin_users', component: AdminUser},
//] },
