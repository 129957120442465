var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('full-width-container',{staticClass:"bg-r-green hidden md:flex py-2"},[_c('content-container',{attrs:{"padding":"px-6 py-5 lg:px-12 lg:py-2"}},[_c('div',{staticClass:"w-full flex flex-row items-center justify-center gap-5 relative"},[_c('div',{staticClass:"absolute inset-y-0 left-0 font-sans text-white font-black italic text-xl flex flex-row items-center"},[_vm._v("\n                    "+_vm._s(_vm.$t("new_reservation.find_roatel"))+"\n                ")]),_vm._v(" "),_c('div',{staticClass:"flex items-center flex-row gap-2"},[_c('div',{staticClass:"flex flex-row items-center space-x-4 flex-grow w-full"},[_c('div',{staticClass:"w-[460px]"},[_c('app-input',{attrs:{"placeholder":_vm.$t(
                                        'new_reservation.find_roatel_input_placeholder'
                                    ),"hasLabel":false,"hasReset":_vm.search.length > 0 ? true : false,"name":"search","value":_vm.search,"icon":"target","icon-click-emit":"geosearch"},on:{"input":function($event){return _vm.$emit('input', $event)},"keyup-enter":function($event){return _vm.$emit('search')},"geosearch":_vm.geolocate},scopedSlots:_vm._u([{key:"before",fn:function(){return [_c('icon',{staticClass:"stroke-r-gray-medium w-6 h-6",attrs:{"icon":"search"}})]},proxy:true}])})],1),_vm._v(" "),_c('div',[_c('app-button',{attrs:{"color":"darkgreen","disabled":_vm.search.length < 2},on:{"click":function($event){return _vm.$emit('search')}}},[_vm._v("\n                                "+_vm._s(_vm.$t("new_reservation.button_search"))+"\n                            ")])],1)])]),_vm._v(" "),_c('div',{staticClass:"absolute right-0 inset-y-0 flex flex-row space-x-5 text-white font-bold text-sm"},[_c('router-link',{staticClass:"flex flex-row space-x-2 cursor-pointer items-center",attrs:{"to":{
                            name: 'bookings.new.search_properties',
                            params: {
                                show: 'map',
                            },
                        }}},[_c('icon',{staticClass:"stroke-white w-6 h-6",attrs:{"icon":"map"}}),_vm._v(" "),_c('div',[_vm._v("\n                            "+_vm._s(_vm.$t("new_reservation.show_map"))+"\n                        ")])],1),_vm._v(" "),_c('button',{staticClass:"flex flex-row space-x-2 cursor-pointer items-center",on:{"click":_vm.getAllProperties}},[_c('div',{staticClass:"inline-flex items-center gap-3"},[_c('div',[_vm._v("\n                                "+_vm._s(_vm.$t("new_reservation.complete_list"))+"\n                            ")]),_vm._v(" "),_c('icon',{staticClass:"stroke-white w-6 h-6",attrs:{"icon":"chevron-right"}})],1)])],1)])])],1),_vm._v(" "),_c('full-width-container',{staticClass:"bg-r-green lg:hidden"},[_c('content-container',[_c('div',{staticClass:"bg-r-green px-0 py-5 grid gap-5"},[_c('div',{staticClass:"text-white font-black text-2xl text-center w-full italic"},[_vm._v("\n                    "+_vm._s(_vm.$t("new_reservation.find_roatel"))+"\n                ")]),_vm._v(" "),_c('div',{staticClass:"flex flex-col items-center gap-5 mb-6"},[_c('app-input',{attrs:{"placeholder":_vm.$t(
                                'new_reservation.find_roatel_input_placeholder'
                            ),"hasLabel":false,"name":"search","value":_vm.search,"icon":"target","iconClickEmit":"location"},on:{"input":function($event){return _vm.$emit('input', $event)},"keyup-enter":function($event){return _vm.$emit('search')},"location":function($event){return _vm.$emit('geosearch')}},scopedSlots:_vm._u([{key:"before",fn:function(){return [_c('icon',{staticClass:"stroke-r-gray-medium w-6 h-6",attrs:{"icon":"search"}})]},proxy:true}])}),_vm._v(" "),_c('app-button',{attrs:{"color":"darkgreen"},on:{"click":function($event){return _vm.$emit('search')}}},[_vm._v("\n                        "+_vm._s(_vm.$t("new_reservation.button_search"))+"\n                    ")])],1),_vm._v(" "),_c('div',{staticClass:"flex items-center justify-around text-white"},[_c('router-link',{staticClass:"flex flex-row gap-2 cursor-pointer items-center",attrs:{"to":{
                            name: 'bookings.new.search_properties',
                            params: {
                                show: 'map',
                            },
                        }}},[_c('icon',{staticClass:"stroke-white w-6 h-6",attrs:{"icon":"map"}}),_vm._v(" "),_c('div',[_vm._v("\n                            "+_vm._s(_vm.$t("new_reservation.show_map"))+"\n                        ")])],1),_vm._v(" "),_c('button',{staticClass:"flex flex-row space-x-2 cursor-pointer items-center",on:{"click":_vm.getAllProperties}},[_c('div',{staticClass:"inline-flex items-center gap-3"},[_c('div',[_vm._v("\n                                "+_vm._s(_vm.$t("new_reservation.complete_list"))+"\n                            ")]),_vm._v(" "),_c('icon',{staticClass:"stroke-white w-6 h-6",attrs:{"icon":"chevron-right"}})],1)])],1)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }