<template>
    <div class="relative flex flex-col min-h-screen">
        <header class="border-b border-gray-200 bg-gray-50">
            <div class="max-w-7xl space-y-2" :class="[contentClass]">
                <div class="text-xl font-medium tracking-wide select-none">
                    <slot name="title">
                        {{ title }}
                    </slot>
                </div>
                <div class="flex flex-row space-x-2" v-if="$slots.buttons">
                    <slot name="buttons"></slot>
                </div>
            </div>
        </header>
        <main>
            <div class="flex-grow" :class="contentClass">
                <slot>content missing</slot>
            </div>
        </main>
        <div
            v-if="loading"
            class="absolute inset-0 bg-gray-500 opacity-50 transition-all duration-150"
        ></div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        title: {
            required: false,
            default: "Titel",
        },
        contentClass: {
            required: false,
            default: "px-2 py-2 sm:px-8 sm:py-4",
        },
        loading: {
            required: false,
            default: false,
        },
    },
};
</script>

<style></style>
