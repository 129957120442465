<template>
    <div v-if="initialized">
        <booking-draft-state
                @reset="$emit('reset')"
                @back="$emit('offers-list')"
            ></booking-draft-state>
        <content-container>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div class="font-bold text-center md:col-span-2">
                    Anlass Ihrer Reise
                </div>
                <div class="text-sm text-center text-gray-500 md:col-span-2">
                    Platzhalter Text weshalb wir wissen wollen welchen Anlass die Reise hat
                </div>
                <div class="grid grid-cols-2 md:col-span-2 gap-4">
                    <div
                        @click="submit(purpose)"
                        v-for="purpose in purposes"
                        :key="purpose.id"
                        class="flex flex-row items-center justify-center p-2 uppercase bg-gray-100 border border-gray-400 rounded-lg shadow cursor-pointer space-x-2">
                        <div>
                            <font-awesome-icon v-if="purpose.icon !== null" :icon="purpose.icon"></font-awesome-icon>
                        </div>
                        <div>
                            {{$t('new_reservation.purposes.' + purpose.trans_label)}}
                        </div>
                    </div>
                </div>
            </div>
        </content-container>
    </div>
</template>

<script>

import {mapActions, mapGetters, mapState} from 'vuex'
import ContentContainer from '@admin/common/ContentContainer'
import AppInput from '@admin/common/Input'
import AppButton from '@admin/common/Button'
import DayJS from '@mixins/DayJS'
import BookingDraftState from '@guest/bookings/new/State'

export default {
    data: function() {
        return {
            initialized: false,
            purposes: [],
        }
    },
    components: {
        BookingDraftState,
        ContentContainer,
        AppInput,
        AppButton,
    },
    mixins: [
        DayJS,
    ],
    methods: {
        ...mapActions({
            setBookingDraftPurpose : 'setBookingDraftPurpose',
            getBookingDraftTravelPurposes : 'getBookingDraftTravelPurposes',
        }),
        submit(purpose) {
            this.setBookingDraftPurpose(purpose)
            //this.setBookingDraftRooms(this.rooms)
            this.$emit('booker')
        },
    },
    computed: {
        //submitDisabled() {
            //return this.offer_index === null ? true : false
        //},
        ...mapState({
            offers: state => state.bookingDraft.offers,
            offer: state => state.bookingDraft.offer,
            property: state => state.bookingDraft.property,
            dates: state => state.bookingDraft.dates,
            availableServices: state => state.bookingDraft.availableServices,
        })
    },
    mounted() {
        this.getBookingDraftTravelPurposes()
            .then(response => {
                this.purposes = response.data.data
                this.initialized = true
            })
            .catch(error => {
                console.error(error)
            })
    },

}
</script>

<style>

</style>
