<template>
  <div class="flex flex-row">
      Empty HeaderButtons.vue
      <router-view name="header_buttons">buttons</router-view>
  </div>
</template>

<script>
export default {
    components: {}
}
</script>

<style>

</style>
