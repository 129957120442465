<template>
    <div>
        <Modal
            :showHideButton="true"
            ref="locale_selector"
            width="w-5/6 lg:w-1/3"
            @closed="open = false"
        >
            <template v-slot:title></template>
            <template v-slot:body>
                <div class="lg:px-10 lg:py-10 py-5 px-2 grid lg:gap-16 gap-5">
                    <div>
                        <h2
                            class="text-2xl lg:text-4xl font-black text-r-green italic text-center truncate"
                        >
                            {{ $t("languages.title") }}
                        </h2>
                    </div>

                    <div class="relative text-r-black">
                        <div class="text-sm font-bold text-r-green mb-2">
                            {{ $t("languages.my_language") }}
                        </div>
                        <div
                            @click="open = !open"
                            :class="open ? 'rounded-t-lg' : 'rounded-lg'"
                            class="flex flex-row justify-between items-center text-lg px-4 py-3 border border-r-gray-medium"
                        >
                            <div class="select-none">
                                <span v-if="!open">
                                    {{ locale.original }}
                                </span>
                                <span v-else class="text-r-gray-medium">
                                    {{ $t("languages.lead") }}
                                </span>
                            </div>
                            <div>
                                <icon
                                    icon="chevron-down"
                                    class="stroke-r-black w-6 h-6"
                                ></icon>
                            </div>
                        </div>
                        <div
                            v-if="open"
                            class="border border-r-gray-medium rounded-b-lg"
                        >
                            <div
                                @click="setLanguage(locale_option.key)"
                                v-for="locale_option in locales"
                                class="px-4 py-3 hover:text-white hover:bg-r-green cursor-pointer"
                            >
                                {{ locale_option.original }}
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import Modal from "@guest/common/Modal";
import AppButton from "@guest/common/Button";
import Icon from "@guest/common/Icon";

export default {
    components: {
        Modal,
        AppButton,
        Icon,
    },
    data: function () {
        return {
            open: false,
        };
    },
    methods: {
        ...mapActions({
            setLocale: "setLocale",
        }),
        async setLanguage(lang) {
            await this.setLocale(lang);
            this.$refs.locale_selector.close();
        },
    },
    watch: {},
    computed: {
        ...mapGetters({
            locale: "currentLocale",
        }),
        ...mapState({
            locales: "locales",
        }),
    },
    mounted() {},
    props: {},
};
</script>

<style lang="scss"></style>
