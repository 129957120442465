<template>
    <div v-show="booking">
        <full-width-container class="bg-r-green">
            <content-container padding="lg:py-4 lg:px-12 py-3">
                <stepper :current_step="current_step">
                    {{ $t("navigation.footer.book_roatel") }}
                </stepper>
            </content-container>
        </full-width-container>
        <full-width-container class="bg-r-gray-ultralight">
            <content-container>
                <div class="flex flex-col lg:flex-row gap-8">
                    <div class="w-full lg:w-1/3 flex flex-col gap-10">
                        <new-booking-summary-sidebar
                            :property="property"
                            :booking="booking"
                            :show-dates="true"
                            :show-room-count="true"
                            :show-total-amount="true"
                            :show-information="true"
                            :show-imprint-link="true"
                            :show-guests="true"
                            :show-booker="true"
                            :show-open-in-map="true"
                            :show-address="true"
                            :show-carousel="true"
                            :swiper-toggle-visible="true"
                            :swiper-initial-status="false"
                        >
                            <template v-slot:before>
                                <router-link
                                    class="group inline-flex gap-3 text-r-gray items-center"
                                    :to="{
                                        name: 'bookings.new.booker',
                                    }"
                                >
                                    <icon
                                        icon="arrow-narrow-left"
                                        class="stroke-r-gray w-6 h-6 group-hover:stroke-r-green"
                                    ></icon>
                                    <span
                                        class="group-hover:text-r-green leading-none"
                                        >{{
                                            $t("new_reservation.booker.heading")
                                        }}</span
                                    ></router-link
                                >
                            </template>
                        </new-booking-summary-sidebar>
                    </div>
                    <div
                        class="w-full lg:w-2/3 flex flex-col gap-10"
                        id="payment_container"
                    >
                        <div>
                            <div
                                class="bg-white lg:px-8 lg:py-16 px-3 py-2 rounded-lg border border-r-gray-light grid gap-8"
                            >
                                <h2
                                    class="text-xl lg:text-2xl italic pb-4 font-black text-center text-r-green py-7"
                                >
                                    {{ $t("new_reservation.payment.heading") }}
                                </h2>
                                <alert v-if="payment_refused" class="mb-6">
                                    {{
                                        $t(
                                            "new_reservation.payment.payment_refused"
                                        )
                                    }}
                                </alert>
                                <alert
                                    v-if="put_booking_errors.length > 0"
                                    class="mb-6"
                                >
                                    <div
                                        v-for="put_booking_error in put_booking_errors"
                                    >
                                        {{ put_booking_error }}
                                    </div>
                                </alert>
                                <alert v-if="payment_error" class="mb-6">
                                    {{
                                        $t(
                                            "new_reservation.payment.payment_error"
                                        )
                                    }}
                                </alert>

                                <div
                                    v-if="payment_starting"
                                    class="mb-6 flex flex-row text-lg space-x-3 text-center p-4 text-white"
                                >
                                    <div>
                                        <font-awesome-icon
                                            icon="spinner"
                                            spin
                                        ></font-awesome-icon>
                                    </div>
                                    <div>
                                        {{
                                            $t(
                                                "new_reservation.payment.payment_starting"
                                            )
                                        }}
                                    </div>
                                </div>
                                <div
                                    v-if="payment_pending"
                                    class="mb-6 flex flex-row text-lg space-x-3 text-center p-4 text-white"
                                >
                                    <div>
                                        <font-awesome-icon
                                            icon="spinner"
                                            spin
                                        ></font-awesome-icon>
                                    </div>
                                    <div>
                                        {{
                                            $t(
                                                "new_reservation.payment.payment_pending"
                                            )
                                        }}
                                    </div>
                                </div>
                                <div id="dropin-container" ref="adyen"></div>
                                <div class="flex justify-center py-4">
                                    <app-button
                                        v-if="
                                            !start_payment &&
                                            hasPaymentPending === false &&
                                            !payment_starting
                                        "
                                        @click="startPayment"
                                        color="green"
                                        size="xl"
                                        >{{
                                            $t(
                                                "new_reservation.payment.button_start_payment"
                                            )
                                        }}</app-button
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <property-imprint-modal ref="propertyImprintModal">
                </property-imprint-modal>
            </content-container>
        </full-width-container>
    </div>
</template>

<script>
import AppButton from "@guest/common/Button";
import DayJS from "@mixins/DayJS";
import BookingPaymentAmount from "@mixins/BookingPaymentAmount";

import ContentContainer from "@guest/common/ContentContainer";
import { mapState, mapActions, mapGetters } from "vuex";
import Currency from "@mixins/Currency";

import AdyenCheckout from "@adyen/adyen-web";
import "@adyen/adyen-web/dist/adyen.css";
import FullWidthContainer from "@guest/common/FullWidthContainer.vue";
import PropertyCard from "@guest/bookings/new/PropertyCard";
import Stepper from "@guest/bookings/new/Stepper";
import RequiredFieldInfo from "@guest/common/RequiredFieldInfo";
import BookingBackLink from "@guest/common/BookingBackLink.vue";
import BookingSummarySidebar from "@guest/bookings/new/BookingSummarySidebar.vue";
import Alert from "@guest/common/Alert.vue";
import PropertyImprintModal from "@guest/common/PropertyImprintModal.vue";
import BookingPropertyLabel from "@guest/common/BookingPropertyLabel.vue";
import NewBookingSummarySidebar from "@guest/common/NewBookingSummarySidebar.vue";
import Icon from "@guest/common/Icon";

export default {
    mixins: [DayJS, Currency, BookingPaymentAmount],
    components: {
        ContentContainer,
        AppButton,
        FullWidthContainer,
        PropertyCard,
        Stepper,
        RequiredFieldInfo,
        BookingBackLink,
        BookingSummarySidebar,
        Alert,
        PropertyImprintModal,
        BookingPropertyLabel,
        NewBookingSummarySidebar,
        Icon,
    },
    watch: {},
    data: function () {
        return {
            start_payment: false,
            initialized: true,
            errors: {},
            loading: false,
            payment_methods: [],
            payment_checkout: null,
            payment_dropin: null,
            amount: 10000,
            //payment_currency: 'EUR',
            payment_uuid: null,
            payment_result: null,
            payment_config: {},
            payment_pending: true,
            payment_error: false,
            payment_starting: false,
            payment_refused: false,
            payment_refused_code: "",
            put_booking_errors: [],
        };
    },
    computed: {
        ...mapState({
            booking: "bookingDraft",
            property: (state) => state.bookingDraft.property,
            offer: (state) => state.bookingDraft.offer,
            booker: (state) => state.bookingDraft.booker,
            dates: (state) => state.bookingDraft.dates,
            reservationsStore: (state) => state.bookingDraft.reservations,
            profile: (state) => state.profile,
        }),
        ...mapGetters({
            currentLocale: "currentLocale",
        }),
        // payment_amount() {
        //     console.log("Payment_Amound computed", this.booking.offer);
        //     if (this.booking.offer) {
        //         let amount = 0;
        //         console.log("calculateBooking", amount);
        //         //Rooms
        //         console.log("Calculating Booking Services before room", amount);
        //         amount +=
        //             this.booking.rooms *
        //             this.booking.offer.totalGrossAmount.amount;
        //         console.log("Calculating Booking Services after room", amount);
        //
        //         this.booking.services.forEach((service) => {
        //             console.log(
        //                 "Calculating Booking Services",
        //                 service,
        //                 amount
        //             );
        //             amount +=
        //                 this.booking.rooms * service.totalAmount.grossAmount;
        //         });
        //
        //         if (this.booking.offer.hasOwnProperty("cityTaxes")) {
        //             this.booking.offer.cityTaxes.forEach((tax) => {
        //                 console.log(
        //                     "Calculating Booking CityTaxes",
        //                     tax,
        //                     amount
        //                 );
        //                 amount += tax.totalGrossAmount.amount;
        //             });
        //         }
        //         return amount;
        //     }
        //     return 0;
        // },
        payment_currency() {
            if (this.booking.offer) {
                return this.booking.offer.totalGrossAmount.currency;
            }
            return "EUR";
        },
        browserInfo() {
            const date = new Date();
            const offset = date.getTimezoneOffset();
            return {
                acceptHeader:
                    "text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8",
                language: navigator.language,
                colorDepth: screen.colorDepth,
                javaEnabled: false,
                screenHeight: screen.height,
                screenWidth: screen.width,
                timeZoneOffset: offset,
                userAgent: navigator.userAgent,
            };
        },
        hasPaymentPending() {
            return this.$route.query.hasOwnProperty("redirectResult")
                ? true
                : false;
        },
    },
    methods: {
        ...mapActions({
            setBookingDraftPayment: "setBookingDraftPayment",
            setLoading: "setLoading",
        }),
        async startPayment() {
            this.payment_starting = true;
            await this.loadPaymentMethods();
            this.start_payment = true;
        },
        async handlePayment(state, dropin) {
            await axios
                .post("bookings/payment", {
                    amount: this.payment_amount * 100,
                    currency: this.payment_currency,
                    paymentMethod: state.data.paymentMethod,
                    storePaymentMethod: state.data.storePaymentMethod,
                    reference: this.booking.uuid,
                    property: this.booking.property,
                    browserInfo: this.browserInfo,
                    shopperEmail: this.booking.booker.email,
                    shopperReference: this.profile.hasOwnProperty("uuid")
                        ? this.profile.uuid
                        : null,
                })
                .then((response) => {
                    console.log(response);
                    this.handlePaymentResult(dropin, response);
                })
                .catch((error) => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    }
                })
                .then(() => {
                    this.loading = false;
                });
        },
        async putBooking() {
            this.setLoading(true);
            this.put_booking_errors = [];
            await axios
                .put("bookings", this.booking)
                .then((response_booking) => {
                    console.log(response_booking);
                    this.$emit("completed", response_booking.data);
                })
                .catch((error_booking) => {
                    if (
                        error_booking.response.data.hasOwnProperty(
                            "original_exception"
                        )
                    ) {
                        if (
                            error_booking.response.data.original_status_code ===
                            422
                        ) {
                            this.put_booking_errors =
                                error_booking.response.data.original_body.messages;
                        } else {
                            this.put_booking_errors.push("unknown_error");
                        }
                    } else {
                        this.put_booking_errors.push("unknown_error");
                    }
                    console.log("put_booking_errors", this.put_booking_errors);
                })
                .then(() => {
                    this.setLoading(false);
                });
        },
        async loadPaymentMethods() {
            this.loading = true;
            console.log("booking draft", this.booking.property.uuid);
            await axios
                .post("bookings/payment_methods", {
                    property: this.booking.property.uuid,
                    amount: this.payment_amount,
                    shopperLocale: this.currentLocale.locale,
                    shopperReference: this.profile.hasOwnProperty("uuid")
                        ? this.profile.uuid
                        : null,
                })
                .then((response) => {
                    console.log(response);
                    this.payment_uuid = response.data.payment_uuid;
                    this.payment_methods = response.data.payment_methods;

                    this.createDropin(); //, this, 100, 'EUR')
                })
                .catch((error) => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    }
                })
                .then(() => {
                    this.loading = false;
                });
        },
        async finalizeBooking(payment_data) {
            console.log("finalize booking");
            this.setBookingDraftPayment(payment_data);
            await this.putBooking();
        },
        async loadPaymentDetails(payload) {
            console.log("loadpaymentdetails payload", payload);
            this.setLoading(true);
            this.payment_pending = true;
            await axios
                .post("bookings/payment_details", {
                    property: this.booking.property.uuid,
                    ...payload,
                })
                .then((response) => {
                    this.handlePaymentResult(this.payment_dropin, response);
                    console.log("loadPaymentDetails", response.data);
                })
                .catch((error) => {
                    this.payment_pending = false;
                    this.payment_error = true;
                    this.startPayment();
                    console.log("loadPaymentDetails", error.response);
                })
                .then(() => {
                    this.payment_pending = false;
                    this.setLoading(false);
                });
        },
        async handlePaymentResult(dropin, response) {
            if (this.start_payment === false) {
                await this.startPayment();
            }
            this.payment_refused_reason = null;
            this.payment_refused = false;
            this.payment_error = false;
            console.log("handlePaymentResult, response");
            this.payment_result = response.data.resultCode;
            if (response.data.action) {
                console.log("action present", response.data);
                dropin.handleAction(response.data.action);
            } else if (response.data.resultCode === "Authorised") {
                console.log("Authorised");
                this.finalizeBooking(response.data);
            } else {
                // (response.data.resultCode === "Refused")
                console.log(response.data.resultCode);
                this.payment_refused = true;
                this.payment_refused_reason = response.data.refusalReason;
                if (dropin) {
                    dropin.setStatus(response.data.resultCode);
                }
            }
        },
        async handleRedirectResult() {
            console.log("handleRedirectResult onready", this.$route);
            if (this.$route.query.hasOwnProperty("redirectResult")) {
                console.log("ReturnURL present", this.$route.query);
                this.loadPaymentDetails({
                    details: {
                        redirectResult: this.$route.query.redirectResult,
                    },
                });
            }
        },
        async removeStoredPaymentMethod(
            storedPaymentMethodId,
            resolve,
            reject
        ) {
            console.log(storedPaymentMethodId, resolve, reject);
            this.payment_error = null;
            await axios
                .post("bookings/remove_stored_payment_method", {
                    storedPaymentMethodId,
                    property: this.booking.property.uuid,
                })
                .then((response) => {
                    resolve();
                })
                .catch((error) => {
                    this.payment_error =
                        error.data?.error ?? "Error has occured";
                    reject(error.data);
                });
        },
        async createDropin() {
            this.payment_config = {
                paymentMethodsResponse: this.payment_methods,
                clientKey: this.booking.property.payment_frontend_api_key,
                locale: this.currentLocale.locale,
                // setStatusAutomatically: false,
                environment: process.env.MIX_PAYMENT_ENVIRONMENT,
                onSubmit: (state, dropin) => {
                    console.log("payment onSubmit", state, dropin);
                    console.log("this context on submit", this);
                    this.handlePayment(state, dropin);
                },
                //onChange: (state, dropin) => {
                //console.log('payment onChange', state, dropin)
                //},
                onError: (error) => {
                    console.log("adyen error", error);
                },
                onAdditionalDetails: (state, dropin) => {
                    this.loadPaymentDetails(state.data);
                    console.log("payment onAdditionalDetails", state, dropin);
                },
                amount: {
                    value: this.payment_amount * 100,
                    currency: this.payment_currency,
                },
                enableRecurring: true,
                shopperReference: this.profile.hasOwnProperty("uuid")
                    ? this.profile.uuid
                    : null,
                shopperInteraction: "Ecommerce",
                recurringProcessingModel: "UnscheduledCardOnFile",
                configuration: {
                    enableStoreDetails: true,
                },
                paymentMethodsConfiguration: {
                    card: {
                        hasHolderName: true,
                        holderNameRequired: true,
                        //name : 'Kreditkarte',
                        enableStoreDetails: true,
                        enableStoreDetails: this.profile.hasOwnProperty("uuid")
                            ? true
                            : false,
                        showRemovePaymentMethodButton: true,
                    },
                },
                allowPaymentMethods: ["scheme", "paypal"],
            };
            // console.log("payment config", this.config);

            this.payment_checkout = await AdyenCheckout(this.payment_config);

            this.payment_dropin = this.payment_checkout
                .create("dropin", {
                    // Starting from version 4.0.0, Drop-in configuration only accepts props related to itself and cannot contain generic configuration like the onSubmit event.
                    openFirstPaymentMethod: false,
                    threeDS2: {
                        challengeWindowSize: "05",
                    },
                    showRemovePaymentMethodButton: true,
                    onDisableStoredPaymentMethod:
                        this.removeStoredPaymentMethod,
                    onReady: () => {
                        this.payment_starting = false;
                        this.$scrollTo("#payment_container", 200);
                    },
                })
                .mount(this.$refs.adyen);
        },
    },
    props: {
        current_step: {
            required: true,
            default: 5,
        },
        //booking: {
        //required: true,
        //type: Object,
        //},
    },
    mounted() {
        this.$scrollTo("#payment_container", 1000);
        this.$emit("step", 5);
        if (this.hasPaymentPending === false) {
            this.payment_pending = false;
            this.startPayment();
        }
        if (this.hasPaymentPending === true) {
            this.handleRedirectResult();
        }
        console.log("payment module mounted");
    },
};
</script>

<style></style>
