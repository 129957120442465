<template>
    <div>
        <content-container>
            <div class="flex flex-col space-y-6">
                <h1 class="text-xl font-bold text-center">
                    {{ $t('new_reservation.set_property.heading') }}
                </h1>
                <app-alert v-if="error" color="red">
                    {{error}}
                </app-alert>
            </div>
        </content-container>
    </div>
</template>

<script>

import ContentContainer from '@admin/common/ContentContainer'
import AppInput from '@admin/common/Input'
import AppButton from '@admin/common/Button'
import AppAlert from '@admin/common/Alert'
import {mapActions, mapState} from 'vuex'

export default {
    components: {
        ContentContainer,
        AppButton,
        AppInput,
        AppAlert,
    },
    data: function() {
        return {
            error: '',
        }
    },
    watch: {
        '$route.params.property': {
            handler: function (val) {
                console.log('property change', val)
                this.getPropertyById()
            }
        }
    },
    computed: {
    },
    methods: {
        async getPropertyById() {
            this.setLoading(true)
            this.error = ''
            await axios.get('bookings/get_property_by_id/' + this.$route.params.property)
                .then(response => {
                    console.log(response.data)
                    this.resetBookingDraft()
                    this.setBookingDraftProperty(response.data.data)
                    this.$emit('dates')

                    //this.$emit('properties-list')
                })
                .catch(error => {
                    this.error = this.$t('new_reservation.set_property.error', {property: this.$route.params.property})
                    console.log(error)
                })
                .then(() => {
                    this.setLoading(false)

                })
        },
        ...mapActions({
            setLoading: 'setLoading',
            setBookingDraftProperty: 'setBookingDraftProperty',
            resetBookingDraft: 'resetBookingDraft',
        }),
    },
    mounted() {
        this.getPropertyById()
    }
}
</script>

<style>

</style>
