import * as de from 'svg-country-flags/svg/de.svg'
import * as en from 'svg-country-flags/svg/gb.svg'
import * as fr from 'svg-country-flags/svg/fr.svg'
import * as tr from 'svg-country-flags/svg/tr.svg'
import * as pl from 'svg-country-flags/svg/pl.svg'
import * as es from 'svg-country-flags/svg/es.svg'
import * as nl from 'svg-country-flags/svg/nl.svg'
import * as pt from 'svg-country-flags/svg/pt.svg'
import * as it from 'svg-country-flags/svg/it.svg'
import * as ru from 'svg-country-flags/svg/ru.svg'
import * as bg from 'svg-country-flags/svg/bg.svg'

export default [
    {
        key: 'de',
        label: 'languages.de',
        locale: 'de-DE',
        original: 'Deutsch',
        flag: de,
    },
    {
        key: 'en',
        label: 'languages.en',
        locale: 'en-EN',
        original: 'English',
        flag: en,
    },
    {
        key: 'tr',
        label: 'languages.tr',
        locale: 'tr-TR',
        original: 'Türkçe',
        flag: tr,
    },
    {
        key: 'pl',
        label: 'languages.pl',
        original: 'Polski',
        locale: 'pl-PL',
        flag: pl,
    },
    {
        key: 'es',
        label: 'languages.es',
        original: 'Español',
        locale: 'es-ES',
        flag: es,
    },
    {
        key: 'fr',
        label: 'languages.fr',
        original: 'Français',
        locale: 'fr-FR',
        flag: fr,
    },
    {
        key: 'nl',
        label: 'languages.nl',
        original: 'Nederlands',
        locale: 'nl-NL',
        flag: nl,
    },
    {
        key: 'pt',
        label: 'languages.pt',
        original: 'Português',
        locale: 'pt-PT',
        flag: pt,
    },
    {
        key: 'it',
        label: 'languages.it',
        original: 'Italiano',
        locale: 'it-IT',
        flag: it,
    },
    {
        key: 'ru',
        label: 'languages.ru',
        original: 'русский',
        locale: 'ru-RU',
        flag: ru,
    },
    {
        key: 'bg',
        label: 'languages.bg',
        original: 'български',
        locale: 'bg-BG',
        flag: bg,
    },
]
