<template>
  <div>
      <div :class="classes">
          <div
              class="py-1 text-lg font-bold leading-loose uppercase"
              v-if="title !== null">
              <slot name="title">
                  {{title}}
              </slot>
          </div>
          <slot></slot>
      </div>
  </div>
</template>

<script>
export default {
    computed: {
        classes() {
            let classes = [
                'rounded p-4 border shadow border',
                'transition-all duration-150',
                'select-none',
                this.textSize
            ]
            let colors = 'bg-gray-100 border-gray-600 text-gray-600'
            switch (this.color) {
                case 'red' :
                    colors = 'hover:bg-red-150 bg-red-100 border-red-600 text-red-600'
                    break
                case 'blue' :
                    colors = 'hover:bg-blue-150 bg-blue-100 border-blue-600 text-blue-600'
                    break
                case 'green' :
                    colors = 'hover:bg-green-150 bg-green-100 border-green-600 text-green-600'
                    break
                case 'indigo' :
                    colors = 'hover:bg-indigo-150 bg-indigo-100 border-indigo-600 text-indigo-600'
                    break
                case 'purple' :
                    colors = 'hover:bg-purple-150 bg-purple-100 border-purple-600 text-purple-600'
                    break
                case 'yellow' :
                    colors = 'hover:bg-yellow-150 bg-yellow-100 border-yellow-600 text-yellow-600'
                    break
            }
            classes.push(colors)
            return classes
        },
    },
    props: {
        color: {
            required: false,
            default: 'gray'
        },
        textSize: {
            required: false,
            default: 'text-base'
        },
        title: {
            required:false,
            default: null,
        }

    }

}
</script>

<style>

</style>
