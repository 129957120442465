<template>
    <div
        id="checkin_container"
        class="bg-white rounded-lg border border-r-gray-light p-5 lg:p-10 grid gap-5"
    >
        <div class="flex flex-col lg:flex-row gap-8">
            <div class="flex flex-col gap-3">
                <h2
                    class="text-2xl lg:text-2xl italic font-black text-r-green text-center lg:text-left"
                >
                    {{ $t("reservations.checkin.heading_guest_data") }}
                </h2>
                <div class="text-center lg:text-left">
                    {{ $t("reservations.checkin.lead_guest_data") }}
                </div>
            </div>
            <div class="flex lg:justify-end justify-center" v-if="isLoggedIn">
                <div>
                    <app-button @click="setDataFromProfile" color="darkgreen">
                        {{
                            $t("reservations.checkin.button_fill_profile_data")
                        }}
                    </app-button>
                </div>
            </div>
        </div>
        <div class="grid gap-5 lg:grid-cols-2">
            <app-input
                name="first_name"
                v-model="guest.first_name"
                :label="$t('models.guest.first_name')"
                :placeholder="$t('models.guest.first_name')"
                :required="true"
                type="text"
                :errors="errors"
                :loading="loading"
            >
            </app-input>
            <app-input
                name="last_name"
                v-model="guest.last_name"
                :label="$t('models.guest.last_name')"
                :placeholder="$t('models.guest.last_name')"
                :required="true"
                type="text"
                :errors="errors"
                :loading="loading"
            >
            </app-input>
            <app-input
                name="email"
                v-model="guest.email"
                :label="$t('models.guest.email')"
                :placeholder="$t('models.guest.email')"
                :required="true"
                type="text"
                :errors="errors"
                :loading="loading"
            >
            </app-input>
            <app-input
                name="phone"
                v-model="guest.phone"
                :label="$t('models.guest.phone')"
                :placeholder="$t('models.guest.phone')"
                type="phone"
                :errors="errors"
                :required="true"
                :loading="loading"
            >
            </app-input>

            <div class="py-2 col-span-full">
                <hr class="border-r-gray-light" />
            </div>

            <div class="flex flex-col lg:flex-row gap-5 col-span-full">
                <app-input
                    classs="flex-grow"
                    name="address_line1"
                    v-model="guest.address_line1"
                    :label="$t('models.guest.address_line1')"
                    :placeholder="$t('models.guest.address_line1')"
                    :required="true"
                    type="text"
                    :errors="errors"
                    :loading="loading"
                >
                </app-input>
                <app-input
                    class="lg:w-1/3"
                    name="postal_code"
                    v-model="guest.postal_code"
                    :label="$t('models.guest.postal_code')"
                    :placeholder="$t('models.guest.postal_code')"
                    type="text"
                    :required="true"
                    :errors="errors"
                    :loading="loading"
                >
                </app-input>
            </div>
            <app-input
                name="city"
                v-model="guest.city"
                :label="$t('models.guest.city')"
                :placeholder="$t('models.guest.city')"
                :required="true"
                type="text"
                :errors="errors"
                :loading="loading"
            >
            </app-input>
            <app-input
                name="country_code"
                v-model="guest.country_code"
                :label="$t('models.guest.country_code')"
                :placeholder="$t('models.guest.country_code')"
                selectLabelProperty="name"
                selectValueProperty="alpha-2"
                :selectOptions="countries"
                type="selectClassic"
                :errors="errors"
                :loading="loading"
            >
            </app-input>

            <app-input
                class="self-start"
                type="checkbox"
                :stacked="false"
                :stackedReversed="true"
                labelClasses="text-sm"
                :label="$t('models.guest.store_changes_in_profile')"
                name="updateProfile"
                v-if="isLoggedIn"
                v-model="guest.updateProfile"
            >
            </app-input>
            <app-input
                type="checkbox"
                :label="$t('models.guest.create_account')"
                :stacked="false"
                :stackedReversed="true"
                :labelUppercase="false"
                name="create_account"
                v-if="!isLoggedIn"
                v-model="guest.createAccount"
            >
            </app-input>

            <div
                v-if="reservation.has_marketing_signup !== true"
                class="flex flex-row space-x-3"
            >
                <div class="w-8">
                    <app-input
                        name="marketing_signup"
                        type="checkbox"
                        :has-label="false"
                        v-model="guest.has_marketing_signup"
                    ></app-input>
                </div>
                <div class="w-full">
                    <i18n
                        path="new_reservation.summary.marketing_signup"
                        tag="label"
                        @click="
                            guest.has_marketing_signup =
                                !guest.has_marketing_signup
                        "
                        class="text-sm"
                    >
                        <a
                            href="https://www.roatel.com/datenschutz-booking"
                            class="underline underline-offset-1"
                            target="_blank"
                            @click.stop="null"
                            >{{ $t("new_reservation.summary.privacy") }}</a
                        >
                    </i18n>
                </div>
            </div>
        </div>

        <div v-if="error" class="col-span-full">
            <alert>{{ $t("common.form_error") }}</alert>
        </div>
        <div class="flex justify-center">
            <div>
                <app-button
                    @click="submit"
                    color="green"
                    :outline="false"
                    size="xl"
                >
                    {{ $t("buttons.continue") }}
                </app-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import AppInput from "@guest/common/Input";
import AppButton from "@guest/common/Button";
import ReservationQueryPayload from "@mixins/ReservationQueryPayload";
import Countries from "@mixins/Countries";
import ClipContainer from "@guest/common/ClipContainer";
import RequiredFieldInfo from "@guest/common/RequiredFieldInfo";
import Alert from "@guest/common/Alert.vue";
import _ from "lodash";

export default {
    mixins: [ReservationQueryPayload, Countries],
    components: {
        AppInput,
        AppButton,
        ClipContainer,
        RequiredFieldInfo,
        Alert,
    },
    data: function () {
        return {
            errors: {},
            error: false,
            storeChangesToProfile: false,
            guest: {
                first_name: "",
                middle_initial: "",
                last_name: "",
                company_name: "",
                address_line1: "",
                address_line2: "",
                city: "",
                postal_code: "",
                country_code: "",
                email: "",
                phone: "",
                createAccount: false,
                updateProfile: true,
                has_marketing_signup: false,
            },
        };
    },
    methods: {
        ...mapActions({
            postUserReservationCheckInName: "postUserReservationCheckInName",
        }),
        submit() {
            this.errors = {};
            this.error = false;
            this.postUserReservationCheckInName({
                id: this.reservationQueryPayload,
                data: this.guest,
            })
                .then((response) => {
                    this.$emit("reservation", response.data.data);
                    if (response.data.data.travel_purpose_id === null) {
                        this.$router.push({
                            name: "bookings.edit.checkin.statistics",
                            params: this.reservationQueryPayload,
                        });
                    } else {
                        this.$router.push({
                            name: "bookings.edit.checkin.identification",
                            params: this.reservationQueryPayload,
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.error = true;
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    }
                });
        },
        setDataFromReservation() {
            for (const [key, val] of Object.entries(this.guest)) {
                if (this.reservation.primary_guest.hasOwnProperty(key)) {
                    console.log(
                        "setting from reservation",
                        key,
                        this.reservation.primary_guest[key]
                    );
                    console.log(
                        "ends with",
                        _.endsWith(
                            this.reservation.primary_guest[key],
                            "guest.booking.com"
                        )
                    );
                    this.guest[key] = this.reservation.primary_guest[key];
                    if (
                        key === "email" &&
                        _.endsWith(
                            this.reservation.primary_guest[key],
                            "guest.booking.com"
                        )
                    ) {
                        this.guest[key] = null;
                    }
                }
            }
        },
        setDataFromProfile() {
            for (const [key, val] of Object.entries(this.guest)) {
                if (this.profile.guest_detail.hasOwnProperty(key)) {
                    console.log(
                        "setting from profile",
                        key,
                        this.profile.guest_detail[key]
                    );
                    this.guest[key] = this.profile.guest_detail[key];
                }
            }
        },
    },
    computed: {
        ...mapState({
            loading: (state) => state.loading,
            profile: (state) => state.profile,
        }),
        ...mapGetters({
            isLoggedIn: "isLoggedIn",
        }),
    },
    mounted() {
        this.setDataFromReservation();
        this.$scrollTo("#checkin_container", 300);
    },
    props: {
        reservation: {
            required: true,
            type: Object,
        },
    },
};
</script>

<style></style>
