<template>
    <div
        class="relative rounded-lg overflow-hidden"
        :class="[
            backgroundColor,
            isClickable ? 'cursor-pointer' : '',
            borderClass,
        ]"
        @click="$emit('property-select', property)"
    >
        <div v-if="$slots.top" class="px-8 pt-8">
            <slot name="top"> </slot>
        </div>
        <div
            v-if="
                property.hasOwnProperty('images') &&
                property.images &&
                property.images.length > 0 &&
                showImage
            "
        >
            <img
                class="w-full object-cover max-h-60"
                :src="`/images/${property.images[0].uuid}/thumb`"
                alt=""
            />
        </div>
        <div
            v-if="
                property.images &&
                property.images.length > 0 &&
                showCarousel &&
                property.hasOwnProperty('images')
            "
            :class="[swiperVisible ? 'block' : 'hidden lg:block']"
        >
            <carousel
                class="property-carousel"
                :autoplay="true"
                :autoplay-timeout="3500"
                :autoplay-hover-pause="true"
                :per-page="1"
                :navigation-enabled="true"
                :pagination-enabled="true"
                :navigation-prev-label="`<div
                            class='flex items-center justify-center w-8 h-8 rounded-full bg-white border border-r-black hover:border-r-green'
                        >
                            <span
                                class='items-center justify-center text-xl text-r-gray hover:stroke-r-green stroke-r-black w-7 h-7'
                            >
                            ${arrow_left}

                            </span></div>`"
                :navigation-next-label="`<div
                            class='flex items-center justify-center w-8 h-8 rounded-full bg-white border border-r-black hover:border-r-green'
                        >
                            <span
                                class='items-center justify-center text-xl stroke-r-black hover:stroke-r-green w-7 h-7'
                            >
                            ${arrow_right}
                            </span>
                        </div>`"
            >
                <slide :key="image.uuid" v-for="image in property.images">
                    <div
                        class="bg-r-black-5 group hover:bg-r-green aspect-w-16 aspect-h-9 w-full"
                    >
                        <img
                            class="w-full h-full object-center object-cover"
                            :src="`/images/${image.uuid}/full`"
                        />
                    </div>
                </slide>
            </carousel>
        </div>
        <div class="px-5 py-5 lg:p-8 group text-r-black">
            <div v-if="$slots.before" class="pb-5">
                <slot name="before"></slot>
            </div>
            <div class="flex flex-col gap-3 mb-10">
                <div class="relative flex flex-row justify-between">
                    <div class="text-xl font-bold font-sans">
                        Roatel<br />
                        {{ property.name }}
                    </div>
                    <div
                        v-if="swiperToggleVisible"
                        class="stroke-r-gray-medium hover:stroke-r-green lg:hidden"
                        @click="swiperVisible = !swiperVisible"
                    >
                        <div
                            class="bg-r-gray-ultralight rounded-full p-1 flex items-center justify-center"
                        >
                            <icon icon="slide-show" class="w-8 h-8"></icon>
                        </div>
                    </div>
                </div>
                <div
                    class="flex text-sm leading-5 text-r-gray"
                    v-if="showAddress"
                >
                    <div>
                        <div>
                            {{ property.address_line1 }}
                        </div>
                        <div>
                            {{ property.postal_code }} {{ property.city }}
                        </div>
                        <div
                            v-if="showOpenInMap || showDistance"
                            class="text-r-green inline-flex group flex-row cursor-pointer font-bold text-sm space-x-3 items-center"
                            @click.stop="openPropertyInMaps()"
                        >
                            <div class="underline">
                                {{ $t("new_reservation.show_map") }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="self-end flex flex-row items-center justify-between gap-2 bottom-5 text-r-gray text-sm"
            >
                <div v-if="showDistance && property.distance" class="flex-grow">
                    {{
                        $t("new_reservation.property_card.distance", {
                            distance: number(property.distance, 0),
                        })
                    }}
                </div>
                <div v-if="showCheckinUntil" class="mb-5">
                    {{
                        $t("new_reservation.property_card.checkin_until", {
                            time: "23:00",
                        })
                    }}
                </div>
            </div>
            <slot name="after" :property="property"></slot>
            <div v-if="showBookRoomButton" :class="[showBookRoomButtonClass]">
                <div class="mt-5">
                    <app-button @click="$emit('property-select', property)">{{
                        $t("new_reservation.property_card.book_property")
                    }}</app-button>
                </div>
            </div>
            <div
                v-if="showImprintLink"
                class="pt-3 border-t border-r-gray-light"
            >
                <div
                    class="text-xs inline-flex gap-2 text-r-gray-medium items-center hover:cursor-pointer"
                    @click.stop="
                        $refs.propertyImprintModal.$refs.modal_imprint.show(
                            property
                        )
                    "
                >
                    <div class="group-hover:text-r-green">
                        {{ $t("new_reservation.property_card.imprint.link") }}
                    </div>
                    <icon
                        icon="arrow-up-right"
                        class="w-4 h-4 stroke-r-gray-medium group-hover:stroke-r-green"
                    >
                    </icon>
                    <property-imprint-modal
                        ref="propertyImprintModal"
                        class="z-50"
                    >
                    </property-imprint-modal>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Currency from "@mixins/Currency";
import ClipContainer from "@guest/common/ClipContainer";
import Icon from "@guest/common/Icon.vue";
import Modal from "@guest/common/Modal.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import PropertyImprintModal from "@guest/common/PropertyImprintModal.vue";
import AppButton from "@guest/common/Button";
import { arrow_narrow_left, arrow_narrow_right } from "@/guest/common/icons";

export default {
    mixins: [Currency],
    components: {
        ClipContainer,
        Icon,
        Modal,
        Swiper,
        SwiperSlide,
        PropertyImprintModal,
        AppButton,
    },
    data: function () {
        return {
            arrow_left: arrow_narrow_left,
            arrow_right: arrow_narrow_right,
            swiperVisible: true,
            swiperOption: {
                slidesPerView: 1,
                spaceBetween: 15,
                loop: true,
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
                navigation: {
                    nextEl: ".next",
                    prevEl: ".previous",
                },
                autoplay: {
                    delay: 3500,
                    disableOnInteraction: false,
                },
            },
        };
    },
    methods: {
        openPropertyInMaps() {
            let url =
                "https://www.google.com/maps/dir/?api=1&destination=" +
                this.property.lat +
                "," +
                this.property.lng;
            window.open(url, "_blank").focus();
            console.log(url);
        },
        onSwiper(swiper) {
            console.log(swiper);
        },
        onSlideChange() {
            console.log("slide change");
        },
    },
    mounted() {
        this.swiperVisible = this.swiperInitialStatus;
    },
    props: {
        property: {
            required: true,
            type: Object,
        },
        showImage: {
            required: false,
            type: Boolean,
            default: false,
        },
        showCarousel: {
            required: false,
            type: Boolean,
            default: false,
        },
        backgroundColor: {
            required: false,
            default: "bg-white",
        },
        hoverPointerClass: {
            required: false,
            default: "",
        },
        showDistance: {
            required: false,
            default: true,
            type: Boolean,
        },
        showNavigation: {
            required: false,
            default: false,
            type: Boolean,
        },
        showImprintButton: {
            required: false,
            default: false,
            type: Boolean,
        },
        showRoomInformationButton: {
            required: false,
            default: false,
            type: Boolean,
        },
        showCheckinUntil: {
            required: false,
            default: false,
            type: Boolean,
        },
        showOpenInMap: {
            required: false,
            default: false,
            type: Boolean,
        },
        showAddress: {
            required: false,
            default: false,
            type: Boolean,
        },
        isClickable: {
            required: false,
            default: false,
        },
        showDistance: {
            required: false,
            default: false,
        },
        showImprintLink: {
            required: false,
            default: false,
        },
        borderClass: {
            required: false,
            default: "",
        },
        showBookRoomButton: {
            required: false,
            default: false,
        },
        showBookRoomButtonClass: {
            required: false,
            default: "",
        },
        swiperInitialStatus: {
            required: false,
            default: true,
        },
        swiperToggleVisible: {
            required: false,
            default: false,
        },
    },
};
</script>

<style>
.swiper-pagination-bullet-active {
    background-color: #3aaa35;
}
.VueCarousel-navigation-button {
    opacity: 1 !important;
}
.VueCarousel-navigation-prev {
    left: 55px !important;
}
.VueCarousel-navigation-next {
    right: 55px !important;
}
.VueCarousel-pagination {
    position: absolute;
    bottom: 0px;
}
/* .property-carousel .VueCarousel-navigation-button { */
/*     opacity: 1 !important; */
/* } */
</style>
