export const mutations = {
    SET_PROFILE: "SET_PROFILE",
    RESET_PROFILE: "RESET_PROFILE",
    SET_PROFILE_INTERVAL: "SET_PROFILE_INTERVAL",
    SET_INITIALIZED: "SET_INITIALIZED",
    SET_SHOW_HAMBURGER_MENU: "SET_SHOW_HAMBURGER_MENU",
    SET_LOADING: "SET_LOADING",
    SET_SEARCH_RESULT: "SET_SEARCH_RESULT",
    RESET_SEARCH_RESULT: "RESET_SEARCH_RESULT",

    SET_LOCALE: "SET_LOCALE",
    SET_COOKIE_CONSENT: "SET_COOKIE_CONSENT",

    SET_RETURN_TO_PATH: "SET_RETURN_TO_PATH",
    SET_LOGIN_INFORMATION_MESSAGE: "SET_LOGIN_INFORMATION_MESSAGE",

    CURRENT_RESERVATION_SET: "CURRENT_RESERVATION_SET",
    IS_CHECKING_IN_SET: "IS_CHECKING_IN_SET",
    IS_AWAITING_RESERVATION_UPDATE: "IS_AWAITING_RESERVATION_UPDATE",

    BOOKING_DRAFT_SET_ALL: "BOOKING_DRAFT_SET_ALL",
    BOOKING_DRAFT_SET_PROPERTY: "BOOKING_DRAFT_SET_PROPERTY",
    BOOKING_DRAFT_SET_DATES: "BOOKING_DRAFT_SET_DATES",
    BOOKING_DRAFT_SET_SERVICES: "BOOKING_DRAFT_SET_SERVICES",
    BOOKING_DRAFT_SET_PURPOSE: "BOOKING_DRAFT_SET_PURPOSE",
    BOOKING_DRAFT_SET_BOOKER: "BOOKING_DRAFT_SET_BOOKER",
    BOOKING_DRAFT_SET_BOOKER_LOCALE: "BOOKING_DRAFT_SET_BOOKER_LOCALE",
    BOOKING_DRAFT_SET_AVAILABLE_SERVICES:
        "BOOKING_DRAFT_SET_AVAILABLE_SERVICES",
    BOOKING_DRAFT_SET_OFFERS: "BOOKING_DRAFT_SET_OFFERS",
    BOOKING_DRAFT_SET_OFFER: "BOOKING_DRAFT_SET_OFFER",
    BOOKING_DRAFT_SET_ROOMS: "BOOKING_DRAFT_SET_ROOMS",
    BOOKING_DRAFT_SET_RESERVATIONS: "BOOKING_DRAFT_SET_RESERVATIONS",
    BOOKING_DRAFT_SET_RESERVATION_LOCALE:
        "BOOKING_DRAFT_SET_RESERVATION_LOCALE",
    BOOKING_DRAFT_SET_PAYMENT: "BOOKING_DRAFT_SET_PAYMENT",
    BOOKING_DRAFT_SET_CONFIRMATION: "BOOKING_DRAFT_SET_CONFIRMATION",
    BOOKING_DRAFT_SET_UUID: "BOOKING_DRAFT_SET_UUID",
    BOOKING_DRAFT_RESET: "BOOKING_DRAFT_RESET",
    BOOKING_DRAFT_SET_MARKETING_SIGNUP: "BOOKING_DRAFT_MARKETING_SIGNUP",
};
