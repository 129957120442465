<template>
    <div>
        <div
            class="bg-white rounded-lg border border-r-gray-light p-5 lg:p-10 grid gap-5 lg:gap-8"
        >
            <h2
                class="text-2xl italic font-black text-r-green text-center lg:text-left"
            >
                {{ $t("reservations.checkin.heading_statistics") }}
            </h2>
            <div>
                <div class="text-center lg:text-left">
                    {{ $t("reservations.checkin.lead_statistics") }}
                </div>
            </div>
            <div class="grid gap-5 lg:grid-cols-2">
                <app-button
                    color="green"
                    @click="submit(purpose)"
                    v-for="purpose in purposesWithContinue"
                    :key="purpose.id"
                    v-if="purpose.id !== null"
                >
                    {{ $t("new_reservation.purposes." + purpose.trans_label) }}
                </app-button>
            </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8">
            <div class="lg:col-span-3">
                <h1></h1>
                <p class="text-sm text-gray-500"></p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import AppInput from "@guest/common/Input";
import AppButton from "@guest/common/Button";
import ReservationQueryPayload from "@mixins/ReservationQueryPayload";
import ClipContainer from "@guest/common/ClipContainer";
import RequiredFieldInfo from "@guest/common/RequiredFieldInfo.vue";

export default {
    mixins: [ReservationQueryPayload],
    components: {
        AppInput,
        AppButton,
        ClipContainer,
    },
    data: function () {
        return {
            errors: {},
            purposes: [],
        };
    },
    methods: {
        ...mapActions({
            postUserReservationCheckInName: "postUserReservationCheckInName",
            postUserReservationCheckInPurpose:
                "postUserReservationCheckInPurpose",
            getPurposes: "getBookingDraftTravelPurposes",
        }),
        submit(purpose = null) {
            this.errors = {};
            if (purpose === null || purpose.id === null) {
                this.$router.push({
                    name: "bookings.edit.checkin.identification",
                    params: this.reservationQueryPayload,
                });
                return;
            }
            this.postUserReservationCheckInPurpose({
                id: this.reservationQueryPayload,
                data: purpose,
            })
                .then((response) => {
                    this.$emit("reservation", response.data.data);
                    this.$router.push({
                        name: "bookings.edit.checkin.identification",
                        params: this.reservationQueryPayload,
                    });
                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    }
                });
        },
        setDataFromReservation() {
            for (const [key, val] of Object.entries(this.guest)) {
                if (this.reservation.primary_guest.hasOwnProperty(key)) {
                    console.log(
                        "setting from reservation",
                        key,
                        this.reservation.primary_guest[key]
                    );
                    this.guest[key] = this.reservation.primary_guest[key];
                }
            }
        },
        setDataFromProfile() {
            for (const [key, val] of Object.entries(this.guest)) {
                if (this.profile.guest_detail.hasOwnProperty(key)) {
                    console.log(
                        "setting from profile",
                        key,
                        this.profile.guest_detail[key]
                    );
                    this.guest[key] = this.profile.guest_detail[key];
                }
            }
        },
    },
    computed: {
        ...mapState({
            loading: (state) => state.loading,
            profile: (state) => state.profile,
        }),
        ...mapGetters({
            isLoggedIn: "isLoggedIn",
        }),
        purposesWithContinue() {
            return [
                ...this.purposes,
                {
                    id: null,
                    trans_label: null,
                    icon: null,
                },
            ];
        },
    },
    mounted() {
        this.$scrollTo("#statistics_container", 300);
        this.getPurposes()
            .then((response) => {
                this.purposes = response.data.data;
                this.initialized = true;
            })
            .catch((error) => {
                console.error(error);
            });
    },
    props: {
        reservation: {
            required: true,
            type: Object,
        },
    },
};
</script>

<style></style>
