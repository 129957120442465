<template>
    <clip-container v-if="property.images && property.images.length > 0">
        <carousel
            :autoplay="true"
            :autoplay-timeout="3500"
            :autoplay-hover-pause="true"
            :per-page="1"
            :navigation-enabled="true"
            :pagination-enabled="true"
            navigation-prev-label='<div
                        class="flex items-center justify-center w-10 h-10 rounded-full bg-white"
                    >
                        <span
                            class="items-center justify-center text-xl text-r-green hover:text-r-darkgreen"
                        >◀
                        </span>
                    </div>'
            navigation-next-label='<div
                        class="flex items-center justify-center w-10 h-10 rounded-full bg-white"
                    >
                        <span
                            class="items-center justify-center text-xl text-r-green hover:text-r-darkgreen"
                        >▶
                        </span>
                    </div>'
        >
            <slide :key="image.uuid" v-for="image in property.images">
                <div
                    class="bg-r-black-5 group hover:bg-r-green aspect-w-16 aspect-h-9 w-full"
                >
                    <img
                        class="w-full h-full object-center object-cover"
                        :src="`/images/${image.uuid}/full`"
                    />
                </div>
            </slide>
            <!-- <div class="swiper-pagination" v-slot="pagination">bullets</div> -->
            <!-- <div v-slot="navigation"> -->
            <!--     <div -->
            <!--         class="previous absolute left-2 inset-y-0 w-10 z-10 flex items-center justify-end" -->
            <!--         slot="button-prev" -->
            <!--     > -->
            <!--         <div -->
            <!--             class="flex items-center justify-center w-10 h-10 rounded-full bg-white opacity-50" -->
            <!--         > -->
            <!--             <font-awesome-icon -->
            <!--                 class="text-xl text-r-green hover:text-r-darkgreen" -->
            <!--                 icon="chevron-left" -->
            <!--             > -->
            <!--             </font-awesome-icon> -->
            <!--         </div> -->
            <!--     </div> -->
            <!--     <div -->
            <!--         class="next absolute right-2 inset-y-0 w-10 z-10 flex items-center justify-end" -->
            <!--         slot="button-next" -->
            <!--     > -->
            <!--         <div -->
            <!--             class="flex items-center justify-center w-10 h-10 rounded-full bg-white opacity-50" -->
            <!--         > -->
            <!--             <font-awesome-icon -->
            <!--                 class="text-xl text-r-green hover:text-r-darkgreen" -->
            <!--                 icon="chevron-right" -->
            <!--             > -->
            <!--             </font-awesome-icon> -->
            <!--         </div> -->
            <!--     </div> -->
            <!-- </div> -->
        </carousel>
    </clip-container>
</template>

<script>
import ClipContainer from "@guest/common/ClipContainer.vue";
// import SwiperClass, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
// import "swiper/css";
// import "swiper/css/pagination";
import "swiper/css/swiper.css";

export default {
    components: {
        ClipContainer,
        Swiper,
        SwiperSlide,
    },
    data: function () {
        return {
            swiperOption: {
                slidesPerView: 1,
                spaceBetween: 15,
                loop: true,
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
                navigation: {
                    nextEl: ".next",
                    prevEl: ".previous",
                },
                autoplay: {
                    delay: 3500,
                    disableOnInteraction: false,
                },
            },
        };
    },
    props: {
        property: {
            required: true,
            type: Object,
        },
    },
    methods: {
        onSwiper(swiper) {
            console.log(swiper);
        },
        onSlideChange() {
            console.log("slide change");
        },
    },
};
</script>

<style scoped>
.swiper-pagination-bullet-active {
    background-color: #3aaa35;
}
.VueCarousel-navigation-button {
    opacity: 0.8 !important;
}
.VueCarousel-navigation-prev {
    left: 50px !important;
}
.VueCarousel-navigation-next {
    right: 50px !important;
}
.VueCarousel-pagination {
    position: absolute;
    bottom: 0px;
}
</style>
