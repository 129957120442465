var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.hide)?_c('div',{staticClass:"relative flex flex-col lg:flex-row lg:items-center justify-center text-white gap-3 w-full py-3 px-3 lg:py-0 lg:px-0"},[_c('div',{staticClass:"lg:absolute lg:left-0 text-xl lg:text-2xl font-black text-white italic text-center lg:text-left"},[_vm._t("default",function(){return [_vm._v("\n                "+_vm._s(_vm.$t("navigation.footer.book_roatel"))+"\n            ")]})],2),_vm._v(" "),_c('div',{staticClass:"justify-self-center flex flex-row gap-1 lg:gap-3 items-center justify-between flex-grow",class:[_vm.maxWidth ? _vm.maxWidth : 'lg:max-w-2xl w-full px-3']},[_vm._l((_vm.steps),function(step,index){return [_c('div',{key:step.label,staticClass:"relative flex items-center justify-center text-white transition duration-150",class:[
                        'text-r-darkgreen bg-white rounded-full',
                        {
                            'cursor-pointer': step.step <= _vm.current_step,
                            'opacity-50': step.step > _vm.current_step,
                            '': step.step === _vm.current_step,
                        },
                    ],on:{"click":function($event){return _vm.requestRouteChange(step)}}},[_c('icon',{class:[
                            'w-8 h-8 lg:w-8 lg:h-8 p-1 stroke-r-green',
                            step.step > _vm.current_step ? 'opacity-50' : '',
                            _vm.steps.length < _vm.current_step ||
                            _vm.steps.length == _vm.current_step
                                ? 'bg-white'
                                : '',
                        ],attrs:{"icon":step.icon}})],1),_vm._v(" "),(index < _vm.steps.length - 1)?_c('div',{key:index,staticClass:"border-white flex-auto border-t-2 md:border-t-3 transition duration-300",class:{
                        'opacity-50': step.step >= _vm.current_step,
                    }}):_vm._e()]})],2),_vm._v(" "),(_vm.$slots.end)?_c('div',{staticClass:"lg:absolute lg:right-0 text-xl lg:text-2xl font-black text-white italic text-center lg:text-right"},[_vm._t("end",function(){return [_vm._v("\n                "+_vm._s(_vm.$t("navigation.footer.book_roatel"))+"\n            ")]})],2):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }