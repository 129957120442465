var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-5 lg:py-16 lg:px-8 grid gap-5 lg:gap-12",class:[_vm.dummy ? 'opacity-50' : '']},[_c('h2',{staticClass:"text-2xl lg:text-3xl italic pb-4 font-black text-r-green text-center py-4"},[_vm._v("\n        "+_vm._s(_vm.$t("new_reservation.offers.our_offers"))+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"grid grid-cols-1 lg:grid-cols-2 divide-y lg:divide-x lg:divide-y-0",class:[_vm.dummy ? 'divide-r-gray-medium' : 'divide-r-black']},[_c('div',{staticClass:"lg:pr-5 pb-3 lg:pb-0"},[_c('app-input',{attrs:{"type":"onlyValue","name":"roomSelect","required":true,"label":_vm.$t('new_reservation.offers.rooms_count_label')}},[_c('div',{staticClass:"grid grid-cols-2 gap-3"},[_vm._l(([1, 2, 3, 4, 5, 6, 7, 8]),function(room){return (room <= _vm.offer.availableUnits)?_c('app-button',{key:room,attrs:{"color":"darkgreen","opacity":_vm.rooms == room && _vm.equalOffer
                                ? 'opacity-100'
                                : 'opacity-50',"disabled":room > _vm.offer.availableUnits || _vm.dummy},on:{"click":function($event){return _vm.$emit('select', {
                                offer: _vm.offer,
                                rooms: room,
                            })}}},[_vm._v("\n                        "+_vm._s(room)+"\n                        "+_vm._s(_vm.$tc("new_reservation.offers.rooms_label", room))+"\n                    ")]):_vm._e()}),_vm._v(" "),_c('div',{staticClass:"col-span-full"},[(4 > _vm.offer.availableUnits)?_c('alert',{attrs:{"type":"error"}},[_vm._v("\n                            "+_vm._s(_vm.$tc(
                                    "new_reservation.offers.available_rooms_text",
                                    _vm.offer.availableUnits
                                ))+"\n                        ")]):_vm._e()],1)],2)]),_vm._v(" "),(!_vm.dummy)?_c('div',{staticClass:"text-center text-sm"},[_vm._v("\n                "+_vm._s(_vm.$t(
                        "new_reservation.offers.offer_card.available_rooms_count",
                        {
                            available_rooms: _vm.offer.availableUnits,
                            room_count: _vm.property.room_count,
                        }
                    ))+"\n            ")]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"py-5 lg:pl-5"},[(!_vm.dummy)?_c('div',[_c('div',{staticClass:"text-lg font-bold text-r-green"},[_vm._v("\n                    "+_vm._s(_vm.apaleoTranslate(
                            _vm.offer.roatel.unit_group.name_trans,
                            _vm.currentLocale.key
                        ))+"\n                ")]),_vm._v(" "),_c('div',{staticClass:"text-lg font-bold mb-2 text-r-green"},[_vm._v("\n                    "+_vm._s(_vm.currency(
                            _vm.offer.totalGrossAmount.amount /
                                _vm.offer.timeSlices.length,
                            _vm.offer.totalGrossAmount.currency
                        ))+"\n                    "+_vm._s(_vm.$t("new_reservation.dates.per_night"))+"\n                    "),(_vm.offer.cityTaxes && _vm.offer.cityTaxes.length > 0)?_vm._l((_vm.offer.cityTaxes),function(tax){return _c('div',{staticClass:"text-sm"},[_vm._v("\n                            "+_vm._s(_vm.$t(
                                    "new_reservation.offers.offer_card.plus_city_tax_per_night",
                                    {
                                        amount: _vm.currency(
                                            tax.totalGrossAmount.amount /
                                                tax.dates.length,
                                            tax.totalGrossAmount.currency
                                        ),
                                    }
                                ))+"\n                        ")])}):_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"flex flex-row gap-2 flex-wrap pt-4 pb-8",attrs:{"id":"features"}},_vm._l((_vm.features),function(feature){return _c('div',{staticClass:"bg-r-gray-token text-r-gray-pretty-dark py-1 px-2 inline-flex gap-0.5 items-center text-xs rounded-full"},[_c('icon',{staticClass:"stroke-r-gray-pretty-dark w-4 h-4",attrs:{"icon":feature.icon}}),_vm._v(" "),_c('span',[_vm._v("\n                            "+_vm._s(_vm.$t(
                                    "new_reservation.offers.offer_card.feature_list." +
                                        feature.key
                                ))+"\n                        ")])],1)}),0),_vm._v(" "),_c('div',{staticClass:"text-xs grid gap-2"},[_c('div',{staticClass:"font-bold text-sm"},[_vm._v("\n                        "+_vm._s(_vm.$t(
                                "new_reservation.offers.offer_card.information"
                            ))+"\n                    ")]),_vm._v(" "),_c('div',{staticClass:"inline-flex gap-2 items-center"},[_c('icon',{staticClass:"stroke-r-black w-5 h-5",attrs:{"icon":"check-circle"}}),_vm._v(" "),_c('span',[_vm._v("\n                            "+_vm._s(_vm.$t(
                                    "apaleo.cancellationFee." +
                                        _vm.offer.cancellationFee.code +
                                        ".description"
                                ))+"\n                        ")])],1),_vm._v(" "),_c('div',{staticClass:"inline-flex gap-2 items-center"},[_c('icon',{staticClass:"stroke-r-black w-5 h-5",attrs:{"icon":"check-circle"}}),_vm._v(" "),_c('span',[_vm._v("\n                            "+_vm._s(_vm.$t(
                                    "new_reservation.offers.offer_card.price_information"
                                ))+"\n                        ")])],1)])]):_c('div',{staticClass:"animate-pulse opacity-50"},[_c('div',{staticClass:"text-2xl font-bold grid gap-3"},[_c('div',{staticClass:"h-4 w-1/3 bg-r-gray-light"}),_vm._v(" "),_c('div',{staticClass:"h-4 w-2/4 bg-r-gray-light"}),_vm._v(" "),_c('div',{staticClass:"flex flex-wrap gap-3"},_vm._l(([1, 2, 3, 4, 5]),function(i){return _c('div',{key:i,staticClass:"h-4 rounded-full w-2/6 bg-r-gray-light"})}),0),_vm._v(" "),_vm._m(0)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-3 grid gap-2"},[_c('div',{staticClass:"h-3 w-1/5 bg-r-gray-light"}),_vm._v(" "),_c('div',{staticClass:"h-3 w-4/5 bg-r-gray-light"}),_vm._v(" "),_c('div',{staticClass:"h-3 w-4/6 bg-r-gray-light"})])
}]

export { render, staticRenderFns }