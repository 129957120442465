<template>
    <div
        id="checkin_container"
        class="bg-white rounded-lg border border-r-gray-light p-5 lg:p-10 grid gap-5"
    >
        <div class="flex flex-row items-start gap-8">
            <div class="flex flex-col gap-3">
                <h2
                    class="text-2xl italic font-black text-r-green text-center lg:text-left"
                >
                    {{ $t("reservations.checkin.heading_signature") }}
                </h2>
                <div class="text-center lg:text-left">
                    {{ $t("reservations.checkin.lead_signature") }}
                </div>
            </div>
        </div>

        <div class="grid gap-2 font-bold">
            <div class="text-r-green text-sm">
                {{ $t("reservations.checkin.heading_signature") }}
            </div>
            <div
                :class="{
                    'border-red-600 bg-red-50': error !== null,
                    'bg-r-gray-ultralight border-r-gray-medium': error === null,
                }"
                class="relative transition-all duration-300 aspect-w-16 aspect-h-6 border rounded-lg overflow-hidden"
            >
                <VueSignaturePad
                    :options="{
                        ...padOptions,
                        onBegin: padOnBegin,
                        onEnd: padOnEnd,
                        backgroundColor: '#F2F2F2',
                    }"
                    width="100%"
                    height="100%"
                    ref="pad"
                ></VueSignaturePad>
            </div>
        </div>
        <alert v-if="error">
            {{ error }}
        </alert>
        <div class="grid gap-4">
            <div class="flex justify-center lg:justify-end items-center">
                <button
                    class="flex flex-row gap-2 items-center"
                    @click="$refs.pad.clearSignature()"
                >
                    <icon icon="circle-x" class="w-6 h-6 stroke-r-black">
                    </icon>
                    <span>
                        {{
                            $t("reservations.checkin.button_signature_restart")
                        }}
                    </span>
                </button>
            </div>
        </div>
        <div class="flex justify-center">
            <div>
                <app-button
                    @click="submit"
                    color="green"
                    :outline="false"
                    size="xl"
                >
                    {{ $t("reservations.checkin.complete_checkin") }}
                </app-button>
            </div>
        </div>
    </div>
</template>

<script>
import AppButton from "@guest/common/Button";
import { mapActions } from "vuex";
import ReservationQueryPayload from "@mixins/ReservationQueryPayload";
import Alert from "@guest/common/Alert";
import Icon from "@guest/common/Icon";

export default {
    components: {
        AppButton,
        Icon,
        Alert,
    },
    mixins: [ReservationQueryPayload],
    data: function () {
        return {
            isEmpty: true,
            error: null,
            padOptions: {
                dotSize: 2,
                minWidth: 1.5,
                maxWidth: 3,
                penColor: "blue",
                backgroundColor: "rgb(255,255,255)",
            },
        };
    },
    methods: {
        ...mapActions({
            postUserReservationCheckInSignature:
                "postUserReservationCheckInSignature",
            postUserReservationCheckInCheckIn:
                "postUserReservationCheckInCheckIn",
        }),
        padOnBegin() {
            console.log(this);
            this.error = null;
            console.log("Begin");
        },
        padOnEnd() {
            console.log("End");
        },
        submit() {
            let { isEmpty, data } = this.$refs.pad.saveSignature("image/jpeg");
            if (isEmpty) {
                this.error = "Bitte unterschreiben Sie im markierten Bereich";
                return;
            }
            this.postUserReservationCheckInSignature({
                id: this.reservationQueryPayload,
                data: {
                    signature: data,
                },
            })
                .then((response) => {
                    console.log("signature response", response);
                    this.error = null;
                    //okay, lets checkin
                    this.postUserReservationCheckInCheckIn({
                        id: this.reservationQueryPayload,
                    })
                        .then((response) => {
                            this.$router.push({
                                name: "bookings.edit.summary",
                                params: this.reservationQueryPayload,
                            });
                        })
                        .catch((error) => {
                            console.error(error);
                            if (error.response.data?.error) {
                                this.error = error.response.data.error;
                            }
                        });
                })
                .catch((error) => {
                    console.error("signature error", error);
                    if (error.response.data?.error) {
                        this.error = error.response.data.error;
                    }
                });
        },
    },
    props: {
        reservation: {
            required: true,
            type: Object,
        },
    },
    mounted() {
        this.$scrollTo("#checkin_container", 300);
    },
};
</script>

<style></style>
