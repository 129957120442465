<template>
    <div>
        <full-width-container>
            <content-container>
                <div class="w-full">
                    <clip-container class="md:cutter-big">
                        <div
                            class="bg-r-green text-white px-5 py-8 md:p-16 flex flex-col space-y-6"
                        >
                            <h2
                                class="font-slanted text-2xl md:text-4xl italic font-black uppercase"
                            >
                                {{ $t("register_success.heading") }}
                            </h2>
                            <div>
                                <div>
                                    {{ $t("register_success.lead") }}
                                </div>
                            </div>
                            <app-button
                                @click="$router.push({ name: 'home' })"
                                color="green"
                            >
                                {{ $t("register_success.button_home") }}
                            </app-button>
                        </div>
                    </clip-container>
                </div>
            </content-container>
        </full-width-container>
    </div>
</template>

<script>
import AppInput from "@guest/common/Input";
import AppButton from "@guest/common/Button";
import AppAlert from "@guest/common/Alert";
import ContentContainer from "@guest/common/ContentContainer";
import ClipContainer from "@guest/common/ClipContainer.vue";
import FullWidthContainer from "@guest/common/FullWidthContainer.vue";

export default {
    components: {
        ContentContainer,
        ClipContainer,
        FullWidthContainer,
        AppInput,
        AppButton,
        AppAlert,
    },
    data: function () {
        return {
            errors: {},
            user: {
                first_name: "",
                last_name: "",
                email: "",
                phone: "",
                password: "",
                password_repeat: "",
            },
            error: null,
        };
    },
    methods: {},
};
</script>

<style></style>
