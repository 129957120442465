<template>
    <div class="flex flex-col mt-5">
        <!-- Checkin -->
        <div
            v-if="withDates"
            class="text-sm flex flex-row space-x-2 border-t-2 last:border-b-2 border-r-black-50 py-4"
        >
            <div :class="[widthLabel]" class="font-bold">
                {{ $t("new_reservation.dates.check_in_label") }}
            </div>
            <div :class="[widthText]">
                {{
                    $t("common.checkin_datetime", {
                        date: date(booking.offer.arrival, "date_localized"),
                        time: date(booking.offer.arrival, "time_localized"),
                    })
                }}
            </div>
        </div>
        <!-- Checkout -->
        <div
            v-if="withDates"
            class="text-sm flex flex-row space-x-2 border-t-2 last:border-b-2 border-r-black-50 py-4"
        >
            <div :class="[widthLabel]" class="font-bold">
                {{ $t("new_reservation.dates.check_out_label") }}
            </div>
            <div :class="[widthText]">
                {{
                    $t("common.checkout_datetime", {
                        date: date(booking.offer.departure, "date_localized"),
                        time: date(booking.offer.departure, "time_localized"),
                    })
                }}
            </div>
        </div>
        <div
            v-if="withNights"
            class="text-sm flex flex-row space-x-2 border-t-2 last:border-b-2 border-r-black-50 py-4"
        >
            <div :class="[widthLabel]" class="font-bold">
                {{ $t("new_reservation.dates.nights_label") }}
            </div>
            <div :class="[widthText]">
                {{ booking.nights }}
            </div>
        </div>
        <div
            v-if="withRooms && booking.rooms > 1 && !withGuests"
            class="text-sm flex flex-row space-x-2 border-t-2 last:border-b-2 border-r-black-50 py-4"
        >
            <div :class="[widthLabel]" class="font-bold">
                {{ $tc("new_reservation.offers.rooms", booking.rooms) }}
            </div>
            <div :class="[widthText]">
                {{ booking.rooms }}
            </div>
        </div>
        <div
            v-if="withGuests"
            class="text-sm flex flex-row space-x-2 border-t-2 last:border-b-2 border-r-black-50 py-4"
        >
            <div :class="[widthLabel]" class="font-bold">
                <span v-if="booking.reservations.length > 1">
                    {{ booking.rooms }}
                    {{
                        $tc("new_reservation.offers.rooms", booking.rooms_label)
                    }}
                </span>
                <span v-else>
                    {{ $t("new_reservation.summary.guest") }}
                </span>
            </div>
            <div
                :class="[widthText]"
                class="flex flex-col divide-y divide-r-black-50"
            >
                <div
                    class="py-4 first:pt-0 last:pb-0"
                    v-for="(guest, reservation_index) in booking.reservations"
                    :key="reservation_index"
                >
                    <span v-if="booking.reservations.length > 1">
                        {{ reservation_index + 1 }} -
                    </span>

                    {{ guest.primaryGuest.firstName }}
                    {{ guest.primaryGuest.lastName }}
                </div>
            </div>
        </div>
        <!-- booker -->
        <div
            class="text-sm flex flex-row space-x-2 border-t-2 border-r-black-50 py-4"
            v-if="withBooker"
        >
            <div :class="[widthLabel]" class="font-bold truncate">
                {{ $t("new_reservation.booker.heading") }}
            </div>
            <div :class="[widthText]" class="flex flex-col">
                <div class="font-bold">
                    {{ booking.booker.firstName }} {{ booking.booker.lastName }}
                </div>
                <div v-if="booking.booker.companyName">
                    {{ booking.booker.companyName }}
                </div>
                <div>
                    {{ booking.booker.addressLine1 }}
                </div>
                <div>
                    {{ booking.booker.postalCode }} {{ booking.booker.city }}
                </div>
                <div v-if="booking.booker.countryCode != 'DE'">
                    {{ booking.booker.countryCode }}
                </div>
            </div>
        </div>
        <!-- total -->
        <div
            class="text-r-green text-2xl font-bold flex flex-row space-x-2 border-t-2 last:border-b-2 border-r-black-50 py-4"
        >
            <div class="w-1/2 truncate">
                {{ $t("new_reservation.payment.amount") }}
            </div>
            <div class="w-1/2">
                {{ currency(payment_amount, payment_currency) }}
            </div>
        </div>

        <ul class="text-sm list-disc list-outside pl-5">
            <li v-if="booking.offer.hasOwnProperty('cityTaxes')">
                {{
                    $t("new_reservation.payment.including_city_tax", {
                        amount: currency(city_tax_amount),
                    })
                }}
            </li>
            <li>
                {{ $t("new_reservation.payment.including_vat") }}
            </li>
            <li>
                {{
                    $t(
                        "apaleo.cancellationFee." +
                            booking.offer.cancellationFee.code +
                            ".description"
                    )
                }}
            </li>
        </ul>
    </div>
</template>

<script>
import { mapState } from "vuex";
import DayJS from "@mixins/DayJS";
import Currency from "@mixins/Currency";
import BookingPaymentAmount from "@mixins/BookingPaymentAmount";
export default {
    mixins: [DayJS, BookingPaymentAmount, Currency],
    data: function () {
        return {
            widthLabel: "w-1/2",
            widthText: "w-1/2",
        };
    },
    computed: {
        ...mapState({
            booking: "bookingDraft",
        }),
    },
    props: {
        withBooker: {
            type: Boolean,
            required: false,
            default: true,
        },
        withDates: {
            type: Boolean,
            required: false,
            default: true,
        },
        withGuests: {
            type: Boolean,
            required: false,
            default: false,
        },
        withRooms: {
            type: Boolean,
            required: false,
            default: true,
        },
        withNights: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>

<style></style>
