<template>
    <div>
        <header class="shadow bg-gray-50">
            <div class="px-8 py-4 mx-auto max-w-7xl space-y-2">
               <header-title></header-title>
               <header-buttons></header-buttons>
            </div>
        </header>

    </div>
</template>

<script>

import HeaderTitle from '@guest/layout/HeaderTitle'
import HeaderButtons from '@guest/layout/HeaderButtons'

export default {
    components: {
        HeaderTitle,
        HeaderButtons,
    }

}
</script>

<style>

</style>
