<template>
    <div
        :class="{'bg-green-50 border-green-400 shadow' : isServiceSelected, 'bg-gray-50 border-gray-400' : !isServiceSelected}"
        class="flex flex-col overflow-hidden border rounded transition-all duration-200 space-y-2 bg-gray-50">
        <div
            v-if="service.roatel.service.images.length > 0"
            class="aspect-w-16 aspect-h-9">
            <img
                class="overflow-hidden"
                :src="`/images/${service.roatel.service.images[0].uuid}/thumb`">
        </div>
        <div class="px-4 text-lg font-bold text-center">
            {{apaleoTranslate(service.roatel.service.name_trans, currentLocale.key)}}
        </div>
        <div class="text-center text-gray-500">
            {{apaleoTranslate(service.roatel.service.description_trans, currentLocale.key)}}
        </div>
        <div class="text-2xl font-bold text-center">
            {{currency(service.totalAmount.grossAmount, service.totalAmount.currency)}}
        </div>
        <div class="flex flex-col px-4 pb-4 text-sm italic text-center text-gray-500 space-y-0">
            <div>
                {{$t('new_reservation.services.service_card.price_information')}}
            </div>
        </div>
        <div class="flex justify-center px-4 pb-4">
            <app-button
                v-if="!isServiceSelected"
                color="gray"
                icon="new"
                size="lg"
                @click="$emit('toggle', service)"
                :outline="false">
                {{$t('buttons.select')}}
            </app-button>
            <app-button
                v-if="isServiceSelected"
                color="green"
                icon="check"
                size="lg"
                @click="$emit('toggle', service)"
                :outline="false">
                {{$t('new_reservation.services.service_card.button_service_selected')}}
            </app-button>
        </div>
    </div>
</template>

<script>

import {mapActions, mapGetters, mapState} from 'vuex'
import ContentContainer from '@admin/common/ContentContainer'
import AppInput from '@admin/common/Input'
import AppButton from '@admin/common/Button'
import DayJS from '@mixins/DayJS'
import Currency from '@mixins/Currency'

export default {
    components: {
        ContentContainer,
        AppInput,
        AppButton,
    },
    mixins: [
        DayJS,
        Currency,
    ],
    computed: {
        ...mapGetters({
            currentLocale: 'currentLocale',
        }),
        isServiceSelected() {
            return this.selectedServices.find(item => item === this.service) === undefined ? false : true
        },
    },
    props: {
        selectedServices: {
            required: true,
            type: Array
        },
        service: {
            required: true,
            type: Object,
        },
    }
}
</script>

<style>

</style>
