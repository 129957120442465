<template>
    <div :class="containerClass">
        <router-link :to="{ name: 'home' }">
            <img src="/img/roatel_logo.svg" :class="imageClass" />
        </router-link>
    </div>
</template>

<script>
export default {
    props: {
        imageClass: {
            required: false,
            default: "w-18",
        },
        containerClass: {
            required: false,
            default: "flex justify-center",
        },
    },
};
</script>

<style></style>
