export default [
    {
        label: 'Home',
        route: 'home',
        icon: 'home',
        name: 'home',
        show: {
            requiresAuth: false,
            requiresGuest: false,
        }
    },
    {
        label: 'Neue Reservierung',
        route: 'bookings.new',
        icon: 'plus',
        name: 'new_reservation',
        show: {
            requiresAuth: false,
            requiresGuest: false,
        }
    },
    {
        label: 'Meine Reservierungen',
        route: 'bookings.list',
        name: 'my_reservations',
        icon: 'list-ul',
        show: {
            requiresAuth: true,
            requiresGuest: false,
        }
    },
    {
        label: 'Profil',
        route: 'profile',
        name: 'profile',
        icon: 'user',
        show: {
            requiresAuth: true,
            requiresGuest: false,
        }
    },
    {
        label: 'Registrieren',
        route: 'register',
        name: 'register',
        icon: 'user-plus',
        show: {
            requiresAuth: false,
            requiresGuest: true,
        }
    },
    {
        label: 'Abmelden',
        route: 'logout',
        name: 'logout',
        icon: 'user-plus',
        show: {
            requiresAuth: true,
            requiresGuest: false,
        }
    },
    {
        label: 'Login',
        route: 'login',
        name: 'login',
        icon: 'user-plus',
        show: {
            requiresAuth: false,
            requiresGuest: true,
        }
    },
]
