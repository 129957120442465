var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full"},[_c('div',{staticClass:"relative"},[_c('div',{staticClass:"absolute inset-0 h-full w-full"},[_c('clip-container',{staticClass:"h-full"},[_c('div',{staticClass:"bg-r-green text-white w-full h-full lg:p-16 px-5 py-8"})])],1),_vm._v(" "),_c('div',{staticClass:"relative text-white px-7 py-10 lg:pb-20"},[_c('h2',{staticClass:"font-sans text-4xl italic font-black mb-6 text-center lg:text-left"},[_vm._v("\n                "+_vm._s(_vm.$t("new_reservation.dates.heading"))+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"grid md:flex md:flex-row items-center gap-5"},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside.stop",value:(
                        () => (_vm.showPropertySelector = false)
                    ),expression:"\n                        () => (showPropertySelector = false)\n                    ",modifiers:{"stop":true}}],staticClass:"lg:relative lg:flex-grow lg:flex grid gap-5",attrs:{"tabindex":"0"},on:{"blur":function($event){_vm.showPropertySelector = false}}},[_c('app-input',{ref:"property",attrs:{"icon":"target","icon-click-emit":"location","required":true,"name":"property","input-rounded":"","label-classes":"text-white tracking-wide text-sm font-bold","type":"text","errors":_vm.errors,"placeholder":_vm.$t(
                                'new_reservation.dates.property_input_placeholder'
                            ),"label":_vm.$t('new_reservation.dates.property_input_label')},on:{"focus":function($event){_vm.showPropertySelector = true},"location":function($event){return _vm.$router.push({
                                name: 'bookings.new.search_properties.locationsearch',
                            })},"input":function($event){_vm.property = null;
                            _vm.property_set_by_selection = false;}},scopedSlots:_vm._u([{key:"afterInput",fn:function(){return [(_vm.showPropertySelector)?_c('div',{staticClass:"text-black absolute border border-r-darkgreen bg-white w-3/4 lg:w-full lg:top-20 shadow-lg max-h-60 lg:max-h-80 overflow-y-auto z-20"},[_c('div',{staticClass:"group hover:bg-r-green hover:text-white",on:{"click":function($event){return _vm.$router.push({
                                            name: 'bookings.new.search_properties.geosearch',
                                            params: {
                                                search: _vm.property_input,
                                            },
                                        })}}},[(
                                            _vm.property_input !== '' &&
                                            _vm.property === null
                                        )?_c('i18n',{staticClass:"py-3 px-4 font-bold",class:[_vm.propertyListClasses],attrs:{"path":"home.booking.search_around","tag":"div"}},[_c('span',{staticClass:"group-hover:text-white text-r-green hover:text-white underline underline-offset-2 font-bold"},[_vm._v(_vm._s(_vm.property_input))]),_vm._v("\n                                        >\n                                    ")]):_vm._e()],1),_vm._v(" "),_vm._l((_vm.propertiesGroupedByState),function(group,index){return (
                                        _vm.property_set_by_selection ||
                                        _vm.property_input === ''
                                    )?_c('div',{key:index},[_c('div',{staticClass:"py-3 px-4 font-bold",class:[_vm.propertyListClasses]},[_vm._v("\n                                        "+_vm._s(index)+" -\n                                        "+_vm._s(_vm.$t(
                                                "new_reservation.find_roatel_all"
                                            ))+"\n                                    ")]),_vm._v(" "),_vm._l((group),function(property){return _c('div',{staticClass:"group flex flex-row gap-2 items-center hover:bg-r-green hover:text-white hover:cursor-pointer",class:[_vm.propertyListClasses],on:{"click":function($event){$event.stopPropagation();return _vm.selectProperty(property)}}},[_c('div',[_c('icon',{staticClass:"text-r-green group-hover:text-white stroke-transparent",attrs:{"icon":"roatel","padding":"","is-rounded":false,"size":"w-5","background":"bg-transparent"}})],1),_vm._v(" "),_c('div',{staticClass:"text-lg leading-4"},[_vm._v("\n                                            "+_vm._s(property.name)+"\n                                        ")])])})],2):_vm._e()})],2):_vm._e()]},proxy:true}]),model:{value:(_vm.property_input),callback:function ($$v) {_vm.property_input=$$v},expression:"property_input"}})],1),_vm._v(" "),_c('div',[_c('app-input',{ref:"dp",attrs:{"required":true,"name":"checkIn","label-classes":"text-white tracking-wide text-sm font-bold","input-rounded":"","type":"datepicker","disabled":_vm.property === null,"order":50,"errors":_vm.errors,"locale":_vm.currentLocale.key,"datepicker-min-date":_vm.dayjs().startOf('day').add(0, 'day').toDate(),"placeholder":_vm.$t('new_reservation.dates.check_in_placeholder'),"label":_vm.$t(
                                'new_reservation.dates.check_in_label_with_time'
                            )},model:{value:(_vm.checkIn),callback:function ($$v) {_vm.checkIn=$$v},expression:"checkIn"}})],1),_vm._v(" "),_c('div',[_c('app-input',{attrs:{"type":"datepicker","label-classes":"text-white tracking-wide text-sm font-bold","required":true,"input-rounded":"","locale":_vm.currentLocale.key,"disabled":_vm.property === null,"name":"checkOut","errors":_vm.errors,"datepicker-min-date":_vm.dayjs(_vm.checkIn)
                                .startOf('day')
                                .add(1, 'day')
                                .toDate(),"placeholder":_vm.$t(
                                'new_reservation.dates.check_out_placeholder'
                            ),"label":_vm.$t(
                                'new_reservation.dates.check_out_label_with_time'
                            )},model:{value:(_vm.checkOut),callback:function ($$v) {_vm.checkOut=$$v},expression:"checkOut"}})],1),_vm._v(" "),_c('div',{staticClass:"self-end mb-0.5"},[_c('app-button',{attrs:{"color":"darkgreen","button-rounded":""},on:{"click":function($event){return _vm.$router.push({
                                name: 'bookings.new.dates',
                                params: {
                                    property: _vm.property.apaleo_id,
                                    checkin: _vm.checkIn,
                                    checkout: _vm.checkOut,
                                },
                            })}}},[_vm._v("\n                        "+_vm._s(_vm.$t("new_reservation.dates.button_search"))+"\n                    ")])],1)]),_vm._v(" "),_c('div',{staticClass:"mt-2"},[_c('router-link',{staticClass:"inline-flex gap-2 items-center",attrs:{"to":{
                        name: 'bookings.new.search_properties',
                        params: { show: 'map' },
                    }}},[_c('icon',{staticClass:"stroke-white w-6 h-6",attrs:{"icon":"map"}}),_vm._v(" "),_c('div',[_vm._v("\n                        "+_vm._s(_vm.$t("new_reservation.show_map"))+"\n                    ")])],1)],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }