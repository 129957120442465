<template>
    <div
        @keyup.esc.stop="closeOutsideEsc"
        tabindex="0"
        ref="modal"
        class="fixed z-20"
    >
        <transition
            enter-active-class="ease-in-out duration-300"
            enter-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="ease-in-out duration-300"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
            @after-enter="afterEnter"
        >
            <div v-if="showModal" class="fixed inset-0 overflow-hidden">
                <div class="absolute inset-0 overflow-hidden">
                    <div
                        class="absolute inset-0 bg-gray-800 bg-opacity-75 transition-opacity"
                        aria-hidden="true"
                    ></div>
                </div>
            </div>
        </transition>

        <transition
            enter-active-class="transform transition ease-in-out duration-300 sm:duration-300"
            enter-class="opacity-0 translate-y-6"
            enter-to-class="opacity-100 translate-y-0"
            leave-active-class="transform transition ease-in-out duration-300 sm:duration-300"
            leave-class="opacity-100 translate-y-0"
            leave-to-class="opacity-0 translate-y-6"
        >
            <div
                v-if="showModal"
                class="fixed inset-0 flex md:items-center justify-center overflow-y-scroll"
            >
                <div
                    v-click-outside.stop="closeOutsideEsc"
                    @click.stop="nothing"
                    class="absolute flex flex-col overflow-visible bg-white border-gray-600 rounded-lg shadow-lg"
                    :class="[width, height]"
                >
                    <div
                        class="flex flex-row items-center justify-between px-3 pt-3 pb-2 md:px-8 md:pt-6 md:pb-4"
                    >
                        <div class="text-lg font-bold">
                            <slot name="title" :model="model">
                                Confirmation
                            </slot>
                        </div>
                        <div>
                            <button
                                v-if="showHideButton"
                                class="text-gray-600 rounded-md hover:text-gray-300 focus:outline-none focus:ring-2 focus:ring-white"
                                @click="close"
                            >
                                <span class="sr-only">Close modal</span>
                                <font-awesome-icon
                                    icon="xmark"
                                    class="mr-2 text-xl"
                                ></font-awesome-icon>
                            </button>
                        </div>
                    </div>
                    <div
                        class="flex-grow px-3 pb-3 overflow-visible md:px-8 md:pb-6"
                    >
                        <slot name="body" :model="model"> Body </slot>
                    </div>
                    <div
                        class="px-3 py-3 bg-gray-50 md:py-3 md:px-8"
                        v-if="$scopedSlots.footer"
                    >
                        <slot name="footer" :model="model"></slot>
                    </div>
                    <div
                        v-if="isConfirmation"
                        class="flex flex-row items-center justify-between px-3 py-2 md:py-6 md:px-8 bg-gray-50"
                    >
                        <slot name="actions" :prop="model">
                            <app-button
                                :icon="cancelIcon"
                                :color="cancelColor"
                                :outline="cancelOutline"
                                :size="cancelSize"
                                @click="close"
                            >
                                {{ cancelLabel }}
                            </app-button>
                            <app-button
                                :icon="confirmIcon"
                                :color="confirmColor"
                                :size="confirmSize"
                                :outline="confirmOutline"
                                @click="confirm"
                            >
                                {{ confirmLabel }}
                            </app-button>
                        </slot>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import AppButton from "@admin/common/Button";
import * as vClickOutside from "v-click-outside-x";

export default {
    directives: {
        clickOutside: vClickOutside.directive,
    },
    components: {
        AppButton,
    },
    data() {
        return {
            showModal: false,
            title: null,
            model: {},
        };
    },
    methods: {
        nothing() {
            console.log("ignoring click");
        },
        afterEnter() {
            if (this.disableAfterEnter) {
                return;
            }
            console.log("after enter");
            this.$refs.modal.focus();
        },
        closeOutsideEsc() {
            if (this.isClickOutsideEnabled) {
                this.close();
            }
        },
        close() {
            this.showModal = false;
            this.$emit("closed", this.model);
        },
        confirm() {
            this.showModal = false;
            this.$emit("confirmed", this.model);
        },
        show(model, clone = false) {
            console.log("Modal show method", model, clone);
            this.model = model;
            if (clone) {
                this.model = _.cloneDeep(model);
            }
            this.showModal = true;
            this.$emit("show", this.model);
            console.log("Modal showModal property", this.showModal);
        },
    },
    mounted() {},
    watch: {
        showModal(val) {
            document.body.style.overflow = val ? "hidden" : "";
        },
    },
    props: {
        //model: {
        //required: false,
        //default: () => {},
        //},
        confirmLabel: {
            required: false,
            default: "Löschen",
        },
        confirmIcon: {
            required: false,
            default: "save",
        },
        confirmColor: {
            required: false,
            default: "red",
        },
        confirmOutline: {
            required: false,
            default: false,
        },
        confirmSize: {
            required: false,
            default: "normal",
        },
        cancelLabel: {
            required: false,
            default: "Abbrechen",
        },
        cancelIcon: {
            required: false,
            default: "cancel",
        },
        cancelOutline: {
            required: false,
            default: true,
        },
        cancelColor: {
            required: false,
            default: "gray",
        },
        cancelSize: {
            required: false,
            default: "normal",
        },
        width: {
            required: false,
            default: "w-1/2",
        },
        height: {
            required: false,
            default: "min-h-1/5",
        },
        isConfirmation: {
            required: false,
            default: false,
        },
        isClickOutsideEnabled: {
            default: true,
        },
        showHideButton: {
            default: true,
        },
        disableAfterEnter: {
            default: false,
            required: false,
        },
    },
};
</script>

<style></style>
