<template>
    <div>
        <div class="flex gap-5">
            <app-button
                class="flex"
                v-for="val in range"
                :key="val"
                :class="[val != value ? 'opacity-50' : '']"
                :color="val != value ? 'darkgreen' : 'darkgreen'"
                @click="$emit('input', val)"
            >
                {{
                    val
                        ? $t("reservations.review.yes")
                        : $t("reservations.review.no")
                }}
            </app-button>
        </div>
        <!-- <div class="flex flex-row space-x-4 text-r-green-5"> -->
        <!--     <div -->
        <!--         @click="$emit('input', val)" -->
        <!--         v-for="val in range" -->
        <!--         :key="val" -->
        <!--         class="transition-all py-2 uppercase font-black italic text-white px-8 text-center flex justify-center duration-150 text-base cursor-pointer" -->
        <!--         :class="[ -->
        <!--             val != value -->
        <!--                 ? 'bg-r-gray opacity-50' -->
        <!--                 : 'bg-r-darkgreen opacity-100', -->
        <!--         ]" -->
        <!--     > -->
        <!--         {{ -->
        <!--             val -->
        <!--                 ? $t("reservations.review.yes") -->
        <!--                 : $t("reservations.review.no") -->
        <!--         }} -->
        <!--     </div> -->
        <!-- </div> -->
        <!-- sterne {{ stars }} -->
        <!-- <button @click="$emit('input', stars + 1)">Inc</button> -->
        <!-- <button @click="$emit('input', stars - 1)">Dec</button> -->
    </div>
</template>

<script>
import AppButton from "@guest/common/Button";
export default {
    components: {
        AppButton,
    },
    props: {
        range: {
            default: () => [true, false],
        },
        value: {
            default: null,
            required: true,
        },
    },
};
</script>

<style></style>
