<template>
    <div>
        <full-width-container>
            <content-container>
                <div class="w-full">
                    <clip-container class="md:cutter-big">
                        <div
                            class="bg-r-green text-white px-5 py-8 md:p-16 flex flex-col space-y-6"
                        >
                            <h2
                                class="font-slanted text-2xl md:text-4xl italic font-black uppercase"
                            >
                                {{ $t("crm.subscribed.heading") }}
                            </h2>
                            <div>
                                <div>
                                    {{ $t("crm.subscribed.lead") }}
                                </div>
                                <div>
                                    {{ $t("crm.subscribed.body") }}
                                </div>
                            </div>
                            <app-button
                                @click="home"
                                color="green"
                                :outline="false"
                            >
                                {{ $t("crm.subscribed.home_button") }}
                            </app-button>
                        </div>
                    </clip-container>
                </div>
            </content-container>
        </full-width-container>
    </div>
</template>
<script>
import ContentContainer from "@guest/common/ContentContainer";
import ClipContainer from "@guest/common/ClipContainer.vue";
import FullWidthContainer from "@guest/common/FullWidthContainer.vue";
import AppInput from "@guest/common/Input";
import AppButton from "@guest/common/Button";
import Alert from "@guest/common/Alert";
import { mapActions } from "vuex";

export default {
    components: {
        ContentContainer,
        ClipContainer,
        AppInput,
        AppButton,
        FullWidthContainer,
        Alert,
    },
    data: function () {},
    methods: {
        home() {
            this.$router.push({ name: "home" });
        },
    },
};
</script>

<style></style>
