// const cancel =
//     '<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 53 53" style="enable-background:new 0 0 53 53;" xml:space="preserve"><polygon class="fill-current" points="39.7,35.7 31.2,27.2 39.7,18.7 35.5,14.5 27,23 18.5,14.5 14.3,18.7 22.8,27.2 14.3,35.7 18.5,39.9 27,31.5 35.5,39.9 "/></svg>';
// const next =
//     '<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 53 53" style="enable-background:new 0 0 53 53;" xml:space="preserve"><polygon class="fill-current" points="38.9,25.3 23.9,10.3 23.2,11 23.2,11 23.9,10.3 23.2,9.6 22.5,10.3 20.3,12.5 19.6,13.2 20.3,13.9 21,13.2 21,13.2 20.3,13.9 32.4,26 20.3,38 19.6,38.7 20.3,39.4 22.5,41.6 23.2,42.3 23.9,41.6 23.2,40.9 23.2,40.9 23.9,41.6 36,29.6 36,29.6 36.7,28.9 38.9,26.7 39.6,26 "/></svg>';
// const location =
//     '<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 53 53" style="enable-background:new 0 0 53 53;" xml:space="preserve"><path class="fill-current" d="M26.5,41c-0.1-0.1-0.2-0.2-0.2-0.3c-2.8-4.1-5.6-8.1-8.3-12.2c-2.1-3.2-2.6-6.7-1.2-10.3 c1.6-4,6.1-6.6,10.5-6.2c4.9,0.4,8.8,3.9,9.5,8.7c0.4,2.7-0.1,5.3-1.7,7.6c-2.8,4.2-5.7,8.3-8.5,12.5C26.6,40.9,26.6,40.9,26.5,41z M30.5,22.2c0-2.1-1.8-3.9-4-3.9s-4,1.7-4,3.8s1.8,3.9,4,3.9C28.7,26.1,30.5,24.3,30.5,22.2z" /></svg>';
// const key =
//     '<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 53 53" style="enable-background:new 0 0 53 53;" xml:space="preserve"><g><path class="fill-current" d="M24.1,23c0.6,1.2,0.9,2.6,0.9,4c0,0.3,0,0.7-0.1,1H46v-5H24.1z"/><path class="fill-current" d="M16,20c-3.9,0-7,3.1-7,7s3.1,7,7,7s7-3.1,7-7S19.9,20,16,20z M16,31c-2.2,0-4-1.8-4-4s1.8-4,4-4s4,1.8,4,4 S18.2,31,16,31z"/><rect x="32" y="30" class="fill-current" width="12" height="4"/></g></svg>';
// const journey =
//     '<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 53 53" style="enable-background:new 0 0 53 53;" xml:space="preserve"><path class="fill-current" d="M37,19v-4v-4H17v4v4h-5v22h30V19H37z M21,19v-4h12v4H21z"/></svg>';
// const plus =
//     '<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 53 53" style="enable-background:new 0 0 53 53;" xml:space="preserve"><polygon class="fill-current" points="42,23 30,23 30,11 24,11 24,23 12,23 12,29 24,29 24,41 30,41 30,29 42,29 "/></svg>';
// const minus =
//     '<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 53 53" style="enable-background:new 0 0 53 53;" xml:space="preserve"><rect x="12" y="23" class="fill-current" width="30" height="6" /></svg>';
// const locate =
//     '<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 53 53" style="enable-background:new 0 0 53 53;" xml:space="preserve"><path class="fill-current" d="M25.2,42l-0.7-14L11,27.2L38,14L25.2,42z"/></svg>';
// const login =
//     '<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 53 53" style="enable-background:new 0 0 53 53;" xml:space="preserve"><path class="fill-current" d="M33.2,28.9C35.5,27,37,24.2,37,21c0-5.5-4.5-10-10-10s-10,4.5-10,10c0,3.3,1.6,6.2,4,8c-8.5,3-9,11.8-9,11.9 l4,0.2c0-0.4,0.5-9.1,11.3-9.1C37.6,32,38,40.1,38,41h4C42,40.9,41.9,31.8,33.2,28.9z M27,15c3.3,0,6,2.7,6,6s-2.7,6-6,6s-6-2.7-6-6 S23.7,15,27,15z"/></svg>';
// const list =
//     '<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 53 53" style="enable-background:new 0 0 53 53;" xml:space="preserve"><g><rect x="12" y="24" class="fill-current" width="4" height="4"/><rect x="12" y="16" class="fill-current" width="4" height="4"/><rect x="12" y="32" class="fill-current" width="4" height="4"/><rect x="18" y="16" class="fill-current" width="24" height="4"/><rect x="18" y="24" class="fill-current" width="24" height="4"/><rect x="18" y="32" class="fill-current" width="24" height="4"/><rect x="18" y="32" class="fill-current" width="24" height="4"/></g></svg>';
// const map =
//     '<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 53 53" style="enable-background:new 0 0 53 53;" xml:space="preserve"><g><path class="fill-current" d="M19.9,35.9c-3.1,1.2-6.2,2.4-9.4,3.6c0-7.4,0-14.8,0-22.2c3.1-1.3,6.2-2.5,9.4-3.8 C19.9,21,19.9,28.4,19.9,35.9z"/><path class="fill-current" d="M42.5,35.9c-3.1,1.2-6.2,2.4-9.4,3.6c0-7.4,0-14.8,0-22.2c3.1-1.3,6.2-2.5,9.4-3.8 C42.5,21,42.5,28.4,42.5,35.9z"/><path class="fill-current" d="M21.8,35.9c3.1,1.2,6.2,2.4,9.4,3.6c0-7.4,0-14.8,0-22.2c-3.1-1.3-6.2-2.5-9.4-3.8 C21.8,21,21.8,28.4,21.8,35.9z"/></g></svg>';
// const calendar =
//     '<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 53 53" style="enable-background:new 0 0 53 53;" xml:space="preserve"><g><rect x="13" y="13" class="fill-current" width="28" height="5"/><path class="fill-current" d="M13,20v19h28V20H13z M19,35.5c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S20.1,35.5,19,35.5z M19,27.5 c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S20.1,27.5,19,27.5z M27,35.5c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S28.1,35.5,27,35.5z M27,27.5c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S28.1,27.5,27,27.5z M35,35.5c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2 S36.1,35.5,35,35.5z M35,27.5c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S36.1,27.5,35,27.5z"/></g></svg>';
//
// const notification =
//     '<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 53 53" style="enable-background:new 0 0 53 53;" xml:space="preserve"><path class="fill-current" d="M28,30.6l1.7-10.1v-8.2h-6.4v8.2L25,30.6H28z M26.5,40.8c2,0,3.4-1.6,3.4-4c0-2.3-1.4-4-3.4-4 c-2,0-3.4,1.6-3.4,4C23.1,39.1,24.5,40.8,26.5,40.8z" /></svg>';
//
// const euro =
//     '<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 53 53" style="enable-background:new 0 0 53 53;" xml:space="preserve"><path class="fill-current" d="M27,36.3c1.8,0,3.5-0.6,5-1.7c1.5-1.1,2.5-2.5,3-4.2l4.3,0.7c-0.4,1.8-1.2,3.3-2.4,4.7 c-1.2,1.4-2.6,2.4-4.4,3.2c-1.7,0.8-3.6,1.2-5.5,1.2c-2.1,0-4.1-0.4-5.9-1.2c-1.8-0.8-3.3-2-4.5-3.5c-1.2-1.5-2.1-3.3-2.5-5.2h-4 v-2.5h3.7c0-0.2,0-0.6,0-1.1c0-0.5,0-0.9,0-1.2H10v-2.6h4.1c0.5-2,1.3-3.7,2.6-5.1c1.2-1.5,2.7-2.6,4.5-3.5c1.8-0.8,3.7-1.2,5.9-1.2 c2,0,3.8,0.4,5.5,1.2c1.8,0.8,3.2,1.9,4.4,3.4c1.2,1.4,2,3.1,2.4,4.9L35,23c-0.3-1.2-0.8-2.2-1.6-3.2c-0.8-0.9-1.7-1.7-2.8-2.2 c-1.1-0.5-2.3-0.8-3.5-0.8c-2,0-3.7,0.5-5.1,1.6c-1.4,1.1-2.5,2.5-3.1,4.4H28v2.6h-9.8c0,0.5-0.1,0.9-0.1,1.2c0,0.5,0,0.8,0,1.1H28 v2.5h-9.3c0.6,1.8,1.7,3.3,3.1,4.4C23.3,35.7,25.1,36.3,27,36.3z" /></svg>';
//
// const documents =
//     '<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 53 53" style="enable-background:new 0 0 53 53;" xml:space="preserve"><g><polygon class="fill-current" points="27,22 27,11 16,11 16,41 39,41 39,22 	"/><path class="fill-current" d="M29.1,20v-9l9.9,9H29.1z"/></g></svg>';
//
// const edit =
//     '<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 53 53" style="enable-background:new 0 0 53 53;" xml:space="preserve"><g><path class="fill-current" d="M13.1,40.7l1.9-7.2l5.3,5.3L13.1,40.7z"/><rect x="15.3" y="23" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -10.9747 26.9157)" class="fill-current" width="23.4" height="7.4"/><rect x="35.3" y="12.1" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -0.1045 31.4379)" class="fill-current" width="5.2" height="7.4"/></g></svg>';
// const completed =
//     '<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 53 53" style="enable-background:new 0 0 53 53;" xml:space="preserve"><path class="fill-current" d="M43,19l-4.1-4L22.8,30.9l-6.7-6.7l-4.1,4l6.7,6.7l0,0l4.1,4L43,19z"/></svg>';
//
// const back =
//     '<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 53 53" style="enable-background:new 0 0 53 53;" xml:space="preserve"><polygon class="fill-current" points="20.6,27 32.7,15 33.4,14.3 32.7,13.6 30.5,11.4 29.8,10.7 29.1,11.4 17,23.4 17,23.4 16.3,24.1  14.1,26.3 13.4,27 14.1,27.7 29.1,42.7 29.8,43.4 30.5,42.7 32.7,40.5 33.4,39.8 32.7,39.1 "/></svg>';
//
const roatel_only_r =
    '<svg viewBox="0 0 16 18" xmlns="http://www.w3.org/2000/svg"><path class="fill-current" d="M0 17.5L2.3776 0.5H10.2149C14.0308 0.5 16.2322 2.84264 15.7626 6.32495C15.381 9.07914 13.4731 11.0102 11.1542 11.4534L10.9487 11.4851L14.6765 17.4683H9.5691L6.51638 11.8966H5.40096L4.57908 17.5H0ZM5.81191 8.6676H8.27756C9.71586 8.6676 10.9487 7.78119 11.1835 6.35661C11.389 4.96369 10.4203 4.1406 8.95268 4.1406H6.51638L5.81191 8.6676Z"/></svg>';

// const roatel =
//     '<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><rect width="24" height="24" rx="2" class="fill-current"/><path d="M4 20.5L6.3776 3.5H14.2149C18.0308 3.5 20.2322 5.84264 19.7626 9.32495C19.381 12.0791 17.4731 14.0102 15.1542 14.4534L14.9487 14.4851L18.6765 20.4683H13.5691L10.5164 14.8966H9.40096L8.57908 20.5H4ZM9.81191 11.6676H12.2776C13.7159 11.6676 14.9487 10.7812 15.1835 9.35661C15.389 7.96369 14.4203 7.1406 12.9527 7.1406H10.5164L9.81191 11.6676Z" fill=""/></svg>';

const roatel =
    '<svg width="100%" height="100%" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><path d="M24,2L24,22C24,23.104 23.104,24 22,24L2,24C0.896,24 0,23.104 0,22L0,2C0,0.896 0.896,0 2,0L22,0C23.104,0 24,0.896 24,2ZM4,20.5L8.579,20.5L9.401,14.897L10.516,14.897L13.569,20.468L18.677,20.468L14.949,14.485L15.154,14.453C17.473,14.01 19.381,12.079 19.763,9.325C20.232,5.843 18.031,3.5 14.215,3.5L6.378,3.5L4,20.5ZM9.812,11.668L10.516,7.141L12.953,7.141C14.42,7.141 15.389,7.964 15.184,9.357C14.949,10.781 13.716,11.668 12.278,11.668L9.812,11.668Z" class="fill-current"/></svg>';

const bed =
    '<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="strike-inherit"><path d="M3 7V18M3 14H21M21 18V10C21 9.46957 20.7893 8.96086 20.4142 8.58579C20.0391 8.21071 19.5304 8 19 8H11V14" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M7 11C7.55228 11 8 10.5523 8 10C8 9.44772 7.55228 9 7 9C6.44772 9 6 9.44772 6 10C6 10.5523 6.44772 11 7 11Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>';

const user =
    '<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="stroke-inherit" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z" stroke="stroke-inherit" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M6.168 18.849C6.41551 18.0252 6.92197 17.3032 7.61225 16.79C8.30252 16.2768 9.13985 15.9997 10 16H14C14.8613 15.9997 15.6996 16.2774 16.3904 16.7918C17.0812 17.3062 17.5875 18.0298 17.834 18.855" stroke="stroke-inherit" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>';

const questionmark =
    '<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="stroke-inherit" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 17V17.01M12 13.5C11.9816 13.1754 12.0692 12.8536 12.2495 12.583C12.4299 12.3125 12.6933 12.1079 13 12C13.3759 11.8563 13.7132 11.6272 13.9856 11.331C14.2579 11.0347 14.4577 10.6792 14.5693 10.2926C14.6809 9.90596 14.7013 9.49869 14.6287 9.10287C14.5562 8.70704 14.3928 8.33345 14.1513 8.01152C13.9099 7.68958 13.597 7.42809 13.2373 7.24762C12.8776 7.06715 12.4809 6.97264 12.0785 6.97153C11.6761 6.97041 11.2789 7.06272 10.9182 7.24119C10.5576 7.41966 10.2432 7.67941 10 8" stroke="stroke-inherit" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>';

const globe =
    '<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="stroke-inherit" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M3.60001 9H20.4M3.60001 15H20.4M11.5 3C9.81535 5.69961 8.92221 8.81787 8.92221 12C8.92221 15.1821 9.81535 18.3004 11.5 21M12.5 3C14.1847 5.69961 15.0778 8.81787 15.0778 12C15.0778 15.1821 14.1847 18.3004 12.5 21" stroke="stroke-inherit" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>';

const target =
    '<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke="stroke-inherit" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z" stroke="stroke-inherit" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 2V4M12 20V22M20 12H22M2 12H4" stroke="stroke-inherit" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>';

const calendar =
    '<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 5H6C4.89543 5 4 5.89543 4 7V19C4 20.1046 4.89543 21 6 21H18C19.1046 21 20 20.1046 20 19V7C20 5.89543 19.1046 5 18 5Z" stroke="stroke-inherit" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M16 3V7M8 3V7M4 11H20M8 15H10V17H8V15Z" stroke="stroke-inherit" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>';

const check_circle =
    '<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="stroke-inherit" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M9 12L11 14L15 10" stroke="stroke-inherit" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>';

const map =
    '<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 4L3 7V20L9 17M9 4L15 7M9 4V17M15 7L21 4V17L15 20M15 7V20M15 20L9 17" stroke="stroke-inherit" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>';

const chevron_right =
    '<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 6L15 12L9 18" stroke="stroke-inherit" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>';
const search =
    '<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17Z" stroke="stroke-inherit" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M21 21L15 15" stroke="stroke-inherit" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>';

const chevron_down =
    '<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 9L12 15L18 9" stroke="stroke-inherit" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>';

const circle_x =
    '<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="stroke-inherit" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M10 10L14 14M14 10L10 14" stroke="stroke-inherit" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>';

const alert =
    '<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 5.99999V7.33332M8 9.99999V10.0067M3.33333 12.6667H12.6667C12.8842 12.6651 13.0981 12.6104 13.2896 12.5072C13.4811 12.4041 13.6445 12.2556 13.7655 12.0748C13.8864 11.894 13.9613 11.6864 13.9836 11.47C14.0059 11.2536 13.9749 11.035 13.8933 10.8333L9.16 2.66666C9.04469 2.45826 8.87567 2.28454 8.6705 2.16358C8.46533 2.04262 8.23151 1.97882 7.99333 1.97882C7.75516 1.97882 7.52133 2.04262 7.31616 2.16358C7.11099 2.28454 6.94197 2.45826 6.82667 2.66666L2.09333 10.8333C2.01329 11.0304 1.98156 11.2437 2.00077 11.4555C2.01998 11.6673 2.08958 11.8715 2.20377 12.0509C2.31795 12.2304 2.47341 12.3799 2.65715 12.487C2.84089 12.5941 3.0476 12.6557 3.26 12.6667" stroke="stroke-inherit" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/></svg>';

const map_pin =
    '<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.657 16.657L13.414 20.9C13.039 21.2746 12.5306 21.4851 12.0005 21.4851C11.4704 21.4851 10.962 21.2746 10.587 20.9L6.343 16.657C5.22422 15.5382 4.46234 14.1127 4.15369 12.5609C3.84504 11.009 4.00349 9.40051 4.60901 7.93873C5.21452 6.47694 6.2399 5.22754 7.55548 4.3485C8.87107 3.46947 10.4178 3.00029 12 3.00029C13.5822 3.00029 15.1289 3.46947 16.4445 4.3485C17.7601 5.22754 18.7855 6.47694 19.391 7.93873C19.9965 9.40051 20.155 11.009 19.8463 12.5609C19.5377 14.1127 18.7758 15.5382 17.657 16.657Z" stroke="stroke-inherit" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 14C13.6569 14 15 12.6569 15 11C15 9.34319 13.6569 8.00005 12 8.00005C10.3431 8.00005 9 9.34319 9 11C9 12.6569 10.3431 14 12 14Z" stroke="stroke-inherit" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>';

const file_euro =
    '<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14 3V7C14 7.26522 14.1054 7.51957 14.2929 7.70711C14.4804 7.89464 14.7348 8 15 8H19" stroke="stroke-inherit" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 14H9M17 21H7C6.46957 21 5.96086 20.7893 5.58579 20.4142C5.21071 20.0391 5 19.5304 5 19V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H14L19 8V19C19 19.5304 18.7893 20.0391 18.4142 20.4142C18.0391 20.7893 17.5304 21 17 21Z" stroke="stroke-inherit" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M14.004 11.172C13.5515 11.0117 13.0671 10.9623 12.5916 11.0281C12.1161 11.0938 11.6633 11.2726 11.2712 11.5497C10.8791 11.8267 10.5593 12.1937 10.3385 12.62C10.1177 13.0462 10.0024 13.5192 10.0023 13.9993C10.0022 14.4793 10.1173 14.9524 10.338 15.3787C10.5586 15.8051 10.8784 16.1722 11.2703 16.4494C11.6623 16.7265 12.1151 16.9055 12.5906 16.9714C13.0661 17.0373 13.5504 16.9881 14.003 16.828" stroke="stroke-inherit" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>';

const currency_euro =
    '<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.2 7C16.3561 6.03533 15.2868 5.38281 14.1263 5.12426C12.9658 4.8657 11.7657 5.01262 10.6765 5.5466C9.5873 6.08058 8.65745 6.97784 8.00357 8.12587C7.34969 9.27391 7.00087 10.6216 7.00087 12C7.00087 13.3784 7.34969 14.7261 8.00357 15.8741C8.65745 17.0222 9.5873 17.9194 10.6765 18.4534C11.7657 18.9874 12.9658 19.1343 14.1263 18.8758C15.2868 18.6172 16.3561 17.9647 17.2 17M13 10H5M5 14H13" stroke="stroke-inherit" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>';

const propeller =
    '<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 10.6667C9.10457 10.6667 10 9.77123 10 8.66666C10 7.56209 9.10457 6.66666 8 6.66666C6.89543 6.66666 6 7.56209 6 8.66666C6 9.77123 6.89543 10.6667 8 10.6667Z" stroke="stroke-inherit" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/><path d="M9.44466 7C9.926 5.97467 10.2153 4.97133 10.3133 3.99067C10.46 2.904 9.80533 2 8 2C6.19466 2 5.54 2.90467 5.68666 3.99067C5.78466 4.97133 6.074 5.97467 6.55533 7M8.77933 11.1673C9.426 12.0973 10.1507 12.8493 10.9507 13.4247C11.8173 14.0953 12.9287 13.98 13.8313 12.4167C14.734 10.8533 14.278 9.834 13.2647 9.418C12.366 9.01267 11.3527 8.76133 10.2233 8.666M5.776 8.666C4.64733 8.76133 3.634 9.01267 2.736 9.418C1.72133 9.83333 1.26533 10.8533 2.168 12.4167C3.07066 13.98 4.18133 14.0947 5.04866 13.4247C5.84866 12.8493 6.57333 12.0973 7.22066 11.1673" stroke="stroke-inherit" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/></svg>';
const wifi =
    '<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 12H8.00667M6.11467 10.1147C6.61474 9.61475 7.2929 9.3339 8 9.3339C8.70711 9.3339 9.38526 9.61475 9.88534 10.1147M4.22867 8.22867C4.72392 7.73339 5.31187 7.34052 5.95896 7.07248C6.60605 6.80443 7.2996 6.66647 8 6.66647C8.70041 6.66647 9.39396 6.80443 10.041 7.07248C10.6881 7.34052 11.2761 7.73339 11.7713 8.22867" stroke="stroke-inherit" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/><path d="M2.34333 6.34335C5.46733 3.21868 10.5327 3.21868 13.6767 6.34335" stroke="stroke-inherit" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/></svg>';
const tv =
    '<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.6667 4.66666H3.33333C2.59695 4.66666 2 5.26361 2 5.99999V12C2 12.7364 2.59695 13.3333 3.33333 13.3333H12.6667C13.403 13.3333 14 12.7364 14 12V5.99999C14 5.26361 13.403 4.66666 12.6667 4.66666Z" stroke="stroke-inherit" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/><path d="M10.6667 2L8 4.66667L5.33333 2" stroke="stroke-inherit" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/></svg>';

const user_plus =
    '<svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 14.6667C14.9455 14.6667 17.3333 12.2789 17.3333 9.33333C17.3333 6.38781 14.9455 4 12 4C9.05448 4 6.66667 6.38781 6.66667 9.33333C6.66667 12.2789 9.05448 14.6667 12 14.6667Z" stroke="stroke-inherit" stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round"/><path d="M4 28V25.3333C4 23.9189 4.5619 22.5623 5.5621 21.5621C6.56229 20.5619 7.91885 20 9.33333 20H14.6667C16.0812 20 17.4377 20.5619 18.4379 21.5621C19.4381 22.5623 20 23.9189 20 25.3333V28M21.3333 14.6667H29.3333M25.3333 10.6667V18.6667" stroke="stroke-inherit" stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round"/></svg>';

const user_check =
    '<svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 14.6667C14.9455 14.6667 17.3333 12.2789 17.3333 9.33333C17.3333 6.38781 14.9455 4 12 4C9.05449 4 6.66667 6.38781 6.66667 9.33333C6.66667 12.2789 9.05449 14.6667 12 14.6667Z" stroke="stroke-inherit" stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round"/><path d="M4 28V25.3333C4 23.9188 4.5619 22.5623 5.5621 21.5621C6.56229 20.5619 7.91885 20 9.33333 20H14.6667C16.0812 20 17.4377 20.5619 18.4379 21.5621C19.4381 22.5623 20 23.9188 20 25.3333V28M21.3333 14.6667L24 17.3333L29.3333 12" stroke="stroke-inherit" stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round"/></svg>';
const user_x =
    '<svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 14.6667C14.9455 14.6667 17.3333 12.2789 17.3333 9.33333C17.3333 6.38781 14.9455 4 12 4C9.05448 4 6.66666 6.38781 6.66666 9.33333C6.66666 12.2789 9.05448 14.6667 12 14.6667Z" stroke="stroke-inherit" stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round"/><path d="M4 28V25.3333C4 23.9188 4.5619 22.5623 5.5621 21.5621C6.56229 20.5619 7.91885 20 9.33333 20H14.6667C16.0812 20 17.4377 20.5619 18.4379 21.5621C19.4381 22.5623 20 23.9188 20 25.3333V28M22.6667 12L28 17.3333M28 12L22.6667 17.3333" stroke="stroke-inherit" stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round"/></svg>';
const chevron_up =
    '<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 15L12 9L6 15" stroke="stroke-inherit" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>';
const arrow_narrow_left =
    '<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 12H19M5 12L9 16M5 12L9 8" stroke="stroke-inherit" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>';
const edit =
    '<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 7H6C5.46957 7 4.96086 7.21071 4.58579 7.58579C4.21071 7.96086 4 8.46957 4 9V18C4 18.5304 4.21071 19.0391 4.58579 19.4142C4.96086 19.7893 5.46957 20 6 20H15C15.5304 20 16.0391 19.7893 16.4142 19.4142C16.7893 19.0391 17 18.5304 17 18V17" stroke="stroke-inherit" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M16 5L19 8M20.385 6.585C20.7788 6.19115 21.0001 5.65698 21.0001 5.1C21.0001 4.54302 20.7788 4.00885 20.385 3.615C19.9912 3.22115 19.457 2.99989 18.9 2.99989C18.343 2.99989 17.8088 3.22115 17.415 3.615L9 12V15H12L20.385 6.585Z" stroke="stroke-inherit" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>';
const phone =
    '<svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.66667 5.33333H12L14.6667 12L11.3333 14C12.7613 16.8954 15.1046 19.2387 18 20.6667L20 17.3333L26.6667 20V25.3333C26.6667 26.0406 26.3857 26.7188 25.8856 27.2189C25.3855 27.719 24.7072 28 24 28C18.799 27.6839 13.8935 25.4753 10.2091 21.7909C6.52467 18.1065 4.31607 13.201 4 7.99999C4 7.29275 4.28095 6.61447 4.78105 6.11438C5.28115 5.61428 5.95942 5.33333 6.66667 5.33333Z" stroke="stroke-inherit" stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round"/></svg>';
const mail =
    '<svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M25.3333 6.66666H6.66667C5.19391 6.66666 4 7.86057 4 9.33333V22.6667C4 24.1394 5.19391 25.3333 6.66667 25.3333H25.3333C26.8061 25.3333 28 24.1394 28 22.6667V9.33333C28 7.86057 26.8061 6.66666 25.3333 6.66666Z" stroke="stroke-inherit" stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round"/><path d="M4 9.33333L16 17.3333L28 9.33333" stroke="stroke-inherit" stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round"/></svg>';
const user_no_circle =
    '<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 7.33333C9.47276 7.33333 10.6667 6.13943 10.6667 4.66667C10.6667 3.19391 9.47276 2 8 2C6.52724 2 5.33333 3.19391 5.33333 4.66667C5.33333 6.13943 6.52724 7.33333 8 7.33333Z" stroke="stroke-inherit" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/><path d="M4 14V12.6667C4 11.9594 4.28095 11.2811 4.78105 10.781C5.28115 10.281 5.95942 10 6.66667 10H9.33333C10.0406 10 10.7189 10.281 11.219 10.781C11.719 11.2811 12 11.9594 12 12.6667V14" stroke="stroke-inherit" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/></svg>';
const id =
    '<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 4H6C4.34315 4 3 5.34315 3 7V17C3 18.6569 4.34315 20 6 20H18C19.6569 20 21 18.6569 21 17V7C21 5.34315 19.6569 4 18 4Z" stroke="stroke-inherit" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M9 12C10.1046 12 11 11.1046 11 10C11 8.89543 10.1046 8 9 8C7.89543 8 7 8.89543 7 10C7 11.1046 7.89543 12 9 12Z" stroke="stroke-inherit" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M15 8H17M15 12H17M7 16H17" stroke="stroke-inherit" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>';
const signature =
    '<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3 17C6.333 13.667 8 11 8 9C8 6 7 6 6 6C5 6 3.968 7.085 4 9C4.034 11.048 5.658 13.877 6.5 15C8 17 9 17.5 10 16L12 13C12.333 15.667 13.333 17 15 17C15.53 17 17.639 15 18 15C18.517 15 19.517 15.667 21 17" stroke="stroke-inherit" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>';
const key =
    '<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 19C10.2091 19 12 17.2091 12 15C12 12.7909 10.2091 11 8 11C5.79086 11 4 12.7909 4 15C4 17.2091 5.79086 19 8 19Z" stroke="stroke-inherit" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M10.85 12.15L19 4M18 5L20 7M15 8L17 10" stroke="stroke-inherit" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>';
const slide_show =
    '<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15 6H15.01" stroke="stroke-inherit" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M18 3H6C4.34315 3 3 4.34315 3 6V14C3 15.6569 4.34315 17 6 17H18C19.6569 17 21 15.6569 21 14V6C21 4.34315 19.6569 3 18 3Z" stroke="stroke-inherit" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M3 13L7 9C7.45606 8.56116 7.97339 8.33012 8.5 8.33012C9.02661 8.33012 9.54394 8.56116 10 9L14 13" stroke="stroke-inherit" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M13 12L15 10C15.4561 9.56116 15.9734 9.33012 16.5 9.33012C17.0266 9.33012 17.5439 9.56116 18 10L21 13M8 21H8.01M12 21H12.01M16 21H16.01" stroke="stroke-inherit" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>';
const menu =
    '<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 8H20M4 16H20" stroke="stroke-inherit" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>';
const x =
    '<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 6L6 18M6 6L18 18" stroke="stroke-inherit" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>';
const arrow_up_right =
    '<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.3333 4.66666L4.66666 11.3333M11.3333 4.66666H5.33333M11.3333 4.66666V10.6667" stroke="stroke-inherit" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/></svg>';
const bed_off =
    '<svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 9.33334V24M4 18.6667H18.6667M24 18.6667H28M28 24V13.3333C28 12.6261 27.719 11.9478 27.219 11.4477C26.7189 10.9476 26.0406 10.6667 25.3333 10.6667H16M14.6667 14.6667V18.6667" stroke="stroke-inherit" stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round"/><path d="M9.33333 14.6667C10.0697 14.6667 10.6667 14.0697 10.6667 13.3333C10.6667 12.597 10.0697 12 9.33333 12C8.59695 12 8 12.597 8 13.3333C8 14.0697 8.59695 14.6667 9.33333 14.6667Z" stroke="stroke-inherit" stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round"/><path d="M4 4L28 28" stroke="stroke-inherit" stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round"/></svg>';
const arrow_narrow_right =
    '<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 12H19M19 12L15 16M19 12L15 8" stroke="stroke-inherit" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>';
export {
    roatel_only_r,
    roatel,
    bed,
    user,
    questionmark,
    globe,
    target,
    calendar,
    check_circle,
    map,
    chevron_right,
    search,
    chevron_down,
    circle_x,
    alert,
    map_pin,
    file_euro,
    currency_euro,
    propeller,
    wifi,
    tv,
    user_plus,
    user_check,
    user_x,
    chevron_up,
    arrow_narrow_left,
    edit,
    mail,
    phone,
    user_no_circle,
    id,
    signature,
    key,
    slide_show,
    menu,
    x,
    arrow_up_right,
    bed_off,
    arrow_narrow_right,
};
