<template>
    <div>
        <div
            v-if="!hide"
            class="relative flex flex-col lg:flex-row lg:items-center justify-center text-white gap-3 w-full py-3 px-3 lg:py-0 lg:px-0"
        >
            <div
                class="lg:absolute lg:left-0 text-xl lg:text-2xl font-black text-white italic text-center lg:text-left"
            >
                <slot>
                    {{ $t("navigation.footer.book_roatel") }}
                </slot>
            </div>
            <div
                :class="[maxWidth ? maxWidth : 'lg:max-w-2xl w-full px-3']"
                class="justify-self-center flex flex-row gap-1 lg:gap-3 items-center justify-between flex-grow"
            >
                <template v-for="(step, index) in steps">
                    <div
                        :key="step.label"
                        :class="[
                            'text-r-darkgreen bg-white rounded-full',
                            {
                                'cursor-pointer': step.step <= current_step,
                                'opacity-50': step.step > current_step,
                                '': step.step === current_step,
                            },
                        ]"
                        @click="requestRouteChange(step)"
                        class="relative flex items-center justify-center text-white transition duration-150"
                    >
                        <icon
                            :class="[
                                'w-8 h-8 lg:w-8 lg:h-8 p-1 stroke-r-green',
                                step.step > current_step ? 'opacity-50' : '',
                                steps.length < current_step ||
                                steps.length == current_step
                                    ? 'bg-white'
                                    : '',
                            ]"
                            :icon="step.icon"
                        >
                        </icon>
                    </div>
                    <div
                        :key="index"
                        class="border-white flex-auto border-t-2 md:border-t-3 transition duration-300"
                        :class="{
                            'opacity-50': step.step >= current_step,
                        }"
                        v-if="index < steps.length - 1"
                    ></div>
                </template>
            </div>
            <div
                class="lg:absolute lg:right-0 text-xl lg:text-2xl font-black text-white italic text-center lg:text-right"
                v-if="$slots.end"
            >
                <slot name="end">
                    {{ $t("navigation.footer.book_roatel") }}
                </slot>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from "@guest/common/Icon";
import ContentContainer from "@guest/common/ContentContainer";
export default {
    components: {
        Icon,
        ContentContainer,
    },
    data: function () {
        return {
            steps: [
                {
                    step: 1,
                    label: "new_reservation.find_roatel",
                    icon: "map-pin",
                    route: "bookings.new.search_properties",
                },
                {
                    step: 2,
                    label: "new_reservation.dates.heading",
                    icon: "calendar",
                    route: "bookings.new.dates",
                },
                {
                    step: 3,
                    label: "new_reservation.summary.heading",
                    icon: "user",
                    route: "bookings.new.summary",
                },
                {
                    step: 4,
                    label: "new_reservation.booker.heading",
                    icon: "file-euro",
                    route: "bookings.new.booker",
                },
                {
                    step: 5,
                    label: "payment",
                    label: "new_reservation.payment.heading",
                    icon: "currency-euro",
                    route: "bookings.new.payment",
                },
                {
                    step: 6,
                    label: "new_reservation.completed.heading",
                    icon: "check-circle",
                },
            ],
        };
    },
    props: {
        current_step: {
            required: false,
            default: 1,
        },
        hide: {
            required: false,
            default: false,
        },
        stepperSteps: {
            required: false,
            default: null,
            type: Array,
        },
        maxWidth: {
            default: "",
        },
    },
    mounted() {
        //set steps if prop exists
        if (this.stepperSteps !== null) {
            this.$set(this, "steps", this.stepperSteps);
        }
    },
    methods: {
        requestRouteChange(step) {
            console.log("request route change", step);
            if (step.step < this.current_step && step.route) {
                this.$router.push({ name: step.route });
            }
        },
    },
};
</script>

<style></style>
