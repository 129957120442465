export default {
    data: function () {
        return {
            preferred_languages: [
                { id: "de", label: "Deutsch (DE)" },
                { id: "en", label: "English (EN)" },
                { id: "tr", label: "Türkçe (TR)" },
                { id: "pl", label: "Polski (PL)" },
                { id: "es", label: "Español (ES)" },
                { id: "fr", label: "Français (FR)" },
                { id: "nl", label: "Nederlands (NL)" },
                { id: "pt", label: "Portuguese (PT)" },
                { id: "it", label: "Italiano (IT)" },
                { id: "bg", label: "български (BG)" },
                { id: "ru", label: "русский (RU)" },
            ],
        };
    },
};
