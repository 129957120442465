<template>
    <content-container>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div class="flex flex-col md:col-span-2 space-y-3">
                <div class="flex flex-row justify-between text-gray-500">
                    <div>
                        {{$t('new_reservation.properties.proximity_search_label')}}
                    </div>
                    <app-button @click="resetSearch" color="gray" size="sm">
                        {{$t('new_reservation.button_new_search')}}
                    </app-button>
                </div>
                <div class="text-lg">
                    {{location}}
                </div>
            </div>
            <div class="text-lg font-bold text-center md:col-span-2">
                Roatels
            </div>
            <property-card
                :key="property.uuid"
                :property="property"
                @property-select="propertySelected($event)"
                class="first:border-2 first:border-green-400"
                v-for="property in properties">
            </property-card>
        </div>
    </content-container>

</template>

<script>

import ContentContainer from '@admin/common/ContentContainer'
import AppButton from '@admin/common/Button.vue'
import {mapGetters, mapState, mapActions} from 'vuex'
import PropertyCard from '@guest/bookings/new/PropertyCard'

export default {
    components: {
        ContentContainer,
        PropertyCard,
        AppButton,
    },
    computed: {
        ...mapState({
            location: state => state.search.location,
            properties: state => state.search.properties,
        })
    },
    methods: {
        ...mapActions({
            resetSearchResult: 'resetSearchResult',
            setBookingDraftProperty: 'setBookingDraftProperty',
        }),
        resetSearch() {
            this.resetSearchResult()
            this.$router.push({name: 'bookings.new'})
        },
        propertySelected(property) {
            this.setBookingDraftProperty(property)
            this.$router.push({name: 'bookings.new.dates'})

            console.log('selected property', property)
        },
    },
    mounted() {
        if (this.properties.length < 1) {
            console.log('error properties length incorrect', this.properties)
            this.$router.push({name: 'bookings.new'})
        }
    }

}
</script>

<style>

</style>
