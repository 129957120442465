<template>
    <div>
        <modal
            :is-confirmation="false"
            ref="modal_imprint"
            width="w-full lg:w-1/3"
        >
            <template v-slot:title="{ model }">
                <!-- {{ model.name }} -->
            </template>
            <template v-slot:body="{ model }">
                <div class="grid gap-4 text-r-black">
                    <div class="grid gap-4">
                        <div>
                            <div
                                class="text-2xl font-black italic text-r-green mb-2 text-center"
                            >
                                {{
                                    $t(
                                        "new_reservation.property_card.imprint.imprint"
                                    )
                                }}
                            </div>
                            <div>{{ model.name }}</div>
                            <div>{{ model.company_name }}</div>
                            <div>{{ model.invoice_address_line1 }}</div>
                            <div v-if="model.invoice_address_line2">
                                {{ model.invoice_address_line2 }}
                            </div>
                            <div>
                                {{ model.invoice_country_code }}
                                {{ model.invoice_postal_code }}
                                {{ model.invoice_city }}
                            </div>
                        </div>
                        <div v-if="model.commercial_register_entry">
                            {{
                                $t(
                                    "new_reservation.property_card.imprint.commercial_register"
                                )
                            }}
                            {{ model.commercial_register_entry }}
                        </div>
                        <div v-if="model.tax_id">
                            {{
                                $t(
                                    "new_reservation.property_card.imprint.vat_id"
                                )
                            }}
                            {{ model.tax_id }}
                        </div>
                    </div>
                    <div v-if="model.managing_directors">
                        <div class="font-bold uppercase text-r-green mb-2">
                            {{
                                $t(
                                    "new_reservation.property_card.imprint.managing_directors"
                                )
                            }}
                        </div>
                        <div>
                            <div
                                v-for="director in model.managing_directors.split(
                                    ','
                                )"
                            >
                                {{ director }}
                            </div>
                        </div>
                    </div>
                    <div class="flex justify-center">
                        <div>
                            <app-button
                                color="gray"
                                @click="$refs.modal_imprint.close()"
                            >
                                {{ $t("buttons.close") }}
                            </app-button>
                        </div>
                    </div>
                </div>
                <!-- {{ model }} -->
            </template>
        </modal>
    </div>
</template>
<script>
import Modal from "@guest/common/Modal.vue";
import AppButton from "@guest/common/Button";
export default {
    components: {
        Modal,
        AppButton,
    },
    // props: {
    //     property: {
    //         required: true,
    //         type: Object,
    //     },
    // },
};
</script>
