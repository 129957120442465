export default {
    data: function () {
        return {
            titles: [
                { id: null, label: '-' },
                { id: 'Mr', label: 'Mr' },
                { id: 'Mrs', label: 'Mrs' },
                { id: 'Dr', label: 'Dr' },
                { id: 'Prof', label: 'Prof' },
                { id: 'Ms', label: 'Ms' },
                { id: 'Other', label: 'Other' },
            ]
        }
    },
}
