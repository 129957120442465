<template>
    <div class="grid gap-8">
        <h2
            class="text-2xl lg:text-3xl italic font-black text-r-green text-center lg:text-left"
        >
            <template v-if="reservation.status == 'CheckedOut'">
                {{ $t("reservations.summary.checked_out.heading") }}
            </template>
            <template v-if="reservation.status == 'Canceled'">
                {{ $t("reservations.summary.canceled.heading") }}
            </template>
            <template v-if="reservation.status == 'NoShow'">
                {{ $t("reservations.summary.no_show.heading") }}
            </template>
        </h2>
        <div>
            <div class="text-center lg:text-left">
                <span v-if="reservation.status == 'CheckedOut'">
                    {{ $t("reservations.summary.checked_out.lead") }}
                </span>
                <span v-if="reservation.status == 'Canceled'">
                    {{
                        $t("reservations.summary.canceled.lead", {
                            date: date(
                                reservation.cancellation_time,
                                "date_localized"
                            ),
                            time: date(
                                reservation.cancellation_time,
                                "time_localized"
                            ),
                        })
                    }}
                </span>
                <span v-if="reservation.status == 'NoShow'">
                    {{ $t("reservations.summary.no_show.lead") }}
                </span>
            </div>
        </div>
        <div v-if="reservation.invoices.length > 0" class="grid gap-5">
            <div
                class="grid lg:gap-2 gap-4 border border-r-gray-medium bg-r-gray-ultralight rounded-lg p-5"
                v-for="invoice in reservation.invoices"
                :key="invoice.uuid"
            >
                <div class="font-bold">
                    <div>
                        {{ $t("reservations.summary.invoice") }}:
                        {{ invoice.number }}
                        ({{
                            $t(
                                "reservations.summary.invoice_type." +
                                    invoice.type
                            )
                        }})
                    </div>
                    <div>
                        {{ $t("reservations.summary.invoice_date_delimiter") }}
                        {{ date(invoice.invoice_date) }}
                    </div>
                </div>
                <div class="flex flex-row items-center justify-between">
                    <div>
                        {{ $t("reservations.summary.invoice_amount") }}:
                        {{
                            currency(
                                invoice.total_amount,
                                invoice.total_currency
                            )
                        }}
                    </div>
                    <div>
                        <div v-if="!invoice.payment_settled">
                            {{
                                $t(
                                    "reservations.summary.invoice_amount_unpaid"
                                )
                            }}:
                            {{
                                currency(
                                    invoice.outstanding_payment_amount,
                                    invoice.outstanding_payment_currency
                                )
                            }}
                        </div>
                        <div v-else>
                            <span
                                class="px-3 text-sm py-0.5 bg-r-gray text-white"
                                >{{
                                    $t(
                                        "reservations.summary.invoice_amount_paid"
                                    )
                                }}</span
                            >
                        </div>
                    </div>
                </div>
                <div class="flex justify-center">
                    <div>
                        <app-button
                            @click="openAssetInNewWindow(invoice)"
                            size="sm"
                            >{{
                                $t("reservations.summary.button_invoice_open")
                            }}</app-button
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AppButton from "@guest/common/Button";
import DayJS from "@mixins/DayJS";
import Currency from "@mixins/Currency";

export default {
    mixins: [DayJS, Currency],
    components: {
        AppButton,
    },
    props: {
        reservation: {
            required: true,
            type: Object,
        },
        reservationQueryPayload: {
            required: true,
        },
    },
    computed: {},
    methods: {
        openAssetInNewWindow(invoice) {
            let url =
                process.env.MIX_ASSET_URL +
                "/assets/guest/reservations/invoice/";
            if (this.reservationQueryPayload.secret !== null) {
                url += "key/";
            } else {
                url += "user/";
            }
            url += this.reservation.uuid + "/" + invoice.uuid;
            if (this.reservationQueryPayload.secret !== null) {
                url += "/" + this.reservationQueryPayload.secret;
            }
            window.open(url, "_blank").focus();
            console.log(url);
        },
    },
};
</script>

<style></style>
