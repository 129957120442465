var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('full-width-container',{staticClass:"bg-r-green"},[_c('content-container',{attrs:{"padding":"lg:py-4 lg:px-12 py-3"}},[_c('stepper',{attrs:{"current_step":_vm.current_step}},[_vm._v("\n                "+_vm._s(_vm.$t("navigation.footer.book_roatel"))+"\n            ")])],1)],1),_vm._v(" "),_c('full-width-container',{staticClass:"bg-r-gray-ultralight"},[_c('content-container',[_c('div',{staticClass:"flex justify-center mb-20"},[_c('div',{staticClass:"border border-r-gray-light p-5 lg:p-16 grid gap-8 bg-white rounded-lg w-full lg:w-[610px] text-center"},[_c('div',{staticClass:"flex flex-col gap-4 justify-center"},[_c('div',{staticClass:"items-center mx-auto"},[_c('icon',{staticClass:"w-12 h-12 stroke-r-green",attrs:{"icon":"check-circle"}})],1),_vm._v(" "),_c('h2',{staticClass:"text-2xl md:text-3xl font-black italic text-r-green text-center"},[_vm._v("\n                            "+_vm._s(_vm.$t("new_reservation.completed.confirmed"))+"\n                        ")])]),_vm._v(" "),_c('div',{staticClass:"leading-relaxed"},[_c('div',[_vm._v("\n                            "+_vm._s(_vm.$t(
                                    "new_reservation.completed.confirmation_message"
                                ))+"\n                        ")])]),_vm._v(" "),_c('div',[_c('div',{staticClass:"grid gap-2"},[_c('div',[_vm._v("\n                                "+_vm._s(_vm.$t(
                                        "new_reservation.completed.booking_number"
                                    ))+"\n                            ")]),_vm._v(" "),_c('div',{staticClass:"font-bold"},[_vm._v("\n                                "+_vm._s(_vm.$route.params.id,)+"\n                            ")])])]),_vm._v(" "),_c('div',{staticClass:"flex justify-center mt-5"},[_c('div',[(!_vm.isLoggedIn)?_c('app-button',{staticClass:"text-base",attrs:{"color":"green"},on:{"click":function($event){return _vm.$router.push({ name: 'home' })}}},[_vm._v("\n                                "+_vm._s(_vm.$t(
                                        "new_reservation.completed.home_button"
                                    ))+"\n                            ")]):_c('app-button',{staticClass:"text-base",attrs:{"color":"green"},on:{"click":function($event){return _vm.$router.push({ name: 'bookings.list' })}}},[_vm._v("\n                                "+_vm._s(_vm.$t("navigation.my_reservations"))+"\n                            ")])],1)])])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }