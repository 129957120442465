<template>
    <input-layout
        :stacked="stacked"
        :stackedReversed="stackedReversed"
        :label="label"
        :hasLabel="hasLabel"
        :labelUppercase="labelUppercase"
    >
        <template v-slot:label>
            <input-label
                :id="id"
                :error="hasError"
                :labelUppercase="labelUppercase"
                :labelClasses="labelClasses"
                :required="required"
            >
                {{ label }}
            </input-label>
        </template>
        <div v-if="initialized">
            <template v-if="showInputElement">
                <template v-if="typeText">
                    <div class="inline-flex relative w-full">
                        <div
                            v-if="$slots.before"
                            class="absolute inset-y-0 left-3 pr-4 space-x-2 flex items-center"
                            @click="$emit(beforeSlotClickEmit)"
                        >
                            <slot name="before"> </slot>
                        </div>

                        <input
                            :type="type"
                            :value="value"
                            :size="size"
                            :disabled="disabled"
                            class="w-full duration-150 transition-all text-r-gray placeholder-r-gray-medium"
                            :class="[
                                $slots.before ? 'pl-10' : '',
                                inputRounded,
                                inputPadding,
                                inputFontSize,
                                hasError ? errorRingClasses : '',
                                hasReset || icon ? 'pr-12' : '',
                                !hasError ? regularRingClasses : '',
                                disabled ? 'opacity-50' : '',
                            ]"
                            :placeholder="placeholder"
                            @input="$emit('input', $event.target.value)"
                            @keyup.enter="$emit('keyup-enter', $event)"
                            @focus="$emit('focus')"
                            @blur="$emit('blur')"
                            :id="id"
                        />
                        <div
                            v-if="hasReset || icon"
                            class="absolute inset-y-0 right-0 pr-4 space-x-2 flex items-center"
                        >
                            <div
                                class="justify-center p-3 h-4 flex w-4 items-center"
                                :class="[
                                    {
                                        'bg-r-red': hasError,
                                        'cursor-pointer': !hasError,
                                    },
                                    !hasError && iconBackgroundColor
                                        ? iconBackgroundColor
                                        : 'text-r-gray-light hover:text-r-green text-2xl',
                                ]"
                            >
                                <div
                                    @click="$emit('input', '')"
                                    v-if="hasReset"
                                ></div>
                                <icon
                                    class="stroke-r-gray-medium w-6 h-6"
                                    icon="circle-x"
                                ></icon>
                                <div v-if="icon" @click="$emit(iconClickEmit)">
                                    <icon
                                        class="stroke-r-gray-medium hover:stroke-r-green w-6 h-6"
                                        :icon="icon"
                                    ></icon>
                                </div>
                                <!-- <font-awesome-icon -->
                                <!--     v-if="icon" -->
                                <!--     :icon="icon" -->
                                <!--     @click="$emit(iconClickEmit)" -->
                                <!-- ></font-awesome-icon> -->
                                <!-- <font-awesome-icon -->
                                <!--     v-if="hasError" -->
                                <!--     icon="exclamation-circle" -->
                                <!-- ></font-awesome-icon> -->
                            </div>
                        </div>
                    </div>
                    <slot name="afterInput"> </slot>
                </template>
                <template v-if="typePhone">
                    <div class="inline-flex relative w-full phone-container">
                        <vue-tel-input
                            :value="value ?? ''"
                            @input="phoneInputEvent"
                            :inputOptions="{
                                placeholder: placeholder,
                            }"
                            :mode="phoneMode"
                            :validCharactersOnly="true"
                            :defaultCountry="phoneDefaultCountry"
                            :preferredCountries="phonePreferredCountries"
                            :dropdownOptions="{
                                showSearchBox: true,
                                showDialCodeInList: true,
                                width: '300px',
                                showFlags: true,
                            }"
                            :class="[
                                hasError ? 'has-error' : '',
                                'rounded-lg',
                                hasError ? errorRingClasses : '',
                                hasReset || icon ? 'pr-12' : '',
                                !hasError ? regularRingClasses : '',
                            ]"
                        >
                        </vue-tel-input>

                        <div
                            v-if="hasReset || icon"
                            class="absolute inset-y-0 right-0 pr-2 space-x-2 flex items-center"
                        >
                            <div
                                class="justify-center p-3 h-5 text-white flex rounded-full w-5 items-center"
                                :class="{
                                    'bg-r-red': hasError,
                                    'cursor-pointer bg-r-gray hover:bg-r-green':
                                        !hasError,
                                }"
                            >
                                <!-- <font-awesome-icon -->
                                <!--     @click="$emit('input', '')" -->
                                <!--     v-if="hasReset" -->
                                <!--     icon="times" -->
                                <!-- ></font-awesome-icon> -->
                                <icon
                                    @click="$emit('input', '')"
                                    v-if="hasReset"
                                    class="stroke-r-gray-medium w-6 h-6"
                                    icon="circle-x"
                                ></icon>
                                <div @click="$emit(iconClickEmit)">
                                    <icon
                                        class="stroke-r-gray-medium hover:stroke-r-green w-5 h-5"
                                        v-if="icon"
                                        :icon="icon"
                                    ></icon>
                                </div>
                                <!-- <font-awesome-icon -->
                                <!--     v-if="icon" -->
                                <!--     :icon="icon" -->
                                <!--     @click="$emit(iconClickEmit)" -->
                                <!-- ></font-awesome-icon> -->
                                <!-- <font-awesome-icon -->
                                <!--     v-if="hasError" -->
                                <!--     icon="exclamation-circle" -->
                                <!-- ></font-awesome-icon> -->
                            </div>
                        </div>
                    </div>
                </template>
                <template v-if="typeCheckbox">
                    <div class="w-full">
                        <input
                            type="checkbox"
                            class="w-6 h-6 shadow duration-150 transition-all"
                            :class="[
                                hasError ? errorRingClasses : '',
                                'text-xl focus:border-r-green outline-offset-0 focus:outline-offset-0 ring-0 focus:ring-0 outline-0 focus:outline-0',
                                checkboxTextClass,
                            ]"
                            :placeholder="placeholder"
                            :value="value"
                            :checked="value"
                            @input="$emit('input', $event.target.checked)"
                            :id="id"
                        />
                    </div>
                </template>
                <template v-if="typeDatepickerSystem">
                    <div class="relative flex flex-row items-center">
                        <input
                            type="date"
                            :value="value"
                            @input="$emit('input', $event.target.value)"
                            :size="size"
                            class="appearance-none w-full duration-150 transition-all text-r-gray placeholder-r-gray-medium"
                            :class="[
                                inputPadding,
                                inputRounded,
                                inputFontSize,
                                hasError
                                    ? 'ring ring-r-red placeholder-r-red bg-r-red-10 text-r-red focus:border-r-red focus:ring focus:ring-r-red focus:ring-offset-2 active:border-r-red transition-all duration-150'
                                    : '',
                                'focus:border-r-green focus:ring-2 focus:ring-r-darkgreen focus:ring-offset focus:ring-offset-r-darkgreen focus:ring-color-r-darkgreen',
                            ]"
                            :placeholder="placeholder"
                            :id="id"
                        />
                    </div>
                </template>
                <template v-if="typeDatepicker">
                    <v-date-picker
                        :value="value"
                        @input="inputDate($event)"
                        :min-date="datepickerMinDate"
                        :max-date="datepickerMaxDate"
                        :mode="datepickerMode"
                        :locale="locale"
                        :is24hr="datepickerIs24hr"
                        :disabled="disabled"
                        :popover="{
                            positionFixed: true,
                            visibility: disabled ? 'hidden' : 'click',
                        }"
                        :minute-increment="datepickerMinuteIncrement"
                    >
                        <template v-slot="{ inputValue, inputEvents }">
                            <div class="relative flex flex-row items-center">
                                <input
                                    type="text"
                                    :value="inputValue"
                                    v-on="inputEvents"
                                    :size="size"
                                    class="w-full duration-150 transition-all text-r-gray placeholder-r-gray-medium"
                                    :class="[
                                        inputPadding,
                                        inputRounded,
                                        inputFontSize,
                                        hasError
                                            ? 'ring ring-r-red placeholder-r-red bg-r-red-10 text-r-red focus:border-r-red focus:ring focus:ring-r-red focus:ring-offset-2 active:border-r-red transition-all duration-150'
                                            : '',
                                        'focus:border-r-green focus:ring-2 focus:ring-r-darkgreen focus:ring-offset focus:ring-offset-r-darkgreen focus:ring-color-r-darkgreen',
                                        disabled ? 'opacity-50' : '',
                                    ]"
                                    :placeholder="placeholder"
                                    :id="id"
                                />
                                <div
                                    class="absolute right-0 flex items-center h-full px-2 my-auto bg-transparent pointer-cursor"
                                >
                                    <div>
                                        <icon
                                            class="w-6 h-6 stroke-r-gray-medium"
                                            icon="calendar"
                                        ></icon>
                                    </div>
                                    <!-- <font-awesome-icon -->
                                    <!--     icon="calendar-day" -->
                                    <!--     class="mr-2 text-xl text-r-gray-light" -->
                                    <!-- > -->
                                    <!-- </font-awesome-icon> -->
                                </div>
                            </div>
                        </template>
                    </v-date-picker>
                </template>
                <template v-if="typeSelect">
                    <div class="relative" v-click-outside.stop="selectClose">
                        <div class="relative flex flex-row items-center">
                            <input
                                type="text"
                                :size="size"
                                class="w-full duration-100 transition-all text-r-black placeholder-r-gray-medium"
                                :class="[
                                    inputPadding,
                                    inputRounded,
                                    inputFontSize,
                                    hasError
                                        ? 'ring ring-r-red placeholder-r-red bg-r-red-10 text-r-red focus:border-r-red focus:ring focus:ring-r-red focus:ring-offset-2 active:border-r-red transition-all duration-150'
                                        : '',
                                    hasReset || icon ? 'pr-12' : '',
                                    'focus:border-r-green focus:ring-2 focus:ring-r-darkgreen focus:ring-offset focus:ring-offset-r-darkgreen focus:ring-color-r-darkgreen',
                                ]"
                                :placeholder="placeholder"
                                v-model="selectValue"
                                @focus="$event.target.select()"
                                @click="selectOpen = true"
                                @input="selectSearch"
                                :id="id"
                                ref="selectInput"
                            />
                            <div
                                @click="selectOpen = !selectOpen"
                                class="absolute right-0 flex items-center h-full px-2 my-auto bg-transparent pointer-cursor"
                            >
                                <font-awesome-icon
                                    :icon="
                                        selectOpen
                                            ? 'chevron-up'
                                            : 'chevron-down'
                                    "
                                    class="mr-2 text-xl text-gray-600"
                                >
                                </font-awesome-icon>
                            </div>
                        </div>
                        <div
                            v-show="selectOpen"
                            ref="selectDropdown"
                            class="absolute z-50 w-full mt-2 overflow-hidden overflow-y-auto border border-gray-600 rounded-lg shadow-lg max-h-80 text-r-black"
                        >
                            <div
                                @click.stop="select(option)"
                                v-for="option in selectOptionsFiltered.slice(
                                    0,
                                    10
                                )"
                                :key="option[selectValueProperty]"
                                :class="{
                                    'bg-r-green-5':
                                        option[selectValueProperty] === value,
                                }"
                                class="px-4 py-3 bg-white border-b cursor-pointer transition-all duration-100 hover:bg-r-green hover:text-white text-r-black last:border-0"
                            >
                                <slot :item="option" name="listItem">
                                    {{ option[selectLabelProperty] }}
                                </slot>
                            </div>
                            <div
                                class="px-4 py-3 text-gray-500 bg-white border-b transition-all duration-100"
                                v-if="selectOptionsFiltered.length < 1"
                            >
                                keine Treffer
                            </div>
                        </div>
                    </div>
                </template>
                <template v-if="typeSelectClassic">
                    <div class="relative">
                        <div class="relative flex flex-row items-center">
                            <select
                                :size="size"
                                class="w-full duration-150 transition-all text-r-gray placeholder-r-gray-light"
                                :class="[
                                    inputRounded,
                                    inputFontSize,
                                    inputPadding,
                                    hasError ? errorRingClasses : '',
                                    !hasError ? regularRingClasses : '',
                                ]"
                                :placeholder="placeholder"
                                v-model="selectClassicValue"
                                @change.stop="
                                    $emit('input', $event.target.value)
                                "
                                :id="id"
                                ref="selectClassic"
                            >
                                <option
                                    v-for="(
                                        option, index
                                    ) in selectOptionsEdited"
                                    :key="id + '_' + index"
                                    :value="option[selectValueProperty]"
                                >
                                    <slot
                                        name="selectClassicOption"
                                        :option="option"
                                    >
                                        {{ option[selectLabelProperty] }}
                                    </slot>
                                </option>
                            </select>
                        </div>
                    </div>
                </template>
                <template v-if="typeTextarea">
                    <textarea
                        :type="type"
                        :value="value"
                        :size="size"
                        :rows="textAreaRows"
                        class="w-full rounded shadow duration-150 transition-all"
                        :class="[inputRounded, inputFontSize, inputPadding]"
                        :placeholder="placeholder"
                        @input="$emit('input', $event.target.value)"
                        :id="id"
                    ></textarea>
                </template>
                <template v-if="typeSlider">
                    <input-slider
                        :type="type"
                        :value="value"
                        :size="size"
                        class="duration-150 transition-all"
                        :placeholder="placeholder"
                        :id="id"
                        @input="$emit('input', $event)"
                    ></input-slider>
                </template>
                <div
                    v-if="hasError && errorMessages.length > 0"
                    :class="[errorFontSize]"
                    class="flex flex-row gap-1 mt-3 text-r-red items-start"
                >
                    <icon class="stroke-r-red w-5 h-5" icon="alert"></icon>
                    <div class="gap-1">
                        <div
                            v-for="(error, index) in errorMessages"
                            :key="index"
                            class="flex flex-col"
                        >
                            {{ error }}
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div
                    type="text"
                    class="px-0 bg-transparent border-0"
                    v-if="$slots.default === undefined"
                    v-html="value"
                >
                    {{ $slots.default }}
                </div>
                <div
                    type="text"
                    v-if="$slots.default !== undefined"
                    class="px-0 bg-transparent border-0"
                >
                    <slot></slot>
                </div>
            </template>
        </div>
        <div v-if="!initialized" class="pulse">
            <div type="text" class="border-gray-300">
                <div class="w-full h-6 bg-gray-300"></div>
            </div>
        </div>
    </input-layout>
</template>

<script>
import InputLayout from "@guest/common/InputLayout";
import InputLabel from "@guest/common/InputLabel";
import InputSlider from "@admin/common/Slider";
import Fuse from "fuse.js";
import { createPopper } from "@popperjs/core";
import * as vClickOutside from "v-click-outside-x";
import { mapGetters } from "vuex";
import Icon from "@guest/common/Icon.vue";

export default {
    directives: {
        clickOutside: vClickOutside.directive,
    },
    components: {
        InputLayout,
        InputLabel,
        InputSlider,
        Icon,
    },
    data: function () {
        return {
            id: null,
            selectOpen: false,
            selectSearchEngine: null,
            selectValue: null,
            selectSearchResult: [],
            selectClassicValue: null,
            popper: null,
            popperConfig: {
                strategy: "fixed",
                placement: "auto",
                modifiers: [
                    {
                        name: "offset",
                        options: {
                            offset: [0, 6],
                        },
                    },
                    {
                        name: "sameWidth",
                        enabled: true,
                        phase: "beforeWrite",
                        requires: ["computeStyles"],
                        fn: ({ state }) => {
                            state.styles.popper.width = `${state.rects.reference.width}px`;
                        },
                        effect: ({ state }) => {
                            state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`;
                        },
                    },
                ],
            },
        };
    },
    watch: {
        selectOpen: {
            handler(val) {
                if (val) {
                    this.popper.update();
                }
            },
        },
        value: {
            handler: function (val, old) {
                //console.log('value set input for ' + this.name, val, old)
                if (this.typeSelectClassic) {
                    this.selectClassicValue = val;
                }
                //console.log('Value handler this.selectValue', val)
                //this.selectValue = val
                //console.log('select hitter from options', this.selectOptions.filter(item => item['alpha-2'] == val))
                if (val !== null && this.typeSelect) {
                    let hit = this.selectOptions.find(
                        (item) => item[this.selectValueProperty] === val
                    );
                    if (hit) {
                        this.select(hit);
                    }
                }
            },
            immediate: true,
        },
        selectOptions: {
            handler: function (val) {
                console.log("selectOptions set input", val);
                //this.selectValue = this.value
                //console.log('SelectOptions', this.selectOptions)
                if (
                    this.selectOptions !== null &&
                    this.selectOptions.length > 0 &&
                    this.typeSelect
                ) {
                    this.selectSearchEngine.setCollection(this.selectOptions);
                    if (this.typeSelect) {
                        if (this.value !== null) {
                            let selected = this.selectOptions.find(
                                (item) =>
                                    item[this.selectValueProperty] ===
                                    this.value
                            );
                            console.log("selected ", selected);
                            if (selected !== undefined) {
                                this.select(selected);
                            }
                        }
                    }
                }
            },
            //immediate: true,
        },
    },
    mounted() {
        this.id = this._uid;
        if (this.typeSelect) {
            const options = {
                includeMatches: true,
                shouldSort: true,
                includeScore: true,
                threshold: 0.3,
                keys: this.selectSearchKeys,
                minMatchCharLength: 2,
            };
            this.selectSearchEngine = new Fuse(this.selectOptions, options);
            this.popper = createPopper(
                this.$refs.selectInput,
                this.$refs.selectDropdown,
                this.popperConfig
            );
        }
        if (this.typeSelectClassic) {
            console.log("mounted selectClassic", this.name, this.value);
        }
    },
    methods: {
        phoneInputEvent(event, data) {
            console.log("phone input event", event, data);
            if (event || data) {
                this.$emit("input", data.formatted);
            }
        },
        selectClose() {
            this.selectOpen = false;
        },
        input() {
            this.$emit("input", this.value);
            //console.log('input')
        },
        inputDate(value) {
            this.$emit("input", value);
            //console.log('dateinput')
        },
        inputDateSystem(value) {
            this.$emit("input", value);
            //console.log('dateinput')
        },
        select(option) {
            this.$emit("select", option);
            this.selectOpen = false;
            this.selectValue = option[this.selectLabelProperty];
            //console.log('this.selectValue option', option['label'])
            //console.log('this.selectLabelProperty ', this.selectLabelProperty)
            //console.log('this.selectValue', option[this.selectLabelProperty])
            //this.selectSearch(this.selectValue)
        },
        selectEnter() {
            console.log();
        },
        selectSearch(query) {
            //Reset value after change until select
            this.$emit("input", null);
            //console.log(this.selectValue, query)
            //console.log('select search engine',this.selectSearchEngine)
            this.selectSearchResult = this.selectSearchEngine.search(
                this.selectValue
            );
        },
    },
    computed: {
        selectOptionsEdited() {
            let options = _.cloneDeep(this.selectOptions);
            if (this.selectNullable) {
                let nullable = {};
                nullable[this.selectLabelProperty] = this.selectNullableLabel;
                nullable[this.selectValueProperty] = this.selectNullableValue;
                options.unshift(nullable);
            }
            return options;
        },
        //selectSearchResult() {
        //console.log('selectValue', this.selectValue)
        //if (this.selectValue !== null) {
        //return this.selectSearchEngine.search(this.selectValue)
        //}
        //return []
        //},
        /*
        selectInputText() {
            if (this.value === null) {
                return null
            }
            let hit = this.selectOptions.find(item => {
                return item[this.selectValueProperty] === this.value
            })
            console.log('HIT', hit)
            if (hit !== undefined) {
                return hit[this.selectLabelProperty]
            }
            return null
        },
        */
        selectOptionsFiltered() {
            //console.log('selectSearchResult', this.selectSearchResult)
            //console.log('selectOptions', this.selectOptions)
            //console.log('selectSearchResultOptions.map', this.selectSearchResult.map(item => item.item))
            //console.log('selectValue', this.selectValue)
            if (
                this.selectValue === null ||
                this.selectValue === "" ||
                this.selectValue.length < 2
            ) {
                //console.log('(this.selectSearchResult === null)')
                return this.selectOptions;
                //if (this.selectSearchResult.length < 1) {
                //console.log('this.selectSearchResult.length < 1')
                //}
            }
            //console.log('Mapping')
            return this.selectSearchResult.map((item) => {
                return item.item;
            });
        },
        hasError() {
            if (this.errors === undefined) {
                return false;
            }
            //console.log('HAS_ERROR', this.errors)
            return this.errors.hasOwnProperty(this.name) ? true : false;
        },
        errorMessages() {
            return this.errors[this.name];
        },
        typeTextarea() {
            return this.type === "textarea";
        },
        typeSelect() {
            return this.type === "select";
        },
        typeSelectClassic() {
            return this.type === "selectClassic";
        },
        typeSlider() {
            return this.type === "slider";
        },
        typeDatepicker() {
            return this.type === "datepicker";
        },
        typeDatepickerSystem() {
            return this.type === "datepicker_system";
        },
        typeCheckbox() {
            return this.type === "checkbox";
        },
        typeText() {
            const types = ["text", "email", "password"];
            if (types.includes(this.type)) {
                return true;
            }
            return false;
        },
        typePhone() {
            return this.type === "phone";
        },
        showInputElement() {
            if (this.type === "onlyValue") {
                return false;
            }
            return true;
        },
    },
    props: {
        stacked: { required: false, default: true },
        stackedReversed: { required: false, default: false },
        label: { required: false, default: "Label not set" },
        hasLabel: { required: false, default: true },
        type: { required: false, default: "text" /* showOnlyValue */ },
        value: { required: false, default: null },
        placeholder: { required: false },
        initialized: { required: false, default: true },
        size: { required: false, default: "base" },
        errors: { required: false, type: Object, default: () => {} },
        name: { required: true, type: String },
        required: { required: false, default: false },
        selectOptions: {
            required: false,
            default: () => [],
            type: Array,
        },
        selectValueProperty: {
            required: false,
            default: "id",
        },
        selectLabelProperty: {
            required: false,
            default: "name",
        },
        selectSearchKeys: {
            required: false,
            default: () => ["name", "description", "location.city"],
        },
        selectNullable: {
            required: false,
            default: false,
        },
        selectNullableLabel: {
            required: false,
            default: "-",
        },
        selectNullableValue: {
            required: false,
            default: "",
        },
        datepickerMinDate: {
            required: false,
            default: null,
        },
        datepickerMaxDate: {
            required: false,
            default: null,
        },
        datepickerMode: {
            required: false,
            default: "date",
        },
        datepickerIs24hr: {
            required: false,
            default: true,
        },
        datepickerMinuteIncrement: {
            required: false,
            default: 15,
            type: Number,
        },
        textAreaRows: {
            required: false,
            default: 10,
        },
        labelUppercase: {
            required: false,
            default: false,
        },
        labelClasses: {
            required: false,
            default: "text-r-green tracking-wide text-sm font-bold",
            type: String | null,
        },
        inputRounded: {
            required: false,
            default: "rounded-lg",
        },
        inputFontSize: {
            required: false,
            default: "text-base",
        },
        inputPadding: {
            required: false,
            default: "px-4 py-2.5",
        },
        errorFontSize: {
            required: false,
            default: "text-sm",
        },
        locale: {
            required: false,
            default: "de",
        },
        icon: {
            required: false,
            type: String,
            default: null,
        },
        iconBackgroundColor: {},
        beforeSlotClickEmit: {
            required: false,
            type: String,
            default: null,
        },
        iconClickEmit: {
            required: false,
            type: String,
            default: null,
        },
        hasReset: {
            required: false,
            type: Boolean,
            default: false,
        },
        checkboxTextClass: {
            required: false,
            default: "text-r-darkgreen",
        },
        phoneMode: {
            required: false,
            default: "international",
        },
        phoneDefaultCountry: {
            required: false,
            default: "de",
        },
        phonePreferredCountries: {
            required: false,
            default: () => ["de", "bg"],
        },
        disabled: {
            required: false,
            default: false,
        },
        errorRingClasses: {
            required: false,
            default:
                "border-r-red focus:border-r-red focus:ring-1 focus:ring-r-red focus:ring-offset transition-all duration-150",
        },
        regularRingClasses: {
            required: false,
            default:
                "focus:border-r-green focus:ring-1 focus:ring-r-darkgreen focus:ring-offset focus:ring-offset-r-darkgreen focus:ring-color-r-darkgreen",
        },
    },
};
</script>

<style lang="postcss">
div.vc-time-picker div.vc-select div.vc-select-arrow {
    color: red;
    background-color: blue;
    display: none;
}
.phone-container .vue-tel-input {
    /* border-color: #6b7280; */
    @apply border-r-gray;
    @apply text-r-gray-medium;
    /* @apply text-sm; */
    @apply placeholder-r-gray-medium;
    @apply duration-150;
    @apply transition-all;
    @apply focus:border-r-green;
    @apply focus:ring-1;
    @apply focus:ring-offset-1;
    @apply focus:ring-offset-r-darkgreen;
    @apply bg-white;
    @apply w-full;
    @apply rounded-lg;

    .vti__input {
        @apply py-2.5;
        @apply text-r-gray;
        @apply placeholder-r-gray-medium;
        @apply rounded-lg;
        @apply text-base;
        @apply font-normal;
    }
    .vti__dropdown {
        @apply border-r;
        @apply border-r-gray;
    }
    .vti__dropdown-list {
        .vti__input.vti__search_box {
            @apply py-2;
        }
        .vti__dropdown-item {
            strong {
                @apply font-normal;
            }
        }
    }
}
.phone-container .vue-tel-input.has-error {
    /* @apply focus:ring-offset-r-red; */
    .vti__input {
        @apply focus:border-r-red;
        /* @apply focus:ring-1; */
        /* @apply focus:ring-offset-1; */
        /* @apply focus:ring-offset-r-red; */
    }
}
</style>
