var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('full-width-container',{staticClass:"md:hidden"}),_vm._v(" "),_c('find-roatel-bar',{ref:"findRoatelBar",attrs:{"search":_vm.address},on:{"search":_vm.getPropertiesByAddress,"input":function($event){_vm.address = $event},"geosearch":_vm.locate}}),_vm._v(" "),(_vm.properties.length > 0 && _vm.$route.params.show === 'map')?_c('app-map',{attrs:{"properties":_vm.properties},on:{"property-select":function($event){return _vm.propertySelected($event)}}},[_vm._v("\n        MAPS\n    ")]):_vm._e(),_vm._v(" "),_c('full-width-container',{staticClass:"bg-r-gray-ultralight"},[_c('content-container',{staticClass:"px-5",attrs:{"default-padding":false}},[(_vm.$route.params.show !== 'map')?_c('div',{staticClass:"flex flex-col w-full"},[(_vm.properties.length > 0)?_c('title-bar',[(_vm.hasGeolocation && _vm.location)?[_vm._v("\n                        "+_vm._s(_vm.$t("new_reservation.find_roatel_geo"))+"\n                    ")]:(_vm.location && !_vm.hasGeolocation)?[_vm._v("\n                        "+_vm._s(_vm.$t("new_reservation.find_roatel_search", {
                                location: _vm.location,
                            }))+"\n                    ")]:[_vm._v("\n                        "+_vm._s(_vm.$t("new_reservation.find_roatel_all"))+"\n                    ")]],2):_vm._e(),_vm._v(" "),_c('transition',{attrs:{"mode":"in-out","enter-active-class":"transition-all duration-300 ease-in-out","leave-active-class":"transition-all duration-300 ease-in-out","enter-class":"h-0 opacity-50 transform","enter-to-class":"h-auto opacity-100","leave-class":"h-auto opacity-100","leave-to-class":"h-0 opacity-50 transform"}},[(_vm.geolocationError)?_c('alert',[_c('div',{staticClass:"font-medium"},[_vm._v("\n                            "+_vm._s(_vm.$t("new_reservation.geosearch_error.error"))+"\n                        ")]),_vm._v(" "),_c('div',[_vm._v("\n                            "+_vm._s(_vm.$t(
                                    "new_reservation.geosearch_error." +
                                        _vm.geolocationErrorCodes
                                ))+"\n                        ")])]):_vm._e()],1),_vm._v(" "),_c('transition',{attrs:{"mode":"out-in","enter-active-class":"transition-all duration-150 ease-in-out","leave-active-class":"transition-all duration-150 ease-in-out","enter-class":"opacity-0 scale-75 transform translate-y-48","enter-to-class":"opacity-100 scale-100","leave-class":"opacity-100 scale-100","leave-to-class":"opacity-0 scale-75 transform -translate-y-48"}},[(_vm.properties.length > 0)?_c('div',{staticClass:"w-full grid grid-cols-1 md:grid-cols-3 gap-5 mb-20"},_vm._l((_vm.properties),function(property){return _c('property-card',{key:property.uuid,attrs:{"property":property,"hoverPointerClass":"hover:cursor-pointer","show-image":true,"show-address":true,"show-open-in-map":true,"show-checkin-until":true,"show-distance":true,"show-book-room-button":true,"show-book-room-button-class":"lg:hidden","show-room-information-button":true},on:{"property-select":function($event){return _vm.propertySelected($event)},"property-imprint":function($event){return _vm.$refs.propertyImprintModal.$refs.modal_imprint.show(
                                    $event
                                )}},scopedSlots:_vm._u([{key:"after",fn:function({ property }){return undefined}}],null,true)})}),1):_c('div',{staticClass:"md:cutter-big"},[_c('div',{staticClass:"flex flex-col space-y-6 justify-center items-center py-10 px-6 bg-r-black-5 text-r-gray md:p-16 px-5 py-8"},[_c('div',[_c('font-awesome-icon',{staticClass:"text-4xl text-r-green",attrs:{"icon":"spinner","spin":""}})],1),_vm._v(" "),_c('div',{staticClass:"font-slanted text-center text-r-green italic uppercase text-2xl font-black"},[_vm._v("\n                                Roatels werden geladen\n                            ")])])])])],1):_vm._e(),_vm._v(" "),_c('property-imprint-modal',{ref:"propertyImprintModal"})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }