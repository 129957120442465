<template>
    <div :class="containerClass">
        <button
            class="truncate font-sans font-bold duration-100 transition-all px-4 py-2.5 w-full"
            :class="[buttonRounded, classes]"
            @click.stop="click"
        >
            <span>
                <slot>ButtonLabel missing</slot>
            </span>
        </button>
    </div>
</template>

<script>
export default {
    methods: {
        click() {
            if (!this.disabled) {
                this.$emit("click");
            }
        },
    },
    computed: {
        classes() {
            if (this.buttonClasses !== null) {
                return [this.buttonClasses];
            }
            let classes = [];
            classes.push("whitespace-nowrap");
            if (this.color === "darkgreen") {
                classes.push("bg-r-darkgreen", "text-white");
            }
            if (this.color === "green") {
                classes.push("bg-r-green", "text-white");
            }
            if (this.color === "red") {
                classes.push("bg-r-red", "text-white");
            }
            if (this.color === "gray") {
                classes.push("bg-r-gray", "text-white");
            }
            if (this.disabled) {
                classes.push("opacity-50");
                classes.push("cursor-not-allowed");
            }

            if (this.color == "transparent") {
                let classes = [];
                classes.push("text-white");
            }
            if (!this.disabled) {
                classes.push(this.opacity);
            }
            return classes;
        },
    },
    props: {
        size: {
            required: false,
            default: "base",
        },
        color: {
            required: false,
            default: "green",
        },
        disabled: {
            required: false,
            default: false,
        },
        buttonClasses: {
            required: false,
            default: null,
        },
        buttonRounded: {
            required: false,
            default: "rounded-lg",
        },
        containerClass: {
            required: false,
            default: "w-full",
        },
        opacity: {
            required: false,
            default: "opacity-100",
        },
    },
};
</script>

<style></style>
