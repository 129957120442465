<template>
    <div class="flex flex-col gap-10">
        <div class="w-full md:w-4/5 flex space-y-10 flex-col text-xl">
            <div class="text-2xl md:text-3xl font-black italic text-r-green">
                {{ $t("reservations.checkin_balance_deny.lead") }}
            </div>
            <div>
                {{ $t("reservations.checkin_balance_deny.salutation") }}
            </div>
            <div>
                {{ $t("reservations.checkin_balance_deny.failed") }}
            </div>
            <div>
                {{ $t("reservations.checkin_balance_deny.contact_times") }}
            </div>
            <div class="flex flex-col space-y-3">
                <div>0800 80 111 08</div>
                <div>+49 211 21092000</div>
                <div>
                    <a
                        class="text-white underline"
                        href="mailto:support@roatel.com"
                        >support@roatel.de</a
                    >
                </div>
            </div>
            <div>
                {{ $t("reservations.checkin_balance_deny.thank_you") }}
            </div>
        </div>
    </div>
</template>

<script>
import ClipContainer from "@guest/common/ClipContainer";
import AppButton from "@guest/common/Button";
import DayJS from "@mixins/DayJS";
import { mapActions } from "vuex";

export default {
    mixins: [DayJS],
    components: {
        ClipContainer,
        AppButton,
    },
    props: {
        reservation: {
            required: true,
            type: Object,
        },
        reservationQueryPayload: {
            required: true,
        },
    },
    computed: {
        checkInToday() {
            return this.dayjs(
                this.reservation.earliest_check_in_time
            ).isToday();
        },
        // checkIn() {
        //     return this.dayjs().isAfter(this.reservation.arrival);
        // },
        checkInEnabled() {
            return (
                this.reservation.status == "Confirmed" &&
                this.dayjs().isAfter(this.reservation.earliest_check_in_time)
            );
        },
    },
    methods: {
        ...mapActions({
            // getUserReservation: "getUserReservation",
            getUserReservationCanCheckIn: "getUserReservationCanCheckIn",
            // setReturnToPath: "setReturnToPath",
            // setLocale: "setLocale",
            // putUserReservationCancel: "putUserReservationCancel",
        }),
        canCheckIn() {
            console.log("can check in");
            this.getUserReservationCanCheckIn(this.reservationQueryPayload)
                .then((response) => {
                    this.$router.push({
                        name: "bookings.edit.checkin.name",
                        params: this.reservationQueryPayload,
                    });
                })
                .catch((error) => {
                    console.log(error.response);
                });
        },
    },
};
</script>

<style></style>
