<template>
    <div class="flex flex-col mt-4"
         :class="borderClasses"
        >
        <div class="flex flex-col w-full"
            >
            <div v-if="$slots.header" class="text-lg font-medium border-b border-gray-200" :class="contentClass">
                <slot name="header"></slot>
            </div>
            <div
                class="content-container"
                :class="contentClass"
                >
                <slot></slot>
            </div>
            <div v-if="$slots.footer" class="border-t border-gray-200 bg-gray-50" :class="contentClass">
                <slot name="footer"></slot>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    computed: {
        borderClasses() {
            if (this.border) {
                return 'overflow-hidden rounded shadow border-gray-200'
            }
            return ''

        },
        //contentPaddingX() {
            //switch (this.contentPx) {
                //case '1':
                    //return 'px-1'
                    //break;
                //case '2':
                    //return 'px-2'
                    //break;
                //case '3':
                    //return 'px-3'
                    //break;
                //case '4':
                    //return 'px-4'
                    //break;
                //case '5':
                    //return 'px-5'
                    //break;
                //default:
                    //return 'px-5'
            //}
        //},
        //contentPaddingY() {
            //switch (this.contentPy) {
                //case '1':
                    //return 'py-1'
                    //break;
                //case '2':
                    //return 'py-2'
                    //break;
                //case '3':
                    //return 'py-3'
                    //break;
                //case '4':
                    //return 'py-4'
                    //break;
                //case '5':
                    //return 'py-5'
                    //break;
                //default:
                    //return 'py-5'
            //}
        //},
    },
    props: {
        contentClass: {
            required: false,
            default: 'py-4 px-4 md:py-8 md:px-10'
        },
        border: {
            required: false,
            default: false,
        }
    }

}
</script>

<style>

</style>
