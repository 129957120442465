<template>
    <div
        v-if="currentReservation !== null"
        @click="openLock(currentReservation)"
        class="text-2xl text-gray-200 cursor-pointer hover:text-green-100">
        <font-awesome-icon icon="key"></font-awesome-icon>
        <span class="hidden md:inline-flex">{{currentReservation.unit.name}} öffnen</span>
    </div>
</template>

<script>

import {mapState, mapGetters, mapActions} from 'vuex'
import OpenLock from '@mixins/OpenLock'

export default {
    mixins: [
        OpenLock
    ],
    computed: {
        ...mapState({
            currentReservation: state => state.currentReservation,
        }),
    },
    methods: {
    },
    mounted() {
        console.log(this.currentReservation)
    }

}
</script>

<style>

</style>
