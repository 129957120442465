require("./bootstrap");

import Vue from "vue";
import Vuex from "vuex";
import GuestApp from "@guest/layout/App";
import VueRouter from "vue-router";
import VCalendar from "v-calendar";
import VueGTag from "vue-gtag";
import VueTelInput from "vue-tel-input";
import VueCarousel from "vue-carousel";
import "vue-tel-input/dist/vue-tel-input.css";

import VueI18n from "vue-i18n";
Vue.use(VueI18n);
Vue.use(VueTelInput);
import { i18n } from "./guest/lang/i18n.js";

const lang = new VueI18n({
    locale: "de",
    messages: i18n,
    fallbackLocale: "de",
});

Vue.use(VueRouter);

import guest_routes from "@/guest/routes/index";

require("./fontawesome");

const routes = [...guest_routes];

const router = new VueRouter({
    mode: "history",
    base: "/guest",
    routes,
});

router.afterEach((to, from) => {
    store.dispatch("setShowHamburgerMenu", false);
});

import navigation from "@/guest/routes/navigation";
import axios from "axios";

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.MIX_API_BASEURL_GUEST;

// Axios interceptor for timed out session
axios.interceptors.response.use(
    (response) => {
        console.log("interceptor response", response.data);
        return response;
    },
    (error) => {
        console.error("interceptor error", error.response);
        console.log("error.request", error.request);
        if (error.response.status === 403 || error.response.status === 401) {
            window.guest.$store.dispatch(
                "setReturnToPath",
                window.guest.$route
            );
            window.guest.$router.push({ name: "login" });
            window.guest.$store.dispatch("getProfile");
        }
        return Promise.reject(error);
    }
);

import dayjs from "dayjs";
require("dayjs/locale/de");
require("dayjs/locale/en");
require("dayjs/locale/tr");
require("dayjs/locale/fr");
require("dayjs/locale/es");
require("dayjs/locale/pl");
require("dayjs/locale/nl");
require("dayjs/locale/pt");
require("dayjs/locale/br");
require("dayjs/locale/it");
require("dayjs/locale/bg");
require("dayjs/locale/ru");

var relativeTime = require("dayjs/plugin/relativeTime");
var localizedFormat = require("dayjs/plugin/localizedFormat");
var isSameOrAfter = require("dayjs/plugin/isSameOrAfter");
var isSameOrBefore = require("dayjs/plugin/isSameOrBefore");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone"); // dependent on utc plugin
var isToday = require("dayjs/plugin/isToday"); // dependent on utc plugin
var localeData = require("dayjs/plugin/localeData"); // dependent on utc plugin
var customParseFormat = require("dayjs/plugin/customParseFormat"); // dependent on utc plugin
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.locale("de");
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(isToday);
dayjs.extend(localeData);
dayjs.extend(customParseFormat);
dayjs.tz.setDefault("Europe/Berlin");

Vue.prototype.dayjs = dayjs;

import VueRx from "vue-rx";
import VuejsClipper from "vuejs-clipper";
import VueSignaturePad from "vue-signature-pad";

// install vue-rx
Vue.use(VueRx);
Vue.use(VuejsClipper);
Vue.use(VueSignaturePad);
Vue.use(VCalendar, {
    // locale: 'de-DE',
});
var VueScrollTo = require("vue-scrollto");
Vue.use(VueScrollTo);

Vue.use(Vuex);
Vue.use(
    VueGTag,
    {
        config: {
            id: "G-FWTS4GWX4X",
        },
        bootstrap: false,
    },
    router
);

Vue.use(VueCarousel);

import ApaleoTranslate from "@mixins/ApaleoTranslate";
Vue.mixin(ApaleoTranslate);

import store from "./guest/store";
store.dispatch("getCsrfCookie");

// require("./fb_pixel");

window.guest = new Vue({
    el: "#app",
    store,
    i18n: lang,
    router,
    components: {
        GuestApp,
    },
});
